import {FilterType} from '../../components/FilterForm/types'
import {buildFilter} from '../../components/FilterForm/utils'
import {EventType} from './types'

export const CalendarFilter = buildFilter({
    start__lte: {filterType:FilterType.DATE, label: 'End of the month', excludeFromForm: true},
    stop__gte: {filterType:FilterType.DATE, label: 'First day of the month', excludeFromForm: true},
    moment__lte: {filterType:FilterType.DATE, label: 'End of the month', excludeFromForm: true},
    moment__gte: {filterType:FilterType.DATE, label: 'First day of the month', excludeFromForm: true},
    resources: {
        filterType: FilterType.SELECT,
        label: 'Include',
        options: [{value: EventType.TASK, label: 'Sarcini'}, {value: EventType.HEARING, label: 'Termene'}],
        defaultValue: [{value: EventType.TASK, label: 'Sarcini'}, {value: EventType.HEARING, label: 'Termene'}],
        isMulti: true
    }
})