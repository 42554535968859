import {push} from 'connected-react-router'
import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'

import {setCurrent} from 'avoapp-react-common/dist/redux/projects'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal} from './modals'
import {closeSlideover} from './slideovers'

const {projects: resource} = RESOURCES

function* handleCreateProjectSuccess(response) {
    toast.success('Proiectul a fost creat cu succes')

    yield put(push(`/projects/${response.payload.id}`))
}

function handleCreateProjectFail() {
    toast.error('Proiectul nu a putut fi creat')
}

function* handleUpdateProjectSuccess() {
    toast.success('Modificările au fost salvate')

    yield put(closeSlideover())
    yield put(closeModal())
}

function* handleRetrieveProjectSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleDestroyProjectSuccess() {
    toast.success('Proiectul a fost șters')

    yield put(closeModal())
    yield put(push('/projects'))
}

function* handleDestroyProjectFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveProjectSuccess)
    yield takeEvery(resource.actions.create.success, handleCreateProjectSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreateProjectFail)
    yield takeEvery(resource.actions.update.success, handleUpdateProjectSuccess)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveProjectSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyProjectSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyProjectFail)
}
