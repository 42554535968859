import React, {useState} from 'react'
import {Select} from '../../../../components/Select'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {useDebouncedAsyncEffect} from '../../../../utils/hooks'
import {connect} from 'react-redux'

import './TimeLogFilters.scss'
import {DatePicker} from '../../../../components/DatePicker'
import {endOfMonth, startOfMonth} from 'date-fns'
import {isNil} from 'lodash'
import {toast} from 'react-toastify'

const TimeLogFilters = ({
    selectedEntityID,
    onStartDateChange,
    onStopDateChange,
    onClientIDChange,
    onProjectIDChange,
    onContractIDChange,
    onUserIDChange,
    onIsTaskCompletedChange
}) => {
    const [startDate, setStartDate] = useState(startOfMonth(new Date()))
    const [stopDate, setStopDate] = useState(endOfMonth(new Date()))
    const [isTaskCompleted, setIsTaskCompleted] = useState(null)

    const [selectedClient, setSelectedClient] = useState(null)
    const [clients, setClients] = useState([])
    const [clientFilter, setClientFilter] = useState('')
    const [isLoadingClients, setIsLoadingClients] = useState(false)

    const [selectedProject, setSelectedProject] = useState(null)
    const [projects, setProjects] = useState([])
    const [projectFilter, setProjectFilter] = useState('')
    const [isLoadingProjects, setIsLoadingProjects] = useState(false)

    const [selectedContract, setSelectedContract] = useState(null)
    const [contracts, setContracts] = useState([])
    const [contractFilter, setContractFilter] = useState('')
    const [isLoadingContracts, setIsLoadingContracts] = useState(false)

    const [selectedUser, setSelectedUser] = useState(null)
    const [users, setUsers] = useState([])
    const [userFilter, setUserFilter] = useState('')
    const [isLoadingUsers, setIsLoadingUsers] = useState(false)

    const fetchClients = async () => {
        setIsLoadingClients(true)
        const {data} = await performRequest(RESOURCES.clients.list({entity_id: selectedEntityID, search: clientFilter}))
        setIsLoadingClients(false)
        setClients(data.results)
    }

    const fetchProjects = async () => {
        if(!selectedClient) {
            return
        }

        setIsLoadingProjects(true)
        const {data} = await performRequest(
            RESOURCES_V2.projects.list({
                entity_id: selectedEntityID,
                client_id: selectedClient?.id || null,
                search: projectFilter
            })
        )
        setIsLoadingProjects(false)
        setProjects(data.results)
    }

    const fetchContracts = async () => {
        if(!selectedProject) {
            return
        }

        setIsLoadingContracts(true)
        const {data} = await performRequest(
            RESOURCES.contracts.list({
                entity_id: selectedEntityID,
                project_id: selectedProject?.id || null,
                search: contractFilter
            })
        )
        setIsLoadingContracts(false)
        setContracts(data.results)
    }

    const fetchUsers = async () => {
        setIsLoadingUsers(true)
        const {data} = await performRequest(
            RESOURCES.entityProfiles.list({entity_id: selectedEntityID, search: userFilter})
        )
        setIsLoadingUsers(false)
        setUsers(data.results)
    }

    useDebouncedAsyncEffect(fetchClients, [clientFilter], 350)
    useDebouncedAsyncEffect(fetchProjects, [projectFilter, selectedClient], 350)
    useDebouncedAsyncEffect(fetchContracts, [contractFilter, selectedProject], 350)
    useDebouncedAsyncEffect(fetchUsers, [userFilter], 350)

    return (
        <div className="reports-filters-form-container">
            <div className="filters-form-row">
                <DatePicker
                    style={{maxWidth: '100px'}}
                    label='Dată start'
                    name='startDate'
                    value={startDate}
                    onChange={(date) => {
                        if (date > stopDate) {
                            toast.error(
                                'Data de început nu poate sa depășească data de sfârșit.'
                            )
                            return
                        }

                        setStartDate(date)
                        onStartDateChange(date)
                    }}
                />
                <DatePicker
                    style={{maxWidth: '100px'}}
                    label='Dată stop'
                    name='stopDate'
                    value={stopDate}
                    onChange={(date) => {
                        if (date < startDate) {
                            toast.error(
                                'Data de început nu poate sa depășească data de sfârșit.'
                            )
                            return
                        }

                        setStopDate(date)
                        onStopDateChange(date)
                    }}
                />
                <Select
                    placeholder='Toti clienții'
                    label='Client'
                    value={selectedClient}
                    options={clients}
                    isClearable
                    loading={isLoadingClients}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(option) => {
                        setSelectedProject(null)
                        setSelectedContract(null)
                        onProjectIDChange(null)
                        onContractIDChange(null)

                        if (option) {
                            setSelectedClient(option)
                            onClientIDChange(option.id)
                        }
                        else{
                            setSelectedClient(null)
                            onClientIDChange(null)
                        }
                    }}
                    onInputChange={(value) => {
                        setClientFilter(value)
                    }}
                    disabled={false}
                    fullWidth
                />
                <Select
                    placeholder='Toate proiectele'
                    label='Project'
                    value={selectedProject}
                    isClearable
                    options={projects}
                    loading={isLoadingProjects}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(option) => {
                        setSelectedContract(null)
                        onContractIDChange(null)

                        if (option) {
                            setSelectedProject(option)
                            onProjectIDChange(option.id)
                        }
                        else{
                            setSelectedProject(null)
                            onProjectIDChange(null)
                        }
                    }}
                    onInputChange={(value) => {
                        setProjectFilter(value)
                    }}
                    disabled={isNil(selectedClient)}
                    fullWidth
                />
                <Select
                    placeholder='Toate contractele'
                    label='Contract'
                    value={selectedContract}
                    options={contracts}
                    loading={isLoadingContracts}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(option) => {
                        if (option) {
                            setSelectedContract(option)
                            onContractIDChange(option.id)
                        }
                        else{
                            setSelectedContract(null)
                            onContractIDChange(null)
                        }
                    }}
                    onInputChange={(value) => {
                        setContractFilter(value)
                    }}
                    disabled={isNil(selectedProject) || isNil(selectedClient)}
                    isClearable
                    fullWidth
                />
                <Select
                    placeholder='Toți utilizatorii'
                    label='Utilizatori'
                    value={selectedUser}
                    options={users}
                    loading={isLoadingUsers}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.full_name}
                    onChange={(option) => {
                        if (option) {
                            setSelectedUser(option)
                            onUserIDChange(option.id)
                        }
                        else{
                            setSelectedUser(null)
                            onUserIDChange(null)
                        }
                    }}
                    onInputChange={(value) => {
                        setUserFilter(value)
                    }}
                    isClearable
                    fullWidth
                />
                <Select
                    placeholder='Orice status'
                    label='Status sarcină'
                    value={isTaskCompleted}
                    options={[{label: 'Finalizată', value: true}, {label: 'Nefinalizată', value: false}]}
                    onChange={(option) => {
                        if (option) {
                            setIsTaskCompleted(option)
                            onIsTaskCompletedChange(option.value)
                        }
                        else{
                            setIsTaskCompleted(null)
                            onIsTaskCompletedChange(null)
                        }
                    }}
                    isClearable
                    fullWidth
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(TimeLogFilters)
