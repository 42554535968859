import {BlobServiceClient} from '@azure/storage-blob'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {getEntityAzureSAS} from 'avoapp-react-common/dist/redux/entities'
import {toast} from 'react-toastify'

export const createBlobInContainer = async (file, uploadURL, containerName) => {
    const blobService = new BlobServiceClient(uploadURL)
    const containerClient = blobService.getContainerClient(containerName)

    const blobClient = containerClient.getBlockBlobClient(file.name)
    const options = {blobHTTPHeaders: {blobContentType: file.type}}

    await blobClient.uploadData(file, options)
    return blobClient.url
}

export const getTokenAndUploadFile = async (file, entityID) => {
    const response = await performRequest(getEntityAzureSAS(entityID))
    if(response.errors) {
        toast.error('A apărut o eroare, te rugăm să încerci din nou. ' +
                                'Dacă eroarea persistă, te rugăm să ne contactezi!')
        return
    }
    const fileURL = await createBlobInContainer(
        file,
        response.data.upload_url,
        response.data.container_name
    ).catch((e) => {
        toast.error('A apărut o eroare, te rugăm să încerci din nou. ' +
                                'Dacă eroarea persistă, te rugăm să ne contactezi!')
        console.error(e)
        return null
    })

    return fileURL
}
