import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeletePartySubscriptionModal({open, partySubscription, isLoading, deletePartySubscription, closeModal}) {
    return (
        <Modal open={open && !isEmpty(partySubscription)} title='Șterge abonarea'>
            {!isEmpty(partySubscription) && (
                <div className='delete-modal'>
                    <p className='message'>
                        Ești sigur că vrei să ștergi abonarea de date pentru{' '}
                        <span>"{partySubscription.party_name}"</span>?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            variant='outlined'
                            loading={isLoading}
                            color='gray'
                        />
                        <Button
                            title='Da, șterge'
                            onClick={() => deletePartySubscription(partySubscription)}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_PARTY_SUBSCRIPTION,
    partySubscription: state.partySubscriptions.currentPartySubscription,
    isLoading: state.partySubscriptions.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deletePartySubscription: (partySubscription) => dispatch(RESOURCES.partySubscriptions.destroy(partySubscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeletePartySubscriptionModal)