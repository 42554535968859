import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {includes, values as _values, filter, debounce} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {slideoverTypes} from '../../../../redux/slideovers'

import {debounceWait} from '../../../../utils/constants'

import {Select} from '../../../../components/Select'
import {Button} from '../../../../components/Button'
import {Slideover} from '../../../../components/Slideover'
import {ErrorsList} from '../../../../components/ErrorComponents'

import '../../../../assets/scss/SlideoverForms.scss'

export class EditContractLocationsSlideover extends Component {
    constructor() {
        super()

        this.state = {
            projectsQuery: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const{contractID, retrieveContract} = this.props

        if(contractID && contractID !== prevProps.contractID) {
            retrieveContract(contractID)
            this.handleFetchProjects()
        }
    }

    handleFetchProjects = (query = this.state.projectsQuery) => {
        const {searchProjects} = this.props

        searchProjects(query)
    }

    debounceSearchProjects = debounce(this.handleFetchProjects, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({projectsQuery: value})
        this.debounceSearchProjects(value)
    }

    getInitialLocationsArray = () => {
        const {contract, projects} = this.props

        return filter(projects, (project) => includes(contract.projects_ids, parseInt(project.id)))
    }

    render() {
        const {

            open,
            contractID,
            isLoading,
            fieldErrors,
            nonFieldErrors,
            projects,
            isLoadingProjects,
            updateContract
        } = this.props

        return (
            <Slideover open={open} title='Editează locații contract'>
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{projectsIds: this.getInitialLocationsArray()}}
                        onSubmit={(values) => {
                            updateContract(
                                {
                                    projects_ids: values.projectsIds.map((project) => project.id)
                                },
                                contractID
                            )
                        }}
                    >
                        {({handleBlur, setFieldValue, handleSubmit, values, touched}) => (
                            <>
                                <Form className='slideover-form'>
                                    <Select
                                        label='Proiecte'
                                        value={values.projectsIds}
                                        placeholder='Alege un proiect'
                                        options={projects}
                                        onChange={(option) => setFieldValue('projectsIds', option)}
                                        onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                        onBlur={handleBlur('projectsIds')}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        name='projectsIds'
                                        errors={fieldErrors}
                                        touched={touched.projectsIds}
                                        loading={isLoadingProjects}
                                        isMulti
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title='Salvează'
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        type='submit'
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </Slideover>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_CONTRACT_LOCATIONS,
    contract: state.contracts.currentContract,
    projects: _values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    client: state.clients.currentClient,
    isLoading: state.contracts.isLoading,
    fieldErrors: state.contracts.fieldErrors,
    nonFieldErrors: state.contracts.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID)),
    updateContract: (contractData, contractID) => dispatch(RESOURCES.contracts.update(contractData, contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContractLocationsSlideover)