import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes, openModal} from './modals'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {createSmartBillInvoicePayment} from 'avoapp-react-common/dist/redux/smartbill'
import _ from 'lodash'
import {store} from './store'
import {externalSyncActions} from '../pages/Invoices/constants'

const {invoicePayments: resource} = RESOURCES

function* handleCreateSuccess({payload: invoicePayment}) {
    toast.success('Încasarea a fost adaugată cu succes.')
    yield put(closeModal())

    performRequest(createSmartBillInvoicePayment(invoicePayment.id)).then((response) => {
        const {errors, status} = response
        if (status === 403) {
            // SmartBill is not enabled for this entity. Missing API key.
            return
        }

        if (status >= 400) {
            const errorMessage = _.get(
                errors, 'non_field_errors.0.message', 'A apărut o eroare la sincronizarea cu SmartBill'
            )
            store.dispatch(openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {
                action: externalSyncActions.CREATE,
                errorMessage: errorMessage
            }))
        }
        else {
            toast('Documentul a fost sincronizat cu succes în SmartBill', {type: 'success'})
            store.dispatch(RESOURCES.invoicePayments.retrieve(invoicePayment.id))
        }
    })
}

function* handleDestroyInvoiceSuccess() {
    toast.success('Încasarea a fost ștearsă')
    yield put(closeModal())
}

function* handleDestroyInvoiceFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyInvoiceSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyInvoiceFail)
}
