import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../redux/modals'

import {Modal} from '../../../../components/Modal'
import {Button} from '../../../../components/Button'

function DeleteClientGroupModal({open, clientGroup, isLoading, closeModal, deleteClientGroup}) {
    return (
        <Modal open={open && !isEmpty(clientGroup)} title='Șterge grup clienți'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi grupul de clienți{' '}
                    <span>"{clientGroup.name}"</span>?
                </p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteClientGroup(clientGroup)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_CLIENT_GROUP,
    clientGroup: state.clientGroups.currentClientGroup,
    isLoading: state.clientGroups.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteClientGroup: (clientGroup) => dispatch(RESOURCES.clientGroups.destroy(clientGroup))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientGroupModal)