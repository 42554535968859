import * as Yup from 'yup'

export const validationSchema = {
    create: Yup.object().shape({
        contractId: Yup.object().label('Contract').nullable().required('Contractul este obligatoriu!'),
        templateId: Yup.object().label('Șablon').nullable().required('Șablonul este obligatoriu!'),
        name: Yup.string().label('Nume act adițional').required('Numele împuternicirii este obligatoriu!'),
        content: Yup.string().label('Conținut').required('Conținutul împuternicirii este obligatoriu!'),
        internalSeriesId: Yup.object().label('Serie internă').required('Seria internă este obligatorie!'),
        barSeriesId: Yup.object().label('Serie barou').required('Seria barou este obligatorie!')
    }),
    update: Yup.object().shape({
        name: Yup.string().label('Nume act adițional').required('Numele împuternicirii este obligatoriu!'),
        content: Yup.string().label('Conținut').required('Conținutul împuternicirii este obligatoriu!')
    })
}
