import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {findLast, startsWith, endsWith, isUndefined, head} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal, modalTypes, openModal} from './modals'

const {contracts: resource} = RESOURCES

function* handleCreateContractSuccess() {
    toast.success('Contractul a fost adăugat cu succes')

    const sourceLocation = yield select((state) => findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts/contracts' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/contracts-contracts'))
    ))

    yield put(push(!isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts/contracts'))
}

function* handleUpdateContractSuccess() {
    const currentLocationPathname = head(yield select((state) => state.routerLocations)).location.pathname

    if(startsWith(currentLocationPathname, '/contracts') && endsWith(currentLocationPathname, '/settings')) {
        yield put(openModal(modalTypes.EDIT_CONTRACT_SETTINGS_DISCLAIMER))
    } else {
        yield put(closeModal())
    }

    toast.success('Modificările au fost salvate cu succes')
}

function handleUpdateContractFail() {
    toast.error('Modificările NU au putut fi salvate. Te rugam să încerci din nou!')
}

function* handleDestroyContractSuccess() {
    toast.success('Contractul a fost șters cu succes')

    const sourceLocation = yield select((state) => findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/contracts-contracts'))
    ))

    yield put(push(!isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts'))
    yield put(closeModal())
}

function handleDestroyContractFail() {
    toast.error('Contractul NU a putut fi șters. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateContractSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateContractSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateContractFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyContractSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyContractFail)
}
