import React from 'react'
import {CheckCircleIcon} from '@heroicons/react/24/outline'

import './CheckCell.scss'

export default function CheckCell() {
    return (
        <CheckCircleIcon className='check-cell'/>
    )
}
