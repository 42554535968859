import React, {useEffect, useMemo, useState} from 'react'

import _ from 'lodash'

import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../../../redux/modals'

import {AppealForm} from 'components/AddMDManualSubscriptionModal'
// @ts-ignore
import {Modal} from '../../../../components/Modal'
// @ts-ignore
import {Select} from '../../../../components/Select'

type AddAppealModalProps = {
    open: boolean,
    subscription: any
}

export const AddAppealModal = ({open, subscription}: AddAppealModalProps) => {
    const [selectedPhase, setSelectedPhase] = useState<any>(undefined)

    useEffect(() => {
        if(!open) {
            setSelectedPhase(undefined)
        }
    }, [open])

    const hasSubscriptionMultiplePhases = useMemo(() => {
        return subscription.litigation.phases.length > 1
    }, [subscription])

    useEffect(() => {
        if(!hasSubscriptionMultiplePhases && _.isNil(selectedPhase)) {
            setSelectedPhase(subscription.litigation.phases[0])
        }
    }, [hasSubscriptionMultiplePhases, selectedPhase, subscription.litigation.phases])

    if(!subscription){
        return <React.Fragment />
    }

    return (
        <Modal open={open} title='Adaugă cale de atac'>
            {hasSubscriptionMultiplePhases && (
                <Select
                    label='Selectează faza pentru care dorești să adaugi o parte*'
                    value={selectedPhase}
                    options={subscription.litigation.phases}
                    getOptionValue={(option: any) => option.id}
                    getOptionLabel={(option: any) => option.phase}
                    onChange={(e: any) => setSelectedPhase(e)}
                    fullWidth
                />
            )}
            {selectedPhase && (
                <AppealForm currentPhase={selectedPhase}/>
            )}
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_APPEAL_MODAL
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAppealModal)