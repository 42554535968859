import React from 'react'

import {Switch} from '@headlessui/react'

import {camelCase, head, isEmpty, snakeCase} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'
import {ToolTip} from '../ToolTip'

import './Toggle.scss'

export default function Toggle({
    checked,
    onChange,
    onBlur,
    label,
    fullWidth,
    name,
    errors,
    frontendErrors,
    touched,
    disabled,
    tooltipText
}) {
    const snakeCaseName = snakeCase(name)
    const camelCaseName = camelCase(name)

    const hasErrors =
        (errors && !isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div
            className={`toggle-label-container ${tooltipText ? 'tooltip': ''} ${fullWidth ? 'full-width' : ''}`}>
            {label ? (
                <label as="span" className="toggle-label">
                    {label}
                </label>
            ) : null}
            <Switch
                checked={checked}
                disabled={disabled || false}
                onChange={onChange}
                onBlur={onBlur}
                className={`toggle-container ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
            >
                <span className='toggle bg-gray-300'>
                    {tooltipText && <ToolTip text={tooltipText} style={{top: '-35px', left: '-90px'}} />}
                    <span className='dot-container dot-disabled-container' aria-hidden="true">
                        <svg className="dot" fill="none" viewBox="0 0 12 12">
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                    <span className='dot-container dot-checked-container' aria-hidden="true">
                        <svg className="dot" fill="currentColor" viewBox="0 0 12 12">
                            {/* eslint-disable-next-line max-len  */}
                            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                    </span>
                </span>
            </Switch>
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
