import {isUndefined} from 'lodash'

export class Filter {
    constructor(key, displayKey) {
        this.key = key
        this.displayKey = displayKey ? displayKey : key
        this.value = ''
        this.displayValue = ''
    }

    setValue(value, displayValue) {
        this.value = value
        this.displayValue = !isUndefined(displayValue) ? displayValue : value
    }

    unsetValue() {
        this.value = ''
        this.displayValue = ''
    }
}
