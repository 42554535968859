import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../../redux/modals'

import {Modal} from '../../../../../../components/Modal'
import {Button} from '../../../../../../components/Button'

import '../../../../../../assets/scss/DeleteModals.scss'

export const DeleteDocumentTemplateModal = ({open, documentTeamplate, isLoading, closeModal, deleteProject}) => {
    return (
        <Modal open={open && !isEmpty(documentTeamplate)} title='Șterge șablon'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi șablonul "<span>{documentTeamplate.name}</span>"?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteProject(documentTeamplate)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_DOCUMENT_TEMPLATE,
    documentTeamplate: state.documentTemplates.currentDocumentTemplate,
    isLoading: state.documentTemplates.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteProject: (documentTeamplate) => dispatch(RESOURCES.documentTemplates.destroy(documentTeamplate))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentTemplateModal)