import {toast} from 'react-toastify'
import {takeEvery, put} from 'redux-saga/effects'

import {ACTIONS as PROJECT_NOTES_ACTIONS} from 'avoapp-react-common/dist/redux/projectNotes'

import {closeModal} from './modals'

function handleCreateProjectNoteSuccess() {
    toast.success('Observația a fost adăugată cu succes!')
}

function* handleDeleteProjectNoteSuccess() {
    toast.success('Observația a fost ștearsă cu succes!')
    yield put(closeModal())
}

function* handleDeleteProjectNoteFail() {
    toast.error('Observația NU a putut fi ștearsă! Vă rugăm să incercați din nou!')
    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(PROJECT_NOTES_ACTIONS.CREATE_PROJECT_NOTE.success, handleCreateProjectNoteSuccess)
    yield takeEvery(PROJECT_NOTES_ACTIONS.DESTROY_PROJECT_NOTE.success, handleDeleteProjectNoteSuccess)
    yield takeEvery(PROJECT_NOTES_ACTIONS.DESTROY_PROJECT_NOTE.fail, handleDeleteProjectNoteFail)
}
