
import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

import {head} from 'lodash'

import {Button} from '../../../../../../components/Button'

export default function UploadTemplateFileButton({onChange}) {
    const onDrop = useCallback((acceptedFiles) => {
        onChange(head(acceptedFiles))
    }, [onChange])

    const {getRootProps, getInputProps, open} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
                title='Încarcă'
                onClick={open}
                color='orange'
                variant='text'
                size='small'
            />
        </div>
    )
}
