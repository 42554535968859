import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {sourceTypes} from '../../../../utils/types'

import {CheckCell} from '../../../../components/CheckCell'

export const mdPartySubscriptionsColumns = [
    {
        Header: 'Cuvânt cheie',
        accessor: 'party_name',
        Cell: ({value}) => `"${value}"`
    },
    {
        Header: 'Sursa',
        accessor: 'source',
        Cell: ({value: source}) => sourceTypes[_.toUpper(source)] || '-'
    },
    {
        Header: 'Instituția',
        accessor: 'institution'
    },
    {
        Header: 'Ultima sincronizare',
        accessor: 'sync_moment',
        Cell: ({value}) => lightFormat(new Date(value), 'dd/MM/yyyy HH:mm')
    }
]

export const mdColumns = [
    {
        Header: 'Număr dosar',
        accessor: 'litigation_number'
    },
    {
        Header: 'Sursa',
        accessor: 'source',
        Cell: ({value: source}) => sourceTypes[_.toUpper(source)] || '-'

    }
]

export const bpiColumns = [
    {
        Header: 'Nume companie',
        accessor: 'company_name'
    },
    {
        Header: 'CUI',
        accessor: 'vat_number'
    },
    {
        Header: 'Activ',
        accessor: 'active',
        Cell: ({value}) => value && <CheckCell />
    },
    {
        Header: 'Ultima actualizare',
        accessor: 'latest_update',
        Cell: ({value}) => lightFormat(new Date(value), 'dd/MM/yyyy HH:mm')
    }
]
