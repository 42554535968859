import {toast} from 'react-toastify'
import {takeEvery, put} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeSlideover} from './slideovers'

const {bpiSubscriptions: resource} = RESOURCES

function* handleUpdateBpiSubscriptionSuccess() {
    toast.success('Modificările au fost salvate')
    yield put(closeSlideover())
}

export function* saga() {
    yield takeEvery(resource.actions.update.success, handleUpdateBpiSubscriptionSuccess)
}
