import React, {useCallback, useMemo} from 'react'

import {useFormik} from 'formik'

import _ from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {Select} from '../Select'

import {RON_CURRENCY_VALUE} from '../../utils/constants'
import {calcMethods, discountTypes, unitOptions} from '../../utils/types'

import './AddDiscountModal.scss'

export const AddDiscountModal = ({
    open,
    closeModal,
    entries,
    invoiceTotal,
    invoiceCurrency,
    invoiceExchangeRate,
    handleAddDiscount
}) => {
    const availableEntries = useMemo(() => {
        return _.filter(entries, (entry) => {
            return !entry.isDiscount && !_.some(entries, ['connectedEntry', entry])
        })
    }, [entries])

    const getDiscountName = useCallback((values) => {
        let name = 'Discount'

        if(values.calcMethod === calcMethods.PERCENT) {
            name = `${name} ${values.value}%`
        }

        if(values.discountType === discountTypes.ENTRY) {
            name = `${name} ${values.entry.name}`
        }

        if(values.calcMethod !== calcMethods.PERCENT && values.discountType !== discountTypes.ENTRY) {
            name = `${name} Produse/Servicii`
        }

        return name
    }, [])

    const getDiscountPrice = useCallback((values) => {
        let price
        let priceRON

        if(values.calcMethod === calcMethods.SUM) {
            price = values.value * -1
        } else {
            let total

            if(values.discountType === discountTypes.INVOICE) {
                total = invoiceCurrency.value === RON_CURRENCY_VALUE ?
                    invoiceTotal.totalWithoutTaxRON :
                    invoiceTotal.totalWithoutTax
            } else {
                total = invoiceCurrency.value === RON_CURRENCY_VALUE ?
                    values.entry.totalRON :
                    values.entry.total
            }

            price = values.value / 100 * total * -1
        }

        if(invoiceCurrency.value === RON_CURRENCY_VALUE) {
            priceRON = price
        } else {
            const exchRate = values.discountType === discountTypes.INVOICE ?
                invoiceExchangeRate :
                values.entry.exchangeRate

            priceRON = _.round(price * exchRate, 2)
        }

        return {price, priceRON}
    }, [invoiceCurrency.value, invoiceExchangeRate, invoiceTotal.totalWithoutTax, invoiceTotal.totalWithoutTaxRON])

    const {values, handleChange, setFieldValue, handleSubmit, handleReset} = useFormik({
        initialValues: {
            discountType: discountTypes.INVOICE,
            entry: null,
            calcMethod: calcMethods.PERCENT,
            value: ''
        },
        onSubmit: (values) => {
            const {price, priceRON} = getDiscountPrice(values)

            let discountData ={
                name: getDiscountName(values),
                unit: unitOptions.BUC,
                quantity: 1,
                currency: invoiceCurrency,
                tax: _.head(entries).tax,
                isDiscount: true,
                connectedEntry: values.entry,
                price,
                priceRON,
                total: price,
                totalRON: priceRON
            }

            let index = entries.length

            if(values.discountType === discountTypes.ENTRY) {
                index = _.findIndex(entries, values.entry) + 1
                const totalKey = invoiceCurrency.value === RON_CURRENCY_VALUE ? 'totalRON' :'total'

                if(values.calcMethod === calcMethods.PERCENT) {
                    discountData = {
                        ...discountData,
                        name: `Discount ${values.value}% ${values.entry.name}`,
                        price: values.value / 100 * values.entry[totalKey] * -1
                    }
                } else {
                    discountData = {
                        ...discountData,
                        name: `Discount ${values.entry.name}`,
                        price: values.value * -1
                    }
                }
            } else {
                const totalKey = invoiceCurrency.value === RON_CURRENCY_VALUE ? 'totalWithoutTaxRON' :'totalWithoutTax'

                if(values.calcMethod === calcMethods.PERCENT) {
                    discountData = {
                        ...discountData,
                        name: `Discount ${values.value}%`,
                        price: values.value / 100 * invoiceTotal[totalKey] * -1
                    }
                } else {
                    discountData = {
                        ...discountData,
                        name: 'Discount Produse/Servicii',
                        price: values.value * -1
                    }
                }
            }

            handleAddDiscount(discountData, index)
            closeModal()
            handleReset()
        }
    })

    const hasMultiTVA = useMemo(() => {
        return _.some(entries, (entry) => entry.tax.value !== _.head(entries).tax.value)
    }, [entries])

    const hasOtherDiscountsApplied = useMemo(() => _.some(entries, 'isDiscount'), [entries])

    return (
        <Modal open={open} onClose={closeModal} title='Adăugare discount'>
            <form className="add-discount-modal-form-container">
                <Select
                    label='Tip discount*'
                    value={values.discountType}
                    options={_.values(discountTypes)}
                    onChange={(e) => setFieldValue('discountType', e)}
                    fullWidth
                />
                {values.discountType === discountTypes.INVOICE && (hasMultiTVA || hasOtherDiscountsApplied) ? (
                    <DisabledInvoiceDiscount
                        hasMultiTVA={hasMultiTVA}
                        hasOtherDiscountsApplied={hasOtherDiscountsApplied}
                    />
                ) : (
                    <>
                        {values.discountType === discountTypes.ENTRY && (
                            <Select
                                label='Produs/Serviciu*'
                                value={values.entry}
                                options={availableEntries}
                                getOptionLabel={(entry) => entry.name}
                                onChange={(e) => setFieldValue('entry', e)}
                                fullWidth
                            />
                        )}
                        <Select
                            label='Metoda calcul*'
                            value={values.calcMethod}
                            options={_.values(calcMethods)}
                            onChange={(e) => setFieldValue('calcMethod', e)}
                            fullWidth
                        />
                        <Input
                            label='Valoare*'
                            value={values.value}
                            onChange={handleChange('value')}
                            fullWidth
                        />
                        <Button
                            title='Adaugă discount'
                            type='button'
                            onClick={handleSubmit}
                            fullWidth
                        />
                    </>
                )}
            </form>
        </Modal>
    )
}

const DisabledInvoiceDiscount = ({hasMultiTVA, hasOtherDiscountsApplied}) => {
    if(hasMultiTVA) {
        return (
            <p className="disabled-invoice-discount">
                Nu se poate adăuga discount total pe factura care conține produse/servicii cu cote TVA diferita.
            </p>
        )
    }

    if(hasOtherDiscountsApplied) {
        return (
            <p className="disabled-invoice-discount">
                Nu se poate adăuga discount total pe factura care conține alte discounturi aplicate.
            </p>
        )
    }

    return null
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_DISCOUNT
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDiscountModal)