import {useEffect} from 'react'
import {useFormikContext} from 'formik'

import {values as _values, find, isEmpty, uniqBy} from 'lodash'

import {connect} from 'react-redux'

export const AutoSelectClient = ({clients, client, handleResetClientsQuery}) => {
    const {values, setFieldValue} = useFormikContext()

    useEffect(() => {
        if(!isEmpty(client)) {
            setFieldValue(
                'clientsIds',
                uniqBy(
                    [
                        ...values.clientsIds,
                        find(clients, (c) => parseInt(c.id) === parseInt(client.id)) || null
                    ],
                    'id'
                )
            )
            handleResetClientsQuery()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client])

    return null
}

const mapStateToProps = (state) => ({
    clients: _values(state.clients.searchData),
    client: state.clients.currentClient
})

export default connect(mapStateToProps)(AutoSelectClient)