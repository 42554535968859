import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'

import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'

import './TasksCard.scss'
import {renderInterval} from '../../../Tasks/partials/TasksList/partials/utils'

export const TasksCard = ({title, filters, selectedEntityID, entityProfileID}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [tasks, setTasks] = useState([])

    const fetchTasks = useCallback(async () => {
        const params = {
            ...filters,
            entity_id: selectedEntityID,
            owner_id: entityProfileID,
            is_completed: false
        }

        setIsLoading(true)

        await performRequest(RESOURCES.tasks.list(params)).then(({data}) => {
            setTasks(data.results)
        }).catch((err) => {
            console.warn('Error while fetching tasks for TODAY', err)
        })

        setIsLoading(false)
    }, [entityProfileID, filters, selectedEntityID])

    useEffect(() => { fetchTasks() }, [fetchTasks])

    const handleChangeCompletion = useCallback((task) => {
        if(!_.isEmpty(task) && _.isNil(task.completion)) {
            const taskData = {
                id: _.toString(task.id),
                completion: `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`
            }

            performRequest(RESOURCES.tasks.update(taskData)).then(({data}) => {
                setTasks((prevState) => {
                    const currentTaskIdx = _.findIndex(prevState, ['id', data.id])
                    const copy = _.cloneDeep(prevState)
                    copy[currentTaskIdx] = data

                    return copy
                })
            }).catch((err) => {
                console.warn('Can\'t update task', err)
            })
        }
    }, [])

    const renderButtons = useCallback((task) => {
        if(_.isNil(task)) {
            return <React.Fragment />
        }

        return (
            <div className='datatable-row-buttons-container'>
                <Link to={`/tasks/${task.id}`}>
                    <Button title='Vizualizează' size='small' />
                </Link>
                {_.isNil(task.completion) && (
                    <Button
                        title='Finalizează'
                        onClick={() => handleChangeCompletion(task)}
                        size='small'
                        color='secondary'
                    />
                )}
            </div>
        )
    }, [handleChangeCompletion])

    const renderIntervalCallback = useCallback(renderInterval, [])

    const columns = useMemo(() => {
        return [
            {
                Header: 'Sarcină',
                accessor: 'title'
            },
            {
                Header: 'Interval',
                accessor: '_interval',
                Cell: ({row: {original: task}}) => renderIntervalCallback(task),
                style: {whiteSpace: 'nowrap'}
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project}) => project?.name || '-'
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({row: {original: task}}) => renderButtons(task)
            }

        ]
    }, [renderButtons, renderIntervalCallback])

    return (
        <div className="dashboard-card-container task-card">
            <Datatable
                title={title}
                data={tasks}
                columns={columns}
                loading={isLoading}
                headerButton={() => (
                    <Link to='/tasks'>
                        <Button
                            title='Vezi toate'
                            size='small'
                            variant='outlined'
                        />
                    </Link>
                )}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID: state.localConfigs.entityProfileID
})

export default connect(mapStateToProps)(TasksCard)