import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {createProjectNote, listProjectNotes} from 'avoapp-react-common/dist/redux/projectNotes'

import {getTimePassedFromDate} from '../../../../utils'

import {Button} from '../../../../components/Button'
import {Loader} from '../../../../components/Loader'

import {DeleteProjectNoteModal} from './modals'

import './InfoProjectNotes.scss'

export class InfoProjectNotes extends Component {
    constructor() {
        super()

        this.state = {
            selectedProjectNote: null
        }
    }

    componentDidMount = () => {
        const {listProjectNotes, selectedEntityID, match} = this.props
        const {projectID} = match.params

        listProjectNotes({entity_id: selectedEntityID}, projectID)
    }

    handleOpenDeleteProjectNoteModal = (note) => {
        const {openModal} = this.props

        this.setState({selectedProjectNote: note})
        openModal(modalTypes.DELETE_PROJECT_NOTE)
    }

    render() {
        const {selectedProjectNote} = this.state
        const {projectNotes, isLoading, createProjectNote, match} = this.props
        const {projectID} = match.params

        return (
            <div className='page-project-show'>
                <DeleteProjectNoteModal
                    projectID={projectID}
                    projectNote={selectedProjectNote}
                    onClose={() => this.setState({selectedProjectNote: null})}
                />
                <div className="info-notes-container">
                    <div className="info-notes-inner-container">
                        <div className="title-container">
                            <h2 className="title">
                                Observații
                            </h2>
                            {isLoading && <Loader />}
                        </div>
                        <div className="notes-container">
                            {!isEmpty(projectNotes) ? (
                                <ul className="notes-list">
                                    {projectNotes.map((note) => (
                                        <li key={note.id} className='note-container'>
                                            <p className="note-user-name">
                                                {`${note.entity_profile.first_name} ${note.entity_profile.last_name}`}
                                            </p>
                                            <p className='note-body'>{note.text}</p>
                                            <div className="note-footer-container">
                                                <p className='note-date'>{getTimePassedFromDate(note.created)}</p>
                                                <span className="font-medium text-gray-500">&middot;</span>{' '}
                                                <Button
                                                    title='Șterge'
                                                    className='delete-note-button'
                                                    onClick={() => this.handleOpenDeleteProjectNoteModal(note)}
                                                    variant='text'
                                                    color='red'
                                                />
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : isEmpty(projectNotes) && !isLoading ? (
                                <p className='no-notes-text'>Nu există observații</p>
                            ) : <Loader />}
                        </div>
                    </div>
                    <div className="add-note-form-container">
                        <Formik
                            initialValues={{text: ''}}
                            onSubmit={(values) => createProjectNote(values, projectID)}
                        >
                            {({setFieldValue, values, handleSubmit}) => (
                                <Form className="flex-1">
                                    <textarea
                                        value={values.text}
                                        className="note-textarea"
                                        placeholder="Adaugă o observație"
                                        onChange={(e) => setFieldValue('text', e.target.value)}
                                        rows={3}
                                    />
                                    <div className="button-container">
                                        <Button
                                            type='submit'
                                            title='Adaugă observație'
                                            disabled={isEmpty(values.text)}
                                            loading={!isEmpty(values.text) && isLoading}
                                            onClick={handleSubmit}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    projectNotes:  orderBy(state.projectNotes.data, 'id', 'desc'),
    isLoading: state.projectNotes.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    listProjectNotes: (params, projectID) => dispatch(listProjectNotes(params, projectID)),
    createProjectNote: (values, projectID) => dispatch(createProjectNote(values, projectID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoProjectNotes)