import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../../../../redux/modals'
import {RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

export const MarkProjectInactiveModal = ({open, project, closeModal, updateProjectActiveStatus}) => {
    return (
        <Modal open={open && !isEmpty(project)} title='Marchează proiectul ca inactiv'>
            {!isEmpty(project) && (
                <div className='delete-modal'>
                    <p className='message'>
                        Ești sigur că vrei să marchezi ca inactiv proiectul <span>"{project.name}"</span>?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            variant='outlined'
                            color='gray'
                        />
                        <Button
                            title='Da, marchează ca inactiv'
                            onClick={() => updateProjectActiveStatus(project)}
                            color='red'
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.MARK_PROJECT_INACTIVE,
    project: state.projects.currentProject
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    updateProjectActiveStatus: (project) => dispatch(RESOURCES_V1.projects.update({active: false}, project.id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MarkProjectInactiveModal)