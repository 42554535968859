import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'

import {find} from 'lodash'

import {connect} from 'react-redux'
import {modalTypes} from '../../../../../redux/modals'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {documentSeriesSchema} from '../../../../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase} from '../../../../../utils'

import {Modal} from '../../../../../components/Modal'
import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {Checkbox} from '../../../../../components/Checkbox'
import {ErrorsList} from '../../../../../components/ErrorComponents'

import './DocumentSeriesForms.scss'

export const EditDocumentSeriesModal = ({
    open,
    selectedDocumentSeries,
    selectedEntityID,
    currentDocumentSeries,
    fieldErrors,
    nonFieldErrors,
    isLoading,
    documentSeriesOptions,
    getDocumentSeriesOptions,
    retrieveDocumentSeries,
    updateDocumentSeries
}) => {
    const types = getFieldOptions(documentSeriesOptions, 'type')

    useEffect(() => {
        getDocumentSeriesOptions()

        if(selectedDocumentSeries) {
            retrieveDocumentSeries(selectedDocumentSeries.id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocumentSeries])

    return (
        <Modal
            open={open && currentDocumentSeries?.id === selectedDocumentSeries?.id}
            title='Editează serie'
        >
            <ErrorsList errors={nonFieldErrors} />
            {currentDocumentSeries?.id === selectedDocumentSeries?.id && (
                <Formik
                    initialValues={{
                        type: find(types, ['value', currentDocumentSeries.type]),
                        name: currentDocumentSeries.name,
                        label: currentDocumentSeries.label,
                        start: currentDocumentSeries.start,
                        stop: currentDocumentSeries.stop,
                        default: currentDocumentSeries.default,
                        active: currentDocumentSeries.active
                    }}
                    validationSchema={documentSeriesSchema}
                    onSubmit={(values) => {
                        const documentSeriesData = {
                            ...objectKeysToSnakeCase(values),
                            entity_id: selectedEntityID,
                            type: values.type.value
                        }

                        updateDocumentSeries(documentSeriesData, currentDocumentSeries.id)
                    }}
                >
                    {({handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched, isValid}) => (
                        <Form className='add-document-series-form-container'>
                            <Select
                                label='Tip'
                                value={values.type}
                                options={types}
                                onChange={(e) => setFieldValue('type', e)}
                                onBlur={handleBlur('type')}
                                name='type'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.type}
                                fullWidth
                                disabled
                            />
                            <div className="split-row-3">
                                <Input
                                    label='Prefix'
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    name='name'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.name}
                                    fullWidth
                                    disabled
                                />
                                <Input
                                    label='Eticheta'
                                    value={values.label}
                                    onChange={handleChange('label')}
                                    onBlur={handleBlur('label')}
                                    name='label'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.label}
                                    fullWidth
                                />
                                <Input
                                    label='Start'
                                    value={values.start}
                                    onChange={handleChange('start')}
                                    onBlur={handleBlur('start')}
                                    name='start'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.start}
                                    fullWidth
                                    disabled
                                />
                                <Input
                                    label='Stop'
                                    value={values.stop}
                                    onChange={handleChange('stop')}
                                    onBlur={handleBlur('stop')}
                                    name='stop'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.stop}
                                    fullWidth
                                />
                            </div>
                            <Checkbox
                                label='Serie principala'
                                checked={values.default}
                                onChange={(e) => setFieldValue('default', e.target.checked)}
                            />
                            <Checkbox
                                label='Serie activă'
                                checked={values.active}
                                onChange={(e) => setFieldValue('active', e.target.checked)}
                            />
                            <Button
                                title='Salvează'
                                type='submit'
                                onClick={handleSubmit}
                                loading={isLoading}
                                disabled={!isValid}
                                fullWidth
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.EDIT_DOCUMENT_SERIES,
    selectedEntityID: state.localConfigs.selectedEntityID,
    documentSeriesOptions: state.documentSeries.options,
    currentDocumentSeries: state.documentSeries.currentDocumentSeries,
    fieldErrors: state.documentSeries.fieldErrors,
    nonFieldErrors: state.documentSeries.nonFieldErrors,
    isLoading: state.documentSeries.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    getDocumentSeriesOptions: () => dispatch(RESOURCES.documentSeries.getOptions()),
    retrieveDocumentSeries: (documentSeriesID) => dispatch(RESOURCES.documentSeries.retrieve(documentSeriesID)),
    updateDocumentSeries: (documentSeriesData, documentSeriesID) =>
        dispatch(RESOURCES.documentSeries.update(documentSeriesData, documentSeriesID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentSeriesModal)