import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, find} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {getFieldOptions, objectKeysToSnakeCase} from '../../../../../utils'
import {clientsSchema} from '../../../../../assets/validations'

import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {PhoneInput} from '../../../../../components/PhoneInput'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {RequiredFieldsText} from '../../../../../components/RequiredFieldsText'
import {Slideover} from '../../../../../components/Slideover'

import '../../../../../assets/scss/SlideoverForms.scss'

export const EditClientInfoSlideover = ({
    open,
    client,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    clientOptions,
    getClientOptions,
    updateClient
}) => {
    const types = getFieldOptions(clientOptions, 'type')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {getClientOptions()}, [])

    return (
        <Slideover open={open && !isEmpty(client)} title='Editează client'>
            <div className="slideover-form-container">
                <ErrorsList errors={nonFieldErrors} />
                {!isEmpty(client) && !isEmpty(clientOptions) && (
                    <Formik
                        initialValues={{
                            name: client.name,
                            type: find(types, ['value', client.type]),
                            vatCode: client.vat_code || '',
                            businessRegistrationNumber: client.business_registration_number || '',
                            email: client.email || '',
                            phone: client.phone || '',
                            IDSeries: client.id_series || '',
                            IDNumber: client.id_number || ''
                        }}
                        validationSchema={clientsSchema.generalInformations}
                        validateOnMount
                        onSubmit={(values) => {
                            const updateData = {
                                ...objectKeysToSnakeCase(values),
                                type: values.type.value,
                                id_series: values.IDSeries,
                                id_number: values.IDNumber,
                                phone: values.phone ? `+${values.phone}` : null
                            }

                            updateClient(updateData, client.id)
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            values,
                            handleSubmit,
                            errors,
                            touched,
                            isValid
                        }) => (
                            <>
                                <Form className="slideover-form">
                                    <Input
                                        label={values.type === find(types, ['value', 'person']) ? 'Nume*' : 'Denumire*'}
                                        value={values.name}
                                        onChange={handleChange('name')}
                                        onBlur={handleBlur('name')}
                                        name='name'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.name}
                                        fullWidth
                                    />
                                    <Select
                                        label='Tip client*'
                                        value={values.type}
                                        options={types}
                                        onChange={(e) => setFieldValue('type', e)}
                                        onBlur={handleBlur('type')}
                                        name='type'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.type}
                                        fullWidth
                                    />
                                    <Input
                                        label={values.type === find(types, ['value', 'person']) ? 'CNP*' : 'CUI*'}
                                        value={values.vatCode}
                                        onChange={handleChange('vatCode')}
                                        onBlur={handleBlur('vatCode')}
                                        name='vatCode'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.vatCode}
                                        fullWidth
                                    />
                                    {values.type === find(types, ['value', 'company']) && (
                                        <Input
                                            label='Reg. com.*'
                                            value={values.businessRegistrationNumber}
                                            onChange={handleChange('businessRegistrationNumber')}
                                            onBlur={handleBlur('businessRegistrationNumber')}
                                            name='businessRegistrationNumber'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.businessRegistrationNumber}
                                            fullWidth
                                        />
                                    )}
                                    {values.type === find(types, ['value', 'person']) && (
                                        <>
                                            <Input
                                                label='Serie CI'
                                                value={values.IDSeries}
                                                onChange={handleChange('IDSeries')}
                                                onBlur={handleBlur('IDSeries')}
                                                name='IDSeries'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.IDSeries}
                                                fullWidth
                                            />
                                            <Input
                                                label='Număr CI'
                                                value={values.IDNumber}
                                                onChange={handleChange('IDNumber')}
                                                onBlur={handleBlur('IDNumber')}
                                                name='IDNumber'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.IDNumber}
                                                fullWidth
                                            />
                                        </>
                                    )}
                                    <Input
                                        label='Email*'
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur('email')}
                                        name='email'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.email}
                                        fullWidth
                                    />
                                    <PhoneInput
                                        label='Telefon*'
                                        value={values.phone}
                                        onChange={handleChange('phone')}
                                        name='phone'
                                        errors={fieldErrors}
                                        fullWidth
                                    />
                                    <RequiredFieldsText />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title='Salvează'
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        disabled={!isValid}
                                        type='submit'
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                )}
            </div>
        </Slideover>
    )
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_CLIENT_INFO,
    clientOptions: state.clients.options,
    client: state.clients.currentClient,
    fieldErrors: state.clients.fieldErrors,
    nonFieldErrors: state.clients.nonFieldErrors,
    isLoading: state.clients.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    getClientOptions: () => dispatch(RESOURCES.clients.getOptions()),
    updateClient: (values, clientID) => dispatch(RESOURCES.clients.update(values, clientID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditClientInfoSlideover)