import React from 'react'
import {LifebuoyIcon, PaperAirplaneIcon, PhoneIcon} from '@heroicons/react/24/outline'
import {Form, Formik} from 'formik'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {ticketsSchema} from '../../assets/validations'

import {Button} from '../../components/Button'
import {Input} from '../../components/Input'

import {TicketCreatedModal} from './partials'

import './Support.scss'

export const Support = ({createTicket, isLoading, fieldErrors}) => {
    const handleCallMe = () => {
        createTicket({
            source: 'web',
            subject: 'Nu vreau sa scriu suna-ma!',
            message: 'Nu vreau sa scriu suna-ma!'
        })
    }

    return (
        <div className="support-page-container">
            <div className="support-left">
                <LifebuoyIcon className='support-icon'/>
                <p className="support-title">
                    Cum te<br />
                    putem <span>ajuta?</span>
                </p>
                <p className="support-text">Dă-ne un semn, iar noi te vom contacta înapoi.</p>
            </div>
            <div className="support-right">
                <div className="call-me-card">
                    <p className="card-title">Creează un tichet de contact</p>
                    <Button
                        title='Nu vreau să scriu, sunați-mă'
                        onClick={handleCallMe}
                        icon={() => <PhoneIcon />}
                        loading={isLoading}
                        color='secondary'
                        size='large'
                        fullWidth
                    />
                </div>
                <div className="create-ticket-card">
                    <p className='card-title'>Sau scrie-ne</p>
                    <Formik
                        initialValues={{source: 'web', subject: 'Tichet nou', message: ''}}
                        validationSchema={ticketsSchema}
                        validateOnMount
                        onSubmit={(values) => createTicket(values)}
                    >
                        {({handleChange, handleBlur, values, handleSubmit, errors, touched, isValid, resetForm}) => (
                            <Form className='create-ticket-form'>
                                <TicketCreatedModal closeModal={resetForm} />
                                <Input
                                    label='Mesajul tău'
                                    value={values.message}
                                    onChange={handleChange('message')}
                                    onBlur={handleBlur('message')}
                                    name='message'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.message}
                                    size='large'
                                    fullWidth
                                />
                                <Button
                                    title='Trimite mesajul'
                                    onClick={handleSubmit}
                                    icon={() => <PaperAirplaneIcon />}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    size='large'
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    fieldErrors: state.tickets.fieldErrors,
    nonFieldErrors: state.tickets.nonFieldErrors,
    isLoading: state.tickets.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    createTicket: (ticketData) => dispatch(RESOURCES.tickets.create(ticketData))
})

export default connect(mapStateToProps, mapDispatchToProps)(Support)