import React from 'react'
import {connect} from 'react-redux'

export const CalendarMonthView = (props) => {
    return (
        <div>CalendarMonthView</div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMonthView)