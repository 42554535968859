import React, {useEffect, useMemo, useState} from 'react'

import _ from 'lodash'

import {connect} from 'react-redux'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {closeModal, modalTypes} from '../../redux/modals'

// @ts-ignore
import {MDSubscriptionSources} from '../../utils/constants'

// @ts-ignore
import {litigationsCheckData} from '../../assets/data/choices'

// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {Select} from '../Select'
// @ts-ignore
import {Input} from '../Input'
// @ts-ignore
import {Modal} from '../Modal'

// @ts-ignore
import {ErrorsList} from '../ErrorComponents'

import './AddMDSubscriptionPartyModal.scss'
import {ProjectMinimal} from '../../types/api'
import {useQueryResourceSearch} from '../../queries/rest'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {useMutationResourceCreate} from '../../mutations/rest'

interface AddMDSubscriptionPartyModalProps {
    open: boolean,
    closeModal: () => void,
    selectedEntityID: number,
}

export const AddMDSubscriptionPartyModal = ({
    open,
    closeModal,
    selectedEntityID
}: AddMDSubscriptionPartyModalProps) => {
    const [nonFieldErrors, setNonFieldErrors] = useState<any>(undefined)
    const [fieldErrors, setFieldErrors] = useState<any>(undefined)
    const {data, isFetching: isFetchingClients} = useQueryResourceSearch(
        RESOURCES.clients,
        '',
        {entity_id: selectedEntityID}
    )
    const clients = data as any

    const mutation = useMutationResourceCreate(RESOURCES.partySubscriptions)

    const {values, setFieldValue, handleSubmit, handleChange, resetForm} = useFormik({
        initialValues: {
            client: undefined,
            source: undefined,
            institution: undefined,
            party_name: undefined
        },
        onSubmit: (values: any) => {
            const data = {
                client_id: values.client?.id,
                source: values.source?.value,
                institution: values.institution?.value,
                party_name: values.party_name
            }
            mutation.mutateAsync(data).then((res: any) =>{
                if(!res.errors) {
                    toast.success('Salvat')
                    closeModal()
                    resetForm()
                    setNonFieldErrors(undefined)
                    setFieldErrors(undefined)
                } else {
                    setNonFieldErrors(res.errors.non_field_errors)
                    setFieldErrors(_.omit(res.errors, 'non_field_errors'))
                }
            })
        }
    })

    useEffect(() => {
        if (clients?.length === 1 && _.isUndefined(values.client)) {
            setFieldValue('client', clients[0])
        }
    }, [clients, values.client, setFieldValue])

    const institutions = useMemo(() => {
        const keys = Object.keys(litigationsCheckData.institution)

        return keys
            .filter((key) => !_.isNil(litigationsCheckData.institution[key]))
            .map((key) => ({
                value: litigationsCheckData.institution[key],
                label: key
            }))
    }, [])

    return (
        <Modal open={open} title='Adaugă abonare parte Monitor Dosare'>
            <form className="add-subscription-party-modal">
                <ErrorsList errors={nonFieldErrors} />
                <Select
                    label='Client*'
                    value={values.client}
                    name="client_id"
                    errors={fieldErrors}
                    options={clients}
                    getOptionValue={(option: ProjectMinimal) => option.id}
                    getOptionLabel={(option: ProjectMinimal) => option.name}
                    onChange={(value: any) => setFieldValue('client', value)}
                    fullWidth
                />
                <Select
                    label='Sursa*'
                    value={values.source}
                    name="source"
                    errors={fieldErrors}
                    options={_.values(MDSubscriptionSources)}
                    onChange={(value: any) => setFieldValue('source', value)}
                    fullWidth
                />
                <Select
                    label='Instituția'
                    value={values.institution}
                    name="institution"
                    errors={fieldErrors}
                    options={institutions}
                    onChange={(value: any) => setFieldValue('institution', value)}
                    fullWidth
                />
                <Input
                    label="Cuvânt cheie*"
                    value={values.party_name}
                    name="party_name"
                    errors={fieldErrors}
                    onChange={handleChange('party_name')}
                    fullWidth
                />
                <hr/>
                <Button
                    title='Adaugă abonare parte'
                    onClick={handleSubmit}
                    loading={mutation.isPending}
                    disabled={isFetchingClients}
                    fullWidth
                />
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_MONITOR_DOSARE_SUBSCRIPTION_PARTY,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMDSubscriptionPartyModal)
