import {lightFormat, parseISO} from 'date-fns'

export const columns =[
    {
        Header: 'Data',
        accessor: 'date',
        Cell: ({value: date}) => date ? lightFormat(parseISO(`${date}T00:00:00`), 'dd/MM/yyyy') : '-'
    },
    {
        Header: 'Start',
        accessor: 'start',
        Cell: ({value: start}) => start ? start : '-'
    },
    {
        Header: 'Stop',
        accessor: 'stop',
        Cell: ({value: stop}) => stop ? stop : '-'
    },
    {
        Header: 'Avocat',
        accessor: 'owner',
        Cell: ({value: owner}) => owner.full_name || '-'
    },
    {
        Header: 'Ore lucrate',
        accessor: 'duration_as_hours',
        Cell: ({value: durationAsHours}) => durationAsHours ? durationAsHours : '-'
    },
    {
        Header: 'Ore facturabile',
        accessor: 'duration_billable_as_hours',
        Cell: ({value: durationBillableAsHours}) => durationBillableAsHours ? durationBillableAsHours : '-'
    },
    {
        Header: 'Notă activitate',
        accessor: 'description'
    }
]
