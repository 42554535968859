import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {PlusIcon} from '@heroicons/react/24/solid'

import {connect} from 'react-redux'

import {actionIds, actions, prefix} from './constants'

import {Button} from '../../components/Button'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {AddendumsList, ContractsList, EmpowermentsList} from './partials'

import './Contracts.scss'
import {Loader} from '../../components/Loader'

export class Contracts extends Component {
    render() {
        const {match, isLoadingContracts, isLoadingAddendums, isLoadingEmpowerments} = this.props
        const urlData = {prefix: prefix, params: match.params}

        switch(match.params.primaryAction) {
            case actionIds.CONTRACTS:
                return (
                    <div>
                        <div className='w-full min-w-full flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center gap-4'>
                                <SecondaryNavbar actions={actions} urlData={urlData}/>
                                <span className='pb-2'>
                                    {isLoadingContracts && <Loader />}
                                </span>
                            </div>

                            <Link to='/contracts/add'>
                                <Button title='Adaugă contract' icon={() => <PlusIcon />} />
                            </Link>
                        </div>
                        <div className="page-info">
                            <ContractsList />
                        </div>
                    </div>
                )
            case actionIds.ADDENDUMS:
                return (
                    <div>
                        <div className='w-full min-w-full flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center gap-4'>
                                <SecondaryNavbar actions={actions} urlData={urlData}/>
                                <span className='pb-2'>
                                    {isLoadingAddendums && <Loader />}
                                </span>
                            </div>

                            <Link to='/contracts/addendums/add'>
                                <Button title='Adaugă act adițional' icon={() => <PlusIcon />} />
                            </Link>
                        </div>
                        <div className="page-info">
                            <AddendumsList />
                        </div>
                    </div>
                )
            case actionIds.EMPOWERMENTS:
                return (
                    <div>
                        <div className='w-full min-w-full flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center gap-4'>
                                <SecondaryNavbar actions={actions} urlData={urlData}/>
                                <span className='pb-2'>
                                    {isLoadingEmpowerments && <Loader />}
                                </span>
                            </div>

                            <Link to='/contracts/empowerments/add'>
                                <Button title='Adaugă împuternicire' icon={() => <PlusIcon />} />
                            </Link>
                        </div>
                        <div className="page-info">
                            <EmpowermentsList />
                        </div>
                    </div>
                )
            default:
                return
        }
    }
}

const mapStateToProps = (state) => ({
    isLoadingContracts: state.contracts.isLoading,
    isLoadingAddendums: state.addendums.isLoading,
    isLoadingEmpowerments: state.empowerments.isLoading
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Contracts)