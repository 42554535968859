import {toast} from 'react-toastify'
import {push} from 'connected-react-router'

import {values, head, find} from 'lodash'

import {takeEvery, put, take, select} from 'redux-saga/effects'

import {ACTIONS} from 'avoapp-react-common/dist/redux/auth'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getPermissions} from 'avoapp-react-common/dist/redux/permissions'
import {retrieveAndSetCurrent} from 'avoapp-react-common/dist/redux/entities'
import {selectEntityID} from 'avoapp-react-common/dist/redux/selectedEntityID'
import {selectEntityProfileID, selectLanguage} from 'avoapp-react-common/dist/redux/localConfigs'
import {getBelongingEntities, ACTIONS as entityProfileActions} from 'avoapp-react-common/dist/redux/entityProfiles'
import {retrieveLastRunningTaskTimeLog} from 'avoapp-react-common/dist/redux/taskTimeLogs'
import {userLanguages} from 'avoapp-react-common/dist/constants'
import {modalTypes, openModal, closeModal} from './modals'

function* handleLoginSuccess() {
    const user = yield select((state) => state.users.user)

    yield put(RESOURCES.usersPreferences.retrieve('me'))

    const usersPreferencesResponse = yield take(action =>
        action.type === RESOURCES.usersPreferences.actions.retrieve.success ||
        action.type === RESOURCES.usersPreferences.actions.retrieve.fail
    )

    if(usersPreferencesResponse.type === RESOURCES.usersPreferences.actions.retrieve.success) {
        const userPreferences = yield select((state) => state.usersPreferences.data)
        const language = userPreferences?.language || userLanguages.RO.value

        yield put(selectLanguage(language))
    }

    yield put(getBelongingEntities(user.id))
    yield take(entityProfileActions.GET_BELONGING_ENTITIES.success)

    const belongingEntities = values(yield select((state) => state.entityProfiles.belongingEntities))

    if (belongingEntities.length === 1) {
        yield put(selectEntityID(head(belongingEntities).id))
        yield put(retrieveAndSetCurrent(head(belongingEntities).id))
        yield put(retrieveLastRunningTaskTimeLog())
        yield put(push('/'))
    } else {
        yield put(push('/select-entity'))
    }
}

function* handleLoginOTPSuccess() {
    yield put(closeModal())
    yield handleLoginSuccess()
}

function* handleLoginFail() {
    const nonFieldErrors = yield select((state) => state.auth.nonFieldErrors)

    const otpError = find(nonFieldErrors, (item) => item.code === 'waiting_for_otp')

    if(otpError) {
        yield put(openModal(modalTypes.OTP))
    }
}

function* handleRefreshTokenSuccess() {
    const user = yield select((state) => state.users.user)

    yield put(RESOURCES.usersPreferences.retrieve('me'))

    const usersPreferencesResponse = yield take(action =>
        action.type === RESOURCES.usersPreferences.actions.retrieve.success ||
        action.type === RESOURCES.usersPreferences.actions.retrieve.fail
    )

    if(usersPreferencesResponse.type === RESOURCES.usersPreferences.actions.retrieve.success) {
        const userPreferences = yield select((state) => state.usersPreferences.data)
        const language = userPreferences?.language || userLanguages.RO.value

        yield put(selectLanguage(language))
    }

    yield put(getBelongingEntities(user.id))
    yield take(entityProfileActions.GET_BELONGING_ENTITIES.success)

    const belongingEntities = values(yield select((state) => state.entityProfiles.belongingEntities))
    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)

    if (belongingEntities.length === 1 && (!selectedEntityID || selectedEntityID !== head(belongingEntities).id)) {
        yield put(selectEntityID(head(belongingEntities).id))
        yield put(retrieveAndSetCurrent(head(belongingEntities).id))
    }

    if(selectedEntityID) {
        yield put(RESOURCES.entityProfiles.list({entity_id: selectedEntityID, user_id: user.id}))
        yield take(RESOURCES.entityProfiles.actions.list.success)

        const myEntityProfileID = yield select((state) => head(values(state.entityProfiles.data)).id)

        yield put(selectEntityProfileID(myEntityProfileID))
        yield put(retrieveAndSetCurrent(selectedEntityID))
        yield put(RESOURCES.entityReports.retrieve(selectedEntityID))
        yield put(getPermissions())
        yield put(retrieveLastRunningTaskTimeLog())
    } else {
        yield put(push('/select-entity'))
    }

    if (window.location.pathname === '/login') {
        yield put(push('/'))
    }
}

function* handleSignupSuccess() {
    toast.success('Contul a fost creat cu success. Verifică-ți emailul!')

    yield put(push('/login'))
}

function* handleLogout() {
    yield put(push('/login'))
}

function* handleForgotPasswordSuccess() {
    toast.success('Am trimis un link de resetare a parolei pe adresa de email introdusă')

    yield put(push('/login'))
}

function* handleResetPasswordSuccess() {
    toast.success('Parola a fost resetată cu succes')

    yield put(push('/login'))
}

export function* saga() {
    yield takeEvery(ACTIONS.LOGIN.success, handleLoginSuccess)
    yield takeEvery(ACTIONS.LOGIN.fail, handleLoginFail)
    yield takeEvery(ACTIONS.LOGIN_OTP.success, handleLoginOTPSuccess)
    yield takeEvery(ACTIONS.REFRESH_TOKEN.success, handleRefreshTokenSuccess)
    yield takeEvery(ACTIONS.SIGNUP.success, handleSignupSuccess)
    yield takeEvery(ACTIONS.FORGOT_PASSWORD.success, handleForgotPasswordSuccess)
    yield takeEvery(ACTIONS.RESET_PASSWORD.success, handleResetPasswordSuccess)
    yield takeEvery(ACTIONS.LOGOUT.main, handleLogout)
}
