import {Form, Formik} from 'formik'
import React, {Component} from 'react'

import {values as _values, find, omitBy} from 'lodash'

import {getCountries, getStates} from 'avoapp-react-common/dist/redux/geo'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../redux/filters/filters'
import {closeSlideover} from '../../../../redux/slideovers'

import {roleTypes} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Input} from '../../../../components/Input'
import {Select} from '../../../../components/Select'

import '../../../../assets/scss/SlideoverForms.scss'

export class FilterClientsForm extends Component {
    componentDidMount() {
        const {getCountries} = this.props

        getCountries()
    }

    handleFilter = (filters) => {
        const {addFilters, filterClients, closeSlideover} = this.props

        addFilters(filters)
        filterClients()
        closeSlideover()
    }

    getInitialCountry = () => {
        const {filters, countries} = this.props

        return find(countries, (country) => filters.country.value === country.code)
    }

    getInitialRole = () => {
        const {roles, filters} = this.props

        return find(roles, (role) => filters.country.value === role.value)
    }

    render() {
        const {filters, countries, isLoading, fieldErrors, nonFieldErrors} = this.props

        return (
            <div className="slideover-form-container">
                <ErrorsList errors={nonFieldErrors} />
                <Formik
                    initialValues={{
                        name: filters.name.value,
                        vatCode: filters.vatCode.value,
                        county: filters.county.value,
                        country: this.getInitialCountry(),
                        locality: filters.locality.value,
                        role: this.getInitialRole()
                    }}
                    onSubmit={(values) => {
                        const filtersData = {
                            name: {
                                value: values.name
                            },
                            vatCode: {
                                value: values.vatCode
                            },
                            county: {
                                value: values.county
                            },
                            country: {
                                value: values.country?.code || '',
                                displayValue: values.country?.name || ''
                            },
                            locality: {
                                value: values.locality
                            },
                            role: {
                                value: values.role?.value || '',
                                displayValue: values.role?.label || ''
                            }
                        }

                        this.handleFilter(filtersData)
                    }}
                >
                    {({handleChange, setFieldValue, handleSubmit, values}) => (
                        <>
                            <Form className='slideover-form'>
                                <Input
                                    label='Nume'
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    name='name'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                <Input
                                    label='CUI/CNP'
                                    value={values.vatCode}
                                    onChange={handleChange('vatCode')}
                                    name='vatCode'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                <Select
                                    label='Țară'
                                    value={values.country}
                                    options={countries}
                                    onChange={(country) => {
                                        setFieldValue('country', country)
                                        setFieldValue('county', '')

                                        // if(country) {
                                        //     getStates(country.code)
                                        // }
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.code}
                                    name='country'
                                    errors={fieldErrors}
                                    isClearable
                                    fullWidth
                                />
                                <Input
                                    label='Județ'
                                    value={values.county}
                                    onChange={handleChange('county')}
                                    name='county'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                <Input
                                    label='Localitate / Oraș'
                                    value={values.locality}
                                    onChange={handleChange('locality')}
                                    name='locality'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                                {/* <Select
                                    label='Rolul meu'
                                    value={values.role}
                                    options={roles}
                                    onChange={(e) => setFieldValue('role', e)}
                                    name='role'
                                    errors={fieldErrors}
                                    fullWidth
                                /> */}
                            </Form>
                            <div className="buttons-container">
                                <Button
                                    title='Aplică'
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    type='submit'
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters.clients,
    isLoading: state.clients.isLoading,
    fieldErrors: state.clients.fieldErrors,
    nonFieldErrors: state.clients.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    countries: _values(state.geo.countries),
    states: _values(state.geo.states),
    roles: _values(omitBy(roleTypes, (role) => role.value === roleTypes.CUSTOM.value))
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryCode) => dispatch(getStates({country_code: countryCode, page_size: 50})),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.clients.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterClientsForm)