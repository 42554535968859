import React, {useEffect, useState} from 'react'

import {values, isEmpty, find, map} from 'lodash'
import {performRequest} from 'avoapp-react-common/dist/redux/api'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {modalTypes, openModal} from '../../redux/modals'

import {CreatableAsync} from '../CreatableAsync'

import {CreateLegalObjectModal} from './partials'

export const LegalObjectCreatable = ({
    value,
    onChange,
    onBlur,
    name,
    errors,
    frontendErrors,
    touched,
    currentLegalObject,
    legalObjects,
    isLoadingLegalObjects,
    listLegalObjects,
    selectedEntityID,
    openCreateLegalObjectModal
}) => {
    const [legalObjectCategory, setLegalObjectCategory] = useState(null)
    const [createNewObjectName, setCreateNewObjectName] = useState('')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {listLegalObjects()}, [])

    useEffect(() => {
        if(!isEmpty(currentLegalObject)) {
            const selectedLegalObject = find(legalObjects, ['value', currentLegalObject.id])

            onChange(selectedLegalObject)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLegalObject])

    const handleCreateNewLegalObject = (newLegalObject) => {
        setCreateNewObjectName(newLegalObject)
        openCreateLegalObjectModal()
    }

    const loadOptions = async (inputValue) => {
        const {data} = await performRequest(
            RESOURCES.legalObjects.list({entity_id: selectedEntityID, search: inputValue})
        )
        const options = map(
            data.results, (legalObject) => ({label: legalObject.name, value: legalObject.id})
        )
        return options
    }

    return (
        <>
            <CreatableAsync
                label='Obiect legal'
                value={value}
                cacheOptions
                defaultOptions={legalObjects}
                loadOptions={loadOptions}
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                onCreateNew={(newLegalObject) => handleCreateNewLegalObject(newLegalObject)}
                disabled={isLoadingLegalObjects}
                name={name}
                errors={errors}
                frontendErrors={ frontendErrors}
                touched={touched}
                fullWidth
            />
            <CreateLegalObjectModal
                categoryValue={legalObjectCategory}
                onChangeCategory={(e) => {
                    setLegalObjectCategory(e)
                    listLegalObjects(e.value)
                }}
                initialObjectName={createNewObjectName}
            />
        </>

    )
}

const mapStateToProps = (state) => {
    const legalObjects = values(state.legalObjects.data).map(
        (category) => ({label: category.name, value: category.id})
    )

    return {
        currentLegalObject: state.legalObjects.currentLegalObject,
        legalObjects: legalObjects,
        fieldErrors: state.legalObjects.fieldErrors,
        nonFieldErrors: state.legalObjects.nonFieldErrors,
        isLoadingLegalObjects: state.legalObjects.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    openCreateLegalObjectModal: () => dispatch(openModal(modalTypes.CREATE_LEGAL_OBJECT)),
    listLegalObjects: (legalObjectCategoryID) => dispatch(
        RESOURCES.legalObjects.list({legal_object_category_id: legalObjectCategoryID})
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(LegalObjectCreatable)