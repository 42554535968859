import {lightFormat} from 'date-fns'
import {toUpper, isNull} from 'lodash'

import {FileTypeBadge} from '../../../../components/FileTypeBadge'

export const columns = [
    {
        Header: 'Nume',
        accessor: 'name'
    },
    {
        Header: 'Format',
        accessor: 'last_version',
        Cell: ({value: last_version}) => {
            const getFileExtension = () => last_version && last_version.file_meta ?
                toUpper(last_version.file_meta.extension) :
                '-'

            return <FileTypeBadge fileType={getFileExtension()}/>

        }
    },
    {
        Header: 'Dată ultima modificare',
        accessor: 'updated',
        Cell: ({value: updated}) => lightFormat(new Date(updated), 'dd/MM/yyyy HH:mm')
    },
    {
        Header: 'Document semnat încărcat',
        accessor: 'signed_file',
        Cell: ({value: signed_file}) => !isNull(signed_file) ? 'Da' : 'Nu'

    }
]
