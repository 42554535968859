import React from 'react'

import {connect} from 'react-redux'

import Modal from '../../components/Modal/Modal'
import {Button} from '../../components/Button'
import {modalTypes, closeModal} from '../../redux/modals'
import {ErrorsList} from '../../components/ErrorComponents'
import {Form, Formik} from 'formik'
import {Input} from '../../components/Input'
import {loginOTP} from 'avoapp-react-common/dist/redux/auth'

export const OTPLoginModal = ({open, isLoading, closeModal, loginOTP, fieldErrors}) => {

    return (
        <Modal open={open} title='Autentificare SMS'>
            <>
                <ErrorsList errors={{}} />
                <Formik
                    initialValues={{
                        token: ''
                    }}
                    onSubmit={(values) => {
                        loginOTP(values)
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched
                    }) => (
                        <Form className='edit-task-time-log-form-container'>

                            <div className="textarea-container">
                                <Input
                                    label='Cod primit prin SMS'
                                    value={values.token}
                                    onChange={handleChange('token')}
                                    name='token'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.token}
                                    onBlur={handleBlur}
                                    fullWidth
                                />
                            </div>

                            <div className="buttons-container">
                                <Button
                                    title='Trimite codul'
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    color='primary'
                                />
                                <Button
                                    title='Renunță'
                                    onClick={closeModal}
                                    variant='outlined'
                                    loading={isLoading}
                                    color='gray'
                                />

                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.OTP,
    fieldErrors: state.auth.fieldErrors,
    nonFieldErrors: state.auth.nonFieldErrors
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    loginOTP: (values) => dispatch(loginOTP(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(OTPLoginModal)
