import _ from 'lodash'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    projectId: Yup.object().label('Proiect').nullable(),
    taskId: Yup.object().label('Sarcina').nullable().when('projectId', {
        is: (projectId) => !_.isNil(projectId),
        then: Yup.object().nullable().required('Sarcina este obligatorie dacă un proiect este selectat!')
    }),
    date: Yup.date().label('Data').nullable().required('Data este obligatorie!'),
    start: Yup.string().label('Interval start').required('Începutul intervalului e obligatoriu!'),
    stop: Yup.string().label('Interval stop'),
    description: Yup.string().label('Descriere')
})
