import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, isNil, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {removeFilter} from '../../../../redux/filters/filters'

import {columns} from './constants'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'

import {ClientDetailsDisclaimer} from '../ClientDetailsDisclaimer'

import './Empowerments.scss'
import {modalTypes, openModal} from '../../../../redux/modals'
import {DeleteEmpowermentModal} from '../../../../components/DeleteEmpowermentModal'

export const Empowerments = ({
    empowerments,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    openDeleteEmpowermentModal,
    removeFilter,
    listEmpowerments,
    match: {params: {clientID}}
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedEmpowerment, setSelectedEmpowerment] = useState(null)

    const handleFetchEmpowerments = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listEmpowerments(clientID, search, appliedFilters, page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {handleFetchEmpowerments()}, [])

    const debounceSearchEmpowerments = debounce((search) => handleFetchEmpowerments(search), debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchEmpowerments(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchEmpowerments(searchFilter, page)

    return (
        <div className='page-client-show client-empowerments-list'>
            <ClientDetailsDisclaimer resource='împuternicirile' />
            <Datatable
                title='Listă împuterniciri'
                data={empowerments}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: empowermentID, row: {original: empowerment}}) => (
                            <div className='flex gap-2 items-center'>
                                <Link to={`/contracts/empowerments/${empowermentID}/preview`}>
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Dropdown
                                    title='Descarcă'
                                    disabled={isNil(empowerment.file) && isNil(empowerment.signed_file)}
                                    items={[
                                        {
                                            customContent: () =>
                                                empowerment.file ? (
                                                    <a
                                                        href={empowerment.file}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        className='avo-dropdown-menu-item'
                                                    >
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                empowerment.signed_file ? (
                                                    <a
                                                        href={empowerment.signed_file}
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                        className='avo-dropdown-menu-item'
                                                    >
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant='text'
                                    color='secondary'
                                    size='small'
                                />
                                <Button
                                    title='Șterge'
                                    onClick={() => {
                                        setSelectedEmpowerment(empowerment)
                                        openDeleteEmpowermentModal()
                                    }}
                                    size='small'
                                    color='red'
                                    variant='text'
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                // filterable
                // filters={filters}
                // filtersForm={() => <FilterEmpowermentsForm filterEmpowerments={handleFetchEmpowerments} />}
                // handleRemoveFilter={(filter) => {
                //     removeFilter(filter)
                //     handleFetchEmpowerments()
                // }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută împuterniciri'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <DeleteEmpowermentModal empowerment={selectedEmpowerment} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    empowerments: orderBy(state.empowerments.data, [(e) => e.signed_date || '', 'id'], ['desc', 'desc']),
    isLoading: state.empowerments.isLoading,
    totalPages: state.empowerments.totalPages,
    nextPage: state.empowerments.next,
    previousPage: state.empowerments.previous,
    currentPage: state.empowerments.current,
    filters: state.filters.empowerments
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteEmpowermentModal: () => dispatch(openModal(modalTypes.DELETE_EMPOWERMENT)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.empowerments.name, filter)),
    listEmpowerments: (clientID, search, filters, page) =>
        dispatch(
            RESOURCES.empowerments.list(
                {
                    ...filters,
                    client_id: clientID,
                    search: search,
                    page: page,
                    page_size: datatablePageSize
                },
                // overwriteData
                true
            )
        )
})

export default connect(mapStateToProps, mapDispatchToProps)(Empowerments)
