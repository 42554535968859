import React from 'react'

import {isEmpty, isNull} from 'lodash'

import {connect} from 'react-redux'
import {removeSignedFile} from 'avoapp-react-common/dist/redux/empowerments'
import {closeModal, modalTypes} from '../../../../redux/modals'

import {Modal} from '../../../../components/Modal'
import {Button} from '../../../../components/Button'

import '../../../../assets/scss/DeleteModals.scss'

function DeleteSignedEmpowermentModal({open, empowerment, isLoading, closeModal, removeSignedFile}) {
    return (
        <Modal
            open={open && !isEmpty(empowerment) && !isNull(empowerment.signed_file)}
            title='Șterge împuternicire semnată'
        >
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi documentul semnat pentru împuternicirea{' '}
                    "<span>{empowerment.name}</span>"?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => removeSignedFile(empowerment.id)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open : state.modals.type === modalTypes.DELETE_SIGNED_EMPOWERMENT,
    empowerment: state.empowerments.currentEmpowerment,
    isLoading: state.empowerments.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    removeSignedFile: (empowermentID) => dispatch(removeSignedFile(empowermentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSignedEmpowermentModal)