import React, {useCallback, useState} from 'react'
import {toast} from 'react-toastify'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {connect} from 'react-redux'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {closeModal, modalTypes} from '../../../../redux/modals'

// @ts-ignore
import {Button} from '../../../../components/Button'
// @ts-ignore
import {Modal} from '../../../../components/Modal'

import '../../../../assets/scss/DeleteModals.scss'

type DeleteAppealModalProps = {
    appeal: any;
    open: boolean;
    closeModal: () => void;
}

function DeleteAppealModal({open, appeal, closeModal}: DeleteAppealModalProps) {
    const [isLoading, setIsLoading] = useState(false)

    const deleteAppeal = useCallback(() => {
        setIsLoading(true)

        performRequest(RESOURCES.appeals.destroy(appeal))
            .then((res: any) => {
                if(!res.errors) {
                    toast.success('Succes')
                    closeModal()
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    _.forEach(_.values(res.errors), (error) => {
                        toast.error(error.message)
                    })
                }
            }).catch((err: any) => {
                console.warn('Error while deleting appeal: ', err)
            })
    }, [closeModal, appeal])

    if(!appeal) {
        return <React.Fragment />
    }

    return (
        <Modal open={open} title='Șterge cale de atac'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi calea de atac din data
                    "<span>{lightFormat(new Date(appeal.date), 'dd/MM/yyyy')}</span>"?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={deleteAppeal}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open : state.modals.type === modalTypes.DELETE_APPEAL
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAppealModal)