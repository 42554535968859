import React, {useEffect, useState} from 'react'
import {HexColorPicker} from 'react-colorful'

import {values} from 'lodash'

import {defaultColors} from '../../constants'

import {Button} from '../../../Button'
import {ColorPreview} from '../../../ColorPreview'
import {Input} from '../../../Input'
import {Modal} from '../../../Modal'

import './ColorPickerModal.scss'

export default function ColorPickerModal({open, initialColor, onChange, onBlur, onClose}) {
    const [color, setColor] = useState(initialColor || '')

    useEffect(() => {
        if(open && color !== initialColor) {
            setColor(initialColor || '')
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialColor])

    const handleChoose = () => {
        onChange(color)
        onClose()
    }

    return (
        <Modal open={open} disableClosing>
            <div className="avo-color-picker-modal">
                <div className="avo-color-picker-modal-content-container">
                    <div className="avo-color-picker-modal-left">
                        <HexColorPicker color={color} onChange={(hex) => setColor(hex)} onBlur={onBlur} />
                        <Input
                            label='Culoare'
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            className='avo-color-picker-modal-color-input'
                            fullWidth
                        />
                    </div>

                    <div className="avo-color-picker-modal-right">
                        <div className="default-colors-title">
                            Culori prestabilite
                        </div>
                        <div className="default-colors-container">
                            {values(defaultColors).map((col, colorIdx) => (
                                <div onClick={() => setColor(col)} className='select-color-container'>
                                    <ColorPreview
                                        key={colorIdx}
                                        color={col}
                                        size='medium'
                                        className={color === col ? 'selected' : ''}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="avo-color-picker-modal-buttons-container">
                    <Button title='Renunță' onClick={onClose} color='gray' variant='text' />
                    <Button title='Folosește culoarea selectată' onClick={handleChoose} />
                </div>
            </div>
        </Modal>
    )
}
