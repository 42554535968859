import React, {useEffect, useState} from 'react'
import {push} from 'connected-react-router'
import {Formik} from 'formik'

import {omit, capitalize, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {createLegalRepresentative} from '../../../redux/contactPersons'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {objectKeysToSnakeCase, splitStringIntoNames} from '../../../utils'

import {Input} from '../../../components/Input'
import {Creatable} from '../../../components/Creatable'
import {PhoneInput} from '../../../components/PhoneInput'
import {DatePicker} from '../../../components/DatePicker'
import {FormSection} from '../../../components/FormSection'
import {Button} from '../../../components/Button'

import {ErrorsList} from '../../../components/ErrorComponents'

export const LegalRepresentative = ({
    client,
    isLoading,
    nonFieldErrors,
    fieldErrors,
    persons,
    createLegalRepresentative,
    navigatToClientDetails,
    selectedEntityID,
    listAllPersons
}) => {
    const [createNew, setCreateNew] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {listAllPersons(selectedEntityID)}, [])

    const handleCreateNewPerson = (newPersonName, setFieldValue ) => {
        const names = splitStringIntoNames(newPersonName)
        const lastName = names.lastName
        const firstName = names.firstName

        setFieldValue('lastName', capitalize(lastName))
        setFieldValue('firstName', capitalize(firstName))

        setCreateNew(true)
    }
    return (
        <div className="client-add-forms-container legal-representative">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',

                    //
                    person: null,
                    nationalIdNumber: '',
                    birthDate: '',
                    role: ''
                }}
                onSubmit={(values) => {
                    let personData = {}

                    if(createNew) {
                        personData = objectKeysToSnakeCase({
                            ...omit(values, 'person'),
                            client_id: client.id,
                            legal_representative: true,
                            receive_md_notifications: false,
                            receive_bpi_notifications: false,
                            phone: values.phone ? `+${values.phone}` : ''
                        })

                    } else {
                        personData = {
                            client_id: client.id,
                            person_id: values.person.value,
                            legal_representative: true
                        }
                    }

                    createLegalRepresentative(personData)
                }}
            >
                {({handleChange, setFieldValue, handleSubmit, values, resetForm}) => (
                    <FormSection
                        renderForm={() =>
                            <>
                                {!createNew ? (
                                    <>
                                        <p className="form-section-title">
                                            Selectează o persoană ca reprezentant legal
                                        </p>
                                        <Creatable
                                            label='Persoană'
                                            placeholder='Scrie aici numele persoanei...'
                                            value={values.person}
                                            options={persons}
                                            onChange={(e) => setFieldValue('person', e)}
                                            onCreateNew={(newPersonName) =>
                                                handleCreateNewPerson(newPersonName, setFieldValue)
                                            }
                                            fullWidth
                                        />
                                    </>
                                ) : (
                                    <>
                                        <p className="form-section-title">
                                            Creează o persoană nouă ca reprezentant legal
                                        </p>
                                        <div className="split-row">
                                            <Input
                                                label='Nume'
                                                value={values.lastName}
                                                onChange={handleChange('lastName')}
                                                name='lastName'
                                                errors={fieldErrors}
                                                fullWidth
                                            />
                                            <Input
                                                label='Prenume'
                                                value={values.firstName}
                                                onChange={handleChange('firstName')}
                                                name='firstName'
                                                errors={fieldErrors}
                                                fullWidth
                                            />
                                        </div>
                                        {/* <div className="split-row">
                                                <Input
                                                    label='Rol în client'
                                                    value={values.role}
                                                    onChange={handleChange('role')}
                                                    fullWidth
                                                /> */}
                                        {/* </div> */}
                                        <DatePicker
                                            label='Data nașterii'
                                            value={values.birthDate}
                                            onChange={(value) => setFieldValue('birthDate', new Date(value))}
                                            name='birthDate'
                                            errors={fieldErrors}
                                            fullWidth
                                        />
                                        <Input
                                            label='CNP'
                                            value={values.nationalIdNumber}
                                            onChange={handleChange('nationalIdNumber')}
                                            name='nationalIdNumber'
                                            errors={fieldErrors}
                                            fullWidth
                                        />
                                        <div className="split-row">
                                            <Input
                                                label='Email'
                                                value={values.email}
                                                onChange={handleChange('email')}
                                                name='email'
                                                errors={fieldErrors}
                                                fullWidth
                                            />
                                            <PhoneInput
                                                label='Telefon'
                                                value={values.phone}
                                                onChange={handleChange('phone')}
                                                name='phone'
                                                errors={fieldErrors}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="buttons-container">
                                            <Button
                                                title='Vreau să aleg o persoană existentă'
                                                onClick={() => {
                                                    setCreateNew(false)
                                                    resetForm()
                                                }}
                                                variant='outlined'
                                                size='small'
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        }
                        onSubmit={handleSubmit}
                        submitButtonTitle={createNew ?
                            'Crează persoana și salveaz-o ca reprezentant legal' :
                            'Salvează persoana ca reprezentant legal'
                        }
                        onSkip={() => navigatToClientDetails(client.id)}
                        skipButtonTitle='Sari peste'
                        loading={isLoading}
                    />
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => {
    let persons = []
    _values(state.persons.data).map((person) =>
        persons.push({value: person.id, label: `${person.last_name} ${person.first_name}`})
    )

    return {
        persons: persons,
        client: state.clients.currentClient,
        isLoading: state.contactPersons.isLoading,
        fieldErrors: state.contactPersons.fieldErrors,
        nonFieldErrors: state.contactPersons.nonFieldErrors,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    listAllPersons: (entityID) => dispatch(RESOURCES.persons.listAll({entity_id: entityID})),
    createLegalRepresentative: (values) => dispatch(createLegalRepresentative(values)),
    navigatToClientDetails: (clientID) => dispatch(push(`/projects/add?clientID=${clientID}`))
})

export default connect(mapStateToProps, mapDispatchToProps)(LegalRepresentative)
