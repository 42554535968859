import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {
    eachDayOfInterval,
    eachWeekOfInterval,
    isSameDay,
    isSameMonth,
    isToday,
    isWithinInterval,
    lightFormat
} from 'date-fns'
import {filter, isNil, isNull, values} from 'lodash'

import {connect} from 'react-redux'

import {classNames, invertColor, toApiDateFormat} from '../../../../../../utils'
import {getMonthEnds} from '../../constants'

import './CalendarMonthView.scss'

export const CalendarMonthView = ({tasks, hocSelectedDay, handleSelectHocSelectedDay}) => {
    const [days, setDays] = useState([])
    const [weeksInInterval, setWeeksInInterval] = useState(6)

    const getDayTasks = useCallback((day) => {
        return filter(tasks, (task) => {
            if(!isNil(task.start) && !isNil(task.stop)) {
                return isSameDay(new Date(day), new Date(task.start)) ||
                            isSameDay(new Date(day), new Date(task.stop))
            }

            if(!isNil(task.start)) {
                return isSameDay(new Date(day), new Date(task.start))
            }

            return null
        })
    }, [tasks])

    const createDaysArray = useCallback((date = new Date()) => {
        const {start, end} = getMonthEnds(new Date(date))
        const rawDays = eachDayOfInterval({start, end})
        const weeks = eachWeekOfInterval({start, end})

        return {
            weeksInInterval: weeks,
            days: rawDays.map((day) => ({
                date: toApiDateFormat(day),
                isCurrentMonth: isSameMonth(new Date(day), new Date(date)),
                events: getDayTasks(day)
            }))
        }
    }, [getDayTasks])

    useEffect(() => {
        const {days, weeksInInterval} = createDaysArray(new Date(hocSelectedDay))

        setDays(days)
        setWeeksInInterval(weeksInInterval.length - 1)
    }, [tasks, hocSelectedDay, createDaysArray])

    const isMultidayMiddle = useCallback(
        (event, day) =>
            event.start &&
            event.stop &&
            !isSameDay(new Date(day.date), new Date(event.start)) &&
            !isSameDay(new Date(day.date), new Date(event.stop)) &&
            isWithinInterval(new Date(day.date), {
                start: new Date(event.start),
                end: new Date(event.stop)
            }),
        []
    )

    const isMultiday = useCallback(
        (event) =>
            event.start &&
            event.stop &&
            !isSameDay(new Date(event.start), new Date(event.stop)),
        []
    )

    const isMultidayStart = useCallback(
        (event, day) =>
            isMultiday(event) && isSameDay(new Date(day.date), new Date(event.start)),
        [isMultiday]
    )

    const isMultidayStop = useCallback(
        (event, day) =>
            isMultiday(event) && isSameDay(new Date(day.date), new Date(event.stop)),
        [isMultiday]
    )

    const getMultiDayEventPosition = useCallback(
        (event, day) => {
            if (isMultidayStart(event, day)) {
                return 'avo-event-start'
            }

            if (isMultidayStop(event, day)) {
                return 'avo-event-stop'
            }

            if (isMultidayMiddle(event, day)) {
                return 'avo-event-middle'
            }

            return ''
        },
        [isMultidayMiddle, isMultidayStart, isMultidayStop]
    )

    return (
        <div className="avo-calendar-month-view-outer-container">
            <div className="avo-calendar-month-view-container">
                <div className="avo-calendar-month-view-header-container">
                    <div className="avo-calendar-month-view-header-day-container">
                        L<span>un.</span>
                    </div>
                    <div className="avo-calendar-month-view-header-day-container">
                        M<span>ar.</span>
                    </div>
                    <div className="avo-calendar-month-view-header-day-container">
                        M<span>ie.</span>
                    </div>
                    <div className="avo-calendar-month-view-header-day-container">
                        J<span>oi</span>
                    </div>
                    <div className="avo-calendar-month-view-header-day-container">
                        V<span>in.</span>
                    </div>
                    <div className="avo-calendar-month-view-header-day-container">
                        S<span>âm.</span>
                    </div>
                    <div className="avo-calendar-month-view-header-day-container">
                        D<span>um.</span>
                    </div>
                </div>
                <div className="avo-calendar-month-view-calendar-container">
                    {/* RESPONSIVE */}
                    {/* <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px"> */}
                    <div
                        className={classNames(
                            'avo-calendar-month-view-large-calendar-container',
                            weeksInInterval === 5 ? 'rows-5' : 'rows-6'
                        )}
                    >
                        {days.map((day, dayIdx) => (
                            <div
                                key={day.date}
                                className={classNames(
                                    'avo-calendar-month-view-day-container',
                                    day.isCurrentMonth && 'is-current-month'
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={classNames(
                                        'avo-calendar-month-view-day-number',
                                        isToday(new Date(day.date)) && 'today'
                                    )}
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                                {day.events.length > 0 && (
                                    <ol className="avo-calendar-month-view-day-events-list">
                                        {day.events.slice(0, 2).map((event) => (
                                            <li key={event.id}>
                                                <Link
                                                    to={`/tasks/${event.id}`}
                                                    className={`group avo-calendar-month-view-event-link ${
                                                        getMultiDayEventPosition(event, day)
                                                    }`}
                                                    style={{backgroundColor: event.color || ''}}
                                                >
                                                    <p
                                                        className="avo-calendar-month-view-event-title"
                                                        style={{
                                                            color: !isNull(event.color) ?
                                                                invertColor(event.color, true) :
                                                                ''
                                                        }}
                                                    >
                                                        {
                                                            (!isMultidayMiddle(event, day) &&
                                                                !isMultidayStop(event, day)) ||
                                                            (isMultidayMiddle(event, day) && dayIdx % 7 === 0)
                                                                ? event.title
                                                                : '\xa0'
                                                        }
                                                    </p>
                                                    {
                                                        !event.all_day && !isNull(event.start) && (
                                                            <time
                                                                dateTime={event.start}
                                                                className="avo-calendar-month-view-event-time"
                                                                style={{
                                                                    color: !isNull(event.color) ?
                                                                        invertColor(event.color, true) :
                                                                        ''
                                                                }}
                                                            >
                                                                {lightFormat(new Date(event.start), 'ha')}
                                                            </time>
                                                        )
                                                    }
                                                </Link>
                                            </li>
                                        ))}
                                        {day.events.length > 2 && (
                                            <Link
                                                to='/tasks/calendar/day'
                                                onClick={() => handleSelectHocSelectedDay( new Date(day.date))}
                                            >
                                                <li className="avo-calendar-month-view-more-text">
                                                    + încă {day.events.length - 2}
                                                </li>
                                            </Link>
                                        )}
                                    </ol>
                                )}
                            </div>
                        ))}
                    </div>
                    {/* RESPONSIVE */}
                    {/* <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                        {days.map((day) => (
                            <button
                                key={day.date}
                                type="button"
                                onClick={() => handleSelectHocSelectedDay(new Date(day.date))}
                                className={classNames(
                                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                    (day.isSelected || day.isToday) && 'font-semibold',
                                    day.isSelected && 'text-white',
                                    !day.isSelected && day.isToday && 'text-primary',
                                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                                    'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10'
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={classNames(
                                        day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                        day.isSelected && day.isToday && 'bg-primary',
                                        day.isSelected && !day.isToday && 'bg-gray-900',
                                        'ml-auto'
                                    )}
                                >
                                    {day.date.split('-').pop().replace(/^0/, '')}
                                </time>
                                <span className="sr-only">{day.events.length} events</span>
                                {day.events.length > 0 && (
                                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        {day.events.map((event) => (
                                            <span
                                                key={event.id}
                                                className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                                            />
                                        ))}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div> */}
                </div>
            </div>
            {/* RESPONSIVE */}
            {/* {selectedDay?.events.length > 0 && (
                <div className="py-10 px-4 sm:px-6 lg:hidden">
                    <ol
                        className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1
                        ring-black ring-opacity-5"
                    >
                        {selectedDay.events.map((event) => (
                            <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                                <div className="flex-auto">
                                    <p className="font-semibold text-gray-900">{event.title}</p>
                                    <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                                        <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        {event.time}
                                    </time>
                                </div>
                                <Link
                                    to='/tasks'
                                    className="self-center opacity-0 hover:bg-gray-50 focus:opacity-100
                                    group-hover:opacity-100"
                                >
                                    <Button title='Vizualizare' variant='outlined' size='small'/>
                                </Link>
                            </li>
                        ))}
                    </ol>
                </div>
            )} */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    tasks: values(state.tasks.data),
    isLoading: state.tasks.isLoading
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMonthView)