import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    projectId: Yup.object().label('Proiect').nullable().required('Proiectul e obligatoriu!'),
    ownersIds: Yup.array()
        .label('Deținători')
        .min(1, 'Selectează cel puțin un deținător')
        .required('Deținătorul e obligatoriu!'),
    supervisorsIds: Yup.array().label('Supraveghetori'),
    title: Yup.string().label('Titlu'),
    requesterId: Yup.object().label('Solicatant').nullable(),
    allDay: Yup.boolean().label('Toată ziua').nullable(),
    timePlanning: Yup.boolean(),
    start: Yup.date().label('Data start').nullable().when('timePlanning', {
        is: true,
        then: (schema) => schema.required('Data start este obligatorie!')
    }),
    stop: Yup.date().label('Data stop').nullable(),
    billable: Yup.boolean().label('De facturat'),
    taskSubscribers: Yup.array().label('Colaboratori interni anunțați'),
    location: Yup.string().label('Locație')
})