import React from 'react'

import _ from 'lodash'

import Clock from '../Clock/Clock'

import './ClockCard.scss'

export default function ClockCard({name}) {
    return (
        <div className="dashboard-card-container clock-dashboard-card">
            {!_.isNil(name) && (
                <div className="dashboard-card-content-container">
                    <div>
                        <h2 className="dashboard-card-title">Buna, {name}!</h2>
                        <p className="dashboard-card-description">
                            Aici găsești un overview al datelor tale din AvoApp.
                        </p>
                    </div>
                    <Clock />
                </div>
            )}
        </div>
    )
}
