import {toast} from 'react-toastify'
import {takeEvery, put} from 'redux-saga/effects'

import {ACTIONS as CLIENT_NOTES_ACTIONS} from 'avoapp-react-common/dist/redux/clientNotes'

import {closeModal} from './modals'

function handleCreateClientNoteSuccess() {
    toast.success('Observația a fost adăugată cu succes!')
}

function* handleDeleteClientNoteSuccess() {
    toast.success('Observația a fost ștearsă cu succes!')
    yield put(closeModal())
}

function* handleDeleteClientNoteFail() {
    toast.error('Observația NU a putut fi ștearsă! Vă rugăm să incercați din nou!')
    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(CLIENT_NOTES_ACTIONS.CREATE_CLIENT_NOTE.success, handleCreateClientNoteSuccess)
    yield takeEvery(CLIENT_NOTES_ACTIONS.DESTROY_CLIENT_NOTE.success, handleDeleteClientNoteSuccess)
    yield takeEvery(CLIENT_NOTES_ACTIONS.DESTROY_CLIENT_NOTE.fail, handleDeleteClientNoteFail)
}
