import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal} from './modals'

const {documentSeries: resource} = RESOURCES

function* handleCreateDocumentSeriesSuccess() {
    toast.success('Seria a fost creată cu succes')
    yield put(closeModal())
}

function* handleUpdateDocumentSeriesSuccess() {
    toast.success('Seria a fost editată cu succes')
    yield put(closeModal())
}

function* handleDestroyDocumentSeriesSuccess() {
    toast.success('Seria a fost ștearsă cu succes')

    yield put(push('/settings/documents/series'))
    yield put(closeModal())
}

function handleDestroyDocumentSeriesFail() {
    toast.error('Seria NU a putut fi ștearsă. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateDocumentSeriesSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateDocumentSeriesSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyDocumentSeriesSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyDocumentSeriesFail)
}
