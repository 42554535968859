import React, {Component} from 'react'

import {toString} from 'lodash'
import {parseISO, differenceInDays, differenceInSeconds} from 'date-fns'

import {connect} from 'react-redux'

import {zeroFill} from '../../../../utils'

import './Timer.scss'

class Timer extends Component {
    constructor() {
        super()

        this.state = {
            timeElapsed: ''
        }
    }

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 1000)
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    getTimeElapsed = () => {
        const {currentTimeLog} = this.props

        const currentTimeStartDate = parseISO(`${currentTimeLog.created}`)

        const secondsElapsed = differenceInSeconds(new Date(), new Date(currentTimeStartDate))

        const days = differenceInDays(new Date(), new Date(currentTimeStartDate))

        const hours = Math.floor(secondsElapsed / 3600)
        const minutes = Math.floor(secondsElapsed / 60 % 60)
        const seconds = secondsElapsed % 60

        let timeElapsed = ''

        if(days !== 0) timeElapsed = toString(days) + ' '

        if(hours !== 0) timeElapsed = timeElapsed + `${zeroFill(hours)}:`

        timeElapsed = timeElapsed + `${zeroFill(minutes)}:${zeroFill(seconds)}`

        return timeElapsed
    }

    tick = () => this.setState({timeElapsed: this.getTimeElapsed()})

    render() {
        const {timeElapsed} = this.state

        return (
            <p className='timer'>{timeElapsed}</p>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        currentTimeLog: state.taskTimeLogs.currentTaskTimeLog
    }
}

export default connect(mapStateToProps)(Timer)
