import React, {useCallback, useEffect, useMemo, useState} from 'react'

import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {getFieldOptions, toApiDateFormat} from '../../utils'

import {Loader} from '../Loader'

import './InvoiceInfobox.scss'

export const InvoiceInfobox = ({
    interval,
    project,
    contract,
    contractOptions,
    getContractOptions,
    selectedEntityID
}) => {
    const [timeLogReports, setTimeLogReports] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => { getContractOptions() }, [getContractOptions])

    const billingPaymentMethods = useMemo(() =>
        getFieldOptions(contractOptions, 'billing_payment_method')
    , [contractOptions])

    const getTaskTimeLogReports = useCallback(async () => {
        if(!_.isNil(interval) && !_.isNil(interval.start) && !_.isNil(interval.stop)) {
            let projectReport, contractReport = []
            const params = {
                entity_id: selectedEntityID,
                date__gte: toApiDateFormat(interval.start),
                date__lte: toApiDateFormat(interval.stop)
            }

            setIsLoading(true)

            if(!_.isNil(project?.id)) {
                await performRequest(
                    RESOURCES.taskTimeLogReports.list({
                        ...params,
                        project_id: project?.id

                    })
                ).then(({data}) => {
                    projectReport = data
                }).catch((err) => {
                    console.warn('Project task time log reports error: ', err)
                })
            }

            if(!_.isNil(contract?.id)) {
                await performRequest(
                    RESOURCES.taskTimeLogReports.list({
                        ...params,
                        contract_id: contract?.id
                    })
                ).then(({data}) => {
                    contractReport = data
                }).catch((err) => {
                    console.warn('Contract task time log reports error: ', err)
                })
            }

            const timeLogReports = _.mergeWith(contractReport, projectReport, (objValue, srcValue) => (
                _.omit({
                    ...objValue,
                    ...srcValue,
                    project_duration: srcValue?.duration_as_hours || 0,
                    contract_duration: objValue?.duration_as_hours || 0
                }, 'duration' )
            ))

            setIsLoading(false)
            setTimeLogReports(timeLogReports)
        }
    }, [contract?.id, interval, project?.id, selectedEntityID])

    useEffect(() => { getTaskTimeLogReports() }, [getTaskTimeLogReports])

    const contractInfo = useMemo(() => {
        if(!_.isNil(contract)) {
            let paymentTypeText
            let price = `${contract.billing_amount} ${contract.billing_currency}`
            let priceExtraHours = `${contract.billing_amount_for_extra_hours} ${contract.billing_currency}`
            const minHours = _.toNumber(contract.billing_minimum_hours)

            const paymentType = _.find(
                billingPaymentMethods,
                (method) => method.value === contract.billing_payment_method
            )

            paymentTypeText = paymentType.label

            if(paymentType === _.find(billingPaymentMethods, ['value', 'hourly_blended'])) {
                paymentTypeText = `${paymentType.label}, minim ${minHours} ore`
                price = `${price}/oră`
            }

            return {paymentTypeText, price, priceExtraHours}
        }

        return null
    }, [billingPaymentMethods, contract])

    const totalHours = useMemo(() => {
        let projectHours, contractHours = 0

        if(!_.isNil(timeLogReports) && !_.isEmpty(timeLogReports)) {
            projectHours = _.reduce(timeLogReports, (sum, record) => {
                return sum + _.toNumber(record.project_duration)
            }, 0)

            contractHours = _.reduce(timeLogReports, (sum, record) => {
                return sum + _.toNumber(record.contract_duration)
            }, 0)
        }

        return {contractHours, projectHours}
    }, [timeLogReports])

    return (
        <div className="infobox-container">
            <p className="infobox-title">Infobox</p>
            <div className="infobox-description">
                Pe baza filtrelor de mai sus, în intervalul de raportare setat, vă putem oferi
                următoarele informații de facturare
            </div>
            <div className="infobox-content">
                <div className="infobox-info-container">
                    <div className="infobox-title-container">
                        <p className='infobox-title'>Date contract</p>
                    </div>
                    {!_.isNil(contract) ? (
                        <>
                            <p className="infobox-text">
                                Tipul tarifării:{' '}
                                <span className="infobox-value">{contractInfo?.paymentTypeText}</span>
                            </p>
                            <p className="infobox-text">
                                Preț:{' '}
                                <span className="infobox-value">{contractInfo.price}</span>
                            </p>
                            {contract.billing_amount_for_extra_hours && (
                                <p className="infobox-text">
                                    Preț ore suplimetare:{' '}
                                    <span className="infobox-value">{contractInfo.priceExtraHours}</span>
                                </p>
                            )}
                        </>
                    ) : (
                        <p className="infobox-description">
                            Selectați un contract pentru a vedea aceste informații
                        </p>
                    )}
                </div>
                <div className="infobox-info-container">
                    <div className="infobox-title-container">
                        <p className='infobox-title'>Activitate utilizatori</p>
                        {isLoading && <Loader size='small' />}
                    </div>
                    {!_.isNil(timeLogReports) && !_.isEmpty(timeLogReports) && !isLoading ? (
                        <>
                            <div className="infobox-user-row infobox-user-header-container">
                                <p className="infobox-user-text">Utilizator</p>
                                <p className="infobox-user-text">Ore contract</p>
                                <p className="infobox-user-text">Ore proiect</p>
                            </div>
                            <div className="infobox-user-content-container">
                                {timeLogReports.map(({owner_name, owner_id, project_duration, contract_duration}) => (
                                    <div className="infobox-user-row" key={owner_id}>
                                        <div className="infobox-text infobox-user-bold-text">{owner_name}</div>
                                        <div className="infobox-text">
                                            {
                                                !_.isNil(contract) && !_.isEmpty(contract) ?
                                                    contract_duration :
                                                    '-'
                                            }
                                        </div>
                                        <div className="infobox-text">
                                            {
                                                !_.isNil(project) && !_.isEmpty(project) ?
                                                    project_duration :
                                                    '-'
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="infobox-user-row infobox-user-footer-container">
                                <p className="infobox-user-bold-text">Total ore</p>
                                <p className="infobox-user-bold-text">
                                    {!_.isNil(contract) && !_.isEmpty(contract) ? totalHours.contractHours :'-'}
                                </p>
                                <p className="infobox-user-bold-text">
                                    {!_.isNil(project) && !_.isEmpty(project) ? totalHours.projectHours :'-'}
                                </p>
                            </div>
                        </>
                    ) : !_.isNil(timeLogReports) && _.isEmpty(timeLogReports) && !isLoading ? (
                        <div className="infobox-description">
                            Nu există activități în intervalul selectat
                        </div>
                    ) : _.isNil(timeLogReports) && !isLoading ? (
                        <div className="infobox-description">
                            Selectați un interval pentru raportul de activitate pentru a vedea aceste informații
                        </div>
                    ) : null}
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => ({
    contractOptions: state.contracts.options,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    getContractOptions: () => dispatch(RESOURCES.contracts.getOptions())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfobox)