import React, {useCallback, useEffect, useState} from 'react'

import {
    addMonths,
    endOfDay,
    endOfMonth,
    endOfWeek,
    format,
    nextMonday,
    previousMonday,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subMonths
} from 'date-fns'
import {ro} from 'date-fns/locale'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {toApiDateFormat} from '../../../../utils'
import {calendarViewTypes} from '../../constants'

import {CalendarDayView, CalendarMonthView, CalendarPagination, CalendarViewSelect, CalendarWeekView} from './partials'

import './Calendar.scss'

export const Calendar = ({selectedEntityID, match: {params: {secondaryAction: calendarViewType}}, listAllTasks}) => {
    const [selectedDay, setSelectedDay] = useState(new Date())

    const handleFetchData = useCallback(() => {
        let params = {
            entity_id: selectedEntityID,
            page_size: 100
        }

        switch (calendarViewType) {
            case calendarViewTypes.DAY_VIEW:
                params = {
                    ...params,
                    start__lte: `${toApiDateFormat(endOfDay(new Date(selectedDay)))} 23:59`,
                    stop__gte: `${toApiDateFormat(startOfDay(new Date(selectedDay)))} 00:00`
                }

                return listAllTasks(params)
            case calendarViewTypes.WEEK_VIEW:
                params = {
                    ...params,
                    start__lte: `${toApiDateFormat(endOfWeek(new Date(selectedDay)))} 23:59`,
                    stop__gte: `${toApiDateFormat(startOfWeek(new Date(selectedDay)))} 00:00`
                }

                return listAllTasks(params)
            case calendarViewTypes.MONTH_VIEW:
                params = {
                    ...params,
                    start__lte: `${toApiDateFormat(endOfMonth(new Date(selectedDay)))} 23:59`,
                    stop__gte: `${toApiDateFormat(startOfMonth(new Date(selectedDay)))} 00:00`
                }

                return listAllTasks(params)
            default:
                return
        }
    }, [calendarViewType, listAllTasks, selectedDay, selectedEntityID])

    useEffect(() => { handleFetchData() }, [handleFetchData])

    const nextWeek = useCallback(() => {
        setSelectedDay(nextMonday(new Date(selectedDay)))
    }, [selectedDay])

    const previousWeek = useCallback(() => {
        setSelectedDay(previousMonday(new Date(selectedDay)))
    }, [selectedDay])

    const nextMonth = useCallback(() => {
        setSelectedDay(startOfMonth(addMonths(new Date(selectedDay), 1)))
    }, [selectedDay])

    const previousMonth = useCallback(() => {
        setSelectedDay(startOfMonth(subMonths(new Date(selectedDay), 1)))
    }, [selectedDay])

    const handleOnToday = useCallback(() => {
        setSelectedDay(new Date())
    }, [])

    const getCalendar = useCallback(() => {
        switch (calendarViewType) {
            case calendarViewTypes.DAY_VIEW:
                return (
                    <CalendarDayView
                        hocSelectedDay={selectedDay}
                        handleSelectHocSelectedDay={(day) => setSelectedDay(new Date(day))}
                        handlePreviousMonth={previousMonth}
                        handleNextMonth={nextMonth}
                    />
                )
            case calendarViewTypes.WEEK_VIEW:
                return (
                    <CalendarWeekView
                        hocSelectedDay={selectedDay}
                        handleSelectHocSelectedDay={(day) => setSelectedDay(new Date(day))}
                    />
                )
            case calendarViewTypes.MONTH_VIEW:
                return (
                    <CalendarMonthView
                        hocSelectedDay={selectedDay}
                        handleSelectHocSelectedDay={(day) => setSelectedDay(new Date(day))}
                    />
                )
            default:
                return
        }
    }, [calendarViewType, nextMonth, previousMonth, selectedDay])

    const handleOnNext = useCallback((_params) => {
        switch (calendarViewType) {
            case calendarViewTypes.WEEK_VIEW:
                return nextWeek()
            case calendarViewTypes.MONTH_VIEW:
                return nextMonth()
            default:
                return
        }
    }, [calendarViewType, nextMonth, nextWeek])

    const handleOnPrevious = useCallback((_params) => {
        switch (calendarViewType) {
            case calendarViewTypes.WEEK_VIEW:
                return previousWeek()
            case calendarViewTypes.MONTH_VIEW:
                return previousMonth()
            default:
                return
        }
    }, [calendarViewType, previousMonth, previousWeek])

    return (
        <div className='calendar-page-container'>
            <header className='calendar-page-header'>
                <div className="flex items-center gap-2">
                    <h1 className='selected-date'>
                        {format(new Date(selectedDay), 'MMMM yyyy', {locale: ro})}
                    </h1>
                </div>
                <div className='flex items-center'>
                    {calendarViewType !== calendarViewTypes.DAY_VIEW && (
                        <CalendarPagination
                            onPrevious={handleOnPrevious}
                            onToday={handleOnToday}
                            onNext={handleOnNext}
                        />
                    )}
                    <CalendarViewSelect calendarViewType={calendarViewType} onToday={handleOnToday} />
                </div>
            </header>
            <div className="calendar-container">
                {getCalendar()}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    listAllTasks: (params) => dispatch(RESOURCES.tasks.listAll(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)