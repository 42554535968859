import {Action} from 'avoapp-react-common/dist/redux'

import {select, takeEvery, put} from 'redux-saga/effects'
import {REHYDRATE} from 'redux-persist'
import jwtDecode from 'jwt-decode'

import {isFuture, fromUnixTime} from 'date-fns'

import {refreshToken} from 'avoapp-react-common/dist/redux/auth'

export const ACTIONS = {
    INITIALIZE: new Action('INITIALIZE'),
    INITIALIZED: new Action('INITIALIZED')
}

const initialState = {}

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ACTIONS.INITIALIZE.main:
            return state
        case ACTIONS.INITIALIZED.main:
            return state
        default:
            return state
    }
}

export function handleAppInitialization() {
    console.log('Do app init stuff...')
}

export function* handleRehydration(response) {
    console.log('Rehydrate...', response)

    const existingToken = yield select((state) => state.users.token)
    var decoded

    if (existingToken) {
        decoded = jwtDecode(existingToken)
    }

    if (decoded && isFuture(fromUnixTime(decoded.exp))) {
        yield put(refreshToken({token: existingToken}))
    }
}

export function* saga() {
    yield takeEvery(ACTIONS.INITIALIZE.main, handleAppInitialization)
    yield takeEvery(REHYDRATE, handleRehydration)
}

export const initialize = () => ({type: ACTIONS.INITIALIZE.main})
