import {endOfDay, startOfDay} from 'date-fns'

// @ts-ignore
import {toApiDateFormat} from '../../utils'

// GTE - Greater Than or Equal
// LTE - Less Than or Equal
export const todayTasksFilters = {
    start__lte: toApiDateFormat(startOfDay(new Date())),
    stop__gte: toApiDateFormat(endOfDay(new Date()))
}

export const pastTasksFilters = {
    stop__lte: toApiDateFormat(startOfDay(new Date()))
}