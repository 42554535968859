import React from 'react'
import {XCircleIcon} from '@heroicons/react/24/outline'

import './RedX.scss'

export default function RedX() {
    return (
        <XCircleIcon className='billable-icon red'/>
    )
}
