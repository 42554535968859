import {lightFormat} from 'date-fns'
import {isNull} from 'lodash'

export const columns = [
    {
        Header: 'Contract',
        accessor: 'contract',
        Cell: ({value: contract}) => contract ? contract.name : ''
    },
    {
        Header: 'Nume',
        accessor: 'name'
    },
    {
        Header: 'Număr',
        accessor: 'number'
    },
    {
        Header: 'Dată generare',
        accessor: 'created',
        Cell: ({value: created}) => created ? lightFormat(new Date(created), 'dd/MM/yyyy HH:mm') : '-'
    },
    {
        Header: 'Document semnat încărcat',
        accessor: 'signed_file',
        Cell: ({value: signed_file}) => !isNull(signed_file) ? 'Da' : 'Nu'
    }
]
