import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {Form, Formik, useFormikContext} from 'formik'

import {formatISO, lightFormat} from 'date-fns'
import {
    filter,
    find,
    get,
    includes,
    isEmpty,
    isNil,
    isNull,
    round,
    sumBy,
    toNumber,
    trim,
    uniq,
    values
} from 'lodash'

import {ExpenseTypes} from 'avoapp-react-common/dist/constants'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {getExchangeRate} from 'avoapp-react-common/dist/redux/exchangeRate'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {modalTypes} from '../../redux/modals'

import {expensesSchema} from '../../assets/validations'
import {getFieldOptions} from '../../utils'
import {debounceWait, RON_CURRENCY_VALUE} from '../../utils/constants'
import {getTokenAndUploadFile} from '../../utils/files'
import {useDebouncedEffect} from '../../utils/hooks'

import {Button} from '../Button'
import {Datatable} from '../Datatable'
import {DatePicker} from '../DatePicker'
import {DocumentDropzone} from '../DocumentDropzone'
import {ErrorsList} from '../ErrorComponents'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {RequiredFieldsText} from '../RequiredFieldsText'
import {Select} from '../Select'
import {Toggle} from '../Toggle'

import './AddExpenseModal.scss'

const getAmountRON = (amount, currency, exchangeRate) => {
    const amount_RON = round(exchangeRate * amount, 2)

    return currency === RON_CURRENCY_VALUE ? amount : amount_RON
}

const AddExpenseModal = ({
    open,
    projectId,
    isLoading,
    nonFieldErrors,
    fieldErrors,
    selectedEntityID,
    isLoadingContracts,
    isLoadingProjects,
    createExpense,
    expenseOptions,
    getExpensesOptions,
    getExchangeRate,
    exchangeRate
}) => {
    const [selectedProject, setSelectedProject] = useState(undefined)
    const [expensePaymentsIDs, setExpensePaymentsIDs] = useState([])
    const [expensePaymentsAmount, setExpensePaymentsAmount] = useState(0)
    const [expensePaymentsLoading, setExpensePaymentsLoading] = useState(false)
    const [expensePayments, setExpensePayments] = useState([])
    const [projects, setProjects] = useState([])
    const [contracts, setContracts] = useState([])
    const [contractsQuery, setContractsQuery] = useState('')

    useEffect(() => { getExpensesOptions() }, [getExpensesOptions])

    useEffect(() => { getExchangeRate() }, [getExchangeRate])

    const initializeProject = useCallback(async () => {
        if(!isNil(projectId)) {
            const {data} = await performRequest(RESOURCES_V2.projects.retrieve(projectId))

            setProjects([data])
            setSelectedProject(data)
        }
    }, [projectId])

    useEffect(() => { initializeProject() }, [initializeProject])

    const listExpensePayments = useCallback(async (entityID, filters) => {
        setExpensePaymentsLoading(true)
        const result = await performRequest(RESOURCES.expensePayments.list(
            {
                ...filters,
                entity_id: entityID,
                page_size: 100
            }
        ))
        setExpensePaymentsLoading(false)
        return result
    }, [])

    const fetchExpensePayments = useCallback(async () => {
        const {data} = await listExpensePayments(
            selectedEntityID,
            {
                project_id: projectId,
                status: [ExpenseTypes.NOT_COVERED, ExpenseTypes.PARTIALLY_COVERED]
            }
        )

        setExpensePayments(data.results)
    }, [listExpensePayments, projectId, selectedEntityID])

    useEffect(() => {
        if(open) {
            fetchExpensePayments()
        }
    }, [open, fetchExpensePayments])

    const handleFetchContracts = useCallback(() => {
        let params

        if(projectId) {
            params = {project_id: projectId}
        }

        performRequest(RESOURCES.contracts.search(contractsQuery, {entity_id: selectedEntityID, ...params}))
            .then((response) => setContracts(response.data.results) )
            .catch((err) => console.warn('Error while fetching contracts: ', err))
    }, [contractsQuery, projectId, selectedEntityID])

    useDebouncedEffect(handleFetchContracts, [contractsQuery, projectId], debounceWait)

    const handleChangeContractsSearchField = useCallback((value) => {
        const searchValue = trim(value)

        setContractsQuery(searchValue)
    }, [])

    const currencies = getFieldOptions(expenseOptions, 'currency')

    const expensePaymentsColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date',
                Cell: ({value: start}) => {
                    return start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-'
                }
            },
            {
                Header: 'Suma',
                accessor: 'amount',
                Cell: ({value: amount, row: {original: expensePayment}}) => {
                    const amountLeft = get(expensePayment, ['uncovered_allocation', 'amount'])
                    const currency = get(expensePayment, ['uncovered_allocation', 'currency'])

                    const sameAmount = amountLeft === amount
                    return sameAmount ? `${amount} ${expensePayment.currency}` :
                                `${amountLeft} ${currency} 
                                (din ${amount} ${expensePayment.currency})`
                }
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({value: expenseID, row: {original: expensePayment}}) => {
                    const {values} = useFormikContext()

                    const expenseAmountRON = getAmountRON(
                        values.amount, values.currency.value, values.exchangeRate
                    )

                    const shouldDisable = (
                        !includes(expensePaymentsIDs, expenseID) &&
                                ((expensePaymentsAmount > expenseAmountRON) || !values.amount)
                    )

                    return (
                        <Toggle
                            checked={includes(expensePaymentsIDs, expenseID)}
                            disabled={shouldDisable}
                            tooltipText={shouldDisable && 'Suma nu este suficientă.'}
                            onChange={(e) => {
                                let newExpensesIDS

                                if (e === true) {
                                    newExpensesIDS = uniq([
                                        ...expensePaymentsIDs, expenseID
                                    ])
                                    setExpensePaymentsIDs(
                                        newExpensesIDS
                                    )
                                }
                                else {
                                    newExpensesIDS = filter(
                                        expensePaymentsIDs, (item) => item !== expenseID
                                    )
                                    setExpensePaymentsIDs(
                                        newExpensesIDS
                                    )
                                }

                                setExpensePaymentsAmount(
                                    sumBy(
                                        filter(
                                            expensePayments,
                                            (item) => includes(newExpensesIDS, item.id)
                                        )
                                        , (o) => {
                                            const amount = get(
                                                o,
                                                ['uncovered_allocation', 'amount_RON'],
                                                o.amount_RON
                                            )

                                            return toNumber(amount)
                                        })
                                )
                            }}
                        />
                    )
                }
            }
        ]
    }, [expensePayments, expensePaymentsAmount, expensePaymentsIDs])

    return (
        <Modal open={open} title='Adaugă cheltuială' maxWidth='450px'>
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    projectId: selectedProject || null,
                    contractId: null,
                    date: new Date(),
                    amount: '',
                    currency: find(currencies, ['value', RON_CURRENCY_VALUE]),
                    description: '',
                    exchangeRate: '',
                    file: ''
                }}
                validationSchema={expensesSchema}
                onSubmit={async (values) => {
                    let fileURL = null

                    if(!isEmpty(values.file)) {
                        fileURL = await getTokenAndUploadFile(values.file, selectedEntityID)
                    }

                    const amount_RON = round(values.exchangeRate * values.amount, 2)

                    const expenseData = {
                        entity_id: selectedEntityID,
                        project_id: projectId,
                        contract_id: values.contractId?.id || null,
                        date: formatISO(values.date, {representation: 'date'}),
                        amount: values.amount,
                        amount_RON: values.currency.value === RON_CURRENCY_VALUE ? values.amount : amount_RON,
                        currency: values.currency.value,
                        exchange_rate: values.exchangeRate,
                        description: values.description,
                        file: fileURL,
                        expense_payments_ids: expensePaymentsIDs
                    }

                    createExpense(expenseData)
                }}
            >
                {({handleChange, setFieldValue, handleBlur, handleSubmit, values, touched, isValid, errors}) => (
                    <Form className='add-task-form-container'>
                        <div className="add-task-form-row">
                            <Select
                                label='Proiect*'
                                value={values.projectId}
                                options={projects}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                name='projectId'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.projectId}
                                loading={isLoadingProjects}
                                disabled
                                fullWidth
                            />
                            <Select
                                label='Contract'
                                value={values.contractId}
                                options={contracts}
                                loading={isLoadingContracts}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={async (e) => {
                                    setFieldValue('contractId', e)
                                }}
                                onInputChange={handleChangeContractsSearchField}
                                onBlur={handleBlur('contractId')}
                                name='contractId'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.contractId}
                                isClearable
                                fullWidth
                            />
                        </div>
                        <DatePicker
                            label='Data*'
                            value={values.date}
                            onChange={(date) => isNull(date) ?
                                setFieldValue('date', date) :
                                setFieldValue('date', new Date(date))
                            }
                            onBlur={handleBlur('date')}
                            name='date'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.date}
                            fullWidth
                        />
                        <div className="add-task-form-row">
                            <div className="w-full">
                                <Input
                                    label='Sumă*'
                                    value={values.amount}
                                    onChange={(e) => {
                                        handleChange('amount')(e)
                                        setExpensePaymentsIDs([])
                                        setExpensePaymentsAmount(0)
                                    }}
                                    onBlur={handleBlur('amount')}
                                    name='amount'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.amount}
                                    fullWidth
                                />
                            </div>
                            <Select
                                label='Moneda*'
                                value={values.currency}
                                options={currencies}
                                onChange={(e) => {
                                    setFieldValue('currency', e)
                                    if (e.value === RON_CURRENCY_VALUE) {
                                        setFieldValue('exchangeRate', '')
                                    }
                                    else {
                                        setFieldValue('exchangeRate', get(exchangeRate, e.value))
                                    }
                                }}
                                onBlur={handleBlur('currency')}
                                name='currency'
                                errors={fieldErrors.currency}
                                frontendErrors={errors}
                                touched={touched.currency}
                                fullWidth
                            />
                        </div>
                        {
                            values.currency.value !== RON_CURRENCY_VALUE && (
                            <div className="add-task-form-row">
                                <Input
                                    label='Curs Valutar*'
                                    value={values.exchangeRate}
                                    onChange={handleChange('exchangeRate')}
                                    onBlur={handleBlur('exchangeRate')}
                                    name='exchangeRate'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.exchangeRate}
                                    fullWidth
                                />
                                <Input
                                    label='Sumă RON*'
                                    value={round(values.exchangeRate * values.amount, 2)}
                                    name='amountRON'
                                    disabled={true}
                                    fullWidth
                                />
                            </div>
                            )
                        }
                        <div className="textarea-container">
                            <label className="note-textarea-label">Descriere</label>
                            <textarea
                                value={values.description}
                                className="note-textarea"
                                placeholder="Adaugă o descriere pentru aceasta cheltuială"
                                onChange={(e) => setFieldValue('description', e.target.value)}
                                name='description'
                                rows={3}
                            />
                        </div>
                        <DocumentDropzone
                            onChange={(file) => {
                                setFieldValue('file', file)
                            }}
                        />
                        <RequiredFieldsText />
                        <Datatable
                            title='Plăți'
                            data={expensePayments}
                            emptyText='Fără rezultate'
                            columns={expensePaymentsColumns}
                            loading={expensePaymentsLoading}
                        />
                        <Button
                            title='Adaugă cheltuială'
                            onClick={handleSubmit}
                            disabled={!isValid}
                            loading={isLoading}
                            color='secondary'
                            type='submit'
                            fullWidth
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_EXPENSE,
    expenseOptions: state.expenses.options,
    exchangeRate: state.exchangeRate.data,
    isLoading: state.expenses.isLoading,
    fieldErrors: state.expenses.fieldErrors,
    nonFieldErrors: state.expenses.nonFieldErrors,
    contracts: values(state.contracts.searchData),
    isLoadingContracts: state.contracts.isLoading,
    projects: values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    getExpensesOptions: () => dispatch(RESOURCES.expenses.getOptions()),
    getExchangeRate: () => dispatch(getExchangeRate()),
    createExpense: (data) => dispatch(RESOURCES.expenses.create(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddExpenseModal)
