import {goBack, push} from 'connected-react-router'
import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import {endsWith, findLast, head, isUndefined, startsWith} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal, modalTypes, openModal} from './modals'

const {addendums: resource} = RESOURCES

function* handleCreateAddendumSuccess() {
    toast.success('Actul adițional a fost adăugat cu succes')
    yield put(goBack())
}

function* handleUpdateAddendumSuccess() {
    toast.success('Modificările au fost salvate cu succes')

    const currentLocationPathname = head(yield select((state) => state.routerLocations)).location.pathname

    if(startsWith(currentLocationPathname, '/contracts') && endsWith(currentLocationPathname, '/settings')) {
        yield put(openModal(modalTypes.EDIT_CONTRACT_SETTINGS_DISCLAIMER))
    } else {
        yield put(closeModal())
    }
}

function handleUpdateAddendumFail() {
    toast.error('Modificările NU au putut fi salvate. Te rugam să încerci din nou!')
}

function* handleDestroyAddendumSuccess() {
    toast.success('Actul adițional a fost șters cu succes')

    const sourceLocation = yield select((state) => findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts/addendums' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/addendums'))
    ))

    yield put(push(!isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts/addendums'))
    yield put(closeModal())
}

function handleDestroyAddendumFail() {
    toast.error('Actul adițional NU a putut fi șters. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateAddendumSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateAddendumSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateAddendumFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyAddendumSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyAddendumFail)
}
