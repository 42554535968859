export const prefix = '/reports'

export const actionIds = {
    TIME_LOGS: 'time-logs'
}

export const actions = {
    [actionIds.TIME_LOGS]: {
        id: actionIds.TIME_LOGS,
        label: 'Pontaj'
    }
}
