import React from 'react'

import {fileTypes} from './constants'

import './FileTypeBadge.scss'

export default function FileTypeBadge({fileType}) {
    const getBadgeProps = () => {
        switch (fileType) {
            case fileTypes.PDF:
                return {
                    label: fileTypes.PDF,
                    color: 'red'
                }
            case fileTypes.DOC:
                return {
                    label: fileTypes.DOC,
                    color: 'secondary'
                }
            case fileTypes.DOCX:
                return {
                	label: fileTypes.DOCX,
                    color: 'secondary'
                }
            case fileTypes.JPG:
            case fileTypes.PNG:
                return {
                    label: 'IMG',
                    color: 'yellow'
                }
            case fileTypes.MP4:
                return {
                    label: 'VID',
                    color: 'purple'
                }
            default:
                return {
                    label: fileType,
                    color: 'primary'
                }
        }
    }

    const badgeProps = getBadgeProps()

    return <div className={`avo-file-type-badge ${badgeProps.color}`}>{badgeProps.label}</div>
}
