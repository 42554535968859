import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'

import {endOfMonth, lightFormat, startOfMonth} from 'date-fns'
import _, {join, toNumber} from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {openSlideover, slideoverTypes} from '../../../../redux/slideovers'

import {getEntityProfilesWithProjectPermissions, toApiDateFormat} from '../../../../utils'
import {roleTypes} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DetailsCard} from '../../../../components/DetailsCard'
import {PageLoader} from '../../../../components/PageLoader'
import {ResourceLogsList} from '../../../../components/ResourceLogsList'

import {DeleteProjectModal, MarkProjectInactiveModal} from './modals'
import {EditProjectInfoSlideover, EditProjectUsersAccessSlideover} from './slideovers'
import {keepPreviousData, useQuery} from '@tanstack/react-query'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/24/solid'

export const InfoGeneral = ({
    match: {params: {projectID}},
    selectedEntityID,
    isLoadingEntityProfiles,
    updateProjectActiveStatus,
    openModal,
    openSlideover
}) => {
    const [projectTimeLogsReports, setProjectTimeLogsReports] = useState(undefined)
    const [thisMonthTimeLogReports, setThisMonthTimeLogReports] = useState(undefined)
    const [project, setProject] = useState(null)
    const [isLoadingProjects, setIsLoadingProjects] = useState(false)
    const {
        data: invoicesReportsData
    } = useQuery({
        queryKey: [RESOURCES.invoiceReports.name, projectID],
        queryFn: async () => {
            const {data} = await performRequest(
                RESOURCES.invoiceReports.list({
                    entity_id: selectedEntityID,
                    project_id: projectID
                })
            )
            return data
        },
        placeholderData: keepPreviousData,
        enabled: !!projectID
    })

    const isProjectLoaded = useMemo(() => {
        return !_.isNil(project) && !_.isEmpty(project)
    }, [project])

    useEffect(() => {
        const fetchProject = async () => {
            setIsLoadingProjects(true)
            const {data: project} = await performRequest(RESOURCES.projects.retrieve(projectID))
            setIsLoadingProjects(false)
            return project
        }

        fetchProject().then((project) => setProject(project))
    },
    [projectID])

    const getProjectTaskTimeLogReports = useCallback(async () => {
        if(isProjectLoaded) {
            const {data: projectReports} = await performRequest(
                RESOURCES.taskTimeLogReports.list({
                    entity_id: selectedEntityID,
                    project_id: project?.id
                })
            )

            const {data: thisMonthReport} = await performRequest(
                RESOURCES.taskTimeLogReports.list({
                    entity_id: selectedEntityID,
                    project_id: project?.id,
                    date__gte: toApiDateFormat(startOfMonth(new Date())),
                    date__lte: toApiDateFormat(endOfMonth(new Date()))
                })
            )

            setProjectTimeLogsReports(() => projectReports || [])
            setThisMonthTimeLogReports(() => thisMonthReport || [])
        }
    }, [isProjectLoaded, project?.id, selectedEntityID])

    useEffect(() => { getProjectTaskTimeLogReports() }, [getProjectTaskTimeLogReports])

    const projectInfoRows = useMemo(() => {
        if(isProjectLoaded) {
            return [
                {label: 'Nume/Denumire', value: project.name},
                {label: 'Tip proiect', value: project.type},
                {label: 'Clienți', value: _.join(project.clients.map((client) => client.name), ', ')},
                {label: 'Obiect Legal', value: project.legal_object ? project.legal_object.name : '-'}
            ]
        }

        return []
    }, [isProjectLoaded, project?.clients, project?.name, project?.type, project?.legal_object])

    const accessRows = useMemo(() => {
        if(isProjectLoaded) {
            return [
                {
                    label: 'Manageri',
                    value: getEntityProfilesWithProjectPermissions(
                        project.project_permissions,
                        roleTypes.MANAGER.value
                    )
                },
                {
                    label: 'Colaboratori Interni',
                    value: getEntityProfilesWithProjectPermissions(
                        project.project_permissions,
                        roleTypes.COLLABORATOR.value
                    )
                }
            ]
        }

        return []
    }, [isProjectLoaded, project?.project_permissions])

    const instanceInfoRows = useMemo(() => {
        if(isProjectLoaded) {
            return [
                {
                    label: 'Următorul termen',
                    value: project.latest_phase.date
                        ? lightFormat(new Date(project.latest_phase.date), 'dd/MM/yyyy HH:mm')
                        : null
                } ,
                {label: 'Număr dosar', value: project.latest_phase.number},
                {label: 'Fază procesuală', value: project.latest_phase.phase}
            ]
        }

        return []
    }, [isProjectLoaded, project?.latest_phase?.date, project?.latest_phase?.number, project?.latest_phase?.phase])

    const calculateWorkedHours = useCallback((reports) => {
        return _.reduce(reports, (sum, record) => {
            return parseFloat(sum + toNumber(record.duration_as_hours))
        }, 0)
    }, [])

    const timeTrackingRows = useMemo( () => {
        if(isProjectLoaded) {
            return [
                {label: 'Ore lucrate total', value: calculateWorkedHours(projectTimeLogsReports)},
                {label: 'Ore lucrate luna aceasta', value: calculateWorkedHours(thisMonthTimeLogReports)}
            ]
        }

        return []
    }, [calculateWorkedHours, isProjectLoaded, projectTimeLogsReports, thisMonthTimeLogReports])

    const financialRows = useMemo(() => {
        const formatCurrency = (value, currency) => toNumber(value) && `${toNumber(value)} ${currency}`
        return [
            {
                label: 'Onorariu facturat',
                value: join([
                    formatCurrency(invoicesReportsData?.RON?.total_with_VAT, 'RON'),
                    formatCurrency(invoicesReportsData?.EUR?.total_with_VAT, 'EUR')
                ].filter(Boolean), ', ')
            },
            {
                label: 'Onorariu încasat',
                value: join([
                    formatCurrency(invoicesReportsData?.RON?.total_paid, 'RON'),
                    formatCurrency(invoicesReportsData?.EUR?.total_paid, 'EUR')
                ].filter(Boolean), ', ')
            },
            {
                label: 'Onorariu de încasat',
                value: join([
                    formatCurrency(invoicesReportsData?.RON?.total_unpaid, 'RON'),
                    formatCurrency(invoicesReportsData?.EUR?.total_unpaid, 'EUR')
                ].filter(Boolean), ', ')
            }
        ]
    }, [invoicesReportsData])

    return (
        <>
            {!_.isEmpty(project) && !isLoadingProjects ? (
                <div className="page-project-show">
                    <div className="row">
                        <div className="left">
                            <DetailsCard
                                title='Informații proiect'
                                rows={projectInfoRows}
                                loading={isLoadingProjects}
                                headerButton={() =>
                                    <Button
                                        title='Editează'
                                        size='small'
                                        onClick={() => openSlideover(slideoverTypes.EDIT_PROJECT_INFO)}
                                    />
                                }
                            />
                        </div>
                        <div className="right">
                            <DetailsCard
                                title='Acces utilizatori'
                                rows={accessRows}
                                loading={isLoadingEntityProfiles}
                                headerButton={() =>
                                    <Button
                                        title='Editează'
                                        size='small'
                                        onClick={() => openSlideover(slideoverTypes.EDIT_PROJECT_USERS_ACCESS)}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">
                            <DetailsCard
                                title='Detalii instanță'
                                rows={instanceInfoRows}
                                loading={isLoadingProjects}
                                headerButton={() => (
                                    <Link to={`/projects/${projectID}/external-data/monitor-dosare`}>
                                        <Button title='Vezi toate termenele' size='small' />
                                    </Link>
                                )}
                            />
                        </div>
                        <div className="right">
                            <DetailsCard
                                title='Pontaj'
                                rows={timeTrackingRows}
                                loading={isLoadingProjects}
                                headerButton={() =>
                                    <Link to='/tasks/time-logs/week'>
                                        <Button
                                            title='Vezi pontaj'
                                            size='small'
                                        />
                                    </Link>
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="left">
                            <DetailsCard
                                title='Financiar'
                                rows={financialRows}
                                loading={isLoadingProjects}
                                headerButton={() =>
                                    <a
                                        href={`projects/${projectID}/invoices`}
                                        rel="noreferrer"
                                    >
                                        <Button
                                            title='Vezi facturi'
                                            size='small'
                                            icon={() => <ArrowTopRightOnSquareIcon />}
                                        />
                                    </a>
                                }
                            />
                        </div>
                        <div className="right">
                            <ResourceLogsList resourceID={projectID} resourceName={RESOURCES_V1.projects.name}/>
                        </div>
                    </div>
                    <div className='mt-10 flex gap-5 justify-end'>
                        <Button
                            title={`Marchează proiectul ca  ${project.active ? 'inactiv' : 'activ'}`}
                            color={`${project.active ? 'red' : 'secondary'}`}
                            size='large'
                            variant='outlined'
                            onClick={() =>
                                project.active ?
                                    openModal(modalTypes.MARK_PROJECT_INACTIVE) :
                                    updateProjectActiveStatus(project)
                            }
                        />
                        <Button
                            title='Șterge proiect'
                            color='red'
                            size='large'
                            onClick={() => openModal(modalTypes.DELETE_PROJECT)}
                        />
                    </div>
                </div>
            ) :
                <PageLoader />
            }
            <DeleteProjectModal />
            <MarkProjectInactiveModal />
            <EditProjectInfoSlideover />
            <EditProjectUsersAccessSlideover />
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    isLoadingLogs: state.logs.isLoading
})

const mapDispatchToProps = dispatch => ({
    openSlideover: (slideoverType) => dispatch(openSlideover(slideoverType)),
    openModal: (modalType) => dispatch(openModal(modalType)),
    updateProjectActiveStatus: (project) => dispatch(RESOURCES_V1.projects.update({active: true}, project.id))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoGeneral)