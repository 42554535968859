import React from 'react'

import {isEmpty, isNull} from 'lodash'

import {connect} from 'react-redux'
import {removeSignedFile} from 'avoapp-react-common/dist/redux/contracts'
import {closeModal, modalTypes} from '../../../../redux/modals'

import {Modal} from '../../../../components/Modal'
import {Button} from '../../../../components/Button'

import '../../../../assets/scss/DeleteModals.scss'

function DeleteSignedContractModal({open, contract, isLoading, closeModal, removeSignedFile}) {
    return (
        <Modal open={open && !isEmpty(contract) && !isNull(contract.signed_file)} title='Șterge contract semnat'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi documentul semnat pentru contractul "<span>{contract.name}</span>"?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => removeSignedFile(contract.id)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open : state.modals.type === modalTypes.DELETE_SIGNED_CONTRACT,
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    removeSignedFile: (contractID) => dispatch(removeSignedFile(contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSignedContractModal)