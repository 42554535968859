import React from 'react'
import {InvoiceExternalSyncModal} from '../InvoiceExternalSyncModal'

const GeneralModals = () => {
    return (
        <>
            <InvoiceExternalSyncModal />
        </>
    )
}
export default GeneralModals
