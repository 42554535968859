import React from 'react'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import _ from 'lodash'

import './InvoiceExternalSyncModal.scss'
import {Button} from '../Button'
import {push} from 'connected-react-router'
import {externalSyncActions} from '../../pages/Invoices/constants'

export const AddInvoicePaymentModal = ({
    open,
    closeModal,
    data,
    navigate
}) => {

    const action = data?.action
    const errorMessage = data?.errorMessage

    const invoiceID = _.get(data, 'invoiceID')
    const invoicePaymentID = _.get(data, 'invoicePaymentID')

    return (
        <Modal open={open} title="Sincronizare document SmartBill">
            <div className="avo-errors-modal-content-container">
                <div className="avo-errors-modal-message-container">
                    {
                        action === externalSyncActions.CREATE && (
                            <span>
                                Ne pare rău, deși documentul a fost creat cu succes în AvoApp,
                                am întâmpinat următoarea eroare în momentul
                                sincronizării cu SmartBill{errorMessage && (<i>: {errorMessage}</i>)}. <br/>
                                Te rugăm să ne contactezi la suport pentru a rezolva
                                această situație în cel mai scurt timp.
                            </span>
                        )
                    }
                    {
                        action === externalSyncActions.MANUAL_UPDATE && (
                            <span>
                                SmartBill nu ne permite să modificăm prin API (acces programatic)
                                facturile deja sincronizate. Dacă doriți să editați această factură,
                                statusul sincronizării se va schimba în "necesită sincronizare manuală"
                                (afișează și iconița) și va trebui să faceți modificările necesare manual,
                                din contul SmartBill. Ulterior modificării manuale în SmartBill,
                                se poate modifica statusul din
                                "necesită sincronizare manuală" în "sincronizat cu succes"
                            </span>
                        )
                    }
                    {
                        invoiceID && (action === externalSyncActions.CANCEL) && (
                            <span>
                                Ne pare rău, nu putem anula factura în AvoApp deoarece am
                                întâmpinat următoarea eroare în momentul
                                sincronizării cu SmartBill:{errorMessage && (<i>: {errorMessage}</i>)}. <br/>
                                Te rugăm să ne contactezi la suport pentru a rezolva
                                această situație în cel mai scurt timp.
                            </span>
                        )
                    }
                    {
                        invoicePaymentID && (action === externalSyncActions.CANCEL) && (
                            <span>
                                Ne pare rău, nu putem anula încasarea in SmartBill deoarece SmarBill nu permite
                                acest lucru. Te rugăm să ne contactezi la suport pentru a rezolva această situație.
                            </span>
                        )
                    }
                    {
                        action === externalSyncActions.DELETE && (
                            <span>
                                Ne pare rău, nu putem șterge documentul din AvoApp deoarece am
                                întâmpinat următoarea eroare în momentul
                                sincronizării cu SmartBill{errorMessage && (<i>: {errorMessage}</i>)}. <br/>
                                Te rugăm să ne contactezi la suport pentru a rezolva
                                această situație în cel mai scurt timp.
                            </span>
                        )
                    }
                </div>
                <div className="avo-errors-modal-buttons-container">
                    <Button title='Am înțeles' onClick={closeModal} fullWidth />
                    <Button
                        title='Contactează-ne'
                        onClick={() => {
                            navigate('/support')
                            closeModal()
                        }}
                        fullWidth
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.INVOICE_EXTERNAL_SYNC_MODAL,
    data: state.modals.data
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    navigate: (route) => dispatch(push(route))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoicePaymentModal)
