import {ArrowLeftIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'

import {endsWith, findLast, isEmpty, isUndefined, startsWith} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'

import {actionIds, actions} from './constants'

import {Button} from '../../components/Button'
import {Loader} from '../../components/Loader'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {
    AddendumMetaInfo,
    AddendumPreview,
    AddendumSettings,
    EditAddendumNameForm,
    SignedAddendumPreview
} from './partials'

import './AddendumDetails.scss'

export const AddendumDetails = ({
    addendum,
    isLoading,
    contract,
    isLoadingContracts,
    match,
    sourceLocation,
    navigate,
    location,
    history,
    retrieveAddendum,
    retrieveContract
}) => {
    const {addendumID, primaryAction} = match.params

    useEffect(() => {
        retrieveAddendum(addendumID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(!isEmpty(addendum) && parseInt(contract.id) !== parseInt(addendum.contract_id) && !isLoadingContracts) {
            retrieveContract(addendum.contract_id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addendum])

    const urlData = {prefix: `/contracts/addendums/${addendumID}`, params: match.params, location, history}

    switch(primaryAction) {
        case actionIds.INFO:
            return (
                <AddendumDetailsLayout
                    urlData={urlData}
                    addendum={addendum}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<AddendumMetaInfo match={match} />}
                />
            )
        case actionIds.SETTINGS:
            return (
                <AddendumDetailsLayout
                    urlData={urlData}
                    addendum={addendum}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<AddendumSettings match={match} urlData={urlData} />}
                />
            )
        case actionIds.PREVIEW:
            return (
                <AddendumDetailsLayout
                    urlData={urlData}
                    addendum={addendum}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<AddendumPreview match={match} urlData={urlData} />}
                />
            )
        case actionIds.SIGNED:
            return (
                <AddendumDetailsLayout
                    urlData={urlData}
                    addendum={addendum}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<SignedAddendumPreview match={match} />}
                />
            )

        default:
            return <Redirect to={`${urlData.prefix}/${actionIds.INFO}`} />
    }
}

export function AddendumDetailsLayout({addendum, loading, component, urlData, goBack}) {
    const [editMode, setEditMode] = useState(false)

    return (
        <div className='resource-details-content'>
            <div className='back-button-container'>
                <Button
                    title='Mergi înapoi'
                    size='small'
                    color='gray'
                    variant='outlined'
                    onClick={goBack}
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            <div className="header-resource-title-container">
                {!loading && !editMode ? (
                    <div className="addendum-name-container">
                        <p className="header-resource-title">
                            {addendum.name}
                        </p>
                        <Button
                            icon={() => <PencilSquareIcon />}
                            onClick={() => setEditMode(true)}
                            size='small'
                        />
                    </div>
                ) : !loading && editMode ? (
                    <EditAddendumNameForm handleCancel={() => setEditMode(false)} />
                ) : loading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : null}
            </div>
            <SecondaryNavbar actions={actions} urlData={urlData}/>
            <div>{component}</div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const sourceLocation = findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts/addendums' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/addendums')) ||
            (startsWith(pathname, '/contracts') && endsWith(pathname, '/addendums'))
    )

    return {
        addendum: state.addendums.currentAddendum,
        isLoading: state.addendums.isLoading,
        contract: state.contracts.currentContract,
        isLoadingContracts: state.contracts.isLoading,
        sourceLocation: !isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts/addendums'
    }
}

const mapDispatchToProps = (dispatch) => ({
    navigate: (route) => dispatch(push(route)),
    retrieveAddendum: (addendumID) => dispatch(RESOURCES.addendums.retrieve(addendumID)),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddendumDetails)