export const actionIds = {
    INFO: 'info',
    INFO_GENERAL: 'general',
    INFO_CONTACT_PERSONS: 'contact',
    INFO_NOTES: 'notes',

    EXTERNAL_DATA: 'external-data',
    EXTERNAL_DATA_MD: 'monitor-dosare',
    EXTERNAL_DATA_BPI: 'monitor-bpi',

    TASKS: 'tasks',
    TASKS_ACTIVE: 'active',
    TASKS_FINISHED: 'finished',

    CONTRACTS: 'contracts',
    CONTRACTS_CONTRACTS: 'contracts-contracts',
    CONTRACTS_EMPOWERMENTS: 'empowerments',
    CONTRACTS_ADDENDUMS: 'addendums',

    DOCUMENTS: 'documents',

    EXPENSES: 'expenses',

    INVOICES: 'invoices',

    HISTORY: 'history'
}

export const actions = {
    [actionIds.INFO]: {
        id: actionIds.INFO,
        label: 'Informații proiect',
        secondaryActions: [
            {
                id: actionIds.INFO_GENERAL,
                label: 'Generale'
            },
            {
                id: actionIds.INFO_CONTACT_PERSONS,
                label: 'Persoane contact'
            },
            {
                id: actionIds.INFO_NOTES,
                label: 'Observații'
            }
        ]
    },
    [actionIds.TASKS]: {
        id: actionIds.TASKS,
        label: 'Sarcini',
        secondaryActions: [
            {
                id: actionIds.TASKS_ACTIVE,
                label: 'Sarcini active'
            },
            {
                id: actionIds.TASKS_FINISHED,
                label: 'Sarcini finalizate'
            }
        ]
    },
    [actionIds.CONTRACTS]: {
        id: actionIds.CONTRACTS,
        label: 'Contracte',
        secondaryActions: [
            {
                id: actionIds.CONTRACTS_CONTRACTS,
                label: 'Contracte'
            },
            {
                id: actionIds.CONTRACTS_ADDENDUMS,
                label: 'Acte adiționale'
            },
            {
                id: actionIds.CONTRACTS_EMPOWERMENTS,
                label: 'Împuterniciri'
            }
        ]
    },
    [actionIds.DOCUMENTS]: {
        id: actionIds.DOCUMENTS,
        label: 'Documente'
    },
    [actionIds.EXTERNAL_DATA]: {
        id: actionIds.EXTERNAL_DATA,
        label: 'Date externe',
        secondaryActions: [
            {
                id: actionIds.EXTERNAL_DATA_MD,
                label: 'Monitor Dosare'
            },
            {
                id: actionIds.EXTERNAL_DATA_BPI,
                label: 'Monitor BPI'
            }
        ]
    },
    [actionIds.EXPENSES]: {
        id: actionIds.EXPENSES,
        label: 'Cheltuieli'
    },
    [actionIds.INVOICES]: {
        id: actionIds.INVOICES,
        label: 'Facturi'
    },
    [actionIds.HISTORY]: {
        id: actionIds.HISTORY,
        label: 'Istoric'
    }
}
