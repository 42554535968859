import {lightFormat} from 'date-fns'
import {isNull} from 'lodash'
import {TableLink} from '../../../../components/TableLink'

export const columns = [
    {
        Header: 'Contract',
        accessor: 'contract',
        Cell: ({value: contract}) => contract ? contract.name : ''
    },
    {
        Header: 'Nume',
        accessor: 'name',
        Cell: ({row: {values}}) => <TableLink to={`/contracts/empowerments/${values.id}`}>{values.name}</TableLink>,
        style: {maxWidth: '20vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Număr',
        accessor: 'number',
        Cell: ({row: {original: doc}}) => `${doc.internal_series_name} (${doc.internal_series_number})`
    },
    {
        Header: 'Dată generare',
        accessor: 'created',
        Cell: ({value: created}) => created ? lightFormat(new Date(created), 'dd/MM/yyyy') : '-'
    },
    {
        Header: 'Document semnat',
        accessor: 'signed_file',
        Cell: ({value: signed_file}) => !isNull(signed_file) ? 'Da' : 'Nu',
        style: {maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}
    }
]
