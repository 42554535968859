import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, find, debounce} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {closeSlideover} from '../../../../redux/slideovers'
import {addFilters} from '../../../../redux/filters/filters'

import {debounceWait} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {Select} from '../../../../components/Select'
import {ErrorsList} from '../../../../components/ErrorComponents'

import '../../../../assets/scss/SlideoverForms.scss'

export class FilterDocumentsForm extends Component {
    constructor() {
        super()

        this.state = {
            projectsQuery: ''
        }
    }

    componentDidMount() {
        this.handleFetchProjects()
    }

    handleFetchProjects = (query = this.state.projectsQuery) => {
        const {searchProjects} = this.props

        searchProjects(query)
    }

    debounceSearchProjects = debounce(this.handleFetchProjects, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({projectsQuery: value})
        this.debounceSearchProjects(value)
    }

    handleFilter = (filters) => {
        const {addFilters, filterDocuments, closeSlideover} = this.props

        addFilters(filters)
        filterDocuments()
        closeSlideover()
    }

    getInitialProject = () => {
        const {filters, projects} = this.props

        return find(projects, (project) => filters.project.value === project.id)
    }

    render() {
        const {projects, isLoadingProjects, isLoading, fieldErrors, nonFieldErrors} = this.props

        return (
            <div className="slideover-form-container">
                <ErrorsList errors={nonFieldErrors} />
                <Formik
                    initialValues={{project: this.getInitialProject()}}
                    onSubmit={(values) => {
                        const filtersData = {
                            project: {
                                value: values.project?.id || '',
                                displayValue: values.project?.name || ''
                            }
                        }

                        this.handleFilter(filtersData)
                    }}
                >
                    {({setFieldValue, handleSubmit, values}) => (
                        <>
                            <Form className='slideover-form'>
                                <Select
                                    label='Proiect'
                                    value={values.project}
                                    options={projects}
                                    onChange={(e) => setFieldValue('project', e)}
                                    onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    loading={isLoadingProjects}
                                    name='projects'
                                    errors={fieldErrors}
                                    fullWidth
                                />
                            </Form>
                            <div className="buttons-container">
                                <Button
                                    title='Aplică'
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    type='submit'
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters.documents,
    isLoading: state.documents.isLoading,
    fieldErrors: state.documents.fieldErrors,
    nonFieldErrors: state.documents.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    projects: _values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.documents.name, filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterDocumentsForm)