import React, {useCallback, useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import {useFormik} from 'formik'
import _ from 'lodash'
import {toast} from 'react-toastify'

import {connect} from 'react-redux'
// @ts-ignore
import {closeModal} from '../../redux/modals'
// @ts-ignore

// @ts-ignore
import {objectKeysToSnakeCase} from '../../utils'

// @ts-ignore
import {Input} from '../Input'
// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'

import './HearingForm.scss'

interface PhaseFormProps {
    hearingToEdit?: any;
    currentPhaseID: string | undefined;
    isMultistepForm?: boolean;
    onSubmit: (hearingData: any) => any;
    goToNextStep?: () => void;
    closeModal: () => void;
}

function HearingForm({
    hearingToEdit,
    currentPhaseID,
    isMultistepForm = false,
    onSubmit,
    goToNextStep,
    closeModal
}: PhaseFormProps) {
    const [hasAddedHearing, setHasAddedHearing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasClickedNext, setHasClickedNext] = useState(false)

    const {
        values,
        // Returns true if values are not deeply equal from initial values
        dirty,
        touched,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm
    } = useFormik({
        initialValues: {
            date: hearingToEdit?.moment ? new Date(hearingToEdit.moment) : new Date(),
            time: hearingToEdit?.moment ? new Date(hearingToEdit.moment) : new Date(),
            fullCourt: hearingToEdit?.full_court ? hearingToEdit.full_court : '',
            documentDate: hearingToEdit?.document_date ? new Date(hearingToEdit.document_date) : null,
            pronouncementDate: hearingToEdit?.pronouncement_date ? new Date(hearingToEdit.pronouncement_date) : null,
            hearingDocument: hearingToEdit?.hearing_document ? hearingToEdit.hearing_document : '',
            solution: hearingToEdit?.solution ? hearingToEdit.solution : '',
            solutionSummary: hearingToEdit?.solution_summary ? hearingToEdit.solution_summary : ''
        },
        onSubmit: () => {
            const finalValue = {
                ...objectKeysToSnakeCase(values),
                phase_id: currentPhaseID,
                date: lightFormat(values.date, 'yyyy-MM-dd'),
                time: lightFormat(values.time, 'HH:mm'),
                document_date: !_.isNil(values.documentDate) ?
                    lightFormat(values.documentDate, 'yyyy-MM-dd') :
                    null,
                pronouncement_date: !_.isNil(values.pronouncementDate) ?
                    lightFormat(values.pronouncementDate, 'yyyy-MM-dd') :
                    null,
                solution: values.solution || null,
                solution_summary: values.solutionSummary || null
            }

            localOnSubmit(finalValue)
        }
    })

    const onSuccess = useCallback(() => {
        toast.success('Salvat')

        if(isMultistepForm) {
            setHasAddedHearing(true)

            if(hasClickedNext && goToNextStep) {
                goToNextStep()
            } else {
                resetForm()
            }
        } else {
            closeModal()

        }
    }, [closeModal, goToNextStep, hasClickedNext, isMultistepForm, resetForm])

    const localOnSubmit = useCallback(async (values) => {
        setIsLoading(true)

        onSubmit(values).then((res: any) => {
            setIsLoading(false)

            if(!res.errors) {
                onSuccess()
            } else {
                _.forEach(_.values(res.errors), (error) => {
                    toast.error(error.message)
                })
            }
        })
    }, [onSubmit, onSuccess])

    const saveButtonTitle = useMemo(() => {
        if(isMultistepForm) {
            return 'Salvează și adaugă încă un termen'
        }

        return 'Salvează'
    }, [isMultistepForm])

    const nextStepButtonTitle = useMemo(() => {
        if(dirty) {
            return 'Salvează și mergi spre căi de atac'
        }

        return 'Mergi spre căi de atac'
    }, [dirty])

    const onNextStepClick = useCallback(() => {
        if(dirty) {
            setHasClickedNext(true)
            handleSubmit()
        } else {
            if(goToNextStep) {
                goToNextStep()
            }
        }
    }, [dirty, goToNextStep, handleSubmit])

    return (
        <form className="add-md-manual-subscription-hearing-form">
            <DatePicker
                label="Data*"
                value={values.date}
                onChange={(date: any) => _.isNil(date) ?
                    setFieldValue('date', date) :
                    setFieldValue('date', new Date(date))
                }
                onBlur={handleBlur('date')}
                name='date'
                touched={touched.date}
                fullWidth
            />
            <DatePicker
                label="Ora*"
                value={values.time}
                timeSelect
                timeFormat='HH:mm'
                onChange={(time: any) => _.isNil(time) ?
                    setFieldValue('time', time) :
                    setFieldValue('time', new Date(time))
                }
                onBlur={handleBlur('time')}
                name='time'
                touched={touched.time}
                fullWidth
            />
            <Input
                label="Complet"
                value={values.fullCourt}
                onChange={handleChange('fullCourt')}
                onBlur={handleBlur('fullCourt')}
                name='fullCourt'
                touched={touched.fullCourt}
                fullWidth
            />
            <DatePicker
                label="Data document"
                value={values.documentDate}
                onChange={(documentDate: any) => _.isNil(documentDate) ?
                    setFieldValue('documentDate', documentDate) :
                    setFieldValue('documentDate', new Date(documentDate))
                }
                onBlur={handleBlur('documentDate')}
                name='documentDate'
                touched={touched.documentDate}
                fullWidth
            />
            <DatePicker
                label="Data ședință"
                value={values.pronouncementDate}
                onChange={(pronouncementDate: any) => _.isNil(pronouncementDate) ?
                    setFieldValue('pronouncementDate', pronouncementDate) :
                    setFieldValue('pronouncementDate', new Date(pronouncementDate))
                }
                onBlur={handleBlur('pronouncementDate')}
                name='pronouncementDate'
                touched={touched.pronouncementDate}
                fullWidth
            />
            <Input
                label="Document ședință"
                value={values.hearingDocument}
                onChange={handleChange('hearingDocument')}
                onBlur={handleBlur('hearingDocument')}
                name='hearingDocument'
                touched={touched.hearingDocument}
                fullWidth
            />
            <Input
                label="Soluție"
                value={values.solution}
                onChange={handleChange('solution')}
                onBlur={handleBlur('solution')}
                name='solution'
                touched={touched.solution}
                fullWidth
            />
            <div className="textarea-container">
                <label className="note-textarea-label">Sumar soluție</label>
                <textarea
                    value={values.solutionSummary}
                    className="note-textarea"
                    onChange={(e) => setFieldValue('solutionSummary', e.target.value)}
                    name='solutionSummary'
                    rows={3}
                />
            </div>
            <RequiredFieldsText />
            <Button
                title={saveButtonTitle}
                onClick={handleSubmit}
                loading={isLoading}
                fullWidth
            />
            {hasAddedHearing && (
                <Button
                    title={nextStepButtonTitle}
                    onClick={onNextStepClick}
                    variant='outlined'
                    color='gray'
                    fullWidth
                />
            )}
        </form>
    )
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(HearingForm)