import React from 'react'

import {differenceInMinutes} from 'date-fns'
import {round} from 'lodash'

import {getDiffTime, getIsoFromTime, toApiTimeFormat} from '../../../../../../utils'

import './TaskTimeLogContainer.scss'

export default function TaskTimeLogContainer({log, onClickLog}) {
    const getDifference = () => {
        const startISO = getIsoFromTime(log.date, log.start)
        const stopISO = getIsoFromTime(log.date, log.stop)

        return differenceInMinutes(stopISO, startISO)
    }

    const formatTime = (time) => {
        if(time) {
            const isoTime = getIsoFromTime(log.date, time)

            return toApiTimeFormat(isoTime, true)
        }

        return '-'
    }

    const height = round(getDifference() / 15)

    return (
        <div
            className='task-time-log-container'
            onClick={() => onClickLog(log)}
            style={{height: `${height}rem`, minHeight: '6rem'}}
        >
            <div className="task-project-container">
                <p className='task-project-name'>{log.project?.name}</p>
                <p className='task-name'>{log.task?.title || '-'}</p>
            </div>
            <div className="time-container">
                <p className="start-stop">{formatTime(log.start)} - {formatTime(log.stop)}</p>
                <p className='diff-time'>{getDiffTime(log)}</p>
            </div>
        </div>
    )
}
