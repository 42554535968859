import CellWithCheck from './CellWithCheck'

export const addressesColumns = [
    {Header: 'Adresă', accessor: 'address'},
    {Header: 'Județ', accessor: 'county'},
    {Header: 'Localitate / Oraș', accessor: 'locality'},
    {Header: 'Țară', accessor: 'country'},
    {
        Header: 'Descriere adresă',
        accessor: 'address_description',
        Cell: (props) => {
            const {original: data} = props.row

            return (
                <CellWithCheck
                    value={props.value}
                    isPrimary={data.is_primary}
                />
            )
        }
    }
]

export const bankAccountsColumns = [
    {Header: 'IBAN', accessor: 'iban'},
    {Header: 'Monedă', accessor: 'currency'},
    {Header: 'Banca', accessor: 'bank_name'},
    {
        Header: 'Descriere',
        accessor: 'bank_account_description',
        Cell: (props) => {
            const {original: data} = props.row

            return (
                <CellWithCheck
                    value={props.value}
                    isPrimary={data.is_primary}
                />
            )
        }
    }
]
