export const defaultColors = {
    tomato: '#d50000',
    flamingo: '#e67c73',
    tangerine: '#f4511e',
    banana: '#f6bf26',
    sage: '#33b679',
    basil: '#0b8043',
    peacock: '#039be5',
    // blueberry: '#3f51b5',
    lavander: '#7986cb',
    grape: '#8e24aa',
    graphite: '#616161'
}
