import {toast} from 'react-toastify'
import {takeEvery, put} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent} from 'avoapp-react-common/dist/redux/clientBankAccounts'

import {closeModal} from './modals'
import {closeSlideover} from './slideovers'

const {clientBankAccounts: resource} = RESOURCES

function* handleCreateClientBankAccountsSuccess() {
    toast.success('Contul bancar a fost creat cu succes')

    yield put(closeSlideover())
}

function* handleRetrieveClientBankAccountsSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleUpdateClientBankAccountsSuccess() {
    toast.success('Modificările au fost salvate')

    yield put(closeSlideover())
}

function* handleDestroyClientBankAccountSuccess() {
    toast.success('Contul bancar a fost șters')

    yield put(closeModal())
}

function* handleDestroyClientBankAccountFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateClientBankAccountsSuccess)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveClientBankAccountsSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateClientBankAccountsSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyClientBankAccountSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyClientBankAccountFail)
}
