export const prefix = '/contracts'

export const actionIds = {
    CONTRACTS: 'contracts',
    ADDENDUMS: 'addendums',
    EMPOWERMENTS: 'empowerments'
}

export const actions = {
    [actionIds.CONTRACTS]: {
        id: actionIds.CONTRACTS,
        label: 'Contracte'
    },
    [actionIds.ADDENDUMS]: {
        id: actionIds.ADDENDUMS,
        label: 'Acte adiționale'
    },
    [actionIds.EMPOWERMENTS]: {
        id: actionIds.EMPOWERMENTS,
        label: 'Împuterniciri'
    }
}
