import React, {Component} from 'react'
import {ArrowRightOnRectangleIcon, UserCircleIcon} from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom'

import {find} from 'lodash'

import {connect} from 'react-redux'
import {logout} from 'avoapp-react-common/dist/redux/auth'
import {clearEntityIDS, clearTaskTimeLogID} from 'avoapp-react-common/dist/redux/localConfigs'

export class ProfileSection extends Component {
    handleLogout = () => {
        const {logout, clearEntityIDS, clearTaskTimeLogID} = this.props

        logout()
        clearEntityIDS()
        clearTaskTimeLogID()
    }

    render() {
        const{user, selectedEntity} = this.props

        return (
            <div className="profile-section">
                <div className="flex-shrink-0 w-full">
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <UserCircleIcon aria-hidden="true" className='h-10 w-10 text-white' />
                            <div className="ml-3">
                                <p className="user-name">{user.first_name} {user.last_name}</p>
                                {selectedEntity && (
                                    <>
                                        <p className="selected-entity">{selectedEntity.name}</p>
                                        <Link to='/select-entity' className='change-entity-link'>
                                            Schimbă compania
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='logout-button-container'>
                            <ArrowRightOnRectangleIcon
                                aria-hidden="true"
                                className='logout-icon'
                                onClick={this.handleLogout}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    const selectedEntityID = state.localConfigs.selectedEntityID
    const selectedEntity = find(state.entityProfiles.belongingEntities, ['id', selectedEntityID])

    return {
        user: state.users.user,
        selectedEntity: selectedEntity
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    clearEntityIDS: () => dispatch(clearEntityIDS()),
    clearTaskTimeLogID: () => dispatch(clearTaskTimeLogID())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSection)
