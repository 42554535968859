import React from 'react'

import {isEmpty} from 'lodash'
import {lightFormat} from 'date-fns/esm'

import {connect} from 'react-redux'
import {destroyProjectNote} from 'avoapp-react-common/dist/redux/projectNotes'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

function DeleteProjectNoteModal({open, projectNote, projectID, isLoading, onClose, closeModal, deleteProjectNote}) {
    const handleCloseModal = () => {
        closeModal()
        onClose()
    }

    return (
        <Modal open={open && !isEmpty(projectNote)} title='Șterge observație' onClose={handleCloseModal}>
            {!isEmpty(projectNote) && (
                <div className='delete-modal'>
                    <p className='message'>
                        Ești sigur că vrei să ștergi observația creată de{' '}
                        <span>
                            {`${projectNote.entity_profile.first_name} ${projectNote.entity_profile.last_name}`}
                        </span>
                        {' '}din data de{' '}
                        <span>"{lightFormat(new Date(projectNote.created), 'dd/MM/yyyy')}"</span>?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            variant='outlined'
                            loading={isLoading}
                            color='gray'
                        />
                        <Button
                            title='Da, șterge'
                            onClick={() => deleteProjectNote(projectNote, projectID)}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_PROJECT_NOTE,
    isLoading: state.projectNotes.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteProjectNote: (projectNote, projectID) => dispatch(destroyProjectNote(projectNote, projectID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectNoteModal)