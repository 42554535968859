import React, {useCallback, useMemo} from 'react'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {Toggle} from '../Toggle'

interface LitigationsListProps {
    litigations: any[],
    selectedLitigations: any[],
    handleAddLitigation: (number: string) => void,
    handleRemoveLitigation: (number: string) => void
}

export default function LitigationsList({
    litigations,
    selectedLitigations,
    handleAddLitigation,
    handleRemoveLitigation
}: LitigationsListProps) {
    return (
        <div className='litigations-list'>
            {litigations.map((litigation, litigationIdx) => (
                <LitigationCard
                    litigation={litigation}
                    selectedLitigations={selectedLitigations}
                    onAdd={handleAddLitigation}
                    onRemove={handleRemoveLitigation}
                    key={litigationIdx}
                />
            ))}
        </div>
    )
}

function LitigationCard({litigation, selectedLitigations, onAdd, onRemove}: any) {
    const isSelected = useMemo(() => {
        return _.some(selectedLitigations, (lit) => lit.litigation_number === litigation.number)
    }, [litigation.number, selectedLitigations])

    const onCheck = useCallback(
        () => {
            if(!isSelected) {
                onAdd(litigation.number)
            } else {
                onRemove(litigation.number)
            }
        }, [isSelected, litigation.number, onAdd, onRemove])

    return (
        <div className="litigation-card">
            <div className="card-header">
                <p className="card-title">{litigation.number}</p>
            </div>
            <div className="card-content">
                <div className="card-content-child">
                    <p className="title"> Instanța: </p>
                    <p className="subtitle"> {litigation.court} </p>
                </div>
                <div className="card-content-child">
                    <p className="title"> Următorul termen: </p>
                    <p className="subtitle">
                        {
                            !_.isEmpty(litigation.hearings) ?
                                lightFormat(new Date(litigation.hearings[0].date), 'dd/MM/yyyy HH:mm') :
                                '-'
                        }
                    </p>
                </div>
                <div className="card-content-child">
                    <p className="title"> Părți: </p>
                    <p className="subtitle"> {_.join(litigation.parties.map((party: any) => party.name), ',')} </p>
                </div>
                <div className="card-content-child">
                    <p className="title"> Faza: </p>
                    <p className="subtitle"> {litigation.phase} </p>
                </div>
            </div>
            <div className="card-footer">
                <Toggle
                    label=" Selectează pentru monitorizare"
                    checked={isSelected}
                    onChange={onCheck}
                />
            </div>
        </div>
    )
}
