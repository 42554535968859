import React from 'react'
import {Link} from 'react-router-dom'
import {BellIcon, Bars3BottomLeftIcon} from '@heroicons/react/24/outline'

import {TimeTracker} from '../TimeTracker'

import './Navbar.scss'

export default function Navbar(props) {
    const {setSidebarOpen} = props

    return (
        <div className="navbar-container">
            <button className='hamburger-menu-button' onClick={() => setSidebarOpen(true)}>
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="navbar-icon" aria-hidden="true" />
            </button>
            <div className="navbar-content-container">
                <div className="left-placeholder" />
                <TimeTracker />
                <div className="right-navbar-container">
                    <Link to='/notifications' className='notifications-link'>
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="navbar-icon" aria-hidden="true" />
                    </Link>
                </div>
            </div>
        </div>
    )
}
