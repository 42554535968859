import React, {useCallback, useMemo, useState} from 'react'

import {useFormik} from 'formik'
import _ from 'lodash'
import {toast} from 'react-toastify'

import {connect} from 'react-redux'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {closeModal} from '../../redux/modals'

// @ts-ignore
import {Input} from '../Input'
// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'

import './PartyForm.scss'

interface PartyFormProps {
    currentPhaseID: string | undefined;
    isMultistepForm?: boolean,
    goToNextStep?: () => void;
    closeModal: () => void;
}

function PartyForm({currentPhaseID, isMultistepForm = false, goToNextStep, closeModal}: PartyFormProps) {
    const [hasAddedParty, setHasAddedParty] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hasClickedNext, setHasClickedNext] = useState(false)

    const {
        values,
        // Returns true if values are not deeply equal from initial values
        dirty,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm
    } = useFormik({
        initialValues: {name: '', role: ''},
        onSubmit: () => {
            const finalValues = {...values, phase_id: currentPhaseID}
            onSubmit(finalValues)
        }
    })

    const onCreateSuccess = useCallback(() => {
        toast.success('Salvat')

        if(isMultistepForm) {
            setHasAddedParty(true)

            if(hasClickedNext && goToNextStep) {
                goToNextStep()
            } else {
                resetForm()
            }
        } else {
            closeModal()

        }
    }, [closeModal, goToNextStep, hasClickedNext, isMultistepForm, resetForm])

    const onSubmit = useCallback(async (values) => {
        setIsLoading(true)
        performRequest(RESOURCES.parties.create(values)).then((res: any) =>{
            setIsLoading(false)

            if(!res.errors) {
                onCreateSuccess()
            } else {
                _.forEach(_.values(res.errors), (error) => {
                    toast.error(error.message)
                })
            }
        })
    }, [onCreateSuccess])

    const saveButtonTitle = useMemo(() => {
        if(isMultistepForm) {
            return 'Salvează și adaugă încă o parte'
        }

        return 'Salvează'
    }, [isMultistepForm])

    const nextStepButtonTitle = useMemo(() => {
        if(dirty) {
            return 'Salvează și mergi spre termene'
        }

        return 'Mergi spre termene'
    }, [dirty])

    const onNextStepClick = useCallback(() => {
        if(dirty) {
            setHasClickedNext(true)
            handleSubmit()
        } else {
            if(goToNextStep) {
                goToNextStep()
            }
        }
    }, [dirty, goToNextStep, handleSubmit])

    return (
        <form className="add-md-manual-subscription-party-form">
            <Input
                label="Nume parte*"
                value={values.name}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
                name='name'
                touched={touched.name}
                fullWidth
            />
            <Input
                label="Rol parte*"
                value={values.role}
                onChange={handleChange('role')}
                onBlur={handleBlur('role')}
                name='role'
                touched={touched.role}
                fullWidth
            />
            <RequiredFieldsText />
            <Button
                title={saveButtonTitle}
                onClick={handleSubmit}
                loading={isLoading}
                fullWidth
            />
            {hasAddedParty && (
                <Button
                    title={nextStepButtonTitle}
                    onClick={onNextStepClick}
                    variant='outlined'
                    color='gray'
                    fullWidth
                />
            )}
        </form>
    )
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps =(dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(PartyForm)