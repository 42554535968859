import React, {Component} from 'react'
import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import {Link} from 'react-router-dom'

import {filter, includes, debounce} from 'lodash'

import {connect} from 'react-redux'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {modalTypes, openModal} from '../../redux/modals'
import {openSlideover, slideoverTypes} from '../../redux/slideovers'

import {clientsColumns} from './constants'
import {debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'

import {Button} from '../../components/Button'
import {Datatable} from '../../components/Datatable'

import {EditClientGroupSlideover, DeleteClientGroupModal} from './partials'

import './ClientGroupDetails.scss'

export class ClientGroupDetails extends Component {
    constructor() {
        super()

        this.state = {
            searchFilter: ''
        }
    }

    componentDidMount = () => {
        const {retrieveClientGroup, match} = this.props
        const {clientGroupID} = match.params

        retrieveClientGroup(clientGroupID)
        this.handleFetchClients()
    }

    handleFetchClients = (query = this.state.searchFilter, page = 1) => {
        const {selectedEntityID, listClients, match} = this.props
        const {clientGroupID} = match.params

        listClients(selectedEntityID, clientGroupID, query, page)
    }

    debounceSearchClients = debounce(this.handleFetchClients, debounceWait)

    handleChangeSearchField = (value) => {
        this.setState({searchFilter: value})
        this.debounceSearchClients(value)
    }

    handleChangePage = (page) => {
        const {searchFilter} = this.state
        const {isLoadingClients} = this.props

        if(!isLoadingClients) {
            this.handleFetchClients(searchFilter, page)
        }
    }

    render() {
        const {searchFilter} = this.state
        const {clientGroup, openEditClientGroupSlideover, openDeleteClientGroupModal} = this.props
        const {clients, isLoadingClients, totalClients, totalPages, nextPage, previousPage, currentPage} = this.props

        return (
            <div className='client-group-details-container'>
                <div className='back-button-container'>
                    <Link to='/clients/groups'>
                        <Button
                            title='Mergi înapoi la lista grupurilor de clienți'
                            size='small'
                            color='gray'
                            variant='outlined'
                            icon={() => <ArrowLeftIcon />}
                            iconLeft
                        />
                    </Link>
                </div>
                <div className="client-group-details-header-container">
                    <p className='client-group-details-header-title'>{clientGroup.name}</p>
                </div>
                <div className='client-group-details-content-container'>
                    <Datatable
                        title={`Listă clienți (${totalClients})`}
                        data={clients}
                        columns={clientsColumns}
                        loading={isLoadingClients}
                        totalPages={totalPages}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentPage={currentPage}
                        onChangePage={(page) => this.handleChangePage(page)}
                        searchable
                        searchValue={searchFilter}
                        searchPlaceholder='Caută clienți'
                        onSearch={(event) => this.handleChangeSearchField(event.target.value)}
                    />
                    <div className='mt-10 flex gap-5 justify-end'>
                        <Button
                            title='Editează grup clienți'
                            size='large'
                            variant='outlined'
                            onClick={openEditClientGroupSlideover}
                        />
                        <Button
                            title='Șterge grup clienți'
                            color='red'
                            size='large'
                            onClick={openDeleteClientGroupModal}
                        />
                    </div>
                </div>
                <DeleteClientGroupModal />
                <EditClientGroupSlideover />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const clientGroup = state.clientGroups.currentClientGroup
    const clients = filter(state.clients.data, (client) => includes(clientGroup.client_ids, client.id))

    return {
        clientGroup: clientGroup,
        clients: clients,
        isLoadingClients: state.clients.isLoading,
        totalClients: state.clients.count,
        totalPages: state.clients.totalPages,
        nextPage: state.clients.next,
        previousPage: state.clients.previous,
        currentPage: state.clients.current
    }
}

const mapDispatchToProps = (dispatch) => ({
    openDeleteClientGroupModal: () => dispatch(openModal(modalTypes.DELETE_CLIENT_GROUP)),
    openEditClientGroupSlideover: () => dispatch(openSlideover(slideoverTypes.EDIT_CILENT_GROUP)),
    retrieveClientGroup: (clientGroupID) => dispatch(RESOURCES.clientGroups.retrieve(clientGroupID)),
    listClients: (entity_id, group_id, search, page) => dispatch(
        RESOURCES.clients.list(
            {
                entity_id: entity_id,
                group_id: group_id,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ClientGroupDetails)