import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, isEmpty, isNil, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {uploadSignedFile} from 'avoapp-react-common/dist/redux/addendums'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {removeFilter} from '../../../../redux/filters/filters'

import {columns} from './constants'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'

// import FilterAddendumsForm from '../forms/FilterAddendumsForm'

export const AddendumsList = ({
    addendums,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    filters,
    removeFilter,
    listAddendums,
    selectedEntityID
}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchAddendums = (search = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listAddendums(search, appliedFilters, page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {handleFetchAddendums()}, [])

    const debounceSearchAddendums = debounce((search) => handleFetchAddendums(search), debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchAddendums(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchAddendums(searchFilter, page)

    const handleDropSignedFile = async (selectedFile, addendumID) => {
        if(!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.addendums.update({signed_file: fileURL}, addendumID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    return (
        <Datatable
            // title='Listă acte adiționale'
            data={addendums}
            columns={[
                ...columns,
                {
                    Header: 'Acțiuni',
                    accessor: 'id',
                    Cell: ({value: addendumID, row: {original: addendum}}) => (
                        <div className='flex gap-2 items-center'>
                            <Link to={`/contracts/addendums/${addendumID}/preview`}>
                                <Button title='Vizualizare' variant='text' size='small' />
                            </Link>
                            <Dropdown
                                title='Descarcă'
                                disabled={
                                    isNil(addendum.file_meta?.url_public) && isNil(addendum.signed_file)
                                }
                                items={[
                                    {
                                        customContent: () => addendum.file_meta?.url_public ? (
                                            <a
                                                href={addendum.file_meta?.url_public}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='avo-dropdown-menu-item'
                                            >
                                                Descarcă
                                            </a>
                                        ) : <React.Fragment />
                                    },
                                    {
                                        customContent: () => addendum.signed_file ? (
                                            <a
                                                href={addendum.signed_file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='avo-dropdown-menu-item'
                                            >
                                                Descarcă fișier semnat
                                            </a>
                                        ) : <React.Fragment />
                                    }
                                ]}
                                variant='text'
                                color='secondary'
                                size='small'
                            />
                            <Dropdown
                                title='Încarcă'
                                items={[
                                    {
                                        customContent: () => (
                                            <SignedFileDropzone
                                                onChange={(selectedFile) => (
                                                    handleDropSignedFile(selectedFile, addendumID)
                                                )}
                                            />
                                        )
                                    }
                                ]}
                                variant='text'
                                color='orange'
                                size='small'
                            />
                        </div>
                    ),
                    disableSortBy: true
                }
            ]}
            previousPage={previousPage}
            currentPage={currentPage}
            nextPage={nextPage}
            totalPages={totalPages}
            onChangePage={(page) => handleChangePage(page)}
            // filterable
            // filters={filters}
            // filtersForm={() => <FilterAddendumsForm filterAddendums={handleFetchAddendums} />}
            // handleRemoveFilter={(filter) => {
            //     removeFilter(filter)
            //     handleFetchAddendums()
            // }}
            searchable
            searchValue={searchFilter}
            searchPlaceholder='Caută acte adiționale'
            onSearch={(event) => handleChangeSearchField(event.target.value)}
        />
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    addendums: orderBy(state.addendums.data, ['signed_date', 'id'], ['desc', 'desc']),
    isLoading: state.addendums.isLoading,
    totalPages: state.addendums.totalPages,
    nextPage: state.addendums.next,
    previousPage: state.addendums.previous,
    currentPage: state.addendums.current,
    filters: state.filters.addendums
})

const mapDispatchToProps = (dispatch) => ({
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.addendums.name, filter)),
    uploadSignedFile: (addendumData, addendumID) => dispatch(uploadSignedFile(addendumData, addendumID)),
    listAddendums: (search, filters, page) => dispatch(
        RESOURCES.addendums.list(
            {
                ...filters,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(AddendumsList)