import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import {connect} from 'react-redux'
import {activateAccount} from 'avoapp-react-common/dist/redux/auth'

import {Loader} from '../../components/Loader'

import logo from '../../assets/images/avoapp-logo-albastru.png'

import './AccountActivation.scss'

export class AccountActivation extends Component {
    componentDidMount() {
        const {activateAccount, match} = this.props
        const {activationCode} = match.params

        activateAccount(activationCode)
    }

    render() {
        const {isLoading, accountActivated} = this.props

        return (
            <div className="account-activation-container">
                <div className="main-container">
                    <div className="header">
                        <img className="logo" src={logo} alt="AvoApp"/>
                        <h1 className="title">Activare cont</h1>
                    </div>
                    <div className="content">
                        {
                            isLoading ?
                                <LoadingComponent /> :
                                accountActivated ? <AccountActivated /> : <AccountActivationFail />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class LoadingComponent extends Component {
    render() {
        return (
            <div className='account-activation'>
                <p className='activation-message'>
                    Așteaptă salvarea răspunsului tău
                </p>
                <Loader size='large' />
            </div>
        )
    }

}

class AccountActivated extends Component {
    render() {
        return (
            <div className='account-activation'>
                <p className='activation-message'>
                    Contul tau a fost confirmat! Poți începe monitorizarea dosarelor tale de instanță chiar acum.
                </p>
                <Link to='/login' className='login-link'>Mergi la login</Link>
            </div>
        )
    }
}

class AccountActivationFail extends Component {
    render() {
        return (
            <div className='account-activation'>
                <p className='activation-message'>
                    <span>Oups! </span>Pare că linkul de activare a expirat. <br />
                    Am retrimis chiar acum un alt link pe adresa ta de email pentru a putea fi validat.
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    accountActivated: state.auth.accountActivated,
    isLoading: state.auth.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    activateAccount: (key) => dispatch(activateAccount({key: key}))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivation)
