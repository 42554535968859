import React, {Component} from 'react'

import {filter, isNull, orderBy} from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {logsOrderingOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {Loader} from '../../../../components/Loader'
import {LogRow} from '../../../../components/ResourceLogsList/partials/LogRow'

import './History.scss'

export class History extends Component {
    componentDidMount() {
        const {match, listLogs} = this.props
        const {projectID} = match.params

        listLogs(projectID)
    }

    render() {
        const {logs, isLoading, nextPage, project} = this.props

        return (
            <div className="history-container">
                <div className="history-page-title-container">
                    <h2 className="page-title">Istoric</h2>
                    {isLoading && <Loader size='medium' />}
                </div>
                <div className="logs-list-container">
                    <ul className="logs-list">
                        {logs.map((log, logIdx) => (
                            <LogRow log={log} isLast={logIdx === logs.length - 1} resource={project} key={log.id} />
                        ))}
                    </ul>
                </div>
                {nextPage && (
                    <div className="button-container">
                        <Button title='Încarcă mai multe' fullWidth/>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const logs = orderBy(
        filter(state.logs.data, (log) => !(log.action === 'update' && isNull(log.changes))),
        'created',
        'desc'
    )

    return {
        project: state.projects.currentProject,
        logs: logs,
        isLoading: state.logs.isLoading,
        nextPage: state.logs.next
    }
}

const mapDispatchToProps = (dispatch) => ({
    listLogs: (projectID) => dispatch(RESOURCES.logs.list({
        resource_id: projectID,
        resource_name: RESOURCES_V1.projects.name,
        ordering: logsOrderingOptions.desc
    }))
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
