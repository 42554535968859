import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    iban: Yup.string().label('IBAN').required('IBAN-ul este obligatoriu!'),
    currency: Yup.object().label('Moneda').required('Moneda este obligatorie!'),
    bankName: Yup.string().label('Banca').required('Banca este obligatorie!'),
    swiftCode: Yup.string().label('Cod SWIFT'),
    bankAccountDescription: Yup.string()
        .label('Descriere cont bancar')
        .required('Descrierea contului bancar este obligatorie!'),
    clientAddressId: Yup.object()
        .label('Adresă cont bancar')
        .nullable()
        .required('Adresa contului bancar e obligatorie!')
})
