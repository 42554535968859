export const textLabels = {
    body: {
        noMatch: 'Nu a fost gasit niciun client',
        toolTip: 'Sortează',
        columnHeaderTooltip: column => `Sortează după ${column.label}`
    },
    pagination: {
        next: 'Pagina Următoare',
        previous: 'Pagina Anterioară',
        rowsPerPage: 'Rănduri pe pagină',
        displayRows: 'din'
    },
    toolbar: {
        search: 'Căutare',
        downloadCsv: 'Descarcă CSV',
        print: 'Printează',
        viewColumns: 'Coloane',
        filterTable: 'Filtre'
    },
    filter: {
        all: 'TOATE',
        title: 'FILTRE',
        reset: 'RESETEAZĂ'
    },
    viewColumns: {
        title: 'Coloane',
        titleAria: 'Arată/Ascunde Coloane din Tabel'
    },
    selectedRows: {
        text: 'rânduri selectate',
        delete: 'Șterge',
        deleteAria: 'Sterge Rândurile Selectate'
    }
}

export const datatableOptions = {
    responsive: 'standard',
    textLabels: textLabels
}

export const datatablePageSize = 15