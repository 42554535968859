import React from 'react'

import {connect} from 'react-redux'

import DocumentViewer from '../../components/DocumentViewer/DocumentViewer'
import {useQuery} from '@tanstack/react-query'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {Loader} from '../../components/Loader'

import './ContractEdit.scss'
import {ArrowLeftIcon} from '@heroicons/react/24/outline'
import {Button} from '../../components/Button'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {goBack} from 'connected-react-router'
import Save from '../../assets/icons/Save'

export const ContractEdit = ({match: {params: {contractID}}, user, goBack}) => {
    const {
        data,
        isFetching
    } = useQuery({
        queryKey: [RESOURCES.contracts.name, contractID],
        queryFn:  async () => {
            const {data} = await performRequest(
                RESOURCES.contracts.retrieve(contractID)
            )
            return data
        },
        staleTime: Infinity // The file must not be re-fetched
    })

    return <div className='document-editor-content'>
        <div className='back-button-container'>
            <Button
                title='Mergi înapoi'
                size='small'
                color='gray'
                variant='outlined'
                onClick={goBack}
                icon={() => <ArrowLeftIcon/>}
                iconLeft
            />
        </div>
        <div className="header-resource-title-container">
            {!isFetching ? (
                <div className="document-name-container">
                    <p className="header-resource-title">
                        Editare document: <span>{data.file_meta.name}</span>
                    </p>

                    <div className="header-resource-disclaimer">
                        Pentru a salva documentul, folosește butonul de salvare din stânga editorului de mai jos <Save/>
                    </div>
                </div>
            ) : null}
        </div>
        {!!data && !isFetching ? (
            <div className="file-container">
                <DocumentViewer
                    fileMeta={data.file_meta}
                    user={user}
                    editable={true}
                />
            </div>
        ) : (
            <div className='flex flex-row items-center gap-4'>
                <span className='pb-2'>
                    <Loader/>
                </span>
            </div>
        )}
    </div>

}

const mapStateToProps = (state) => ({
    user: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
    goBack: () => dispatch(goBack())
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractEdit)