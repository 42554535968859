import React, {useState} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {createDocumentVersion, updateLastDocumentVersion} from 'avoapp-react-common/dist/redux/documentVersions'
import {modalTypes} from '../../redux/modals'

import {removeExtension} from '../../utils'

import {Modal} from '../Modal'
import {Input} from '../Input'
import {Select} from '../Select'
import {Button} from '../Button'
import {ErrorsList} from '../ErrorComponents'
import {DocumentDropzone} from '../DocumentDropzone'

import './UploadDocumentModal.scss'
import {getTokenAndUploadFile} from '../../utils/files'

const uploadMethods = {
    NEW: {label: 'Crează versiune nouă', value: 'new'},
    OVERWRITE: {label: 'Suprascrie ultima versiune', value: 'overwrite'}
}

export const UploadDocumentModal = ({
    open,
    documentID,
    isLoading,
    nonFieldErrors,
    fieldErrors,
    selectedEntityID,
    createNewDocumentVersion,
    updateDocumentVersion
}) => {
    const [uploadMethod, setUploadMethod] = useState(uploadMethods.NEW)
    const [isUploading, setIsUploading] = useState(false)

    return (
        <Modal open={open} title='Încarcă document'>
            <div className="upload-document-modal-content-container">
                <Formik
                    initialValues={{
                        file: [],
                        name: ''
                    }}
                    onSubmit={ async (values) => {
                        setIsUploading(true)
                        const fileURL = await getTokenAndUploadFile(values.file, selectedEntityID)

                        setIsUploading(false)
                        if (!fileURL) {
                            return
                        }

                        const data = {
                            entity_id: selectedEntityID,
                            document_id: documentID,
                            file: fileURL
                        }

                        if(uploadMethod === uploadMethods.NEW) {
                            createNewDocumentVersion(data, documentID)
                        } else if(uploadMethod === uploadMethods.OVERWRITE) {
                            updateDocumentVersion(data, documentID)
                        }
                    }}
                >
                    {({handleChange, setFieldValue, handleSubmit, values}) => (
                        <Form className='upload-document-form'>
                            <ErrorsList errors={nonFieldErrors} />
                            <DocumentDropzone
                                onChange={(file) => {
                                    setFieldValue('file', file)
                                    setFieldValue('name', !isEmpty(file ) ? removeExtension(file.name) : '')
                                }}
                            />
                            {!isEmpty(values.file) && (
                                <>
                                    <Input
                                        value={values.name}
                                        label='Nume document'
                                        onChange={handleChange('name')}
                                        name='name'
                                        errors={fieldErrors}
                                        fullWidth
                                    />
                                    <Select
                                        label='Metodă încărcare'
                                        value={uploadMethod}
                                        options={_values(uploadMethods)}
                                        onChange={(method) => setUploadMethod(method)}
                                        labelInline
                                        fullWidth
                                    />
                                </>
                            )}
                            <Button
                                title='Salvează'
                                onClick={handleSubmit}
                                loading={isLoading || isUploading}
                                disabled={isEmpty(values.file) || isEmpty(values.name)}
                                color='secondary'
                                fullWidth
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.UPLOAD_DOCUMENT,
    isLoading: state.documentVersions.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    createNewDocumentVersion: (documentVersionData, documentID) =>
        dispatch(createDocumentVersion(documentVersionData, documentID)),
    updateDocumentVersion: (documentVersionData, documentID) =>
        dispatch(updateLastDocumentVersion(documentVersionData, documentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentModal)