import React from 'react'
import './RequiredFieldsText.scss'

export default function RequiredFieldsText() {
    return (
        <div className='required-fields-container'>
            <p className='required-fields-text'>* - câmpuri obligatorii</p>
        </div>
    )
}
