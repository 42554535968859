import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'

import {classNames, isCurrentNavigationItem} from '../../utils'

export default function NavigationItemComponent(props) {
    const {item} = props
    const location = useLocation()

    return (
        <Link
            to={item.path}
            className={classNames(
                isCurrentNavigationItem(location, item.path) ?
                    'bg-primary-dark text-white' :
                    'text-white hover:bg-primary-light',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
            )}
        >
            <item.icon className="mr-3 h-6 w-6 text-white" aria-hidden="true" />
            <p>{item.name}</p>
        </Link>
    )
}
