import React, {useCallback, useState} from 'react'
import {toast} from 'react-toastify'

import _ from 'lodash'

import {connect} from 'react-redux'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {closeModal, modalTypes} from '../../../../redux/modals'

// @ts-ignore
import {Button} from '../../../../components/Button'
// @ts-ignore
import {Modal} from '../../../../components/Modal'

import '../../../../assets/scss/DeleteModals.scss'

type DeletePartyModalProps = {
    party: any;
    open: boolean;
    closeModal: () => void;
}

function DeletePartyModal({open, party, closeModal}: DeletePartyModalProps) {
    const [isLoading, setIsLoading] = useState(false)

    const deleteParty = useCallback(() => {
        setIsLoading(true)

        performRequest(RESOURCES.parties.destroy(party))
            .then((res: any) => {
                if(!res.errors) {
                    toast.success('Succes')
                    closeModal()
                    setIsLoading(false)
                } else {
                    setIsLoading(false)
                    _.forEach(_.values(res.errors), (error) => {
                        toast.error(error.message)
                    })
                }
            }).catch((err: any) => {
                console.warn('Error while deleting party: ', err)
            })
    }, [closeModal, party])

    if(!party) {
        return <React.Fragment />
    }

    return (
        <Modal open={open} title='Șterge parte'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi partea "<span>{party.name}</span>"?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={deleteParty}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open : state.modals.type === modalTypes.DELETE_PARTY
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(DeletePartyModal)