import React from 'react'
import {CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Form, Formik} from 'formik'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {Input} from '../../../../components/Input'
import {Button} from '../../../../components/Button'

import './EditAddendumNameForm.scss'

export const EditAddendumNameForm = ({addendum, isLoading, fieldErrors, handleCancel, updateAddendum}) => {
    return (
        <Formik
            initialValues={{name: addendum.name}}
            onSubmit={(values) => {
                updateAddendum({name: values.name}, addendum.id)
                handleCancel()
            }}
        >
            {({handleChange, handleSubmit, values}) => (
                <Form className='edit-addendum-name-form-container'>
                    <Input
                        label='Nume act adițional'
                        value={values.name}
                        onChange={handleChange('name')}
                        name='name'
                        errors={fieldErrors}
                        fullWidth
                    />
                    <div className="buttons-container">
                        <Button
                            title='Salvează'
                            icon={() => <CheckCircleIcon />}
                            onClick={handleSubmit}
                            color='secondary'
                        />
                        <Button
                            icon={() => <XMarkIcon />}
                            onClick={handleCancel}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const mapStateToProps = (state) => ({
    addendum: state.addendums.currentAddendum,
    isLoading: state.addendums.isLoading,
    fieldErrors: state.addendums.fieldErrors,
    nonFieldErrors: state.addendums.nonFieldErrors
})

const mapDispatchToProps = (dispatch) => ({
    updateAddendum: (addendumData, addendumID) => dispatch(RESOURCES.addendums.update(addendumData, addendumID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAddendumNameForm)