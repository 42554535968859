import React, {useEffect, useState} from 'react'
import {ArrowDownTrayIcon, ArrowUpTrayIcon, TrashIcon, PencilSquareIcon} from '@heroicons/react/24/outline'

import {isEmpty, isUndefined, isNull, find, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {getLastDocumentVersion} from '../../../../redux/documentVersions'
import {getDocumentVersion, getDocumentVersions} from 'avoapp-react-common/dist/redux/documentVersions'

import {useQueryArgs} from '../../../../utils/hooks'

import {Select} from '../../../../components/Select'
import {Button} from '../../../../components/Button'
import {PageLoader} from '../../../../components/PageLoader'
import {UploadDocumentModal} from '../../../../components/UploadDocumentModal'

import {DeleteDocumentVersionModal} from '../modals'

import './DocumentPreview.scss'
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer'
import {ALLOW_EDIT_FILE_EXTENSION} from '../../../../components/DocumentViewer/constants'

export const DocumentPreview = ({
    urlData,
    match: {params: {documentID}},
    user,
    currentDocument,
    currentDocumentVersion,
    documentVersions,
    isLoading,
    openModal,
    getDocumentVersions,
    getDocumentVersion,
    getLastDocumentVersion
}) => {
    const query = useQueryArgs()
    const [selectedDocumentVersion, setSelectedDocumentVersion] = useState({signed_file_meta: null})

    const queryVersion = query.get('version')

    useEffect(() => {
        getDocumentVersions(documentID)

        if (isNull(queryVersion) || isUndefined(queryVersion)) {
            getLastDocumentVersion(documentID)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentID])

    useEffect(() => {
        if (!isEmpty(currentDocumentVersion) && currentDocumentVersion !== selectedDocumentVersion) {
            setSelectedDocumentVersion(currentDocumentVersion)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDocumentVersion])

    useEffect(() => {
        if (
            !isEmpty(documentVersions) &&
            (isNull(selectedDocumentVersion) ||
                (!isNull(selectedDocumentVersion) &&
                    parseInt(queryVersion) !== parseInt(selectedDocumentVersion.version)))
        ) {
            if (queryVersion) {
                const version = find(
                    documentVersions,
                    (docVersion) => parseInt(docVersion.version) === parseInt(queryVersion)
                )

                getDocumentVersion(documentID, version.id)
                setSelectedDocumentVersion(version)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentVersions])

    const handleChangeVersion = (version) => {
        if (version) {
            query.set('version', version.version)
        } else {
            query.delete('version')
        }

        urlData.history.push({
            pathname: urlData.location.pathname,
            search: query.toString()
        })
    }

    return (
        <div className="page-info">
            <div className="document-preview-page-container">
                <div className="document-preview-page-header">
                    {currentDocumentVersion.file_meta ? (
                        <Select
                            label='Versiune'
                            value={selectedDocumentVersion}
                            options={documentVersions}
                            onChange={(version) => handleChangeVersion(version)}
                            getOptionLabel={(option) => option.version}
                            getOptionValue={(option) => option.id}
                            labelInline
                        />
                    ) : <div/>}
                    <div className="header-buttons-container">
                        {currentDocumentVersion.file_meta && (
                            <>
                                {ALLOW_EDIT_FILE_EXTENSION.includes(currentDocumentVersion.file_meta.extension) ? <a
                                    href={`/documents/${documentID}/edit/${currentDocumentVersion.id}`}
                                    rel="noopener noreferrer"
                                >
                                    <Button title="Editează" icon={() => <PencilSquareIcon/>} color="gray"/>
                                </a> : null}

                                <a
                                    href={currentDocumentVersion.file_meta.url_public}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button title='Descarcă' icon={() => <ArrowDownTrayIcon/>}/>
                                </a>

                            </>
                        )}
                        <Button
                            title='Încarcă'
                            onClick={() => openModal(modalTypes.UPLOAD_DOCUMENT)}
                            icon={() => <ArrowUpTrayIcon/>}
                            color='orange'
                        />
                        <UploadDocumentModal documentID={documentID}/>
                        {currentDocumentVersion.file_meta && (
                            <>
                                <DeleteDocumentVersionModal/>
                                <Button
                                    title='Șterge'
                                    onClick={() => openModal(modalTypes.DELETE_DOCUMENT_VERSION)}
                                    icon={() => <TrashIcon/>}
                                    color='red'
                                />
                            </>
                        )}
                    </div>
                </div>
                {
                    isLoading ? (
                        <PageLoader />
                    ) : currentDocumentVersion.document_id === currentDocument.id &&
                        currentDocumentVersion.file_meta ? (
                            <div className="document-preview-container">
                                <DocumentViewer fileMeta={currentDocumentVersion.file_meta} user={user} />
                            </div>
                        ) : <p className="no-file">Nu există niciun fișier încărcat</p>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const documentVersions = orderBy(state.documentVersions.data, 'version', 'desc')

    return {
        currentDocument: state.documents.currentDocument,
        documentVersions: documentVersions,
        currentDocumentVersion: state.documentVersions.currentDocumentVersion,
        isLoading: state.documentVersions.isLoading,
        user: state.users.user
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    getDocumentVersions: (documentID) => dispatch(getDocumentVersions(documentID)),
    getDocumentVersion: (documentID, versionID) => dispatch(getDocumentVersion(documentID, versionID)),
    getLastDocumentVersion: (documentID) => dispatch(getLastDocumentVersion(documentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentPreview)
