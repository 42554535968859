import {toast} from 'react-toastify'
import {takeEvery, put, take, select} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {Action} from 'avoapp-react-common/dist/redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent, ACTIONS as CONTACT_PERSONS_ACTIONS} from 'avoapp-react-common/dist/redux/contactPersons'

import {closeModal} from './modals'
import {closeSlideover} from './slideovers'

const {contactPersons: resource} = RESOURCES

const ACTIONS = {
    CREATE_LEGAL_REPRESENTATIVE: new Action('CREATE_LEGAL_REPRESENTATIVE')
}

function* handleCreateContactPersonSuccess(response) {
    toast.success('Persoana de contact a fost creată cu succes')

    yield put(closeSlideover())
    yield put(setCurrent(response.payload))
}

function* handleRetrieveContactPersonSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleCreateLegalRepresentative({payload}) {
    yield put(RESOURCES.contactPersons.create(payload))

    yield take(RESOURCES.contactPersons.actions.create.success)
    yield take(CONTACT_PERSONS_ACTIONS.SET_CURRENT_CONTACT_PERSON.main)

    const currentContactPerson = yield select((state) => state.contactPersons.currentContactPerson)

    yield put(push(`/projects/add?clientID=${currentContactPerson.client_id}`))
}

function* handleUpdateContactPersonSuccess() {
    toast.success('Modificările au fost salvate')

    yield put(closeSlideover())
}

function* handleDestroyContactPersonSuccess() {
    toast.success('Persoana de contact a fost ștearsă')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateContactPersonSuccess)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveContactPersonSuccess)
    yield takeEvery(ACTIONS.CREATE_LEGAL_REPRESENTATIVE.main, handleCreateLegalRepresentative)
    yield takeEvery(resource.actions.update.success, handleUpdateContactPersonSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyContactPersonSuccess)
}

export const createLegalRepresentative = (payload) => ({
    type: ACTIONS.CREATE_LEGAL_REPRESENTATIVE.main,
    payload: payload
})
