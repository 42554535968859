import {toast} from 'react-toastify'
import {put, select, take, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {findLast, startsWith, endsWith, isUndefined} from 'lodash'

import {Action} from 'avoapp-react-common/dist/redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {createDocumentVersion} from 'avoapp-react-common/dist/redux/documentVersions'

import {removeExtension} from '../utils'
import {closeModal} from './modals'
import {closeSlideover} from './slideovers'
import {getFileNameFromUrl} from 'avoapp-react-common/dist/utils'

const {documents: resource} = RESOURCES

const ACTIONS = {
    CREATE_DOCUMENT: new Action('CREATE_DOCUMENT'),
    CREATE_DOCUMENT_FROM_TEMPLATE: new Action('CREATE_DOCUMENT_FROM_TEMPLATE')
}

function* handleCreateDocument({payload: {documentData}}) {
    const {entityID, name, projectsIDs, file} = documentData

    const documentInfo = {
        entity_id: entityID,
        projects_ids: projectsIDs,
        name: name ? name : removeExtension(getFileNameFromUrl(file))
    }

    yield put(RESOURCES.documents.create(documentInfo))
    const createDocumentResponse = yield take(action =>
        action.type === resource.actions.create.success ||
        action.type === resource.actions.create.fail
    )

    if(createDocumentResponse.type === resource.actions.create.success) {
        const {payload: document} = createDocumentResponse

        yield put(
            createDocumentVersion(
                {
                    entity_id: document.entity_id,
                    document_id:document.id,
                    file: file
                },
                document.id
            )
        )
    }
}

function* handleCreateDocumentFromTemplate({payload: {documentData}}) {
    const {entityId, projectsIds, name, documentTemplateId} = documentData

    const documentInfo = {
        entity_id: entityId,
        projects_ids: projectsIds,
        name: name
    }

    yield put(RESOURCES.documents.create(documentInfo))
    const createDocumentResponse = yield take(action =>
        action.type === resource.actions.create.success ||
        action.type === resource.actions.create.fail
    )

    if(createDocumentResponse.type === resource.actions.create.success) {
        const {payload: document} = createDocumentResponse

        const versionInfo = {
            entity_id: document.entity_id,
            document_template_id: documentTemplateId
        }

        yield put(
            createDocumentVersion(
                versionInfo,
                document.id
            )
        )
    }
}

function* handleCreateDocumentSuccess() {
    yield put(closeModal())
}

function* handleUpdateDocumentSuccess() {
    toast.success('Modificările au fost salvate cu succes')

    yield put(closeModal())
    yield put(closeSlideover())
}

function handleUpdateDocumentFail() {
    toast.error('Modificările NU au putut fi salvate. Te rugam să încerci din nou!')
}

function* handleDestroyDocumentSuccess() {
    toast.success('Documentul a fost șters cu succes')

    const sourceLocation = yield select((state) => findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/documents' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/documents'))
    ))

    yield put(push(!isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/documents'))
    yield put(closeModal())
}

function handleDestroyDocumentFail() {
    toast.error('Documentul NU a putut fi șters. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(ACTIONS.CREATE_DOCUMENT.main, handleCreateDocument)
    yield takeEvery(ACTIONS.CREATE_DOCUMENT_FROM_TEMPLATE.main, handleCreateDocumentFromTemplate)
    yield takeEvery(resource.actions.create.success, handleCreateDocumentSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateDocumentSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateDocumentFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyDocumentSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyDocumentFail)
}

export const createDocument = (documentData) => ({
    type: ACTIONS.CREATE_DOCUMENT.main,
    payload: {documentData}
})

export const createDocumentFromTemplate = (documentData) => ({
    type: ACTIONS.CREATE_DOCUMENT_FROM_TEMPLATE.main,
    payload: {documentData}
})
