import React from 'react'
import {ArrowDownIcon, ArrowUpIcon} from '@heroicons/react/20/solid'
import {Link} from 'react-router-dom'

function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(' ')
}

interface StatsItemProps {
    name: string,
    stat: number | string,
    Icon?: any,
    changeType?: string,
    change?: number,
    link?: string
    linkText?: string
}

export default function StatsItem(
    {
        name,
        stat,
        Icon = undefined,
        changeType = undefined,
        change = undefined,
        link = undefined,
        linkText = undefined
    }: StatsItemProps
) {
    return (
        <div
            className="relative overflow-hidden rounded-lg bg-gray-50 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
        >
            <dt>
                {Icon && (
                    <div className="absolute rounded-md p-3"
                        style={{backgroundColor: 'rgb(0 172 236)'}}>
                        <Icon aria-hidden="true" className="h-6 w-6 text-white"/>
                    </div>
                )}
                <p className={'text-sm font-medium text-gray-500' + (Icon ? ' ml-16' : '')}>{name}</p>
            </dt>
            <dd className={'flex items-baseline pb-6 sm:pb-7' + (Icon ? ' ml-16' : '')}>
                <p className="text-2xl font-semibold text-gray-900">{stat}</p>
                {change && changeType && (
                    <p
                        className={classNames(
                            changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                            'ml-2 flex items-baseline text-sm font-semibold'
                        )}
                    >
                        {changeType === 'increase' ? (
                            <ArrowUpIcon
                                aria-hidden="true"
                                className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                            />
                        ) : (
                            <ArrowDownIcon
                                aria-hidden="true" className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                            />
                        )}
                        {change}
                    </p>
                )}
                {link && linkText && (
                    <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                        <div className="text-sm">
                            <Link to={link} className="font-medium text-indigo-600 hover:text-indigo-500">
                                <button
                                    style={{display: 'inline-block'}}
                                    className="avo-button outlined small primary" type="button">
                                    {linkText}
                                </button>
                            </Link>
                        </div>
                    </div>
                )}
            </dd>
        </div>
    )
}