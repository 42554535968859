export const actionIds = {
    ARCHIVE: 'archive',
    PREVIEW: 'preview',
    SIGNED: 'signed'
}

export const actions = {
    [actionIds.ARCHIVE]: {
        id: actionIds.ARCHIVE,
        label: 'Informații'
    },
    [actionIds.PREVIEW]: {
        id: actionIds.PREVIEW,
        label: 'Previzualizare'
    },
    [actionIds.SIGNED]: {
        id: actionIds.SIGNED,
        label: 'Document semnat'
    }
}
