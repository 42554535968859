import React, {useCallback, useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import {useFormik} from 'formik'
import _ from 'lodash'
import {toast} from 'react-toastify'

import {connect} from 'react-redux'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {closeModal} from '../../redux/modals'

import {useQueryResourceSearch} from 'queries/rest'
// @ts-ignore
import {objectKeysToSnakeCase} from '../../utils'
// @ts-ignore
import {useDebouncedState} from '../../utils/hooks'

// @ts-ignore
import {Input} from '../Input'
// @ts-ignore
import {Button} from '../Button'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {Select} from '../Select'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'

import './AppealForm.scss'

interface PhaseFormProps {
    currentPhase: any;
    isMultistepForm?: boolean,
    goToNextStep?: () => void;
    closeModal: () => void;
}

function AppealForm({currentPhase, isMultistepForm = false, goToNextStep, closeModal}: PhaseFormProps) {
    const [isLoading, setIsLoading] = useState(false)

    const [partiesQuery, setPartiesQuery] = useDebouncedState('', 300)
    const {data: parties, isFetching: isLoadingParties} = useQueryResourceSearch(
        RESOURCES.parties,
        partiesQuery,
        {litigation_id: currentPhase.litigation_id}
    )

    const initialDate = useMemo(() => {
        return new Date()
    }, [])

    const {values, touched, setFieldValue, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
        initialValues: {
            date: initialDate,
            party: _.head((parties as any)),
            type: ''
        },
        enableReinitialize: true,
        onSubmit: () => {
            const finalValue = {
                ...objectKeysToSnakeCase(values),
                phase_id: currentPhase.id,
                date: lightFormat(values.date, 'yyyy-MM-dd'),
                party: values.party.id,
                type: values.type
            }

            onSubmit(finalValue)
        }
    })

    const onCreateSuccess = useCallback(() => {
        toast.success('Salvat')

        if(isMultistepForm) {
            resetForm()
        } else {
            closeModal()
        }
    }, [closeModal, isMultistepForm, resetForm])

    const onSubmit = useCallback(async (values) => {
        setIsLoading(true)
        performRequest(RESOURCES.appeals.create(values)).then((res: any) =>{
            setIsLoading(false)

            if(!res.errors) {
                onCreateSuccess()
            } else {
                _.forEach(_.values(res.errors), (error) => {
                    toast.error(error.message)
                })
            }
        })
    }, [onCreateSuccess])

    const saveButtonTitle = useMemo(() => {
        if(isMultistepForm) {
            return 'Salvează și adaugă încă o cale de atac'
        }

        return 'Salvează'
    }, [isMultistepForm])

    if(_.isNil(parties) && isLoadingParties) {
        return <React.Fragment />
    }

    return (
        <form className="add-md-manual-subscription-hearing-form">
            <DatePicker
                label="Data*"
                value={values.date}
                onChange={(date: any) => _.isNil(date) ?
                    setFieldValue('date', date) :
                    setFieldValue('date', new Date(date))
                }
                onBlur={handleBlur('date')}
                name='date'
                touched={touched.date}
                fullWidth
            />
            <Select
                label='Parte*'
                value={values.party}
                options={parties}
                loading={isLoadingParties}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) => option.name}
                onChange={(e: any) => setFieldValue('party', e)}
                onInputChange={(value: any) => setPartiesQuery(value)}
                onBlur={handleBlur('party')}
                name='party'
                touched={touched.party}
                fullWidth
            />
            <Input
                label="Tip*"
                value={values.type}
                onChange={handleChange('type')}
                onBlur={handleBlur('type')}
                name='type'
                touched={touched.type}
                fullWidth
            />
            <RequiredFieldsText />
            <Button
                title={saveButtonTitle}
                onClick={handleSubmit}
                loading={isLoading}
                fullWidth
            />
            {isMultistepForm && (
                <Button
                    title='Vezi detalii dosar'
                    onClick={goToNextStep}
                    variant='outlined'
                    color='gray'
                    fullWidth
                />
            )}
        </form>
    )
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(AppealForm)