import React from 'react'

import './TaskStatusBadge.scss'

export default function TaskStatusBadge({isActive}) {
    return (
        <div className={`task-status-badge ${isActive ? 'active' : 'finished'}`}>
            <p className='task-status-badge-text'>Sarcină {isActive ? 'activă' : 'finalizată'}</p>
        </div>
    )
}
