import {Form, Formik, useFormikContext} from 'formik'
import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {formatISO, lightFormat} from 'date-fns'
import {
    filter,
    find,
    get,
    includes,
    isEmpty,
    isNil,
    isNull,
    round, sumBy, toNumber,
    toString, trim,
    uniq,
    values
} from 'lodash'

import {ExpenseTypes} from 'avoapp-react-common/dist/constants'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {getExchangeRate} from 'avoapp-react-common/dist/redux/exchangeRate'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {modalTypes} from '../../redux/modals'

import {expensesSchema} from '../../assets/validations'
import {getFieldOptions} from '../../utils'
import {debounceWait, RON_CURRENCY_VALUE} from '../../utils/constants'
import {getTokenAndUploadFile} from '../../utils/files'
import {useDebouncedEffect} from '../../utils/hooks'

import {Button} from '../Button'
import {Datatable} from '../Datatable'
import {DatePicker} from '../DatePicker'
import {DocumentDropzone} from '../DocumentDropzone'
import {ErrorsList} from '../ErrorComponents'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {RequiredFieldsText} from '../RequiredFieldsText'
import {Select} from '../Select'
import {Toggle} from '../Toggle'

import './AddExpensePaymentModal.scss'

const getAmountRON = (amount, currency, exchangeRate) => {
    const amount_RON = round(exchangeRate * amount, 2)

    return currency === RON_CURRENCY_VALUE ? amount : amount_RON
}

const AddExpensePaymentModal = ({
    open,
    projectId,
    isLoading,
    nonFieldErrors,
    fieldErrors,
    selectedEntityID,
    entityProfiles,
    isLoadingContracts,
    isLoadingProjects,
    createExpensePayment,
    expenseOptions,
    getExpensesOptions,
    getExchangeRate,
    exchangeRate
}) => {
    const [expensesIDs, setExpensesIDs] = useState([])
    const [expensesAmount, setExpensesAmount] = useState(0)
    const [expensesLoading, setExpensesLoading] = useState(false)
    const [expenses, setExpenses] = useState([])
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState(
        projectId ? find(projects, (project) => toString(project.id) === toString(projectId)) : null
    )
    const [contracts, setContracts] = useState([])
    const [contractsQuery, setContractsQuery] = useState('')

    useEffect(() => { getExpensesOptions() }, [getExpensesOptions])

    useEffect(() => { getExchangeRate() }, [getExchangeRate])

    const initializeProject = useCallback(async () => {
        if(!isNil(projectId)) {
            const {data} = await performRequest(RESOURCES_V2.projects.retrieve(projectId))

            setProjects([data])
            setSelectedProject(data)
        }
    }, [projectId])

    useEffect(() => { initializeProject() }, [initializeProject])

    const listExpenses = useCallback(
        async (entityID, filters) => {
            setExpensesLoading(true)
            const result = await performRequest(RESOURCES.expenses.list(
                {
                    ...filters,
                    entity_id: entityID,
                    page_size: 100
                }
            ))
            setExpensesLoading(false)
            return result
        }, [])

    const fetchExpenses = useCallback(async () => {
        const {data} = await listExpenses(
            selectedEntityID, {
                project_id: projectId,
                status: [ExpenseTypes.NOT_COVERED, ExpenseTypes.PARTIALLY_COVERED]
            }
        )

        setExpenses(data.results)
    }, [listExpenses, projectId, selectedEntityID])

    useEffect(() => {
        if(open){
            fetchExpenses()
        }
    }, [open, fetchExpenses])

    const handleFetchContracts = useCallback(() => {
        let params

        if(projectId) {
            params = {project_id: projectId}
        }

        performRequest(RESOURCES.contracts.search(contractsQuery, {entity_id: selectedEntityID, ...params}))
            .then((response) => setContracts(response.data.results) )
            .catch((err) => console.warn('Error while fetching contracts: ', err))
    }, [contractsQuery, projectId, selectedEntityID])

    useDebouncedEffect(handleFetchContracts, [contractsQuery, projectId, selectedProject], debounceWait)

    const handleChangeContractsSearchField = useCallback((value) => {
        const searchValue = trim(value)

        setContractsQuery(searchValue)
    }, [])

    const currencies = getFieldOptions(expenseOptions, 'currency')

    const expensesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date',
                Cell: ({value: start}) => {
                    return start ? lightFormat(new Date(start), 'dd/MM/yyyy') : '-'
                }
            },
            {
                Header: 'Suma',
                accessor: 'amount',
                Cell: ({value: amount, row: {original: expense}}) => {
                    const amountLeft = get(expense, ['uncovered_allocation', 'amount'])
                    const currency = get(expense, ['uncovered_allocation', 'currency'])

                    const sameAmount = amountLeft === amount
                    return sameAmount ? amount :
                        `${amountLeft} ${currency} 
                        (din ${amount} ${expense.currency})`
                }
            },
            {
                Header: '',
                accessor: 'id',
                Cell: ({value: expenseID, row: {original: expensePayment}}) => {
                    const {values} = useFormikContext()

                    const expensePaymentAmountRON = getAmountRON(
                        values.amount, values.currency.value, values.exchangeRate
                    )

                    const shouldDisable = (
                        !includes(expensesIDs, expenseID) &&
                        ((expensesAmount >= expensePaymentAmountRON) || !values.amount)
                    )

                    return (
                        <Toggle
                            checked={includes(expensesIDs, expenseID)}
                            disabled={shouldDisable}
                            tooltipText={shouldDisable && 'Suma nu este suficientă.'}
                            onChange={(e) => {
                                let newExpensesIDS

                                if (e === true) {
                                    newExpensesIDS = uniq([...expensesIDs, expenseID])
                                    setExpensesIDs(newExpensesIDS)
                                } else {
                                    newExpensesIDS = filter(expensesIDs, (item) => item !== expenseID)
                                    setExpensesIDs(newExpensesIDS)
                                }

                                setExpensesAmount(
                                    sumBy(
                                        filter(expenses, (item) => includes(newExpensesIDS, item.id)),
                                        (o) => {
                                            const amount = get(o, ['uncovered_allocation', 'amount_RON'], o.amount_RON)

                                            return toNumber(amount)
                                        })
                                )
                            }}
                        />
                    )
                }
            }
        ]
    }, [expenses, expensesAmount, expensesIDs])

    useEffect(() => {
        if(!open) setExpenses([])
    }, [open])

    return (
        <Modal
            open={open}
            title='Adaugă încasare'
            maxWidth='450px'
        >
            {!isEmpty(entityProfiles) && (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            projectId:  selectedProject,
                            contractId: null,
                            date: new Date(),
                            amount: '',
                            currency: find(currencies, ['value', RON_CURRENCY_VALUE]),
                            description: '',
                            exchangeRate: '',
                            file: ''
                        }}
                        validationSchema={expensesSchema}
                        onSubmit={async (values) => {
                            let fileURL = null

                            if(!isEmpty(values.file)) {
                                fileURL = await getTokenAndUploadFile(values.file, selectedEntityID)
                            }

                            const amount_RON = round(values.exchangeRate * values.amount, 2)

                            const expenseData = {
                                entity_id: selectedEntityID,
                                project_id: projectId,
                                contract_id: values.contractId?.id || null,
                                date: formatISO(values.date, {representation: 'date'}),
                                amount: values.amount,
                                amount_RON: values.currency.value === RON_CURRENCY_VALUE ? values.amount : amount_RON,
                                currency: values.currency.value,
                                exchange_rate: values.exchangeRate,
                                description: values.description,
                                file: fileURL,
                                to_be_paid: values.toBePaid,
                                expenses_ids: expensesIDs
                            }

                            createExpensePayment(expenseData)
                        }}
                    >
                        {({
                            handleChange,
                            setFieldValue,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            isValid
                        }) => (
                            <Form className='add-task-form-container'>
                                <div className="add-task-form-row">
                                    <Select
                                        label='Proiect*'
                                        value={values.projectId || selectedProject}
                                        options={projects}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        disabled={true}
                                        name='projectId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.projectId}
                                        loading={isLoadingProjects}
                                        fullWidth
                                    />
                                    <Select
                                        label='Contract'
                                        value={values.contractId}
                                        options={contracts}
                                        loading={isLoadingContracts}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={async (e) => {
                                            setFieldValue('contractId', e)
                                        }}
                                        onInputChange={handleChangeContractsSearchField}
                                        onBlur={handleBlur('contractId')}
                                        name='contractId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.contractId}
                                        isClearable
                                        fullWidth
                                    />
                                </div>
                                <DatePicker
                                    label='Data*'
                                    value={values.date}
                                    onChange={(date) => isNull(date) ?
                                        setFieldValue('date', date) :
                                        setFieldValue('date', new Date(date))
                                    }
                                    onBlur={handleBlur('date')}
                                    name='date'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.date}
                                    fullWidth
                                />
                                <div className="add-task-form-row">
                                    <div className="w-full">
                                        <Input
                                            label='Sumă*'
                                            value={values.amount}
                                            onChange={(e) => {
                                                handleChange('amount')(e)
                                                setExpensesIDs([])
                                                setExpensesAmount(0)
                                            }}
                                            onBlur={handleBlur('amount')}
                                            name='amount'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.amount}
                                            fullWidth
                                        />
                                    </div>
                                    <Select
                                        label='Moneda*'
                                        value={values.currency}
                                        options={currencies}
                                        onChange={(e) => {
                                            setFieldValue('currency', e)
                                            if (e.value === RON_CURRENCY_VALUE) {
                                                setFieldValue('exchangeRate', '')
                                            } else {
                                                setFieldValue('exchangeRate', get(exchangeRate, e.value))
                                            }
                                        }}
                                        onBlur={handleBlur('currency')}
                                        name='currency'
                                        errors={fieldErrors.currency}
                                        frontendErrors={errors}
                                        touched={touched.currency}
                                        fullWidth
                                    />
                                </div>
                                {
                                    values.currency.value !== RON_CURRENCY_VALUE && (
                                        <div className="add-task-form-row">
                                            <Input
                                                label='Curs Valutar*'
                                                value={values.exchangeRate}
                                                onChange={handleChange('exchangeRate')}
                                                onBlur={handleBlur('exchangeRate')}
                                                name='exchangeRate'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.exchangeRate}
                                                fullWidth
                                            />
                                            <Input
                                                label='Sumă RON*'
                                                value={round(values.exchangeRate * values.amount, 2)}
                                                name='amountRON'
                                                disabled={true}
                                                fullWidth
                                            />
                                        </div>
                                    )
                                }

                                <div className="textarea-container">
                                    <label className="note-textarea-label">Descriere</label>
                                    <textarea
                                        value={values.description}
                                        className="note-textarea"
                                        placeholder="Adaugă o descriere pentru aceasta cheltuială"
                                        onChange={(e) => setFieldValue('description', e.target.value)}
                                        name='description'
                                        rows={3}
                                    />
                                </div>
                                <DocumentDropzone onChange={(file) => setFieldValue('file', file) } />
                                <RequiredFieldsText />
                                <Datatable
                                    title='Cheltuieli'
                                    data={expenses}
                                    emptyText={
                                        isNull(selectedProject) ? 'Alegeți mai întâi proiectul.' : 'Fără rezultate'
                                    }
                                    columns={expensesColumns}
                                    loading={expensesLoading}
                                    totalPages={1}
                                />
                                <Button
                                    title='Adaugă plată'
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    color='secondary'
                                    type='submit'
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_EXPENSE_PAYMENT,
    expenseOptions: state.expensePayments.options,
    exchangeRate: state.exchangeRate.data,
    isLoading: state.expensePayments.isLoading,
    fieldErrors: state.expensePayments.fieldErrors,
    nonFieldErrors: state.expensePayments.nonFieldErrors,
    entityProfiles: values(state.entityProfiles.data),
    contracts: values(state.contracts.searchData),
    isLoadingContracts: state.contracts.isLoading,
    projects: values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID,
    expenses: values(state.expenses.data),
    expensesLoading: state.expenses.isLoading,
    totalPages: state.expenses.totalPages,
    nextPage: state.expenses.next,
    previousPage: state.expenses.previous,
    currentPage: state.expenses.current,
    filters: state.filters.expenses
})

const mapDispatchToProps = (dispatch) => ({
    getExpensesOptions: () => dispatch(RESOURCES.expensePayments.getOptions()),
    getExchangeRate: () => dispatch(getExchangeRate()),
    createExpensePayment: (data) => dispatch(RESOURCES.expensePayments.create(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddExpensePaymentModal)
