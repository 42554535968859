import {toast} from 'react-toastify'
import {push} from 'connected-react-router'

import {head} from 'lodash'

import {takeEvery, put} from 'redux-saga/effects'

import {Action} from 'avoapp-react-common/dist/redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {keepClientInStore, ACTIONS as CLIENT_ACTIONS, setCurrent} from 'avoapp-react-common/dist/redux/clients'

import {closeModal} from './modals'
import {closeSlideover} from './slideovers'

const {clients: resource} = RESOURCES

const ACTIONS = {
    RESET_ADD_STEP: new Action('RESET_ADD_STEP'),
    INCREASE_ADD_STEP: new Action('INCREASE_ADD_STEP'),
    OPEN_SIMILAR_CLIENT_MODAL: new Action('OPEN_SIMILAR_CLIENT_MODAL'),
    CLOSE_SIMILAR_CLIENT_MODAL: new Action('CLOSE_SIMILAR_CLIENT_MODAL')
}

const initialState = {
    clientAddingCurrentStep: 0,
    openSimilarClientModal: false,
    similarClientData: ''
}

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ACTIONS.INCREASE_ADD_STEP.main:
            return {
                ...state,
                clientAddingCurrentStep: state.clientAddingCurrentStep + 1
            }
        case ACTIONS.RESET_ADD_STEP.main:
            return {
                ...state,
                clientAddingCurrentStep: 0
            }
        case ACTIONS.OPEN_SIMILAR_CLIENT_MODAL.main:
            return {
                ...state,
                openSimilarClientModal: true,
                similarClientData: action.payload.similarClientData
            }
        case ACTIONS.CLOSE_SIMILAR_CLIENT_MODAL.main:
            return {
                ...state,
                openSimilarClientModal: false
            }
        default:
            return state
    }
}

function* handleCreateClientSuccess(response) {
    toast.success('Clientul a fost creat cu succes')

    yield put(keepClientInStore(response.payload))
    yield put(increaseClientAddingStep())
}

function* handleCreateClientFail(response) {
    toast.error('Clientul nu a putut fi creat')

    if(response.payload.name && head(response.payload.name).code === 'too_similar') {
        yield put(openSimilarClientModal(response.meta.object))
    }
}

function* handleUpdateClientSuccess(response) {
    toast.success('Modificările au fost salvate')

    yield put(keepClientInStore(response.payload))

    yield put(closeSlideover())
    yield put(closeModal())
}

function* handleUpdateOnboardingClientSuccess(response) {
    toast.success('Informarțiile au fost salvate cu success')

    yield put(keepClientInStore(response.payload))
    yield put(increaseClientAddingStep())
}

function* handleRetrieveClientSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleDestroyClientSuccess() {
    toast.success('Clientul a fost șters')

    yield put(closeModal())
    yield put(push('/clients'))
}

function* handleDestroyClientFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateClientSuccess)
    yield takeEvery(resource.actions.create.fail, handleCreateClientFail)
    yield takeEvery(CLIENT_ACTIONS.UPDATE_ONBOARDING_CLIENT.success, handleUpdateOnboardingClientSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateClientSuccess)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveClientSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyClientSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyClientFail)
}

export const increaseClientAddingStep = () => {
    return ({
        type: ACTIONS.INCREASE_ADD_STEP.main
    })
}

export const resetClientAddingStep = () => {
    return ({
        type: ACTIONS.RESET_ADD_STEP.main
    })
}

export const openSimilarClientModal = (clientData) => ({
    type: ACTIONS.OPEN_SIMILAR_CLIENT_MODAL.main,
    payload: {
        similarClientData: clientData
    }
})

export const closeSimilarClientModal = () => ({
    type: ACTIONS.CLOSE_SIMILAR_CLIENT_MODAL.main
})
