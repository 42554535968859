import {CheckIcon} from '@heroicons/react/24/solid'
import React from 'react'

import './Step.scss'

export default function Step({step, stepIdx, isCurrent, isComplete}) {
    return (
        <li key={step.id} className="step-list-item">
            <div className={`step-container ${isComplete ? 'complete' : isCurrent ? 'current' : ''}`}>
                <span className="step-bottom-line" aria-hidden="true" />
                <span className={`step-details-container ${stepIdx !== 0 ? 'first' : ''}`}>
                    <span className="step-circle-container">
                        <span className="step-circle">
                            {isComplete ? (
                                <CheckIcon className="step-complete-icon" aria-hidden="true" />
                            ) : (
                                <span className="step-number">{step.stepNumber}</span>
                            )}
                        </span>
                    </span>
                    <span className="step-texts">
                        <span className="step-name">{step.name}</span>
                    </span>
                </span>
                {stepIdx !== 0 ? (
                    <div className="step-separator" aria-hidden="true">
                        <svg className="separator-svg" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                            <path
                                d="M0.5 0V31L10.5 41L0.5 51V82"
                                stroke="currentcolor"
                                vectorEffect="non-scaling-stroke"
                            />
                        </svg>
                    </div>
                ) : null}
            </div>
        </li>
    )
}
