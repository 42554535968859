import {Action} from 'avoapp-react-common/dist/redux'

import {FILTERS} from './spec'

const ACTIONS = {
    ADD_FILTERS: new Action('ADD_FILTERS'),
    REMOVE_FILTER: new Action('REMOVE_FILTER'),
    RESET_FILTERS: new Action('RESET_FILTERS')
}

const initialState = {
    clients: FILTERS.clients,
    projects: FILTERS.projects,
    tasks: FILTERS.tasks,
    expenses: FILTERS.expenses,
    contracts: FILTERS.contracts,
    documents: FILTERS.documents,
    documentTemplates: FILTERS.documentTemplates,
    invoices: FILTERS.invoices,
    invoicePayments: FILTERS.invoicePayments,
    proformas: FILTERS.proformas
}

export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case ACTIONS.ADD_FILTERS.main:
            const {filters} = action.payload

            Object.keys(filters).forEach((key) => {
                const filter = filters[key]

                state[action.payload.resource][key].setValue(filter.value, filter.displayValue)
            })

            return state
        case ACTIONS.REMOVE_FILTER.main:
            const {filter} = action.payload

            filter.unsetValue()

            return state
        case ACTIONS.RESET_FILTERS.main:
            return {
                ...state,
                [action.payload.resource]: {}
            }
        default:
            return state
    }
}

export const addFilters = (resource, filters) => ({
    type: ACTIONS.ADD_FILTERS.main,
    payload: {resource, filters}
})

export const removeFilter = (resource, filter) => ({
    type: ACTIONS.REMOVE_FILTER.main,
    payload: {resource, filter}
})

export const resetFilters = (resource) => ({
    type: ACTIONS.RESET_FILTERS.main,
    payload: {resource}
})
