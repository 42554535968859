import {useCallback, useEffect, useMemo, useState} from 'react'

import {Form, Formik} from 'formik'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {addFilters} from '../../../../redux/filters/filters'
import {closeSlideover} from '../../../../redux/slideovers'

import {getFieldOptions, toApiDateFormat} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'
import {useDebouncedEffect} from '../../../../utils/hooks'
import {documentSeriesTypes} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DatePicker} from '../../../../components/DatePicker'
import {ErrorsList} from '../../../../components/ErrorComponents'
import {Input} from '../../../../components/Input'
import {Select} from '../../../../components/Select'

import '../../../../assets/scss/SlideoverForms.scss'

const FilterInvoicesForm = ({
    hideClientField,
    filters,
    invoiceOptions,
    getInvoicesOptions,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    addFilters,
    selectedEntityID,
    filterInvoices,
    closeSlideover,
    documentSeries,
    isLoadingDocumentSeries,
    listAllDocumentSeries,
    clients,
    isLoadingClients,
    searchClients,
    contracts,
    isLoadingContracts,
    searchContracts
}) => {
    const [clientsQuery, setClientsQuery] = useState('')
    const [contractsQuery, setContractsQuery] = useState('')

    const handleFilter = (filters) => {
        addFilters(filters)
        filterInvoices()
        closeSlideover()
    }

    useEffect(() => {
        getInvoicesOptions()
        handleFetchSeries()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const invoiceStates = useMemo(() => {
        return getFieldOptions(invoiceOptions, 'state')
    }, [invoiceOptions])

    const handleFetchClients = useCallback((query = clientsQuery) => {
        searchClients(query)
    }, [clientsQuery, searchClients])

    useDebouncedEffect(handleFetchClients, [clientsQuery], debounceWait)

    const handleChangeClientsSearchField = useCallback((value) => setClientsQuery(value), [])

    const handleFetchContracts = useCallback((query = contractsQuery) => {
        searchContracts(query)
    }, [contractsQuery, searchContracts])

    useDebouncedEffect(handleFetchContracts, [contractsQuery], debounceWait)

    const handleChangeContractsSearchField = useCallback((value) => setContractsQuery(value), [])

    const handleFetchSeries = useCallback(() => {
        listAllDocumentSeries({entity_id: selectedEntityID})
    }, [listAllDocumentSeries, selectedEntityID])

    const handleChangeDate = useCallback((field, date, setFieldValue) => {
        if(!_.isNil(date)) {
            setFieldValue(field, new Date(date))
        } else {
            setFieldValue(field, date)
        }
    }, [])

    const invoiceSeries = useMemo(() => {
        return _.filter(documentSeries, ['type', documentSeriesTypes.FISCAL_INVOICE.value])
    }, [documentSeries])

    const initialClient = useMemo(() => {
        return _.find(clients, (client) => filters.client.value === client.id)
    }, [clients, filters.client.value])

    const initialContract = useMemo(() => {
        return _.find(contracts, (contract) => filters.contract.value === contract.id)
    }, [contracts, filters.contract.value])

    const initialSeries = useMemo(() => {
        return _.find(invoiceSeries, (series) => filters.series.value === series.id)
    }, [filters.series.value, invoiceSeries])

    const initialState = useMemo(() => {
        return _.find(invoiceStates, (states) => filters.state.value === states.value)
    }, [filters.state.value, invoiceStates])

    return (
        <div className="slideover-form-container">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    dateGTE: filters.dateGTE?.value ? new Date(filters.dateGTE.value) : null,
                    dateLTE: filters.dateLTE?.value ? new Date(filters.dateLTE.value) : null,
                    series: initialSeries,
                    seriesNumber: filters.seriesNumber.value || '',
                    state: initialState,
                    client: initialClient,
                    contract: initialContract
                }}
                onSubmit = {(values) => {
                    const filtersData = {
                        dateGTE: {
                            value: toApiDateFormat(values.dateGTE),
                            displayValue: toApiDateFormat(values.dateGTE)
                        },
                        dateLTE: {
                            value: toApiDateFormat(values.dateLTE),
                            displayValue: toApiDateFormat(values.dateLTE)
                        },
                        series: {
                            value: values.series?.id || '',
                            displayValue: values.series?.name || ''
                        },
                        seriesNumber: {
                            value: values.seriesNumber,
                            displayValue: values.seriesNumber
                        },
                        state: {
                            value: values.state?.value || '',
                            displayValue: values.state?.label || ''
                        },
                        client: {
                            value: values.client?.id || '',
                            displayValue: values.client?.name || ''
                        },
                        contract: {
                            value: values.contract?.id || '',
                            displayValue: values.contract?.name || ''
                        }
                    }

                    handleFilter(filtersData)
                }}
            >
                {({handleChange, setFieldValue, values, handleSubmit}) => (
                    <>
                        <Form className='slideover-form'>
                            <DatePicker
                                label='Dată start'
                                value={values.dateGTE}
                                onChange={(date) => handleChangeDate('dateGTE', date, setFieldValue)}
                                fullWidth
                            />
                            <DatePicker
                                label='Dată stop'
                                value={values.dateLTE}
                                onChange={(date) => handleChangeDate('dateLTE', date, setFieldValue)}
                                fullWidth
                            />
                            <Select
                                label='Serie factură'
                                value={values.series}
                                options={invoiceSeries}
                                loading={isLoadingDocumentSeries}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(e) => setFieldValue('series', e)}
                                disabled={_.isEmpty(documentSeries)}
                                isClearable
                                fullWidth
                            />
                            <Input
                                label='Număr factură'
                                value={values.seriesNumber}
                                onChange={handleChange('seriesNumber')}
                                type='number'
                                fullWidth
                            />
                            <Select
                                label='Status'
                                value={values.state}
                                options={invoiceStates}
                                loading={isLoading}
                                onChange={(e) => setFieldValue('state', e)}
                                disabled={_.isEmpty(invoiceStates)}
                                isClearable
                                fullWidth
                            />
                            {!hideClientField && (
                                <Select
                                    label='Client'
                                    value={values.client}
                                    options={clients}
                                    onChange={(e) => setFieldValue('client', e)}
                                    onInputChange={(value) => handleChangeClientsSearchField(value)}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    disabled={_.isEmpty(clients)}
                                    loading={isLoadingClients}
                                    name='clients'
                                    errors={fieldErrors}
                                    isClearable
                                    fullWidth
                                />
                            )}
                            <Select
                                label='Contract'
                                value={values.contract}
                                options={contracts}
                                onChange={(e) => setFieldValue('contract', e)}
                                onInputChange={(value) => handleChangeContractsSearchField(value)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled={_.isEmpty(contracts)}
                                loading={isLoadingContracts}
                                name='contracts'
                                errors={fieldErrors}
                                isClearable
                                fullWidth
                            />
                        </Form>
                        <div className="buttons-container">
                            <Button
                                title='Aplică'
                                onClick={handleSubmit}
                                loading={isLoading}
                                type='submit'
                                fullWidth
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filters.invoices,
    invoiceOptions: state.invoices.options,
    isLoading: state.invoices.isLoading,
    fieldErrors: state.invoices.fieldErrors,
    nonFieldErrors: state.invoices.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    documentSeries: _.values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading,
    clients: _.values(state.clients.searchData),
    isLoadingClients: state.clients.isLoading,
    contracts: _.values(state.contracts.searchData),
    isLoadingContracts: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch, props) => ({
    closeSlideover: () => dispatch(closeSlideover()),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.invoices.name, filters)),
    getInvoicesOptions: () => dispatch(RESOURCES.invoices.getOptions()),
    searchClients: (search) => dispatch(RESOURCES.clients.search(search)),
    searchContracts: (search) => dispatch(RESOURCES.contracts.search(search)),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterInvoicesForm)
