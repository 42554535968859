import React from 'react'

import {connect} from 'react-redux'

import {Steps} from '../Steps'

export const ClientAddingSteps = ({steps, clientAddingCurrentStep}) => {
    return (
        <Steps
            steps={steps}
            currentStepId={clientAddingCurrentStep}
        />
    )
}

const mapStateToProps = (state) => ({
    clientAddingCurrentStep: state.localClientsSettings.clientAddingCurrentStep
})

export default connect(mapStateToProps)(ClientAddingSteps)
