import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import './ViewClientLink.scss'

export default class ViewClientLink extends Component {
    render() {
        const {to} = this.props

        return <Link to={to} className="view-client-link">Vezi clientul</Link>
    }
}
