import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

export const DeleteDocumentSeriesModal = ({
    open,
    selectedDocumentSeries,
    isLoading,
    closeModal,
    deleteDocumentSeries
}) => {
    return (
        <Modal open={open && !isEmpty(selectedDocumentSeries)} title='Șterge serie'>
            {!isEmpty(selectedDocumentSeries) && (
                <div className='delete-modal'>
                    <p className='message'>
                        Ești sigur că vrei să ștergi seria "<span>{selectedDocumentSeries.name}</span>"?</p>
                    <div className="buttons-container">
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            variant='outlined'
                            loading={isLoading}
                            color='gray'
                        />
                        <Button
                            title='Da, șterge'
                            onClick={() => deleteDocumentSeries(selectedDocumentSeries)}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_DOCUMENT_SERIES,
    isLoading: state.documentSeries.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteDocumentSeries: (documentSeries) => dispatch(RESOURCES.documentSeries.destroy(documentSeries))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocumentSeriesModal)