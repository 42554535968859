import React from 'react'

import {Button} from '../Button'

import './FormSection.scss'

export default function FormSection({
    renderForm,
    autoHeight,
    submitButtonTitle,
    onSubmit,
    skipButtonTitle,
    onSkip,
    loading,
    buttonDisabled
}) {
    return (
        <div className={`form-section-container ${autoHeight ? 'auto-height' : ''}`}>
            <div className="form-container">
                <div className="inner">{renderForm()}</div>
                {onSubmit || onSkip ? (
                    <div className="form-buttons-container">
                        {onSkip &&
                            <Button
                                title={skipButtonTitle}
                                onClick={onSkip}
                                variant='outlined'
                                color='gray'
                                loading={loading}
                            />
                        }
                        {onSubmit &&
                            <Button
                                title={submitButtonTitle}
                                type='submit'
                                onClick={onSubmit}
                                loading={loading}
                                disabled={buttonDisabled}
                            />
                        }
                    </div>
                ) : null}
            </div>
        </div>
    )
}
