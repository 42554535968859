import React from 'react'
import Select from 'react-select'

import {isEmpty, snakeCase, camelCase, head, isUndefined} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'
import {InputError} from '../ErrorComponents'

import './Select.scss'

export default function CustomSelect({
    label,
    value,
    options,
    placeholder,
    onChange,
    onBlur,
    isMulti,
    isClearable,
    onInputChange,
    loading,
    size,
    fullWidth,
    menuPlacement,
    getOptionLabel,
    getOptionValue,
    labelInline,
    noOptionsMessage,
    name,
    disabled,
    errors,
    frontendErrors,
    touched
}) {
    const snakeCaseName = snakeCase(name)
    const camelCaseName = camelCase(name)

    const hasErrors =
        (errors && !isEmpty(errors[snakeCaseName])) ||
        (frontendErrors && touched && !isEmpty(frontendErrors[camelCaseName]))

    const chooseErrorMessageToShow = () =>
        errors && !isEmpty(errors[snakeCaseName])
            ? getErrorMessage(head(errors[snakeCaseName]), name)
            : frontendErrors[camelCaseName]

    return (
        <div className={`avo-select-container ${labelInline ? 'label-inline' : ''}`}>
            {label && <label className="avo-select-label">{label}</label>}
            <Select
                name={name}
                value={value}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder || ''}
                onInputChange={(value) => onInputChange && onInputChange(value)}
                filterOption={onInputChange ? false : undefined}
                menuPlacement={menuPlacement ? menuPlacement : 'auto'}
                getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : option.label}
                getOptionValue={(option) => getOptionValue ? getOptionValue(option) : option.value}
                classNamePrefix='avo-select'
                className={`avo-select ${size || 'medium'} ${fullWidth ? 'full-width' : ''}`}
                noOptionsMessage={() => !isUndefined(noOptionsMessage) ? noOptionsMessage() : 'Fără rezultate'}
                menuPortalTarget={document.body}
                styles={{
                    input: (base) => ({...base, 'input:focus': {boxShadow: 'none'}}),
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                isLoading={loading}
                isDisabled={disabled}
                isClearable={isClearable}
                isMulti={isMulti}
            />
            {hasErrors && <InputError message={chooseErrorMessageToShow()} />}
        </div>
    )
}
