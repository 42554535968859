import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

function DeleteClientModal({open ,client, isLoading, closeModal, deleteClient}) {
    return (
        <Modal open={open && !isEmpty(client)} title='Șterge client'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi clientul "<span>{client.name}</span>"?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteClient(client)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open : state.modals.type === modalTypes.DELETE_CLIENT,
    client: state.clients.currentClient,
    isLoading: state.clients.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteClient: (client) => dispatch(RESOURCES.clients.destroy(client))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientModal)