import React from 'react'
import {CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {Form, Formik} from 'formik'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {Input} from '../../../../components/Input'
import {Button} from '../../../../components/Button'

import './EditDocumentNameForm.scss'

export const EditDocumentNameForm = ({document, isLoading, fieldErrors, handleCancel, updateDocument}) => {
    return (
        <Formik
            initialValues={{name: document.name}}
            onSubmit={(values) => {
                updateDocument({name: values.name}, document.id)
                handleCancel()
            }}
        >
            {({handleChange, handleSubmit, values}) => (
                <Form className='edit-document-name-form-container'>
                    <Input
                        label='Nume document'
                        value={values.name}
                        onChange={handleChange('name')}
                        name='name'
                        errors={fieldErrors}
                        fullWidth
                    />
                    <div className="buttons-container">
                        <Button
                            title='Salvează'
                            icon={() => <CheckCircleIcon />}
                            onClick={handleSubmit}
                            color='secondary'
                        />
                        <Button
                            icon={() => <XMarkIcon />}
                            onClick={handleCancel}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const mapStateToProps = (state) => ({
    document: state.documents.currentDocument,
    isLoading: state.documents.isLoading,
    fieldErrors: state.documents.fieldErrors,
    nonFieldErrors: state.documents.nonFieldErrors
})

const mapDispatchToProps = (dispatch) => ({
    updateDocument: (documentData, documentID) => dispatch(RESOURCES.documents.update(documentData, documentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentNameForm)