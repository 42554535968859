import {Form, Formik} from 'formik'
import React, {useCallback, useEffect, useState} from 'react'

import {values as _values, find, isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {clientsSchema} from '../../../assets/validations'
import {getFieldOptions} from '../../../utils'
import {debounceWait} from '../../../utils/constants'
import {useDebouncedEffect} from '../../../utils/hooks'
import {attainerCategories} from '../constants'

import {ErrorsList} from '../../../components/ErrorComponents'
import {FormSection} from '../../../components/FormSection'
import {Input} from '../../../components/Input'
import {PageLoader} from '../../../components/PageLoader'
import {RequiredFieldsText} from '../../../components/RequiredFieldsText'
import {Select} from '../../../components/Select'
import {SimilarClientModal} from '../../../components/SimilarClientModal'

export const InitialInformations = ({
    personalEntityProfile,
    entityProfiles,
    isLoadingEntityProfiles,
    clients,
    isLoadingClients,
    createClient,
    selectedEntityID,
    fieldErrors,
    nonFieldErrors,
    clientOptions,
    getClientOptions,
    listAllEntityProfiles,
    searchClients
}) => {
    const [clientsQuery, setClientsQuery] = useState('')

    useEffect(() => { getClientOptions() }, [getClientOptions])

    useEffect(() => { listAllEntityProfiles(selectedEntityID)}, [listAllEntityProfiles, selectedEntityID])

    const handleFetchClients = useCallback((query = clientsQuery ) => {
        searchClients(query)
    }, [clientsQuery, searchClients])

    const handleChangeClientsSearchField = (value) => setClientsQuery(value)

    useDebouncedEffect(handleFetchClients, [clientsQuery], debounceWait)

    const types = getFieldOptions(clientOptions, 'type')

    // useEffect(() => {
    //     if(!isEmpty(clients) && !isLoadingClients) {
    //         setAttainerOptions((prevState) => [...prevState, attainerCategories.client])
    //     }
    //
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [clients])

    return (
        <>
            <SimilarClientModal />
            {!isEmpty(entityProfiles) && !isLoadingEntityProfiles && !isEmpty(clientOptions) ? (
                <Formik
                    initialValues={{
                        type: find(types, ['value', 'company']),
                        name: '',
                        // managers: [personalEntityProfile],
                        // collaborators: [],
                        attainerCategory: attainerCategories.society,
                        attainerID: null
                    }}
                    validationSchema={clientsSchema.initialInformations}
                    validateOnMount
                    onSubmit={(values) => {
                        // const managers = values.managers.map((manager) => ({
                        //     entity_profile_id: manager.value,
                        //     role: roleTypes.MANAGER.value
                        // }))
                        //
                        // const collaborators = values.collaborators.map((colaborator) => ({
                        //     entity_profile_id: colaborator.value,
                        //     role: roleTypes.COLLABORATOR.value
                        // }))

                        const clientData = {
                            name: values.name,
                            type: values.type.value,
                            entity_id: selectedEntityID,
                            // client_permissions: [...collaborators, ...managers],
                            attainer_category: values.attainerCategory?.value || null,
                            attainer_id: values.attainerID?.value || null
                        }

                        createClient(clientData)
                    }}
                >
                    {({
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        values,
                        touched,
                        errors,
                        isValid
                    }) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <FormSection
                                renderForm={() =>
                                    <>
                                        <Select
                                            label='Tip client*'
                                            value={values.type}
                                            options={types}
                                            onChange={(e) => setFieldValue('type', e)}
                                            onBlur={handleBlur('type')}
                                            name='type'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.type}
                                            fullWidth
                                        />
                                        <Input
                                            label={
                                                values.type === find(types, ['value', 'person']) ? 'Nume*' : 'Denumire*'
                                            }
                                            value={values.name}
                                            onChange={handleChange('name')}
                                            onBlur={handleBlur('name')}
                                            name='name'
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.name}
                                            fullWidth
                                        />
                                        {/*<Select*/}
                                        {/*    label='Manageri*'*/}
                                        {/*    value={values.managers}*/}
                                        {/*    options={differenceBy(entityProfiles, values.collaborators, 'value')}*/}
                                        {/*    onChange={(e) => setFieldValue('managers', e)}*/}
                                        {/*    onBlur={handleBlur('managers')}*/}
                                        {/*    name='managers'*/}
                                        {/*    frontendErrors={errors}*/}
                                        {/*    touched={touched.managers}*/}
                                        {/*    isMulti*/}
                                        {/*    fullWidth*/}
                                        {/*/>*/}
                                        {/*<Select*/}
                                        {/*    label='Colaboratori'*/}
                                        {/*    value={values.collaborators}*/}
                                        {/*    options={differenceBy(entityProfiles, values.managers, 'value')}*/}
                                        {/*    onChange={(e) => setFieldValue('collaborators', e)}*/}
                                        {/*    onBlur={handleBlur('collaborators')}*/}
                                        {/*    name='collaborators'*/}
                                        {/*    frontendErrors={errors}*/}
                                        {/*    touched={touched.collaborators}*/}
                                        {/*    isMulti*/}
                                        {/*    fullWidth*/}
                                        {/*/>*/}
                                        <Select
                                            label='Adus de'
                                            value={values.attainerCategory}
                                            options={[
                                                attainerCategories.society,
                                                attainerCategories.user,
                                                attainerCategories.client
                                            ]}
                                            onChange={(e) => {
                                                setFieldValue('attainerCategory', e)
                                                setFieldValue('attainerID', null)
                                            }}
                                            name='attainerCategory'
                                            errors={fieldErrors}
                                            isClearable
                                            fullWidth
                                        />
                                        {values.attainerCategory === attainerCategories.user ? (
                                            <Select
                                                label='Avocat'
                                                value={values.attainerID}
                                                name="attainerID"
                                                errors={fieldErrors}
                                                options={entityProfiles}
                                                frontendErrors={errors}
                                                onChange={(e) => setFieldValue('attainerID', e)}
                                                fullWidth
                                            />
                                        ) : values.attainerCategory === attainerCategories.client ? (
                                            <Select
                                                label='Client'
                                                value={values.attainerID}
                                                name="attainerID"
                                                errors={fieldErrors}
                                                options={clients}
                                                loading={isLoadingClients}
                                                frontendErrors={errors}
                                                onChange={(e) => setFieldValue('attainerID', e)}
                                                onInputChange={(value) => handleChangeClientsSearchField(value)}
                                                isClearable
                                                fullWidth
                                            />
                                        ) : null}
                                        <RequiredFieldsText />
                                    </>
                                }
                                onSubmit={handleSubmit}
                                submitButtonTitle='Mai departe'
                                buttonDisabled={!isValid}
                                loading={isLoadingClients}
                            />
                        </Form>
                    )}
                </Formik>
            ) :
                <PageLoader />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    let entityProfiles = _values(state.entityProfiles.data).map((profile) =>
        ({value: profile.id, label: `${profile.first_name} ${profile.last_name}`})
    )

    let clients = _values(state.clients.searchData).map((client) => ({value: client.id, label: client.name}))

    return {
        clientOptions: state.clients.options,
        clients: clients,
        isLoadingClients: state.clients.isLoading,
        fieldErrors: state.clients.fieldErrors,
        nonFieldErrors: state.clients.nonFieldErrors,
        entityProfiles: entityProfiles,
        isLoadingEntityProfiles: state.entityProfiles.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID,
        personalEntityProfile: find(entityProfiles, ['value', state.localConfigs.entityProfileID])
    }
}

const mapDispatchToProps = (dispatch) => ({
    getClientOptions: () => dispatch(RESOURCES.clients.getOptions()),
    searchClients: (search, params) => dispatch(RESOURCES.clients.search(search, {...params, active: 'true'})),
    createClient: (values) => dispatch(RESOURCES.clients.create(values)),
    listAllEntityProfiles: (selectedEntityID) => dispatch(RESOURCES.entityProfiles.listAll({
        entity_id: selectedEntityID
    }))
})

export default connect(mapStateToProps, mapDispatchToProps)(InitialInformations)