import React from 'react'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'

export const GenericWarningModal = ({
    open,
    action,
    text,
    title,
    closeModal
}) => {
    const [isLoading, setIsLoading] = React.useState(false)

    return (
        <Modal open={open} title={title}>
            <div className='delete-modal'>
                <p className='message'>{text}</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        color='gray'
                    />
                    <Button
                        title='Da'
                        onClick={async () => {
                            setIsLoading(true)
                            await action()
                            setIsLoading(false)
                        }}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.GENERIC_WARNING_MODAL
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(GenericWarningModal)