import React, {useCallback, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {ArrowDownTrayIcon, PlusIcon} from '@heroicons/react/24/solid'

import {lightFormat} from 'date-fns'
import fileDownload from 'js-file-download'
import {isEmpty, orderBy} from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {addFilters, removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {modalTypes, openModal} from '../../../../redux/modals'

import {CANCELED_STATE, debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DeleteInvoiceButton} from '../../../../components/DeleteInvoiceButton'
import {DeleteInvoiceModal} from '../../../../components/DeleteInvoiceModal'
import FilterInvoicesForm from '../../../Invoices/partials/forms/FilterInvoicesForm'

import './Invoices.scss'

export const Invoices = ({
    invoices,
    isLoading,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    filters,
    selectedEntityID,
    match: {params: {projectID}},
    listInvoices,
    navigate,
    openAddInvoiceModal,
    openDeleteInvoiceModal,
    removeFilter,
    match,
    cancelInvoice
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [isPendingExport, setIsPendingExport] = useState(false)
    const pageProjectID = match.params.projectID

    const handleFetchInvoices = (page = 1) => {
        const appliedFilters = {
            ...generateFiltersForAPI(filters),
            project_id: pageProjectID
        }

        listInvoices(selectedEntityID, searchFilter, appliedFilters, page)
    }

    useDebouncedEffect(handleFetchInvoices, [searchFilter], debounceWait)

    const handleChangePage = (page) => !isLoading && handleFetchInvoices( page)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const getCanceledInvoiceClassName = useCallback((invoice) => {
        if(invoice.state === 'canceled') {
            return 'invoice-canceled'
        }

        return ''
    }, [])

    const handleDeleteInvoice = useCallback((invoice) => {
        if(invoice.deletion_prevented) {
            cancelInvoice(invoice.id)
        } else {
            setSelectedInvoice(invoice)
            openDeleteInvoiceModal()
        }
    }, [cancelInvoice, openDeleteInvoiceModal])

    const invoicesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'issue_date',
                Cell: ({value: issueDate, row:{original: invoice}}) => (
                    <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                        {issueDate ? lightFormat(new Date(issueDate), 'dd/MM/yyyy') : '-'}
                    </span>
                )
            },
            {
                Header: 'Serie și număr',
                accessor: 'series_id',
                Cell: ({_value, row: {original: invoice}}) => (
                    <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                        {invoice.series_name} {invoice.series_number}
                    </span>
                )
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({value: client, row: {original: invoice}}) => (
                    <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                        {client ? client.name : '-'}
                    </span>
                ),
                style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
            },
            {
                Header: 'Suma',
                accessor: 'total_with_VAT',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return (
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {total} {invoice.currency}
                        </span>
                    )
                }
            },
            {
                Header: 'De încasat',
                accessor: 'total_unpaid',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return (
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {total} {invoice.currency}
                        </span>
                    )
                }
            },
            // {
            //     Header: 'SmartBill',
            //     accessor: 'sync_moment_smartbill',
            //     Cell: ({value}) => !isEmpty(value)? <CheckCell /> :<RedX/>,
            //     style: {
            //         width: '55px'
            //     }
            // },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({value: invoiceID, row: {original: invoice}}) => (
                    <div className='datatable-row-buttons-container'>
                        <Link to={`/invoices/${invoiceID}`}>
                            <Button title='Vezi' size='small' />
                        </Link>
                        <DeleteInvoiceButton
                            invoice={invoice}
                            onClick={() => handleDeleteInvoice(invoice)}
                        />
                    </div>
                )
            }
        ]
    }, [getCanceledInvoiceClassName, handleDeleteInvoice])

    return (
        <div className="page-info">
            <Datatable
                title='Facturi'
                data={invoices}
                columns={invoicesColumns}
                loading={isLoading}
                searchContainerButton={() => (
                    <Button
                        loading={isPendingExport}
                        disabled={isEmpty(invoices) || isPendingExport}
                        title='Descarcă CSV'
                        icon={() => <ArrowDownTrayIcon/>}
                        onClick={async () => {
                            setIsPendingExport(true)

                            const preparedFilters = {
                                ...generateFiltersForAPI(filters), search: searchFilter
                            }

                            const response = await performRequest(RESOURCES.invoices.listCSV({
                                ...preparedFilters,
                                entity_id: selectedEntityID,
                                project_id: projectID
                            }))
                            setIsPendingExport(false)

                            if (response.status === 200) {
                                toast.success('Fișierul CSV a fost generat.')
                                fileDownload(response.data, 'export-facturi.csv')
                            } else {
                                toast.error('A apărut o eroare la generarea raportului.')
                            }
                        }}
                    />
                )}
                headerButton={() => (
                    <Link style={{marginLeft: '1rem'}} to={`/invoices/add?initialProjectId=${projectID}`}>
                        <Button title='Adaugă factură' icon={() => <PlusIcon />} color='secondary' />
                    </Link>
                )}
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => <FilterInvoicesForm filterInvoices={handleFetchInvoices} />}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchInvoices()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută facturi'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <DeleteInvoiceModal selectedInvoice={selectedInvoice}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    invoices: orderBy(state.invoices.data, ['issue_date', 'created'], ['desc', 'desc']),
    isLoading: state.invoices.isLoading,
    totalPages: state.invoices.totalPages,
    nextPage: state.invoices.next,
    previousPage: state.invoices.previous,
    currentPage: state.invoices.current,
    filters: state.filters.invoices,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteInvoiceModal: () => dispatch(openModal(modalTypes.DELETE_INVOICE)),
    navigate: (route) => dispatch(push(route)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.invoices.name, filters)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.invoices.name, filter)),
    cancelInvoice: (invoiceID) => dispatch(RESOURCES.invoices.update({state: CANCELED_STATE}, invoiceID)),
    listInvoices: (entityID, search, filters, page) => dispatch(
        RESOURCES.invoices.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)
