import React from 'react'

import {format} from 'date-fns'

import {getLogIcon, getLogText} from './constants'

import './LogRow.scss'

export default function LogRow({log, isLast}) {
    const {icon, bgColorClass} = getLogIcon(log)

    return (
        <div className="log-row">
            {!isLast && <span className="log-icon-divider" aria-hidden="true"/>}
            <div className="log-container">
                <div className='log-icon-container'>
                    <span className={`log-icon ${bgColorClass}`}>
                        {icon()}
                    </span>
                </div>
                <div className="log-info-container">
                    {getLogText(log)}
                    <p className="log-date">
                        {format(new Date(log.created), 'dd LLL')}
                    </p>
                </div>
            </div>
        </div>
    )
}
