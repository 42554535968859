import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {openSlideover} from '../../../../redux/slideovers'

import {Button} from '../../../../components/Button'
import {DeleteAddendumModal} from '../../../../components/DeleteAddendumModal'
import {DetailsCard} from '../../../../components/DetailsCard'
import {Loader} from '../../../../components/Loader'
import {ResourceLogsList} from '../../../../components/ResourceLogsList'

import {EditAddendumLocationsSlideover} from '../slideovers'

import './AddendumMetaInfo.scss'
import {logsOrderingOptions} from '../../../../utils/types'

export const AddendumMetaInfo = ({
    addendum,
    isLoading,
    logs,
    nextPage,
    listLogs,
    openDeleteAddendumModal,
    openSlideover,
    match: {params: {addendumID}}
}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {listLogs(addendumID)}, [addendumID])

    const addendumInfoRows = (addendum) => [
        {label: 'Nume', value: addendum.name},
        {label: 'Mărime', value: addendum.size ? `${addendum.size} Mb` : '-'},
        {label: 'Dată', value: addendum.created ? lightFormat(new Date(addendum.created), 'dd/MM/yyyy') : ''}
    ]

    return (
        <div className="addendum-details-page-container">
            <div className="addendum-info-cards-container">
                <div className="row">
                    <div className="left">
                        <DetailsCard
                            title='Informații act adițional'
                            rows={addendumInfoRows(addendum)}
                            loading={isLoading}
                        />
                    </div>
                    <div className="right">
                        <div className="addendum-info-card addendum-locations-card">
                            <div className="header">
                                <p className="header-title">Locații</p>
                                {/*<Button*/}
                                {/*    title='Editează'*/}
                                {/*    onClick={() => openSlideover(slideoverTypes.EDIT_CONTRACT_LOCATIONS)}*/}
                                {/*    size='small'*/}
                                {/*/>*/}
                            </div>
                            {!isLoading ? (
                                <div className="content">
                                    <div className="section">
                                        <p className="section-title">
                                            Proiecte
                                        </p>
                                        {!isEmpty(addendum.projects) ? (
                                            <div className="location-rows-container">
                                                {addendum.projects.map((project) => (
                                                    <LocationContainer project={project} key={project.id} />
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="no-locations">
                                                Acest addendum nu este folosit pentru niciun proiect
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ResourceLogsList resourceID={addendumID} resourceName={RESOURCES.addendums.name} />
                </div>
                <div className='mt-10 flex gap-5 justify-end'>
                    <Button
                        title='Șterge act adițional'
                        onClick={openDeleteAddendumModal}
                        size='large'
                        color='red'
                    />
                </div>
            </div>
            <DeleteAddendumModal />
            <EditAddendumLocationsSlideover addendumID={addendumID}/>
        </div>
    )
}

const LocationContainer = ({project}) => {
    return (
        <Link to={`/projects/${project.id}`} className="location-container">
            <p className="location-name">
                {project.name}
            </p>
        </Link>
    )
}

const mapStateToProps = (state) => {

    return {
        project: state.projects.currentProject,
        addendum: state.addendums.currentAddendum,
        isLoading: state.addendums.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    openDeleteAddendumModal: () => dispatch(openModal(modalTypes.DELETE_ADDENDUM)),
    openSlideover: (slideoverType) => dispatch(openSlideover(slideoverType)),
    listLogs: (addendumID) => dispatch(RESOURCES.logs.list({
        resource_id: addendumID,
        resource_name: RESOURCES.addendums.name,
        ordering: logsOrderingOptions.desc
    }))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddendumMetaInfo)
