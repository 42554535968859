import {toast} from 'react-toastify'
import {takeEvery, put} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent} from 'avoapp-react-common/dist/redux/clientAddresses'

import {closeModal} from './modals'
import {closeSlideover} from './slideovers'

const {clientAddresses: resource} = RESOURCES

function* handleCreateClientAddressSuccess() {
    toast.success('Adresa a fost creată cu succes')

    yield put(closeSlideover())
}

function* handleRetrieveClientAddressSuccess(response) {
    yield put(setCurrent(response.payload))
}

function* handleUpdateClientAddressSuccess() {
    toast.success('Modificările au fost salvate')

    yield put(closeSlideover())
}

function* handleDestroyClientAddressSuccess() {
    toast.success('Adresa a fost ștearsă')

    yield put(closeModal())
}

function* handleDestroyClientAddressFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateClientAddressSuccess)
    yield takeEvery(resource.actions.retrieve.success, handleRetrieveClientAddressSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateClientAddressSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyClientAddressSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyClientAddressFail)
}
