import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, find, head, values as _values, isNull, isObject, values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {getCountries, getStates} from 'avoapp-react-common/dist/redux/geo'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {objectKeysToSnakeCase} from '../../../../../utils'
import {clientAddressesSchema} from '../../../../../assets/validations'

import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {Loader} from '../../../../../components/Loader'
import {Toggle} from '../../../../../components/Toggle'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {Slideover} from '../../../../../components/Slideover'

import '../../../../../assets/scss/SlideoverForms.scss'

export const AddClientAddressSlideover = ({
    open,
    client,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    createClientAddress,
    clientAddresses,
    countries,
    states,
    getStates,
    getCountries
}) => {
    useEffect(() => {
        getCountries()
        getStates('RO')

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Slideover open={open && !isEmpty(client)} title='Adaugă adresă'>
            {!isEmpty(client) && !isEmpty(countries) && !isLoading ? (
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            clientID: client.id,
                            postalCode: '',
                            country: find(countries, ['code', 'RO']),
                            county: '',
                            locality: '',
                            address: '',
                            addressDescription: !clientAddresses.length ? 'Sediu principal' : '',
                            isPrimary: !clientAddresses.length
                        }}
                        validationSchema={clientAddressesSchema}
                        validateOnMount
                        onSubmit={(values) => {
                            const updateData = {
                                ...objectKeysToSnakeCase(values),
                                country: values.country.code,
                                county: isObject(values.county) ? values.county.name : values.county
                            }

                            createClientAddress(updateData)
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            values,
                            handleSubmit,
                            touched,
                            errors,
                            isValid
                        }) => (
                            <>
                                <Form className="slideover-form">
                                    <Select
                                        label='Țara*'
                                        value={values.country}
                                        options={countries}
                                        onChange={(e) => {
                                            setFieldValue('country', e)
                                            setFieldValue('county', '')

                                            if(!isNull(e)) {
                                                getStates(e.code)
                                            }
                                        }}
                                        onBlur={handleBlur('country')}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.code}
                                        name='country'
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.country}
                                        isClearable
                                        fullWidth
                                    />
                                    {!isEmpty(states) || isNull(values.country) ? (
                                        <Select
                                            label='Județ*'
                                            value={values.county}
                                            options={states}
                                            onChange={(e) => setFieldValue('county', e)}
                                            onBlur={handleBlur('county')}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.code}
                                            name='county'
                                            errors={head(fieldErrors.client_addresses)}
                                            frontendErrors={errors}
                                            touched={touched.county}
                                            disabled={isNull(values.country)}
                                            isClearable
                                            fullWidth
                                        />
                                    ) : (
                                        <Input
                                            label='Județ*'
                                            value={values.county}
                                            onChange={handleChange('county')}
                                            onBlur={handleBlur('county')}
                                            name='county'
                                            errors={head(fieldErrors.client_addresses)}
                                            frontendErrors={errors}
                                            touched={touched.county}
                                            fullWidth
                                        />
                                    )}
                                    <Input
                                        label='Localitate / Oraș*'
                                        value={values.locality}
                                        onChange={handleChange('locality')}
                                        onBlur={handleBlur('locality')}
                                        name='locality'
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.locality}
                                        fullWidth
                                    />
                                    <Input
                                        label='Cod Poștal'
                                        value={values.postalCode}
                                        onChange={handleChange('postalCode')}
                                        onBlur={handleBlur('postalCode')}
                                        name='postalCode'
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.postalCode}
                                        fullWidth
                                    />
                                    <Input
                                        label='Adresa*'
                                        value={values.address}
                                        onChange={handleChange('address')}
                                        onBlur={handleBlur('address')}
                                        name='address'
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.address}
                                        fullWidth
                                    />
                                    <Input
                                        label='Descriere adresă*'
                                        value={values.addressDescription}
                                        onChange={handleChange('addressDescription')}
                                        onBlur={handleBlur('addressDescription')}
                                        name='addressDescription'
                                        errors={head(fieldErrors.client_addresses)}
                                        frontendErrors={errors}
                                        touched={touched.addressDescription}
                                        fullWidth
                                    />
                                    <Toggle
                                        label='Adresă principală'
                                        checked={values.isPrimary}
                                        onChange={(e) => setFieldValue('isPrimary', e)}
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title='Salvează'
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        disabled={!isValid}
                                        type='submit'
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            ) : isLoading ? (
                <div className="loader-container">
                    <Loader size='large' />
                </div>
            ) : null}
        </Slideover>
    )
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.ADD_CLIENT_ADDRESS,
    client: state.clients.currentClient,
    isLoading: state.clientAddresses.isLoading,
    fieldErrors: state.clientAddresses.fieldErrors,
    nonFieldErrors: state.clientAddresses.nonFieldErrors,
    clientAddresses: values(state.clientAddresses.data),
    countries: _values(state.geo.countries),
    states: _values(state.geo.states)
})

const mapDispatchToProps = (dispatch) => ({
    createClientAddress: (values) => dispatch(RESOURCES.clientAddresses.create(values)),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryCode) => dispatch(getStates({country_code: countryCode, page_size: 50}))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddClientAddressSlideover)