import _ from 'lodash'
import {EntityProfileMinimal, ProjectMinimal} from '../../../../types/api'
import {renderInterval} from '../../../Tasks/partials/TasksList/partials/utils'
import {Row} from '../../../../types/local'

export const tasksColumns = [
    {
        Header: 'Interval',
        accessor: '_interval',
        Cell: ({row: {original: task}} : {row: Row}) => renderInterval(task),
        style: {whiteSpace: 'nowrap'}
    },
    {
        Header: 'Sarcină',
        accessor: 'title'
    },
    {
        Header: 'Responsabili',
        accessor: 'owners',
        Cell: ({value: owners}: {value: Array<EntityProfileMinimal>}) => {
            return _.join(owners.map((owner) => owner.full_name), ', ')
        }
    },
    {
        Header: 'Proiect',
        accessor: 'project',
        Cell: ({value: project}: {value: ProjectMinimal}) => project?.name || '-'
    },

    {
        Header: 'Ore Lucrate',
        accessor: 'duration_as_hours'
    }
]

export const actionIds = {
    ACTIVE_TASKS: 'active',
    FINISHED_TASKS: 'finished'
}
