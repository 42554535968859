import React from 'react'
import {Link} from 'react-router-dom'

import './TableLink.scss'

interface TableLinkProps {
    to: string
}

const TableLink: React.FC<TableLinkProps> = ({to, children}) => {
    return <Link className='table-link' to={to}>
        <div>
            {children}
        </div>
    </Link>
}

export default TableLink