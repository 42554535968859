import React, {Component} from 'react'

import {XMarkIcon} from '@heroicons/react/24/outline'

import {debounce, isNull, values} from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../../../redux/modals'

import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'

import {Button} from '../../../Button'
import {Modal} from '../../../Modal'
import {Select} from '../../../Select'

import './SelectTaskModal.scss'

export class SelectTaskModal extends Component {
    constructor() {
        super()

        this.state = {
            tasksQuery: '',
            projectsQuery: '',
            selectedProject: null,
            selectedTask: null
        }
    }

    componentDidMount = () => this.handleFetchProjects()

    handleFetchProjects = (query = this.state.projectsQuery) => {
        const {searchProjects} = this.props

        searchProjects(query)
    }

    debounceSearchProjects = debounce(this.handleFetchProjects, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({projectsQuery: value})
        this.debounceSearchProjects(value)
    }

    handleFetchTasks = (query = this.state.tasksQuery, project = this.state.selectedProject) => {
        const {searchTasks, selectedEntityID} = this.props

        searchTasks(query, {project_id: project.id, entity_id: selectedEntityID, is_completed: 'false'})
    }

    debounceSearchTasks = debounce(this.handleFetchTasks, debounceWait)

    handleChangeTasksSearchField = (value) => {
        this.setState({tasksQuery: value})
        this.debounceSearchTasks(value)
    }

    handleSelectTask = (task) => {
        const {currentTaskTimeLog} = this.props

        this.setState({selectedTask: task})

        if(isNull(currentTaskTimeLog.task)) {
            this.changeTaskForCurrentLog(task)
        }
    }

    handleStartNewTimeLog = () => {
        const {selectedTask} = this.state
        const {currentTaskTimeLog, selectedEntityID, updateTimeLog, createTimeLog} = this.props

        updateTimeLog({stop: toApiTimeFormat(new Date())}, currentTaskTimeLog.id)

        const timeLogData = {
            entity_id: selectedEntityID,
            date: toApiDateFormat(new Date()),
            start: toApiTimeFormat(new Date()),
            task_id: selectedTask.id
        }

        createTimeLog(timeLogData)
    }

    changeTaskForCurrentLog = (task = this.state.selectedTask) => {
        const {currentTaskTimeLog, updateTimeLog} = this.props

        updateTimeLog({task_id: task.id}, currentTaskTimeLog.id)
    }

    render() {
        const {selectedProject, selectedTask, tasksQuery} = this.state
        const {
            open,
            projects,
            isLoadingProjects,
            tasks,
            isLoadingTasks,
            currentTaskTimeLog,
            isLoadingTaskTimeLogs,
            closeModal
        } = this.props

        return (
            <Modal open={open} hideHeader>
                <div className="select-task-modal-container">
                    <div className="select-task-modal-header">
                        <p className='select-task-modal-title'>Selectează sarcină</p>
                        <Button onClick={closeModal} icon={() => <XMarkIcon />} size='small'/>
                    </div>
                    <div className="selects-container">
                        <div className="select-project-container">
                            <Select
                                label='Proiect'
                                placeholder='Alege un proiect'
                                options={projects}
                                onChange={(option) => {
                                    this.setState({selectedProject: option})
                                    this.handleFetchTasks(tasksQuery, option)
                                }}
                                onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                loading={isLoadingProjects}
                            />
                        </div>
                        {!isNull(selectedProject) ? (
                            <Select
                                label='Sarcină'
                                placeholder='Alege o sarcină'
                                options={tasks}
                                onChange={(option) => this.handleSelectTask(option)}
                                onInputChange={(value) => this.handleChangeTasksSearchField(value)}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                                loading={isLoadingTasks}
                            />
                        ) : null}
                    </div>
                    {
                        !isNull(selectedTask) &&
                        !isNull(currentTaskTimeLog.task) &&
                        isNull(currentTaskTimeLog.stop) &&
                        !isLoadingTaskTimeLogs ? (
                            <div className="task-selected-action-container">
                                <p className="time-log-method">
                                    Aveți deja un pontaj în derulare. Doriți să începeți un nou pontaj nou
                                    pentru noua sarcină sau să schimbați sarcina pentru pontajul curent?
                                </p>
                                <div className="buttons-container">
                                    <Button
                                        title="Schimbă sarcina"
                                        onClick={this.changeTaskForCurrentLog}
                                        variant="outlined"
                                        fullWidth
                                    />
                                    <Button
                                        title="Începe pontaj nou"
                                        onClick={this.handleStartNewTimeLog}
                                        fullWidth
                                    />
                                </div>
                            </div>
                            ) : null
                    }
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.SELECT_TASK,
    selectedEntityID: state.localConfigs.selectedEntityID,
    tasks: values(state.tasks.searchData),
    isLoadingTasks: state.tasks.isLoading,
    currentTaskTimeLog: state.taskTimeLogs.currentTaskTimeLog,
    isLoadingTaskTimeLogs: state.taskTimeLogs.isLoading,
    projects: values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search, {active: 'true'})),
    searchTasks: (search, params) => dispatch(RESOURCES.tasks.search(search, params)),
    createTimeLog: (data) => dispatch(RESOURCES.taskTimeLogs.create(data)),
    updateTimeLog: ( data, taskTimeLogID) => dispatch(RESOURCES.taskTimeLogs.update(data, taskTimeLogID)),
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectTaskModal)
