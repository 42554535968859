import {MagnifyingGlassIcon} from '@heroicons/react/24/outline'
import {debounce, isEmpty, values} from 'lodash'
import React from 'react'
import {connect} from 'react-redux'
// @ts-ignore
import {Button} from '../../../Button'
// @ts-ignore
import {Input} from '../../../Input'

// @ts-ignore
import {Slideover} from '../../../Slideover'
// @ts-ignore
import {FilterTag} from '../FilterTag'

// @ts-ignore
import {openSlideover, slideoverTypes} from '../../../../redux/slideovers'

import '../../Datatable.scss'
import {debounceWait} from '../../../../utils/constants'
interface FilterHeaderProps {
  filtersOpen: boolean
  openFiltersSlideover: () => void
  filterable?: boolean
  filtersForm?: any
  searchable?: boolean
  searchValue?: string
  searchDefaultValue?: string
  onSearch?: (value: any) => void
  searchPlaceholder?: string
  searchContainerButton?: () => any,
  appliedFilters?: any,
  handleRemoveFilter?: (value: any) => void,
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
    filtersOpen,
    openFiltersSlideover,
    filterable,
    filtersForm,
    searchable,
    searchValue,
    searchDefaultValue,
    onSearch,
    searchPlaceholder,
    searchContainerButton,
    appliedFilters,
    handleRemoveFilter
}) => {

    const setSearch = debounce((event: any) => {
        onSearch?.(event)
    }, debounceWait)

    return <div>
        <div className="avo-search-filters-container">
            {filterable && (
                <div className="avo-filters-container">
                    <Button title='Filtre' onClick={openFiltersSlideover}/>
                    <Slideover title='Filtre' open={filtersOpen}>
                        {filtersForm && filtersForm()}
                    </Slideover>
                </div>
            )}
            {searchable && (
                <div className="avo-search-container">
                    <Input
                        // value={searchValue}
                        defaultValue={searchDefaultValue}
                        onChange={(event: any) => setSearch(event)}
                        placeholder={searchPlaceholder}
                        renderIcon={() =>
                            <MagnifyingGlassIcon aria-hidden="true"/>
                        }
                        fullWidth
                    />
                </div>
            )}
            {searchContainerButton && (
                <div className="avo-search-container-button-container">
                    {searchContainerButton()}
                </div>
            )}
        </div>
        {!isEmpty(appliedFilters) ? (
            <div className="filters-container">
                <p className='filters-text'>Filtre aplicate: </p>
                <div className="filters-list">
                    {values(appliedFilters).map((filter) => (
                        <FilterTag
                            filter={filter}
                            onRemove={() => handleRemoveFilter?.(filter)}
                            key={filter.key}
                        />
                    ))}
                </div>
            </div>
        ) : null}
    </div>
}

const mapStateToProps = (state: any) => ({
    filtersOpen: state.slideovers.type === slideoverTypes.DATATABLE_FILTERS
})

const mapDispatchToProps = (dispatch: any) => ({
    openFiltersSlideover: () => dispatch(openSlideover(slideoverTypes.DATATABLE_FILTERS))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterHeader)