import React, {useCallback} from 'react'

import {connect} from 'react-redux'
// @ts-ignore
import {closeModal, modalTypes} from '../../../../redux/modals'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {HearingForm} from 'components/AddMDManualSubscriptionModal'
// @ts-ignore
import {Modal} from '../../../../components/Modal'

type EditHearingModalProps = {
    open: boolean,
    hearing: any
}

export const EditHearingModal = ({open, hearing}: EditHearingModalProps) => {
    const onEditHearing = useCallback((hearingData) => {
        return performRequest(RESOURCES.hearings.update(hearingData, hearing.id))
    }, [hearing?.id])

    if(!hearing){
        return <React.Fragment />
    }

    return (
        <Modal open={open} title='Editare termen'>
            <HearingForm currentPhaseID={hearing.phase_id} onSubmit={onEditHearing} hearingToEdit={hearing} />
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.EDIT_HEARING
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditHearingModal)