import React, {useCallback, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {CalendarIcon, ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/24/solid'

import {eachDayOfInterval, isAfter, isSameDay, isSameMonth, isToday, isWithinInterval} from 'date-fns'
import {capitalize, filter, isNull, values} from 'lodash'

import {connect} from 'react-redux'

import {classNames, roLocaleFormat, toApiDateFormat} from '../../../../../../utils'
import {getMonthEnds} from '../../constants'

import {ColorPreview} from '../../../../../../components/ColorPreview'

import './CalendarDayView.scss'

export const CalendarDayView = ({
    tasks,
    hocSelectedDay,
    handleSelectHocSelectedDay,
    handleNextMonth,
    handlePreviousMonth
}) => {
    const [days, setDays] = useState([])
    const [dayTasks, setDayTasks] = useState([])

    const createDaysArray = useCallback((date = new Date()) => {
        const {start, end} = getMonthEnds(new Date(date))
        const rawDays = eachDayOfInterval({start, end})

        return rawDays.map((day) => ({
            date: toApiDateFormat(day),
            isCurrentMonth: isSameMonth(new Date(day), new Date(date))
        }))
    }, [])

    const isSelectedDay = useCallback(
        (date) => isSameDay(new Date(hocSelectedDay), new Date(date)),
        [hocSelectedDay]
    )

    const getDayTasks = useCallback(
        (day) =>
            filter(
                tasks,
                (task) =>
                    !isNull(task.start) &&
              ((!isNull(task.stop) &&
                isAfter(new Date(task.stop), new Date(task.start)) &&
                isWithinInterval(new Date(day), {
                    start: new Date(task.start),
                    end: new Date(task.stop)
                })) ||
                isSelectedDay(task.start))
            ),
        [isSelectedDay, tasks]
    )

    useEffect(() => {
        setDays(createDaysArray(new Date(hocSelectedDay)))
        setDayTasks(getDayTasks(new Date(hocSelectedDay)))
    }, [createDaysArray, getDayTasks, hocSelectedDay])

    return (
        <div className="avo-calendar-day-view-container">
            <div className="avo-calendar-day-view-left-container">
                <div className="avo-calendar-day-view-calendar-pagination-container">
                    <button
                        type="button"
                        className='avo-calendar-day-view-calendar-pagination-button'
                        onClick={handlePreviousMonth}
                    >
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon
                            className="avo-calendar-day-view-calendar-pagination-button-icon"
                            aria-hidden="true"
                        />
                    </button>
                    <div className="avo-calendar-day-view-calendar-pagination-month">
                        {capitalize(roLocaleFormat(new Date(hocSelectedDay), 'MMMM'))}
                    </div>
                    <button
                        type="button"
                        className='avo-calendar-day-view-calendar-pagination-button'
                        onClick={handleNextMonth}
                    >
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon
                            className="avo-calendar-day-view-calendar-pagination-button-icon"
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div className="avo-calendar-day-view-calendar-weekdays-container">
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                    <div>S</div>
                </div>
                <div className='avo-calendar-day-view-calendar-container'>
                    {days.map((day, dayIdx) => (
                        <button
                            key={day.date}
                            type="button"
                            onClick={() => handleSelectHocSelectedDay(new Date(day.date))}
                            className={classNames(
                                'avo-calendar-day-view-calendar-calendar-day-button',
                                day.isCurrentMonth && 'is-current-month',
                                isToday(new Date(day.date)) && 'is-today',
                                isSelectedDay(new Date(day.date)) && 'is-selected',
                                dayIdx === 0 && 'rounded-tl-lg',
                                dayIdx === 6 && 'rounded-tr-lg',
                                dayIdx === days.length - 7 && 'rounded-bl-lg',
                                dayIdx === days.length - 1 && 'rounded-br-lg'
                            )}
                        >
                            <time dateTime={day.date} className='avo-calendar-day-view-day'>
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                        </button>
                    ))}
                </div>
            </div>
            <ol className="avo-calendar-day-view-tasks-list">
                {dayTasks.map((task) => (
                    <li key={task.id} className="avo-calendar-day-view-task-container">
                        <div className="avo-calendar-day-view-task-inner-container">
                            <Link to={`/tasks/${task.id}`} className='avo-calendar-day-view-task-name-container'>
                                {!isNull(task.color) && <ColorPreview color={task.color} shape='circle'/>}
                                {task.title}
                            </Link>
                            <dl className="avo-calendar-day-view-task-description-container">
                                <div className="avo-calendar-day-view-task-description-inner-container">
                                    <dt className="avo-calendar-day-view-task-calendar-icon-container">
                                        <span className="sr-only">Data</span>
                                        <CalendarIcon className="calendar-icon" aria-hidden="true" />
                                    </dt>
                                    <dd>
                                        <p>
                                            {roLocaleFormat(new Date(task.start), 'eee, dd MMM yyyy HH:mm')}{' '}
                                            {!isNull(task.stop) && (
                                                `- ${roLocaleFormat(new Date(task.stop), 'eee, dd MMM yyyy HH:mm')}`
                                            )}
                                        </p>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </li>
                ))}
            </ol>
        </div>
    )
}

const mapStateToProps = (state) => {
    const tasks = values(state.tasks.data).sort(
        (a, b) => !isNull(a.stop) && !isNull(b.stop) && isAfter(new Date(a.stop), new Date(b.stop))
    )

    return {
        tasks: tasks
    }
}

export default connect(mapStateToProps)(CalendarDayView)