import {goBack, push} from 'connected-react-router'
import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import {endsWith, findLast, head, isUndefined, startsWith} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal, modalTypes, openModal} from './modals'

const {empowerments: resource} = RESOURCES

function* handleCreateEmpowermetSuccess() {
    toast.success('Împuternicirea a fost adăugată cu succes')
    yield put(goBack())
}

function* handleUpdateEmpowermetSuccess() {
    toast.success('Modificările au fost salvate cu succes')

    const currentLocationPathname = head(yield select((state) => state.routerLocations)).location.pathname

    if(startsWith(currentLocationPathname, '/contracts') && endsWith(currentLocationPathname, '/settings')) {
        yield put(openModal(modalTypes.EDIT_CONTRACT_SETTINGS_DISCLAIMER))
    } else {
        yield put(closeModal())
    }
}

function handleUpdateEmpowermetFail() {
    toast.error('Modificările NU au putut fi salvate. Te rugam să încerci din nou!')
}

function* handleDestroyEmpowermetSuccess() {
    toast.success('Împuternicirea a fost ștearsă cu succes')

    const sourceLocation = yield select((state) => findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/contracts/empowerments' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/empowerments'))
    ))

    yield put(push(!isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/contracts/empowerments'))
    yield put(closeModal())
}

function handleDestroyEmpowermetFail() {
    toast.error('Împuternicirea NU a putut fi ștearsă. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateEmpowermetSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateEmpowermetSuccess)
    yield takeEvery(resource.actions.update.fail, handleUpdateEmpowermetFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyEmpowermetSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyEmpowermetFail)
}
