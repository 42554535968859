export const genders = [
    {value: 'M', label: 'Masculin'},
    {value: 'F', label: 'Feminin'}
]

export const languages = [
    {value: 'RO', label: 'Română'},
    {value: 'EN', label: 'Engleză'},
    {value: 'DE', label: 'Germană'},
    {value: 'IT', label: 'Italiană'},
    {value: 'FR', label: 'Franceză'},
    {value: 'ES', label: 'Spaniolă'},
    {value: 'OTHER', label: 'Altă limbă'}
]

export const attainerCategories = {
    client: {value: 'client', label: 'Recomandare client'},
    user: {value: 'user', label: 'Avocat'},
    society: {value: null, label: 'Societate'}
}
