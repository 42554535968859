import _ from 'lodash'

// @ts-ignore
import {ColorPreview} from '../../../../components/ColorPreview'
import React from 'react'
import {EntityProfileMinimal, Task} from '../../../../types/api'
import {renderInterval, isLate} from './partials/utils'
import {TableLink} from '../../../../components/TableLink'

type Row = {
    original: Task
}

export const columns =[
    {
        Header: 'Interval',
        accessor: '_interval',
        Cell: ({row: {original: task}} : {row: Row}) => (<div style={isLate(task) ? {color: 'red'} : undefined}>
            {renderInterval(task)}
        </div>),
        style: {whiteSpace: 'nowrap'}
    },
    {
        Header: 'Sarcină',
        accessor: 'title',
        Cell: ({row: {original: task}}: {row: Row}) => <TableLink to={`/tasks/${task.id}`}>{task.color ? (
            <div className="tasks-list-datatable-task-title-column">
                <ColorPreview color={task.color}/>
                {task.title}
            </div>
        ) : task.title
        }</TableLink>,
        style: {maxWidth: '15vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Responsabili',
        accessor: 'owners',
        Cell: ({value: owners}: {value: Array<EntityProfileMinimal>}) => {
            return _.join(owners.map((owner) => owner.full_name), ', ')
        }
    },
    {
        Header: 'Proiect',
        accessor: 'project.name',
        Cell: ({row: {original: task}}: {row: Row}) => <TableLink to={`/projects/${task?.project?.id}`}>
            {task?.project?.name}
        </TableLink>,
        style: {maxWidth: '15vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Clienți',
        accessor: 'project.clients',
        Cell: ({value: clients}: any) => <div style={{display: 'flex'}}>{clients?.map(
            (client: any, index: number) => <>
                <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                {index + 1 !== clients.length && ', '}
            </>
        )}</div>,
        style: {maxWidth: '15vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
    }
]

export const isCompletedOptions = {
    FALSE: {
        label: 'Active',
        value: false
    },
    TRUE: {
        label: 'Finalizate',
        value: true
    }
}
