import React from 'react'

import './ClientDetailsDisclaimer.scss'

export default function ClientDetailsDisclaimer({resource}) {
    return (
        <p className='client-details-disclaimer'>
            Aici se pot vizualiza <span>{resource}</span> din toate proiectele în care e implicat acest client. Orice
            adăugare/modificare trebuie realizată în proiectul specific.
        </p>
    )
}
