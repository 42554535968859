import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {debounce, isNil, orderBy} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {columns} from './constants'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'

import {ClientDetailsDisclaimer} from '../ClientDetailsDisclaimer'

import './Documents.scss'

export const Documents = ({
    documents,
    isLoading,
    previousPage,
    currentPage,
    nextPage,
    totalPages,
    match: {params: {clientID}},
    listDocuments
}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchDocuments = (query = searchFilter, page = 1) => listDocuments(clientID, query, page)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {handleFetchDocuments()}, [])

    const debounceSearchDocuments = debounce(handleFetchDocuments, debounceWait)

    const handleChangeSearchField = (value) => {
        setSearchFilter(value)
        debounceSearchDocuments(value)
    }

    const handleChangePage = (page) => !isLoading && handleFetchDocuments(searchFilter, page)

    return (
        <div className="client-documents-list">
            <ClientDetailsDisclaimer resource='documentele' />
            <Datatable
                title='Documente'
                data={documents}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: documentID, row: {original: document}}) => (
                            <div className='flex gap-2 items-center'>
                                <Link to={`/documents/${documentID}/preview`}>
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Dropdown
                                    title='Descarcă'
                                    disabled={isNil(document.last_version?.file) && isNil(document.signed_file)}
                                    items={[
                                        {
                                            customContent: () => document.last_version?.file ? (
                                                <a
                                                    href={document.last_version.file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă
                                                </a>
                                            ) : <React.Fragment />
                                        },
                                        {
                                            customContent: () => document.signed_file ? (
                                                <a
                                                    href={document.signed_file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă fișier semnat
                                                </a>
                                            ) : <React.Fragment />
                                        }
                                    ]}
                                    variant='text'
                                    color='secondary'
                                    size='small'
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isLoading}
                previousPage={previousPage}
                currentPage={currentPage}
                nextPage={nextPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută documente'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    documents: orderBy(state.documents.data, 'id', 'desc'),
    isLoading: state.documents.isLoading,
    totalPages: state.documents.totalPages,
    nextPage: state.documents.next,
    previousPage: state.documents.previous,
    currentPage: state.documents.current
})

const mapDispatchToProps = (dispatch) => ({
    listDocuments: (clientID, search, page) => dispatch(
        RESOURCES.documents.list(
            {
                client_id: clientID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents)