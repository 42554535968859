import React from 'react'

import {isEmpty} from 'lodash'
import {lightFormat} from 'date-fns/esm'

import {connect} from 'react-redux'
import {destroyClientNote} from 'avoapp-react-common/dist/redux/clientNotes'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

function DeleteClientNoteModal({open, clientNote, clientID, isLoading, onClose, closeModal, deleteClientNote}) {
    const handleCloseModal = () => {
        closeModal()
        onClose()
    }

    return (
        <Modal open={open && !isEmpty(clientNote)} title='Șterge observație' onClose={handleCloseModal}>
            {!isEmpty(clientNote) && (
                <div className='delete-modal'>
                    <p className='message'>
                        Ești sigur că vrei să ștergi observația creată de{' '}
                        <span>{`${clientNote.entity_profile.first_name} ${clientNote.entity_profile.last_name}`}</span>
                        {' '}din data de{' '}
                        <span>"{lightFormat(new Date(clientNote.created), 'dd/MM/yyyy')}"</span>?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            variant='outlined'
                            loading={isLoading}
                            color='gray'
                        />
                        <Button
                            title='Da, șterge'
                            onClick={() => deleteClientNote(clientNote, clientID)}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_CLIENT_NOTE,
    isLoading: state.clientNotes.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteClientNote: (clientNote, clientID) => dispatch(destroyClientNote(clientNote, clientID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientNoteModal)