import React, {Component, Fragment} from 'react'

import {values, isEmpty, head, isNull, includes} from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'
import {setCompanyDetails} from '../../redux/companies'

import {Modal} from '../Modal'
import {Loader} from '../Loader'
import {Button} from '../Button'

import './CompaniesModal.scss'

export class CompaniesModal extends Component {
    constructor() {
        super()

        this.state = {
            selectedCompany: null
        }
    }

    componentDidUpdate = (prevProps) => {
        const {selectedCompany} = this.state
        const {companies, openModal, open} = this.props

        if (
            (isNull(selectedCompany) ||
                (!isNull(selectedCompany) &&
                    !isEmpty(companies) &&
                    !includes(head(companies).vat_number, selectedCompany?.vat_number))) &&
          prevProps.companies !== companies &&
          !isEmpty(companies) &&
          !open
        ) {
            this.setState({selectedCompany: head(companies)})
            openModal(modalTypes.FOUND_COMPANIES)
        }
    }

    handleSelectCompany = (company) => this.setState({selectedCompany: company})

    handleUseSelectedCompany = () => {
        const {selectedCompany} = this.state
        const {setFieldValue, setCompanyDetails} = this.props

        setCompanyDetails(selectedCompany.vat_number, setFieldValue)
    }

    getInputValue = (o) => !isNull(o) ? o : ''

    render() {
        const {selectedCompany} = this.state
        const {open, closeModal, companies, isLoading} = this.props

        return (
            <Modal open={open} title=' Am găsit următoarele companii cu ajutorul numelui introdus'>
                <div className='similar-company-modal-container'>
                    {!isEmpty(companies) && !isLoading ? (
                        <>
                            <div className="similar-company-modal-content-container">
                                {companies.map((company) =>
                                    <CompanyCard
                                        company={company}
                                        selected={company === selectedCompany}
                                        onClick={(company) => this.handleSelectCompany(company)}
                                        key={company.vat_number}
                                    />
                                )}
                            </div>
                            <div className="similar-company-modal-buttons-container">
                                <Button
                                    title='Vreau să introduc datele manual'
                                    onClick={closeModal}
                                    variant='outlined'
                                    fullWidth
                                />
                                <Button
                                    title='Preia datele companiei selectate'
                                    disabled={isNull(selectedCompany)}
                                    onClick={this.handleUseSelectedCompany}
                                    fullWidth
                                />
                            </div>
                        </>
                    ) : isLoading ? (
                        <div className="loader-container">
                            <Loader size='large' />
                        </div>
                    ) : null}
                </div>
            </Modal>
        )
    }
}

function CompanyCard({company, onClick, selected}) {
    return (
        <div className={`similar-company-info-card ${selected ? 'selected' : ''}`} onClick={() => onClick(company)}>
            <div className="similar-company-info-card-header">
                <p className="company-name">
                    {company.name}
                </p>
            </div>
            <div className="similar-company-info-card-content">
                <div className="company-info-row">
                    <p className="company-info-key">CUI:</p>
                    <p className="company-info-value">{company.vat_number ? company.vat_number : '-'}</p>
                </div>
                <div className="company-info-row">
                    <p className="company-info-key">Județ:</p>
                    <p className="company-info-value">
                        {company.county ? company.county : '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.FOUND_COMPANIES,
    companies: values(state.companies.data),
    isLoading: state.companies.isLoading
})

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(closeModal()),
    openModal: (modalType) => dispatch(openModal(modalType)),
    setCompanyDetails: (vatNumber, setFieldValue) => dispatch(setCompanyDetails(vatNumber, setFieldValue))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesModal)