import React from 'react'
import {HandRaisedIcon} from '@heroicons/react/24/outline'

import './ManualOperation.scss'

export default function ManualOperation() {
    return (
        <HandRaisedIcon className='billable-icon yellow'/>
    )
}
