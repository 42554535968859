import React, {useCallback, useState} from 'react'

import {values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {addFilters, removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {modalTypes, openModal} from '../../../../redux/modals'

import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'

import {Datatable} from '../../../../components/Datatable'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'

import {DeleteProformaModal} from '../../../../components/DeleteProformaModal'

import {proformasColumns} from '../../constants'
import FilterProformasForm from '../forms/FilterProformasForm'

import './ProformasList.scss'

export const ProformasList = ({
    proformas,
    isLoading,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    filters,
    selectedEntityID,
    listProformas,
    navigate,
    openDeleteProformaModal,
    removeFilter
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedProforma, setSelectedProforma] = useState(null)

    const handleFetchProformas = useCallback(
        (query = searchFilter, page = 1) => {
            const appliedFilters = generateFiltersForAPI(filters)
            listProformas(selectedEntityID, query, appliedFilters, page)
        },
        [filters, listProformas, searchFilter, selectedEntityID]
    )

    useDebouncedEffect(handleFetchProformas, [searchFilter], debounceWait)

    const handleChangePage = (page) => !isLoading && handleFetchProformas(searchFilter, page)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    return (
        <div className="page-info">
            <Datatable
                data={proformas}
                columns={[
                    ...proformasColumns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: proformaID, row: {original: proforma}}) => (
                            <DatatableRowButtons
                                mainButtonTitle='Vezi'
                                onMainButtonClick={() => {
                                    navigate(`/proformas/${proformaID}`)
                                }}
                                onDeleteButtonClick={() => {
                                    setSelectedProforma(proforma)
                                    openDeleteProformaModal()
                                }}
                            />
                        )
                    }
                ]}
                nextPage={nextPage}
                previousPage={previousPage}
                currentPage={currentPage}
                totalPages={totalPages}
                onChangePage={(page) => handleChangePage(page)}
                filterable
                filters={filters}
                filtersForm={() => <FilterProformasForm filterProformas={handleFetchProformas} />}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchProformas()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută facturi'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <DeleteProformaModal selectedProforma={selectedProforma}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    proformas: values(state.proformas.data),
    isLoading: state.proformas.isLoading,
    totalPages: state.proformas.totalPages,
    nextPage: state.proformas.next,
    previousPage: state.proformas.previous,
    currentPage: state.proformas.current,
    filters: state.filters.proformas,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteProformaModal: () => dispatch(openModal(modalTypes.DELETE_PROFORMA)),
    navigate: (route) => dispatch(push(route)),
    addFilters: (filters) => dispatch(addFilters(RESOURCES.proformas.name, filters)),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.proformas.name, filter)),
    listProformas: (entityID, search, filters, page) => dispatch(
        RESOURCES.proformas.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ProformasList)
