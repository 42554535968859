import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../redux/modals'

import {Modal} from '../../../../../components/Modal'
import {Button} from '../../../../../components/Button'

import '../../../../../assets/scss/DeleteModals.scss'

function DeleteClientBankAccountModal({open, clientBankAccount, isLoading, closeModal, deleteClientBankAccount}) {
    return (
        <Modal open={open && !isEmpty(clientBankAccount)} title='Șterge cont bancar'>
            <div className='delete-modal'>
                <p className='message'>
                    Ești sigur că vrei să ștergi contul bancar{' '}
                    <span>"{clientBankAccount.bank_account_description}"</span>?
                </p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteClientBankAccount(clientBankAccount)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_CLIENT_BANK_ACCOUNT,
    clientBankAccount: state.clientBankAccounts.currentClientBankAccount,
    isLoading: state.clientBankAccounts.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteClientBankAccount: (clientBankAccount) => dispatch(
        RESOURCES.clientBankAccounts.destroy(clientBankAccount)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteClientBankAccountModal)