import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {useFormik} from 'formik'

import {lightFormat} from 'date-fns'
import _ from 'lodash'

import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes} from '../../redux/modals'

import {getFieldOptions, objectKeysToSnakeCase} from '../../utils'
import {debounceWait} from '../../utils/constants'
import {useDebouncedEffect} from '../../utils/hooks'
import {documentSeriesTypes, documentTemplateTypes} from '../../utils/types'

import {Button} from '../Button'
import {DatePicker} from '../DatePicker'
import {ErrorsList} from '../ErrorComponents'
import {Input} from '../Input'
import {Modal} from '../Modal'
import {Select} from '../Select'

import './AddInvoicePaymentModal.scss'

export const AddInvoicePaymentModal = ({
    open,
    createInvoicePayment,
    invoice,
    invoiceID,
    invoicePaymentsOptions,
    getInvoicePaymentsOptions,
    fieldErrors,
    nonFieldErrors,
    projects,
    isLoadingProjects,
    searchProjects,
    invoices,
    isLoadingInvoices,
    searchInvoices,
    entityBankAccounts,
    isLoadingEntityBankAccounts,
    listEntityBankAccounts,
    documentSeries,
    isLoadingDocumentSeries,
    selectedEntityID,
    listAllDocumentSeries,
    documentTemplates,
    isLoadingDocumentTemplates,
    listAllDocumentTemplates,
    invoiceReports
}) => {
    const [selectedProject, setSelectedProject] = useState(undefined)
    const [projectsQuery, setProjectsQuery] = useState('')
    const [invoicesQuery, setInvoicesQuery] = useState('')

    useEffect(() => { getInvoicePaymentsOptions() }, [getInvoicePaymentsOptions])

    const handleFetchProjects = useCallback((query = projectsQuery) => {
        searchProjects(query)
    }, [projectsQuery, searchProjects])

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const handleChangeProjectsSearchField = (value) => setProjectsQuery(value)

    const handleFetchInvoices = useCallback((query = invoicesQuery) => {
        const params = {
            project_id: selectedProject?.id || null
        }

        searchInvoices(query, params)
    }, [invoicesQuery, searchInvoices, selectedProject?.id])

    useDebouncedEffect(handleFetchInvoices, [invoicesQuery, selectedProject?.id], debounceWait)

    const handleChangeInvoicesSearchField = (value) => setInvoicesQuery(value)

    const getInvoiceOptionLabel = useCallback((option) => {
        let label = ''
        if(!_.isEmpty(invoices) && !_.isNil(option.series_name) && !_.isNil(option.series_number)) {
            label = `${option.series_name}${option.series_number}`
        }

        if(!_.isNil(option.issue_date)) {
            label = `${label} din  ${lightFormat(new Date(option.issue_date), 'dd/MM/yyyy')}`
        }

        return label
    }, [invoices])

    const invoicePaymentTypes = useMemo(() => {
        return getFieldOptions(invoicePaymentsOptions, 'type')
    }, [invoicePaymentsOptions])

    useEffect(() => { listEntityBankAccounts() }, [listEntityBankAccounts])

    useEffect(() => {
        listAllDocumentSeries({entity_id: selectedEntityID})
    }, [listAllDocumentSeries, selectedEntityID])

    useEffect(() => {
        listAllDocumentTemplates({entity_id: selectedEntityID})
    }, [listAllDocumentTemplates, selectedEntityID])

    const {values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit} = useFormik({
        initialValues: {
            projectId: null,
            invoiceId: null,
            date: new Date(),
            type: null,
            seriesId: null,
            documentTemplateId: null,
            amount: invoiceReports.amount_unpaid || ''
        },
        onSubmit: (values) => {
            createInvoicePayment({
                ...objectKeysToSnakeCase(values),
                entity_id: selectedEntityID,
                date: lightFormat(new Date(values.date), 'yyy-MM-dd'),
                project_id: values.projectId.id,
                invoice_id: values.invoiceId.id,
                type: values.type.value,
                bank_account_id: values.bankAccountId?.id || null,
                series_id: values.seriesId?.id || null,
                template_id: values.documentTemplateId?.id || null
            })
        }
    })

    // useEffect(() => {
    //     if(!open) handleReset()
    // }, [handleReset, open])

    useEffect(() => {
        setFieldValue('amount', invoiceReports.amount_unpaid)
    }, [invoiceReports.amount_unpaid, setFieldValue])

    useEffect(() => {
        if(!_.isNil(invoiceID) && !_.isNil(invoice.project) && _.isNil(values.projectId)) {
            setFieldValue('projectId', invoice.project)
            setSelectedProject(invoice.project)
        }
    }, [invoice.project, invoiceID, setFieldValue, values.projectId])

    useEffect(() => {
        if(!_.isNil(invoiceID) && !_.isEmpty(invoice) && _.isNil(values.invoiceId)) {
            setFieldValue('invoiceId', invoice)
        }
    }, [invoice, invoiceID, setFieldValue, values.invoiceId])

    const isInvoiceCanceled = useMemo(() => {
        return invoice.state === 'canceled'
    }, [invoice.state])

    return (
        <Modal open={open && !isInvoiceCanceled} title='Adăugare încasare'>
            <ErrorsList errors={nonFieldErrors} />
            <form className='add-invoice-payment-form-container'>
                <Select
                    label='Proiect*'
                    value={values.projectId}
                    options={projects}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={(option) => {
                        setFieldValue('projectId', option)
                        setFieldValue('invoiceId', null)
                        setSelectedProject(option)
                    }}
                    onInputChange={(value) => handleChangeProjectsSearchField(value)}
                    onBlur={handleBlur('projectId')}
                    disabled={!_.isNil(invoiceID)}
                    name='projectId'
                    errors={fieldErrors}
                    frontendErrors={errors}
                    touched={touched.projectId}
                    loading={isLoadingProjects}
                    isClearable
                    fullWidth
                />
                <Select
                    label='Factură de încasat*'
                    value={values.invoiceId}
                    options={invoices}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={getInvoiceOptionLabel}
                    onChange={(option) => setFieldValue('invoiceId', option)}
                    onInputChange={(value) => handleChangeInvoicesSearchField(value)}
                    onBlur={handleBlur('invoiceId')}
                    disabled={!_.isNil(invoiceID) || _.isNil(selectedProject)}
                    name='invoiceId'
                    errors={fieldErrors}
                    frontendErrors={errors}
                    touched={touched.invoiceId}
                    loading={isLoadingInvoices}
                    isClearable
                    fullWidth
                />
                <DatePicker
                    label='Data încasării*'
                    value={values.date}
                    onChange={(date) => _.isNull(date) ?
                        setFieldValue('date', date) :
                        setFieldValue('date', new Date(date))
                    }
                    onBlur={handleBlur('date')}
                    name='date'
                    errors={fieldErrors}
                    frontendErrors={errors}
                    touched={touched.date}
                    fullWidth
                />
                <Select
                    label='Metodă de plată*'
                    value={values.type}
                    options={invoicePaymentTypes}
                    onChange={(e) => setFieldValue('type', e)}
                    onBlur={handleBlur('type')}
                    name='type'
                    errors={fieldErrors}
                    frontendErrors={errors}
                    touched={touched.type}
                    fullWidth
                />
                {values.type?.value === 'bank_transfer' && (
                <Select
                    label='Cont bancar*'
                    value={values.bankAccountId}
                    options={entityBankAccounts}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.iban}
                    onChange={(option) => setFieldValue('bankAccountId', option)}
                    onBlur={handleBlur('bankAccountId')}
                    name='bankAccountId'
                    errors={fieldErrors}
                    frontendErrors={errors}
                    touched={touched.bankAccountId}
                    loading={isLoadingEntityBankAccounts}
                    isClearable
                    fullWidth
                />
                )}
                {values.type?.value === 'cash' && (
                    <>
                        <Select
                            label='Serie chitanță*'
                            value={values.seriesId}
                            options={documentSeries}
                            loading={isLoadingDocumentSeries}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => `${option.name} (${option.current_number})`}
                            onChange={(e) => setFieldValue('seriesId', e)}
                            onBlur={handleBlur('seriesId')}
                            name='seriesId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.seriesId}
                            fullWidth
                        />
                        <Select
                            label='Șablon chitanță*'
                            value={values.documentTemplateId}
                            options={documentTemplates}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => setFieldValue('documentTemplateId', e)}
                            onBlur={handleBlur('documentTemplateId')}
                            name='documentTemplateId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.documentTemplateId}
                            loading={isLoadingDocumentTemplates}
                            isClearable
                            fullWidth
                        />
                    </>
                )}
                <div className="invoice-remaining-sum-container">
                    <p className="invoice-remaining-sum">
                        Sumă rămasă de încasat:{' '}
                        <span>
                            {!_.isNil(invoiceReports.amount_unpaid) ?
                                        `${invoiceReports.amount_unpaid} ${invoice.currency}` :
                                '-'}
                        </span>
                    </p>
                    <div className="amount-input-container">
                        <Input
                            label='Suma încasată*'
                            value={values.amount}
                            onChange={handleChange('amount')}
                            onBlur={handleBlur('amount')}
                            name='amount'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.amount}
                            fullWidth
                        />
                        <p className="amount-currency">{invoice.currency}</p>
                    </div>
                </div>
                <Button
                    title='Salvează încasare'
                    onClick={handleSubmit}
                    color='secondary'
                />
            </form>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_INVOICE_PAYMENT,
    invoicePaymentsOptions: state.invoicePayments.options,
    nonFieldErrors: state.invoicePayments.nonFieldErrors,
    fieldErrors: state.invoicePayments.fieldErrors,
    invoice: state.invoices.currentInvoice,
    projects: _.values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    invoices: _.values(state.invoices.searchData),
    isLoadingInvoices: state.invoices.isLoading,
    entityBankAccounts: _.values(state.entityBankAccounts.data),
    isLoadingEntityBankAccounts: state.entityBankAccounts.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID,
    documentSeries: _.filter(state.documentSeries.data, ['type', documentSeriesTypes.RECEIPT.value]),
    isLoadingDocumentSeries: state.documentSeries.isLoading,
    documentTemplates: _.filter(state.documentTemplates.data, ['type', documentTemplateTypes.RECEIPT.value]),
    isLoadingDocumentTemplates: state.documentTemplates.isLoading,
    invoiceReports: state.invoiceReports.data
})

const mapDispatchToProps = (dispatch) => ({
    createInvoicePayment: (values) => dispatch(RESOURCES.invoicePayments.create(values)),
    getInvoicePaymentsOptions: () => dispatch(RESOURCES.invoicePayments.getOptions()),
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search, {active: 'true'})),
    searchInvoices: (search, params) => dispatch(RESOURCES.invoices.search(search, params)),
    listEntityBankAccounts: () => dispatch(RESOURCES.entityBankAccounts.list()),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    listAllDocumentTemplates: (params) => dispatch(RESOURCES.documentTemplates.listAll(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoicePaymentModal)