import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'

import {values as _values, debounce, isNull, capitalize} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {hasWhiteSpace, splitStringIntoNames} from '../../utils'
import {debounceWait} from '../../utils/constants'

import {Select} from '../Select'

import {AutoSelectRequester} from './partials'

export const RequesterCreatable = ({
    value,
    disabled,
    onChange,
    onBlur,
    name,
    errors,
    frontendErrors,
    touched,
    projectId,
    contactPersons,
    isLoadingContactPersons,
    searchContactPersons,
    selectedEntityID,
    createContactPerson
}) => {
    const [contactPersonsQuery, setContactPersonsQuery] = useState('')

    const handleFetchContactPersons = (query = contactPersonsQuery) => {
        searchContactPersons(query, {project_id: projectId})
    }

    useEffect(() => {
        if(!isNull(projectId)) {
            handleFetchContactPersons()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])

    const debounceSearchContactPersons = debounce(handleFetchContactPersons, debounceWait)

    const handleChangeContactPersonsSearchField = (value) => {
        setContactPersonsQuery(value)
        debounceSearchContactPersons(value)
    }

    const handleCreateContactPerson = () => {
        if(hasWhiteSpace(contactPersonsQuery)) {
            const contactPersonNames = splitStringIntoNames(contactPersonsQuery)

            const contactPersonData = {
                entity_id: selectedEntityID,
                first_name: capitalize(contactPersonNames.firstName),
                last_name: capitalize(contactPersonNames.lastName),
                project_id: projectId
            }

            createContactPerson(contactPersonData)
        } else {
            toast.error('Numele si prenumele solicitantului trebuie sa fie separate de un spatiu.')
        }
    }

    return (
        <>
            <Select
                label='Solicitant (persoana care a solicitat sarcina din partea clientului)'
                value={value}
                options={contactPersons}
                loading={isLoadingContactPersons}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => `${option.last_name} ${option.first_name}`}
                onInputChange={(value) => handleChangeContactPersonsSearchField(value)}
                noOptionsMessage={() => (
                    <div
                        className='avoapp-select-no-options-custom-message'
                        onClick={handleCreateContactPerson}
                    >
                        Crează persoana de contact <span>"{contactPersonsQuery}"</span>
                    </div>
                )}
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
                name={name}
                errors={errors}
                frontendErrors={frontendErrors}
                touched={touched}
                isClearable
                fullWidth
            />
            <AutoSelectRequester
                handleResetContactPersonsQuery={() => setContactPersonsQuery('')}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    contactPersons: _values(state.contactPersons.searchData),
    isLoadingContactPersons: state.contactPersons.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchContactPersons: (search, params) => dispatch(RESOURCES.contactPersons.search(search, params)),
    createContactPerson: (contactPersonData) => dispatch(RESOURCES.contactPersons.create(contactPersonData))
})

export default connect(mapStateToProps, mapDispatchToProps)(RequesterCreatable)