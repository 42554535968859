import {Filter} from '.'

const clientsFilters = {
    name: new Filter('name', 'Nume'),
    vatCode: new Filter('vat_code', 'CUI/CNP'),
    county: new Filter('county', 'Județ'),
    country: new Filter('country', 'Țară'),
    locality: new Filter('locality', 'Loc./Oraș'),
    role: new Filter('role', 'Rolul Meu')
}

const projectsFilters = {
    name: new Filter('name', 'Nume'),
    client: new Filter('client_id', 'Client'),
    type: new Filter('type', 'Tip'),
    role: new Filter('role', 'Rolul Meu')
}

const tasksFilters = {
    project: new Filter('project_id', 'Proiect'),
    isCompleted: new Filter('is_completed', 'Status'),
    startGTE: new Filter('start__gte', 'Start din'),
    stopLTE: new Filter('stop__lte', 'Stop pana la')
}

const expensesFilters = {
    project: new Filter('project_id', 'Proiect'),
    client: new Filter('client_id', 'Client'),
    status: new Filter('status', 'Status'),
    dateGTE: new Filter('date__gte', 'Start din'),
    dateLTE: new Filter('date__lte', 'Stop pana la')
}

const documentsFilters = {
    project: new Filter('project_id', 'Proiect')
}

const contractsFilters = {
    project: new Filter('project_id', 'Proiect'),
    client: new Filter('client_id', 'Client')
}

const documentTemplatesFilters = {
    type: new Filter('type', 'Tip')
}

const invoicesFilters = {
    dateLTE: new Filter('date__lte', 'Dată stop'),
    dateGTE: new Filter('date__gte', 'Dată start'),
    series: new Filter('series_id', 'Seria facturii'),
    seriesNumber: new Filter('series_number', 'Numărul facturii'),
    state: new Filter('state', 'Status'),
    client: new Filter('client_id', 'Client'),
    contract: new Filter('contract_id', 'Contract')
}

const invoicePaymentsFilters = {
    dateLTE: new Filter('date__lte', 'Dată stop'),
    dateGTE: new Filter('date__gte', 'Dată start'),
    type: new Filter('type', 'Tipul'),
    client: new Filter('client_id', 'Client'),
    project: new Filter('project_id', 'Proiect'),
    series: new Filter('series_id', 'Serie chitanță'),
    invoice_series: new Filter('invoice_series_id', 'Serie factură')
}

const proformasFilters = {
}

export const FILTERS = {
    clients: clientsFilters,
    projects: projectsFilters,
    tasks: tasksFilters,
    expenses: expensesFilters,
    contracts: contractsFilters,
    documents: documentsFilters,
    documentTemplates: documentTemplatesFilters,
    invoices: invoicesFilters,
    invoicePayments: invoicePaymentsFilters,
    proformas: proformasFilters
}