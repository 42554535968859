import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    type: Yup.object()
        .nullable()
        .required('Tipul este obligatoriu!'),
    name: Yup.string()
        .required('Prefixul este obligatoriu!'),
    label: Yup.string()
        .required('Eticheta este obligatorie!')
})
