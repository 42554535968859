import React from 'react'
import {go} from 'connected-react-router'

import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function EditContractSettingsDisclaimerModal({open, refreshPage, closeModal}) {
    return (
        <Modal open={open} title='Atenție!'>
            <div className='delete-modal'>
                <p className='message'>
                    Datele modificate aici nu produc efecte în conținutul efectiv al documentului. Dacă doriți să
                    modificați și documentul contractului, trebuie să editați separat conținutul.
                </p>
                <div className="buttons-container">
                    <Button
                        title='Ok'
                        onClick={() => {
                            closeModal()
                            refreshPage()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open : state.modals.type === modalTypes.EDIT_CONTRACT_SETTINGS_DISCLAIMER
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    refreshPage: () => dispatch(go(0))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditContractSettingsDisclaimerModal)