import {takeEvery} from 'redux-saga/effects'
import {toast} from 'react-toastify'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

const {users: resource} = RESOURCES

function handleUserUpdateSuccess() {
    toast.success('Modificările au fost salvate')
}

function handleUserUpdateFail(response) {
    const phoneErrors = response.payload['phone']

    if(phoneErrors) {
        phoneErrors.map((err) => err.code === 'unique' ?
            toast.error('Acest număr de telefon este utilizat deja') :
            toast.error('A apărut o eroare!')
        )
    } else {
        toast.error('A apărut o eroare!')
    }
}

export function* saga() {
    yield takeEvery(resource.actions.update.success, handleUserUpdateSuccess)
    yield takeEvery(resource.actions.update.fail, handleUserUpdateFail)
}
