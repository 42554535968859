import React, {useEffect, useState} from 'react'
import {ArrowLeftIcon, PencilSquareIcon} from '@heroicons/react/24/outline'
import {Redirect} from 'react-router-dom'

import {findLast, startsWith, endsWith, isUndefined} from 'lodash'

import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {actionIds, actions} from './constants'

import {Button} from '../../components/Button'
import {Loader} from '../../components/Loader'
import {SecondaryNavbar} from '../../components/SecondaryNavbar'

import {EditDocumentNameForm, DocumentMetaInfo, DocumentPreview, SignedDocumentPreview} from './partials'

import './DocumentDetails.scss'

export const DocumentDetails = ({
    document,
    isLoading,
    match,
    retrieveDocument,
    sourceLocation,
    navigate,
    location,
    history
}) => {
    const {documentID, primaryAction} = match.params

    useEffect(() => {
        retrieveDocument(documentID)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const urlData = {prefix: `/documents/${documentID}`, params: match.params, location, history}

    switch(primaryAction) {
        case actionIds.ARCHIVE:
            return (
                <DocumentDetailsLayout
                    urlData={urlData}
                    document={document}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<DocumentMetaInfo match={match} />}
                />
            )

        case actionIds.PREVIEW:
            return (
                <DocumentDetailsLayout
                    urlData={urlData}
                    document={document}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<DocumentPreview match={match} urlData={urlData} />}
                />
            )
        case actionIds.SIGNED:
            return (
                <DocumentDetailsLayout
                    urlData={urlData}
                    document={document}
                    loading={isLoading}
                    goBack={() => navigate(sourceLocation)}
                    component={<SignedDocumentPreview match={match} documentID={documentID} />}
                />
            )

        default:
            return <Redirect to={`${urlData.prefix}/${actionIds.ARCHIVE}`} />
    }
}

export function DocumentDetailsLayout({document, loading, component, urlData, goBack}) {
    const [editMode, setEditMode] = useState(false)

    return (
        <div className='resource-details-content'>
            <div className='back-button-container'>
                <Button
                    title='Mergi înapoi'
                    size='small'
                    color='gray'
                    variant='outlined'
                    onClick={goBack}
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            <div className="header-resource-title-container">
                {!loading && !editMode ? (
                    <div className="document-name-container">
                        <p className="header-resource-title">
                            {document.name}
                        </p>
                        <Button
                            icon={() => <PencilSquareIcon />}
                            onClick={() => setEditMode(true)}
                            size='small'
                        />
                    </div>
                ) : !loading && editMode ? (
                    <EditDocumentNameForm handleCancel={() => setEditMode(false)} />
                ) : loading ? (
                    <div className="loader-container">
                        <Loader />
                    </div>
                ) : null}
            </div>
            <SecondaryNavbar actions={actions} urlData={urlData}/>
            <div>{component}</div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const sourceLocation = findLast(
        state.routerLocations,
        ({location: {pathname}}) =>
            pathname === '/documents' ||
            (startsWith(pathname, '/projects') && endsWith(pathname, '/documents'))
    )

    return {
        document: state.documents.currentDocument,
        isLoading: state.documents.isLoading,
        sourceLocation: !isUndefined(sourceLocation) ? sourceLocation.location.pathname : '/documents'
    }
}

const mapDispatchToProps = (dispatch) => ({
    navigate: (route) => dispatch(push(route)),
    retrieveDocument: (documentID) => dispatch(RESOURCES.documents.retrieve(documentID))
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails)