import React, {useEffect} from 'react'

import {isEmpty, omitBy, values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../../../../../redux/modals'

import {Modal} from '../../../../../../components/Modal'
import {Button} from '../../../../../../components/Button'

import './RemoveOrDeleteDocumentModal.scss'

export const RemoveOrDeleteDocumentModal = ({
    open,
    documentID,
    project,
    currentDocument,
    isLoading,
    retrieveDocument,
    updateDocument,
    deleteDocument,
    closeModal
}) => {
    useEffect(() => {
        if(documentID) retrieveDocument(documentID)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentID])

    const handleRemoveFromProject = () =>
        updateDocument(
            {
                projects_ids: values(omitBy(currentDocument.projects_ids, (id) => id === project.id))
            },
            documentID
        )

    return (
        <Modal
            open={open && !isEmpty(currentDocument) && currentDocument.id === documentID}
            title={currentDocument?.projects?.length > 1 ? 'Elimină sau șterge document' : 'Șterge document'}
        >
            {currentDocument?.projects?.length > 1 ? (
                <div className='delete-modal'>
                    <p className='message'>
                        Vrei să elimini documentul <span>{currentDocument.name}</span> din
                        proiectul <span>{project.name}</span> sau vrei să ștergi{' '}
                        <span>definitiv</span> documentul?
                    </p>
                    <div className="remove-or-delete-document-modal-buttons-container">
                        <div className="top-buttons">
                            <Button
                                title='Șterge DEFINITIV'
                                onClick={() => deleteDocument(currentDocument)}
                                loading={isLoading}
                                variant='text'
                                color='red'
                                fullWidth
                            />
                            <Button
                                title='Elimină din proiect'
                                onClick={handleRemoveFromProject}
                                loading={isLoading}
                                color='red'
                                fullWidth
                            />
                        </div>
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            loading={isLoading}
                            variant='outlined'
                            color='gray'
                            fullWidth
                        />
                    </div>
                </div>
            ) : (
                <div className='delete-modal'>
                    <p className='message'>
                        Ești sigur că vrei să ștergi documentul "<span>{currentDocument.name}</span>"
                        și toate versiunile asociate??
                    </p>
                    <div className="buttons-container">
                        <Button
                            title='Nu, renunță'
                            onClick={closeModal}
                            variant='outlined'
                            loading={isLoading}
                            color='gray'
                        />
                        <Button
                            title='Da, șterge'
                            onClick={() => deleteDocument(currentDocument)}
                            loading={isLoading}
                            color='red'
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.REMOVE_OR_DELETE_DOCUMENT,
    project: state.projects.currentProject,
    currentDocument: state.documents.currentDocument,
    isLoading: state.documents.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    retrieveDocument: (documentID) => dispatch(RESOURCES.documents.retrieve(documentID)),
    updateDocument: (documentData, documentID) => dispatch(RESOURCES.documents.update(documentData, documentID)),
    deleteDocument: (document) => dispatch(RESOURCES.documents.destroy(document))
})

export default connect(mapStateToProps, mapDispatchToProps)(RemoveOrDeleteDocumentModal)
