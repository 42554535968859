import React, {useEffect, useMemo, useState} from 'react'

import {isEmpty, values, toUpper} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {setCurrent as setCurrentClientBankAccount} from 'avoapp-react-common/dist/redux/clientBankAccounts'
import {setCurrent as setCurrentClientAddress} from 'avoapp-react-common/dist/redux/clientAddresses'
import {openSlideover, slideoverTypes} from '../../../../redux/slideovers'
import {modalTypes, openModal} from '../../../../redux/modals'

import {getEntityProfilesWithClientPermissions} from '../../../../utils'
import {personTypes, roleTypes} from '../../../../utils/types'
import {datatablePageSize} from '../../../../utils/datatables'
import {addressesColumns, bankAccountsColumns} from './constants'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {PageLoader} from '../../../../components/PageLoader'
import {DetailsCard} from '../../../../components/DetailsCard'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'

import {
    DeleteClientModal,
    DeleteClientBankAccountModal,
    DeleteClientAddressModal,
    MarkClientInactiveModal
} from './modals'

import {
    AddClientAddressSlideover,
    AddClientBankAccountSlideover,
    EditClientAddressSlideover,
    EditClientBankAccountSlideover,
    EditClientInfoSlideover,
    EditClientUsersAccessSlideover
} from './slideovers'

export const InfoGeneral = ({
    match : {params : {clientID}},
    selectedEntityID,
    client,
    isLoadingClients,
    isLoadingEntityProfiles,
    updateClientActiveStatus,
    clientAddresses,
    isLoadingClientAddresses,
    totalPagesClientAddresses,
    nextPagClientAddresses,
    previousPageClientAddresses,
    currentPageClientAddresses,
    setCurrentClientAddress,
    retrieveClientAddress,
    listClientAddresses,
    clientBankAccounts,
    isLoadingClientBankAccounts,
    totalPagesClientBankAccounts,
    nextPagClientBankAccounts,
    previousPageClientBankAccounts,
    currentPageClientBankAccounts,
    setCurrentClientBankAccount,
    retrieveClientBankAccount,
    listClientBankAccounts,
    openModal,
    openSlideover
}) => {
    const [selectedAddressId, setSelectedAddressId] = useState(null)
    const [selectedBankAccountId, setSelectedBankAccountId] = useState(null)

    useEffect(() => {
        listClientAddresses(selectedEntityID, clientID)
        listClientBankAccounts(selectedEntityID, clientID)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntityID, clientID])

    const handleChangeClientAddressesPage = (page) => {
        if(!isLoadingClientAddresses) {
            listClientAddresses(selectedEntityID, client.id, page)
        }
    }

    const handleChangeClientBankAccountsPage = (page) => {
        if(!isLoadingClientBankAccounts) {
            listClientBankAccounts(selectedEntityID, client.id, page)
        }
    }

    const getUserAttainer = (client) => {
        return `${client.attainer.full_name} (Avocat)`
    }

    const getClientAttainer = (client) => {
        return `${client.attainer.name} (Client)`
    }

    let attainer = 'Societate'

    if(client.attainer_category === 'user') {
        attainer = getUserAttainer(client)
    }
    else if (client.attainer_category === 'client') {
        attainer = getClientAttainer(client)
    }

    const clientInfoRows = useMemo(() => {
        if(!isEmpty(client)) {
            const defaultRows = [
                {label: 'Nume/Denumire', value: client.name},
                {label: 'Tip client', value: client.type ? personTypes[toUpper(client.type)].label : null},
                {label: 'CUI/CNP', value: client.vat_code},

                {label: 'Email', value: client.email},
                {label:'Telefon', value: client.phone},
                {label:'Adus de', value: attainer}
            ]

            if (client.type === personTypes.PERSON.value) {
                return [
                    ...defaultRows,
                    {label: 'Serie CI', value: client.id_series},
                    {label: 'Număr CI', value: client.id_number}
                ]
            }

            if (client.type === personTypes.COMPANY.value) {
                return [
                    ...defaultRows,
                    {label: 'Reg. com.', value: client.business_registration_number}
                ]
            }

            return defaultRows
        }

        return []
    }, [client, attainer])

    const accessRows = useMemo(() => {
        return [{
            label: 'Permisiunile pe client au legătură cu accesul tău în proiectele aferente clientului.',
            value: ''
        }]

        // eslint-disable-next-line no-unreachable
        if(!isEmpty(client)) {
            return [
                {
                    label: 'Manageri',
                    value: getEntityProfilesWithClientPermissions(client, roleTypes.MANAGER.value)
                },
                {
                    label: 'Colaboratori Interni',
                    value: getEntityProfilesWithClientPermissions(client, roleTypes.COLLABORATOR.value)
                }
            ]
        }

        return []
    }, [client])

    return (
        <>
            {!isEmpty(client) && !isLoadingClients ? (
                <div className="page-client-show">
                    <div className="row">
                        <div className="left">
                            <DetailsCard
                                title='Informații client'
                                rows={clientInfoRows}
                                loading={isLoadingClients}
                                headerButton={() =>
                                    <Button
                                        title='Editează'
                                        onClick={() => openSlideover(slideoverTypes.EDIT_CLIENT_INFO)}
                                        size='small'
                                    />
                                }
                            />
                        </div>
                        <div className="right">
                            <DetailsCard
                                title='Acces utilizatori'
                                rows={accessRows}
                                loading={isLoadingEntityProfiles}
                                // headerButton={() =>
                                //     <Button
                                //         title='Editează'
                                //         onClick={() => openSlideover(slideoverTypes.EDIT_CLIENT_USERS_ACCESS)}
                                //         size='small'
                                //     />
                                // }
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <Datatable
                            title='Adrese client'
                            data={clientAddresses}
                            columns={[
                                ...addressesColumns,
                                {
                                    Header: 'Acțiuni',
                                    accessor: 'id',
                                    Cell: ({value: addressId, row: {original: address}}) => (
                                        <DatatableRowButtons
                                            mainButtonTitle='Editează'
                                            onMainButtonClick={() => {
                                                retrieveClientAddress(addressId)
                                                setSelectedAddressId(addressId)
                                                openSlideover(slideoverTypes.EDIT_CLIENT_ADDRESS)
                                            }}
                                            onDeleteButtonClick={() => {
                                                setCurrentClientAddress(address)
                                                openModal(modalTypes.DELETE_CLIENT_ADDRESS)
                                            }}
                                        />
                                    )
                                }
                            ]}
                            headerButton={() =>
                                <Button
                                    title='Adaugă adresă'
                                    onClick={() => openSlideover(slideoverTypes.ADD_CLIENT_ADDRESS)}
                                    color='secondary'
                                />
                            }
                            loading={isLoadingClientAddresses}
                            totalPages={totalPagesClientAddresses}
                            nextPage={nextPagClientAddresses}
                            previousPage={previousPageClientAddresses}
                            currentPage={currentPageClientAddresses}
                            onChangePage={(page) => handleChangeClientAddressesPage(page)}
                            emptyText='Nu există nicio adresă pentru acest client'
                        />
                    </div>
                    <div className="mt-4">
                        <Datatable
                            title='Conturi bancare client'
                            data={clientBankAccounts}
                            columns={[
                                ...bankAccountsColumns,
                                {
                                    Header: 'Acțiuni',
                                    accessor: 'id',
                                    Cell: ({value: bankAccountId, row: {original: bankAccount}}) => (
                                        <DatatableRowButtons
                                            mainButtonTitle='Editează'
                                            onMainButtonClick={() => {
                                                retrieveClientBankAccount(bankAccountId)
                                                setSelectedBankAccountId(bankAccountId)
                                                openSlideover(slideoverTypes.EDIT_CLIENT_BANK_ACCOUNT)
                                            }}
                                            onDeleteButtonClick={() => {
                                                setCurrentClientBankAccount(bankAccount)
                                                openModal(modalTypes.DELETE_CLIENT_BANK_ACCOUNT)
                                            }}
                                        />
                                    )
                                }
                            ]}
                            headerButton={() =>
                                <Button
                                    title='Adaugă cont bancar'
                                    color='secondary'
                                    onClick={() =>
                                        openSlideover(slideoverTypes.ADD_CLIENT_BANK_ACCOUNT)
                                    }
                                />
                            }
                            loading={isLoadingClientBankAccounts}
                            totalPages={totalPagesClientBankAccounts}
                            nextPage={nextPagClientBankAccounts}
                            previousPage={previousPageClientBankAccounts}
                            currentPage={currentPageClientBankAccounts}
                            onChangePage={(page) => handleChangeClientBankAccountsPage(page)}
                            emptyText='Nu există niciun cont bancar pentru acest client'
                        />
                    </div>
                    <div className='mt-10 flex gap-5 justify-end'>
                        <Button
                            title={`Marchează clientul ca  ${client.active ? 'inactiv' : 'activ'}`}
                            color={`${client.active ? 'red' : 'secondary'}`}
                            size='large'
                            variant='outlined'
                            onClick={() => client.active ?
                                openModal(modalTypes.MARK_CLIENT_INACTIVE) :
                                updateClientActiveStatus(client)
                            }
                        />
                        <Button
                            title='Șterge client'
                            color='red'
                            size='large'
                            onClick={() => openModal(modalTypes.DELETE_CLIENT)}
                        />
                    </div>
                </div>
            ) :
                <PageLoader />
            }
            <DeleteClientModal />
            <DeleteClientAddressModal />
            <DeleteClientBankAccountModal />
            <MarkClientInactiveModal />
            <EditClientInfoSlideover />
            <EditClientUsersAccessSlideover />
            <AddClientAddressSlideover />
            <AddClientBankAccountSlideover />
            <EditClientAddressSlideover
                selectedAddressId={selectedAddressId}
                setSelectedAddressId={setSelectedAddressId}
            />
            <EditClientBankAccountSlideover
                selectedBankAccountId={selectedBankAccountId}
                setSelectedBankAccountId={setSelectedBankAccountId}
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    client: state.clients.currentClient,
    isLoadingClients: state.clients.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID,
    clientAddresses: values(state.clientAddresses.data),
    isLoadingClientAddresses: state.clientAddresses.isLoading,
    totalPagesClientAddresses: state.clientAddresses.totalPages,
    nextPagClientAddresses: state.clientAddresses.next,
    previousPageClientAddresses: state.clientAddresses.previous,
    currentPageClientAddresses: state.clientAddresses.current,
    clientBankAccounts: values(state.clientBankAccounts.data),
    isLoadingClientBankAccounts: state.clientBankAccounts.isLoading,
    totalPagesClientBankAccounts: state.clientBankAccounts.totalPages,
    nextPagClientBankAccounts: state.clientBankAccounts.next,
    previousPageClientBankAccounts: state.clientBankAccounts.previous,
    currentPageClientBankAccounts: state.clientBankAccounts.current
})

const mapDispatchToProps = (dispatch) => ({
    openSlideover: (slideoverType) => dispatch(openSlideover(slideoverType)),
    openModal: (modalType) => dispatch(openModal(modalType)),
    updateClientActiveStatus: (client) => dispatch(RESOURCES.clients.update({active: true}, client.id)),
    listClientAddresses: (entityID, clientID, page = 1) => dispatch(
        RESOURCES.clientAddresses.list(
            {
                entity_id: entityID,
                client_id: clientID,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    retrieveClientAddress: (addressID) => dispatch(RESOURCES.clientAddresses.retrieve(addressID)),
    setCurrentClientAddress: (clientAddress) => dispatch(setCurrentClientAddress(clientAddress)),
    listClientBankAccounts: (entityID, clientID, page = 1) => dispatch(
        RESOURCES.clientBankAccounts.list(
            {
                entity_id: entityID,
                client_id: clientID,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    retrieveClientBankAccount: (bankAccountID) => dispatch(RESOURCES.clientBankAccounts.retrieve(bankAccountID)),
    setCurrentClientBankAccount: (clientBankAccount) => dispatch(setCurrentClientBankAccount(clientBankAccount))
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoGeneral)