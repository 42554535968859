import {find, toUpper} from 'lodash'
import {lightFormat} from 'date-fns'

import {roleTypes} from '../../utils/types'
import {getEntityProfilesWithProjectPermissions} from '../../utils'

import {ViewDetailsLink} from '../../components/ViewDetailsLink'
import {TableLink} from '../../components/TableLink'

export const prefix = '/projects'

export const getProjectsColumns = (entityProfileID) => [
    {
        Header: 'Nume',
        accessor: 'name',
        Cell: ({row: {values}}) => <TableLink to={`/projects/${values.id}`}>{values.name}</TableLink>
    },
    {
        Header: 'Clienți',
        accessor: 'clients',
        Cell: ({value}) => <div style={{display: 'flex', flexWrap: 'wrap'}}>{value.map(
            (client, index) => <>
                <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                {index + 1 !== value.length && ', '}
            </>
        )}</div>
    },
    {
        Header: 'Tip',
        accessor: 'type'
    },
    {
        Header: 'Manager',
        accessor: 'manager',
        Cell: ({row: {original: project}}) => getEntityProfilesWithProjectPermissions(project, roleTypes.MANAGER.value)
    },
    {
        Header: 'Rolul meu',
        accessor: 'role',
        Cell: ({row}) => {
            const myPermission = find(row.original.project_permissions, ['entity_profile_id', entityProfileID])

            return myPermission ? roleTypes[toUpper(myPermission.role)]?.label : ''

        }
    },
    {
        Header: 'Ultima activitate',
        accessor: 'updated',
        Cell: ({row: {original: project}}) => {
            const lastUpdate = project.update_moment_crm || project.updated
            return lastUpdate ? lightFormat(new Date(lastUpdate), 'dd/MM/yyyy HH:mm') : '-'
        }
    },
    {
        Header: '',
        accessor: 'id',
        Cell: ({value}) => <ViewDetailsLink to={`/projects/${value}`} />,
        disableSortBy: true
    }
]

export const actionIds = {
    ACTIVE_PROJECTS: 'active',
    INACTIVE_PROJECTS: 'inactive'
}

export const actions = {
    [actionIds.ACTIVE_PROJECTS]: {
        id: actionIds.ACTIVE_PROJECTS,
        label: 'Proiecte active'
    },
    [actionIds.INACTIVE_PROJECTS]: {
        id: actionIds.INACTIVE_PROJECTS,
        label: 'Proiecte inactive'
    }
}
