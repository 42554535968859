import React from 'react'

import {Loader} from '../Loader'

import './Button.scss'

export default function Button({
    title,
    type,
    icon,
    iconLeft,
    disabled,
    loading,
    onClick,
    size,
    variant,
    color,
    fullWidth,
    disableTabSelect,
    className,
    dataTest
}) {
    return (
        <button
            onClick={onClick}
            className={
                'avo-button ' +
                `${variant || 'contained'} ` +
                `${size || 'medium'} ` +
                `${color || 'primary'} ` +
                `${fullWidth ? 'full-width' : ''} ` +
                `${iconLeft ? 'icon-left' : ''} ` +
                `${className || ''}`
            }
            disabled={disabled || loading}
            tabIndex={disableTabSelect ? '-1' : null}
            type={type || 'button'}
            data-test={dataTest}
        >
            {title ? title : null}
            {loading || icon ? (
                <div className={`avo-button-icon-container ${title ? 'has-title' : 'icon-only'}`}>
                    {loading ? <Loader size='small' /> : icon()}
                </div>
            ) : null}
        </button>
    )
}
