export const actionIds = {
    INFO: 'info',
    PREVIEW: 'preview',
    REPORTS: 'reports',
    PAYMENTS: 'payments'
}

export const actions = {
    [actionIds.INFO]: {
        id: actionIds.INFO,
        label: 'Informații'
    },
    [actionIds.PREVIEW]: {
        id: actionIds.PREVIEW,
        label: 'Previzualizare'
    },
    [actionIds.REPORTS]: {
        id: actionIds.REPORTS,
        label: 'Raport activitate'
    },
    [actionIds.PAYMENTS]: {
        id: actionIds.PAYMENTS,
        label: 'Încasări'
    }
}

export const columns = [
    {
        Header: 'Nr. crt.',
        accessor: '_idx',
        Cell: ({row: {index}}) => index + 1
    },
    {
        Header: 'Denumire produs / serviciu',
        accessor: 'name'
    },
    {
        Header: 'U.M.',
        accessor: 'unit'
    },
    {
        Header: 'Cant.',
        accessor: 'quantity'
    },
    {
        Header: 'Monedă',
        accessor: 'currency'
    },
    {
        Header: 'Preț',
        accessor: 'price'
    },
    {
        Header: 'Total',
        accessor: 'total'
    }
]