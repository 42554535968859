import {lightFormat} from 'date-fns'
import {isNull} from 'lodash'
import {TableLink} from '../../../../components/TableLink'

export const columns = [
    {
        Header: 'Număr contract',
        accessor: 'internal_series',
        Cell: ({row: {original: contract}}) => `${contract.internal_series_name} (${contract.internal_series_number})`
    },
    {
        Header: 'Dată generare',
        accessor: 'created',
        Cell: ({value: created}) => created ? lightFormat(new Date(created), 'dd/MM/yyyy') : '-'
    },
    {
        Header: 'Nume',
        accessor: 'name',
        style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'},
        Cell: ({row: {values}}) => <TableLink to={`/contracts/${values.id}`}>{values.name}</TableLink>
    },
    {
        Header: 'Clienți',
        accessor: 'clients',
        Cell: ({value: clients}) => <div style={{display: 'flex', flexWrap: 'wrap'}}>{clients.map(
            (client, index) => <>
                <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                {index + 1 !== clients.length && ', '}
            </>
        )}</div>,
        style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Proiecte',
        accessor: 'projects',
        Cell: ({value: projects}) => <div style={{display: 'flex', flexWrap: 'wrap'}}>{projects.map(
            (project, index) => <>
                <TableLink to={`/projects/${project.id}`}>{project.name}</TableLink>
                {index + 1 !== projects.length && ', '}
            </>
        )}</div>,
        style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
    },
    {
        Header: 'Serie barou',
        accessor: 'bar_series',
        Cell: ({row: {original: contract}}) => `${contract.bar_series_name} (${contract.bar_series_number})`
    },
    {
        Header: 'Document semnat',
        accessor: 'signed_file',
        Cell: ({value: signed_file}) => !isNull(signed_file) ? 'Da' : 'Nu',
        style: {maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}
    }
]
