import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

import {lightFormat} from 'date-fns'
import {filter, isEmpty, isNull, orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'
import {openSlideover} from '../../../../redux/slideovers'

import {logsOrderingOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DeleteEmpowermentModal} from '../../../../components/DeleteEmpowermentModal'
import {DetailsCard} from '../../../../components/DetailsCard'
import {Loader} from '../../../../components/Loader'
import {ResourceLogsList} from '../../../../components/ResourceLogsList'

import {EditEmpowermentLocationsSlideover} from '../slideovers'

import './EmpowermentMetaInfo.scss'

export const EmpowermentMetaInfo = ({
    empowerment,
    isLoading,
    logs,
    nextPage,
    listLogs,
    openDeleteEmpowermentModal,
    openSlideover,
    match: {params: {empowermentID}}
}) => {
    useEffect(() => {listLogs(empowermentID)}, [empowermentID, listLogs])

    const empowermentInfoRows = (empowerment) => [
        {label: 'Nume', value: empowerment.name},
        {label: 'Mărime', value: empowerment.size ? `${empowerment.size} Mb` : '-'},
        {label: 'Dată', value: empowerment.created ? lightFormat(new Date(empowerment.created), 'dd/MM/yyyy') : ''},
        {label: 'În fața', value: empowerment.in_front_of}
    ]

    return (
        <div className="empowerment-details-page-container">
            <div className="empowerment-info-cards-container">
                <div className="row">
                    <div className="left">
                        <DetailsCard
                            title='Informații împuternicire'
                            rows={empowermentInfoRows(empowerment)}
                            loading={isLoading}
                        />
                    </div>
                    <div className="right">
                        <div className="empowerment-info-card empowerment-locations-card">
                            <div className="header">
                                <p className="header-title">Locații</p>
                                {/*<Button*/}
                                {/*    title='Editează'*/}
                                {/*    onClick={() => openSlideover(slideoverTypes.EDIT_CONTRACT_LOCATIONS)}*/}
                                {/*    size='small'*/}
                                {/*/>*/}
                            </div>
                            {!isLoading ? (
                                <div className="content">
                                    <div className="section">
                                        <p className="section-title">
                                            Proiecte
                                        </p>
                                        {!isEmpty(empowerment.projects) ? (
                                            <div className="location-rows-container">
                                                {empowerment.projects.map((project) => (
                                                    <LocationContainer project={project} key={project.id} />
                                                ))}
                                            </div>
                                        ) : (
                                            <p className="no-locations">
                                                Acest empowerment nu este folosit pentru niciun proiect
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="center-loader">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <ResourceLogsList resourceID={empowermentID} resourceName={RESOURCES.empowerments.name} />
                </div>
                <div className='mt-10 flex gap-5 justify-end'>
                    <Button
                        title='Șterge împuternicire'
                        onClick={openDeleteEmpowermentModal}
                        size='large'
                        color='red'
                    />
                </div>
            </div>
            <DeleteEmpowermentModal empowerment={empowerment} />
            <EditEmpowermentLocationsSlideover empowermentID={empowermentID}/>
        </div>
    )
}

const LocationContainer = ({project}) => {
    return (
        <Link to={`/projects/${project.id}`} className="location-container">
            <p className="location-name">
                {project.name}
            </p>
        </Link>
    )
}

const mapStateToProps = (state) => {
    const logs = orderBy(
        filter(state.logs.data, (log) => !(log.action === 'update' && isNull(log.changed))),
        'created',
        'desc'
    )

    return {
        project: state.projects.currentProject,
        empowerment: state.empowerments.currentEmpowerment,
        isLoading: state.empowerments.isLoading,
        logs: logs,
        nextPage: state.logs.next
    }
}

const mapDispatchToProps = (dispatch) => ({
    openDeleteEmpowermentModal: () => dispatch(openModal(modalTypes.DELETE_EMPOWERMENT)),
    openSlideover: (slideoverType) => dispatch(openSlideover(slideoverType)),
    listLogs: (empowermentID) => dispatch(RESOURCES.logs.list({
        resource_id: empowermentID,
        resource_name: RESOURCES.empowerments.name,
        ordering: logsOrderingOptions.desc
    }))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmpowermentMetaInfo)
