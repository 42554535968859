import {toast} from 'react-toastify'
import {push} from 'connected-react-router'
import {takeEvery, put, select} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {datatablePageSize} from '../utils/datatables'

import {closeModal} from './modals'
import {closeSlideover} from './slideovers'

const {clientGroups: resource} = RESOURCES

function* handleCreateClientGroupSuccess({payload: clientGroup}) {
    toast.success('Grupul a fost creat cu succes')

    yield put(closeSlideover())
    yield put(push(`/clients/groups/${clientGroup.id}`))
}

function* handleUpdateClientGroupSuccess({payload: clientGroup}) {
    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)

    yield put(
        RESOURCES.clients.list(
            {
                entity_id: selectedEntityID,
                group_id: clientGroup.id,
                page: 1,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )

    toast.success('Modificările au fost salvate')

    yield put(closeSlideover())
}

function* handleDestroyClientGroupSuccess() {
    toast.success('Grupul a fost șters')

    yield put(closeModal())
    yield put(push('/clients/groups/'))
}

function* handleDestroyClientGroupFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateClientGroupSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateClientGroupSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyClientGroupSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyClientGroupFail)
}
