import React, {Component} from 'react'
import './InputError.scss'

export default class InputError extends Component {
    render() {
        const {message} = this.props

        return (
            <div className="input-error-container">
                <p className="input-error-text">{message}</p>
            </div>
        )
    }
}
