import React from 'react'
import {connect} from 'react-redux'

export const CalendarDayView = (props) => {
    return (
        <div>CalendarDayView</div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDayView)