import React, {useEffect, useState} from 'react'

import {ArrowLeftIcon, CheckCircleIcon, TrashIcon, XCircleIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import {isEmpty, isNil, isNull, join, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../redux/modals'

import {durationAsHoursAndMinutes, toApiDateFormat, toApiTimeFormat} from '../../utils'

import {AddTaskTimeLogModal} from '../../components/AddTaskTimeLogModal'
import {Button} from '../../components/Button'
import {ColorPreview} from '../../components/ColorPreview'
import {Datatable} from '../../components/Datatable'
import {DeleteTaskTimeLogModal} from '../../components/DeleteTaskTimeLogModal'
import {EditTaskModal} from '../../components/EditTaskModal'
import {EditTaskTimeLogModal} from '../../components/EditTaskTimeLogModal'
import {PageLoader} from '../../components/PageLoader'

import {columns} from './constants'
import {TaskStatusBadge} from './partials'

import './TaskDetails.scss'
import {useQuery} from '@tanstack/react-query'
import {performRequest} from 'avoapp-react-common/dist/redux/api'

export const TaskDetails = ({
    match: {params: {taskID}},
    task,
    isLoading,
    taskTimeLogs,
    isLoadingTaskTimeLogs,
    listTaskTimeLogs,
    openModal,
    retrieveTask,
    updateTask,
    selectedEntityID,
    history: {goBack}
}) => {
    const [selectedTaskTimeLog, setSelectedTaskTimeLog] = useState(undefined)

    const {data: timeLogsReports} = useQuery({
        queryKey: [RESOURCES.taskTimeLogTotalsReports.name, taskID, taskTimeLogs],
        queryFn: async () =>{
            const response = await performRequest(RESOURCES.taskTimeLogTotalsReports.list({
                task_id: taskID,
                entity_id: selectedEntityID
            }))

            return response.data
        }
    })

    useEffect(() => { retrieveTask(taskID) }, [retrieveTask, taskID])

    useEffect(() => { listTaskTimeLogs(taskID) }, [listTaskTimeLogs, taskID])

    const joinTaskSubscribers = (task) => {
        const {task_subscribers: taskSubscribers} = task
        const taskSubscribersNames = values(taskSubscribers).map((subcriber) => {
            return subcriber.full_name || subcriber.subscriber_id
        })

        return join(taskSubscribersNames, ', ')
    }

    const intervalFormat = task.all_day ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm'

    const handleChangeCompletion = (task) => {
        let taskData = {completion: null}

        if(isNull(task.completion)) {
            const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

            taskData = {completion: datetime}
        }

        updateTask(taskData, task.id)
    }

    return (
        <div className='resource-details-content'>
            <div className='back-button-container'>
                <Button
                    title='Mergi înapoi'
                    onClick={goBack}
                    size='small'
                    color='gray'
                    variant='outlined'
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            {!isNil(task) && !isEmpty(task) && !isLoading ? (
                <>
                    <div className="header-container">
                        <div className="header-resource-title-container">
                            <p className='header-resource-title'>{task.title}</p>
                            <TaskStatusBadge isActive={isNull(task.completion)} />
                        </div>
                        {task.description && <p className="task-description">{task.description}</p>}
                    </div>
                    <div className="content-container task-details">
                        <div className="task-info-card">
                            <div className="task-info-card-header">
                                <p className='header-title'>Informații sarcină</p>
                                <Button
                                    title='Editează'
                                    onClick={() => openModal(modalTypes.EDIT_TASK)}
                                    size='small'
                                />
                            </div>
                            <div className="task-info-card-content">
                                <div className="task-interval-container">
                                    <div className="times-container">
                                        <p className="task-time">
                                            De la:{' '}
                                            <span>
                                                {!isNull(task.start)
                                                    ? lightFormat(new Date(task.start), intervalFormat)
                                                    : '-'}
                                            </span>
                                        </p>
                                        <p className="task-time">
                                            Până la:{' '}
                                            <span>
                                                {!isNull(task.stop)
                                                    ? lightFormat(new Date(task.stop), intervalFormat)
                                                    : '-'}
                                            </span>
                                        </p>
                                    </div>
                                    {task.all_day &&
                                        <div className="all-day-container">
                                            <p className="all-day-text">Toată ziua</p>
                                        </div>
                                    }
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Proiect</p>
                                    <p className='value'>{task.project?.name || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Contract</p>
                                    <p className='value'>{task.contract?.name || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Deținători</p>
                                    <p className="value">
                                        {task.owners?.map(
                                            (profile) => `${profile.first_name} ${profile.last_name}`
                                        ) || '-'}
                                    </p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Supraveghetori</p>
                                    <p className="value">
                                        {!isEmpty(task.supervisors) ? task.supervisors.map(
                                            (profile) => `${profile.first_name} ${profile.last_name}`
                                        ) : '-'}
                                    </p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Solicitant</p>
                                    <p className='value'>
                                        {
                                            !isNull(task.requester) ?
                                                `${task.requester?.first_name} ${task.requester?.last_name}` :
                                                '-'
                                        }
                                    </p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">De facturat</p>
                                    <div className="value">
                                        {task.billable ? (
                                            <CheckCircleIcon className="billable-icon green" />
                                        ) : (
                                            <XCircleIcon className="billable-icon red" />
                                        )}
                                    </div>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Colaboratori interni anunțați</p>
                                    <div className="value">
                                        {!isEmpty(task.task_subscribers) ? joinTaskSubscribers(task): '-'}
                                    </div>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Culoare</p>
                                    <p className="value">
                                        {!isNull(task.color) ? (
                                            <div className="task-color-container">
                                                <ColorPreview color={task.color} />
                                                <p className="task-color-hex">{task.color}</p>
                                            </div>
                                        ) : '-'}
                                    </p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Locație</p>
                                    <p className='value'>{task.location || '-'}</p>
                                </div>
                                <div className="task-info-button-container">
                                    <Button
                                        title={isNull(task.completion) ? 'Finalizează sarcină' : 'Activează sarcină'}
                                        color={isNull(task.completion) ? 'secondary' : 'gray'}
                                        onClick={() => handleChangeCompletion(task)}
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="task-activities-container">
                            <Datatable
                                title='Activități'
                                customHeader={() => {
                                    const duration = timeLogsReports?.duration ?
                                        durationAsHoursAndMinutes(timeLogsReports?.duration) : '...'
                                    const durationAsHours = timeLogsReports?.duration_as_hours || '...'

                                    const durationBillable = timeLogsReports?.duration_billable ?
                                        durationAsHoursAndMinutes(timeLogsReports?.duration_billable) : '...'
                                    const durationBillableAsHours = timeLogsReports?.duration_billable_as_hours || '...'

                                    return (
                                        <>
                                            Ore lucrate: {duration} ({durationAsHours}),
                                            Ore facturabile: {durationBillable} ({durationBillableAsHours})
                                        </>
                                    )

                                }}
                                data={taskTimeLogs}
                                columns={[
                                    ...columns,
                                    {
                                        Header: '',
                                        accessor: 'id',
                                        Cell: ({row: {original: taskTimeLog}}) => (
                                            <div className="buttons-container">
                                                <Button
                                                    title='Editează'
                                                    size='small'
                                                    onClick={() => {
                                                        setSelectedTaskTimeLog(taskTimeLog)
                                                        openModal(modalTypes.EDIT_TASK_TIME_LOG)
                                                    }}
                                                />
                                                <Button
                                                    icon={() => <TrashIcon />}
                                                    onClick={() => {
                                                        setSelectedTaskTimeLog(taskTimeLog)
                                                        openModal(modalTypes.DELETE_TASK_TIME_LOG)
                                                    }}
                                                    size='small'
                                                    color='red'
                                                />
                                            </div>
                                        )
                                    }
                                ]}
                                headerButton={() =>
                                    <Button
                                        title='Adaugă'
                                        color='secondary'
                                        onClick={() => openModal(modalTypes.ADD_TASK_TIME_LOG)}
                                    />
                                }
                                loading={isLoadingTaskTimeLogs}
                            />
                        </div>
                    </div>
                </>
            ) : isLoading ? (
                <PageLoader />
            ) : null}
            <AddTaskTimeLogModal taskToAdd={task} />
            {task && <EditTaskModal task={task}/>}
            <EditTaskTimeLogModal selectedLog={selectedTaskTimeLog} />
            <DeleteTaskTimeLogModal selectedLog={selectedTaskTimeLog} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    task: state.tasks.currentTask,
    isLoading: state.tasks.isLoading,
    taskTimeLogs: values(state.taskTimeLogs.data),
    isLoadingTaskTimeLogs:state.taskTimeLogs.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    retrieveTask: (taskID) => dispatch(RESOURCES.tasks.retrieve(taskID)),
    updateTask: (taskData, taskID) => dispatch(RESOURCES.tasks.update(taskData, taskID)),
    listTaskTimeLogs: (taskID) => dispatch(RESOURCES.taskTimeLogs.list({task_id: taskID}))
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails)