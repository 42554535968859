import {Form, Formik} from 'formik'
import React, {useEffect, useMemo} from 'react'

import {values as _values, filter, find, isEmpty, isNull, omit, orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {addendumsSchema} from '../../../../../../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase, toApiDateFormat} from '../../../../../../../utils'
import {
    documentSeriesTypes,
    taxPercentageOptions
} from '../../../../../../../utils/types'
import {documentTemplateTypes} from '../../../../../constants'

import {DatePicker} from '../../../../../../../components/DatePicker'
import {ErrorsList} from '../../../../../../../components/ErrorComponents'
import {FormSection} from '../../../../../../../components/FormSection'
import {Input} from '../../../../../../../components/Input'
import {RequiredFieldsText} from '../../../../../../../components/RequiredFieldsText'
import {Select} from '../../../../../../../components/Select'
import {Toggle} from '../../../../../../../components/Toggle'

export const EditAddendumForm = ({
    addendum,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    contract,
    contracts,
    isLoadingContracts,
    selectedEntityID,
    documentSeries,
    isLoadingDocumentSeries,
    documentTemplates,
    isLoadingDocumentTemplates,
    addendumOptions,
    getAddendumOptions,
    updateAddendum,
    handleExitEditMode,
    retrieveContract,
    retrieveDocumentTemplate,
    listAllDocumentSeries
}) => {
    const amountToRecoverCurrencies = useMemo(() =>
        getFieldOptions(addendumOptions, 'amount_to_recover_currency')
    , [addendumOptions])

    const billingPaymentMethods = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_payment_method')
    , [addendumOptions])

    const billingCurrencies = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_currency')
    , [addendumOptions])

    const billingSuccessBonusTypes = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_success_bonus_type')
    , [addendumOptions])

    const billingSuccessBonusCurrencies = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_success_bonus_currency')
    , [addendumOptions])

    useEffect(() => {
        getAddendumOptions()
        listAllDocumentSeries({entity_id: selectedEntityID})
        retrieveDocumentTemplate(addendum.template_id)
        retrieveContract(addendum.contract_id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="addendum-add-form-container">
            {!isEmpty(contracts) && !isEmpty(documentTemplates) && !isEmpty(addendumOptions) && (
                <Formik
                    initialValues={{
                        contractId: find(contracts, (c) => parseInt(c.id) === parseInt(addendum.contract_id)),
                        internalSeriesId: find(
                            documentSeries,
                            (series) => parseInt(series.id) === parseInt(contract.internal_series_id)
                        ),
                        barSeriesId: find(
                            documentSeries,
                            (series) => parseInt(series.id) === parseInt(contract.bar_series_id)
                        ),
                        name: addendum.name,
                        legalObject: addendum.legal_object || '',
                        signedDate: addendum.signed_date ? new Date(addendum.signed_date) : null,
                        startDate: new Date(addendum.start_date),
                        endDate: addendum.end_date ? new Date(addendum.end_date) : null,
                        hasAmontToRecover: Boolean(
                            addendum.amount_to_recover && addendum.amount_to_recover_currency
                        ),
                        amountToRecover: addendum.amount_to_recover || '',
                        amountToRecoverCurrency: addendum.amount_to_recover_currency ?
                            find(amountToRecoverCurrencies, ['value', addendum.amount_to_recover_currency])
                            : null,
                        billingPaymentMethod: find(billingPaymentMethods, ['value', addendum.billing_payment_method]),
                        billingAmount: addendum.billing_amount,
                        billingCurrency: find(billingCurrencies, ['value', addendum.billing_currency]),
                        billingTaxPercentage: taxPercentageOptions[parseInt(addendum.billing_tax_percentage)],
                        billingMinimumHours: addendum.billing_minimum_hours || '',
                        billingAmountForExtraHours: addendum.billing_amount_for_extra_hours || '',
                        isRecurring: !isNull(addendum.billing_recurring_months),
                        billingRecurringMonths: !isNull(addendum.billing_recurring_months) ?
                            addendum.billing_recurring_months : '',
                        hasSuccessBonus: !isNull(addendum.billing_success_bonus_value),
                        billingSuccessBonusValue: addendum.billing_success_bonus_value || '',
                        billingSuccessBonusType: !isNull(addendum.billing_success_bonus_type) ?
                            find(billingSuccessBonusTypes, ['value', addendum.billing_success_bonus_type]) :
                            null,
                        billingSuccessBonusCurrency: !isNull(addendum.billing_success_bonus_currency) ?
                            find(billingSuccessBonusCurrencies, ['value', addendum.billing_success_bonus_currency])
                            : null,
                        templateId: find(
                            documentTemplates,
                            (template) => parseInt(template.id) === parseInt(addendum.template_id)
                        )
                    }}
                    validationSchema={addendumsSchema.update}
                    onSubmit={(values) => {
                        const addendumData = {
                            ...objectKeysToSnakeCase(
                                omit(values, [
                                    'internalSeriesId',
                                    'barSeriesId',
                                    'hasAmontToRecover',
                                    'isRecurring',
                                    'hasSuccessBonus',
                                    'templateId'
                                ])
                            ),
                            entity_id: selectedEntityID,
                            signed_date: toApiDateFormat(new Date(values.signedDate)),
                            start_date: toApiDateFormat(new Date(values.startDate)),
                            end_date: toApiDateFormat(new Date(values.endDate)),
                            amount_to_recover_currency: values.amountToRecoverCurrency.value,
                            billing_payment_method: values.billingPaymentMethod.value,
                            billing_currency: values.billingCurrency.value,
                            billing_tax_percentage: values.billingTaxPercentage.value,
                            billing_recurring_months: values.isRecurring ? values.billingRecurringMonths : null,
                            billing_success_bonus_type: values.hasSuccessBonus ?
                                values.billingSuccessBonusType.value :
                                null,
                            billing_success_bonus_currency: values.hasSuccessBonus &&
                                values.billingSuccessBonusType === find(billingSuccessBonusTypes, ['value', 'value']) ?
                                values.billingSuccessBonusCurrency.value :
                                null
                        }

                        updateAddendum(addendumData, addendum.id)
                    }}
                >
                    {({
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        values,
                        touched,
                        errors,
                        isValid
                    }) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <div className="space-y-6">
                                <FormSection
                                    autoHeight
                                    renderForm={() =>
                                        <>
                                            <p className="form-section-title">
                                                Setări generale
                                            </p>
                                            <Select
                                                label='Contract*'
                                                value={values.contractId}
                                                options={contracts}
                                                loading={isLoadingContracts}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                fullWidth
                                                disabled
                                            />
                                            <Select
                                                label='Șablon*'
                                                value={values.templateId}
                                                options={documentTemplates}
                                                loading={isLoadingDocumentTemplates}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                fullWidth
                                                disabled
                                            />
                                            <div className="split-row">
                                                <Select
                                                    label='Serie interna*'
                                                    value={values.internalSeriesId}
                                                    options={
                                                        filter(
                                                            documentSeries,
                                                            ['type', documentSeriesTypes.CONTRACT_INTERNAL.value]
                                                        )
                                                    }
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    fullWidth
                                                    disabled
                                                />
                                                <Select
                                                    label='Serie barou*'
                                                    value={values.barSeriesId}
                                                    options={
                                                        filter(
                                                            documentSeries,
                                                            ['type', documentSeriesTypes.CONTRACT_BAR.value]
                                                        )
                                                    }
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    disabled
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row">
                                                <Input
                                                    label='Nume addendum*'
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    onBlur={handleBlur('name')}
                                                    name='name'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.name}
                                                    fullWidth
                                                />
                                                <Input
                                                    label='Obiect addendum'
                                                    value={values.legalObject}
                                                    onChange={handleChange('legalObject')}
                                                    onBlur={handleBlur('legalObject')}
                                                    name='legalObject'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.legalObject}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row-3">
                                                <DatePicker
                                                    label='Data semnării'
                                                    value={values.signedDate}
                                                    fullWidth
                                                    disabled
                                                />
                                                <DatePicker
                                                    label='Data producere efecte*'
                                                    value={values.startDate}
                                                    fullWidth
                                                    disabled
                                                />
                                                <DatePicker
                                                    label='Data încetare'
                                                    value={values.endDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'endDate',
                                                            isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('endDate')}
                                                    name='endDate'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.endDate}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row-3">
                                                <Toggle
                                                    label='Sumă de recuperat'
                                                    checked={values.hasAmontToRecover}
                                                    onChange={(e) => setFieldValue('hasAmontToRecover', e)}
                                                    onBlur={handleBlur('hasAmontToRecover')}
                                                    name='hasAmontToRecover'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.hasAmontToRecover}
                                                />
                                                {values.hasAmontToRecover && (
                                                    <>
                                                        <Input
                                                            label='Suma'
                                                            value={values.amountToRecover}
                                                            onChange={handleChange('amountToRecover')}
                                                            onBlur={handleBlur('amountToRecover')}
                                                            name='amountToRecover'
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.amountToRecover}
                                                            fullWidth
                                                        />
                                                        <Select
                                                            label='Moneda'
                                                            value={values.amountToRecoverCurrency}
                                                            options={amountToRecoverCurrencies}
                                                            onChange={(e) =>
                                                                setFieldValue('amountToRecoverCurrency', e)
                                                            }
                                                            onBlur={handleBlur('amountToRecoverCurrency')}
                                                            name='amountToRecoverCurrency'
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.amountToRecoverCurrency}
                                                            fullWidth
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <RequiredFieldsText />
                                        </>
                                    }
                                />
                                <FormSection
                                    autoHeight
                                    renderForm={() =>
                                        <>
                                            <p className="form-section-title">
                                                Setări facturare
                                            </p>
                                            <div className="split-row-4">
                                                <Select
                                                    label='Tip facturare*'
                                                    value={values.billingPaymentMethod}
                                                    options={billingPaymentMethods}
                                                    onChange={(e) => setFieldValue('billingPaymentMethod', e)}
                                                    onBlur={handleBlur('billingPaymentMethod')}
                                                    name='billingPaymentMethod'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingPaymentMethod}
                                                    fullWidth
                                                />
                                                <Input
                                                    label='Suma*'
                                                    value={values.billingAmount}
                                                    onChange={handleChange('billingAmount')}
                                                    onBlur={handleBlur('billingAmount')}
                                                    name='billingAmount'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingAmount}
                                                    fullWidth
                                                />
                                                <Select
                                                    label='Moneda*'
                                                    value={values.billingCurrency}
                                                    options={billingCurrencies}
                                                    onChange={(e) => setFieldValue('billingCurrency', e)}
                                                    onBlur={handleBlur('billingCurrency')}
                                                    name='billingCurrency'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingCurrency}
                                                    fullWidth
                                                />
                                                <Select
                                                    label='Taxă adăugată*'
                                                    value={values.billingTaxPercentage}
                                                    options={_values(orderBy(taxPercentageOptions, 'value', 'desc'))}
                                                    onChange={(e) => setFieldValue('billingTaxPercentage', e)}
                                                    onBlur={handleBlur('billingTaxPercentage')}
                                                    name='billingTaxPercentage'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingTaxPercentage}
                                                    fullWidth
                                                />
                                            </div>
                                            {
                                                values.billingPaymentMethod ===
                                                    find(billingPaymentMethods, ['value', 'hourly_blended'])
                                                    && (
                                                        <div className="split-row">
                                                            <Input
                                                                label='Ore minime'
                                                                value={values.billingMinimumHours}
                                                                onChange={handleChange('billingMinimumHours')}
                                                                onBlur={handleBlur('billingMinimumHours')}
                                                                name='billingMinimumHours'
                                                                errors={fieldErrors}
                                                                frontendErrors={errors}
                                                                touched={touched.billingMinimumHours}
                                                                type='number'
                                                                fullWidth
                                                            />
                                                            <Input
                                                                label='Sumă ore suplimentare'
                                                                value={values.billingAmountForExtraHours}
                                                                onChange={handleChange('billingAmountForExtraHours')}
                                                                onBlur={handleBlur('billingAmountForExtraHours')}
                                                                name='billingAmountForExtraHours'
                                                                errors={fieldErrors}
                                                                frontendErrors={errors}
                                                                touched={touched.billingAmountForExtraHours}
                                                                type='number'
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                            <div className="split-row-4">
                                                <Toggle
                                                    label='Recurent'
                                                    checked={values.isRecurring}
                                                    onChange={(e) => setFieldValue('isRecurring', e)}
                                                    onBlur={handleBlur('isRecurring')}
                                                    name='isRecurring'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.isRecurring}
                                                />
                                                {values.isRecurring && (
                                                <div className="recurring-months-input-container">
                                                    <Input
                                                        value={values.billingRecurringMonths}
                                                        onChange={handleChange('billingRecurringMonths')}
                                                        onBlur={handleBlur('billingRecurringMonths')}
                                                        name='billingRecurringMonths'
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.billingRecurringMonths}
                                                        fullWidth
                                                    />
                                                    <p>luni*</p>
                                                </div>
                                                )}
                                            </div>
                                            <div className="split-row-4">
                                                <Toggle
                                                    label='Onorariu succes'
                                                    checked={values.hasSuccessBonus}
                                                    onChange={(e) => setFieldValue('hasSuccessBonus', e)}
                                                    onBlur={handleBlur('hasSuccessBonus')}
                                                    name='hasSuccessBonus'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.hasSuccessBonus}
                                                />
                                                {values.hasSuccessBonus && (
                                                    <>
                                                        <Select
                                                            label='Tip'
                                                            value={values.billingSuccessBonusType}
                                                            options={billingSuccessBonusTypes}
                                                            onChange={(e) =>
                                                                setFieldValue('billingSuccessBonusType', e)
                                                            }
                                                            onBlur={handleBlur('billingSuccessBonusType')}
                                                            name='billingSuccessBonusType'
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.billingSuccessBonusType}
                                                            fullWidth
                                                        />
                                                        <Input
                                                            label='Număr'
                                                            value={values.billingSuccessBonusValue}
                                                            onChange={handleChange('billingSuccessBonusValue')}
                                                            onBlur={handleBlur('billingSuccessBonusValue')}
                                                            name='billingSuccessBonusValue'
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.billingSuccessBonusValue}
                                                            fullWidth
                                                        />
                                                        {
                                                            values.billingSuccessBonusType ===
                                                                find(billingSuccessBonusTypes, ['value', 'value'])
                                                                && (
                                                                    <Select
                                                                        label='Monedă'
                                                                        value={values.billingSuccessBonusCurrency}
                                                                        options={billingSuccessBonusCurrencies}
                                                                        onChange={(e) =>
                                                                            setFieldValue(
                                                                                'billingSuccessBonusCurrency',
                                                                                e
                                                                            )
                                                                        }
                                                                        onBlur={
                                                                            handleBlur('billingSuccessBonusCurrency')
                                                                        }
                                                                        name='billingSuccessBonusCurrency'
                                                                        errors={fieldErrors}
                                                                        frontendErrors={errors}
                                                                        touched={touched.billingSuccessBonusCurrency}
                                                                        fullWidth
                                                                    />
                                                                )}
                                                    </>
                                                )}
                                            </div>
                                            <RequiredFieldsText />
                                        </>
                                    }
                                    buttonDisabled={!isValid}
                                    onSubmit={handleSubmit}
                                    submitButtonTitle='Salvează'
                                    onSkip={handleExitEditMode}
                                    skipButtonTitle='Renunță'
                                    loading={isLoading}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    addendumOptions: state.addendums.options,
    addendum: state.addendums.currentAddendum,
    isLoading: state.addendums.isLoading,
    fieldErrors: state.addendums.fieldErrors,
    nonFieldErrors: state.addendums.nonFieldErrors,
    contract: state.contracts.currentContract,
    contracts: _values(state.contracts.searchData),
    isLoadingContracts: state.contracts.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID,
    documentSeries: _values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading,
    documentTemplates: filter(
        state.documentTemplates.data,
        ['type', find(documentTemplateTypes, ['value', 'addendum']).value]
    ),
    isLoadingDocumentTemplates: state.documentTemplates.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    getAddendumOptions: () => dispatch(RESOURCES.addendums.getOptions()),
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID)),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    retrieveDocumentTemplate: (templateID) => dispatch(RESOURCES.documentTemplates.retrieve(templateID)),
    updateAddendum: (addendumData, addendumId) => dispatch(RESOURCES.addendums.update(addendumData, addendumId))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAddendumForm)