import {AccessPolicy} from '../types/api'
import _ from 'lodash'
import {AccessPolicyAction, AccessPolicyPermission, AccessPolicyResource} from '../types/openapi'

export const hasPermission = (
    accessPolicies: Array<AccessPolicy>,
    resource: AccessPolicyResource,
    permission: AccessPolicyPermission,
    action: AccessPolicyAction
): boolean => {
    return Boolean(
        _.filter(
            accessPolicies, (policy) => {
                return policy.resource === resource && policy.permission === permission && policy.action === action
            }
        ).length
    )
}
