import React from 'react'

import {connect} from 'react-redux'
import {modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import './ResetInvoiceEntriesInfoModal.scss'

function ResetInvoiceEntriesInfoModal({open, onConfirm, onClose}) {
    return (
        <Modal open={open} title='Atenție!'>
            <div className='reset-entries-info-modal'>
                <p className='message'>
                    <span>Prin schimbarea monedei facturii produsele/serviciile adăugate mai jos for fi resetate.</span>
                </p>
                <p className='message mt-4'>
                    Sunteți sigur ca doriți să schimbați moneda facturii?
                </p>
                <div className="buttons-container">
                    <Button title='Renunță' onClick={onClose} variant='outlined' color='gray'/>
                    <Button title='Da, schimbă moneda' onClick={onConfirm}/>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.RESET_ENTRIES_INFO
})

export default connect(mapStateToProps)(ResetInvoiceEntriesInfoModal)