import {CheckCell} from '../../../../components/CheckCell'

export const columns = [
    {
        Header: 'Nume',
        accessor: 'last_name'
    },
    {
        Header: 'Prenume',
        accessor: 'first_name'
    },
    {
        Header: 'Rol',
        accessor: 'role',
        Cell: ({value: role}) => role?.name || ''
    },
    {
        Header: 'Rep. legal',
        accessor: 'legal_representative',
        Cell: (props) => props.value && <CheckCell />
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Telefon',
        accessor: 'phone'
    }
]

export const genders = [
    {value: 'M', label: 'Masculin'},
    {value: 'F', label: 'Feminin'}
]

export const languages = [
    {value: 'RO', label: 'Română'},
    {value: 'OTHER', label: 'Altă limbă'}
]
