import React from 'react'

import Step from './Step'

import './Steps.scss'

export default function Steps({steps, currentStepId}) {
    console.log('currentStepId: ', currentStepId)
    return (
        <div className="steps-container">
            <nav className="steps-nav" aria-label="Progress">
                <ol className="steps-list">
                    {steps.map((step, stepIdx) =>
                        <Step
                            key={stepIdx}
                            step={step}
                            stepIdx={stepIdx}
                            isCurrent={step.id === currentStepId}
                            isComplete={step.id < currentStepId}
                        />
                    )}
                </ol>
            </nav>
        </div>
    )
}
