import React, {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'

import {head, isUndefined} from 'lodash'

export default function SignedFileDropzone({onChange, active, customButton}) {
    const [file, setFile] = useState([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {onChange(file)}, [file])

    const handleDrop = (acceptedFiles) => setFile(head(acceptedFiles))

    const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), [])

    const {getRootProps, getInputProps, open} = useDropzone({onDrop: onDrop, noClick: true, noKeyboard: true})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div onClick={open} className={`avo-dropdown-menu-item ${active ? 'selected' : ''}`}>
                {!isUndefined(customButton) ? customButton() : 'Încarcă fișier semnat'}
            </div>
        </div>
    )
}
