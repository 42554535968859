import React, {useCallback, useMemo, useState} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {useQueryResourceList} from '../../../../queries/rest'
import {useDebouncedState} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'

import AddPartyModal from './AddPartyModal'
import DeletePartyModal from './DeletePartyModal'

function PartiesSection({subscription, selectedEntityID, isManualSubscription, openModal, openedModal}) {
    const [searchFilter, setSearchFilter] = useDebouncedState('', 300)
    const [page, setPage] = useState(1)
    const [selectedParty, setSelectedParty] = useState(undefined)

    const columns = useMemo(() => {
        return [
            {
                Header: 'Nume',
                accessor: 'name'
            },
            {
                Header: 'Rol',
                accessor: 'role'
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row}) => (
                    <div className='datatable-row-buttons-container'>
                        <Button
                            icon={() => <TrashIcon />}
                            onClick={() => {
                                setSelectedParty(row.original)
                                openModal(modalTypes.DELETE_PARTY)
                            }}
                            size='small'
                            color='red'
                        />
                    </div>
                )
            }
        ]
    }, [openModal])

    const filters = useMemo(() => ({
        subscription_id: subscription.id,
        search: searchFilter,
        entity_id: selectedEntityID,
        page_size: 5,
        page: page
    }), [page, searchFilter, selectedEntityID, subscription.id])

    const {data: partiesResult, isFetching} = useQueryResourceList(
        RESOURCES.parties,
        filters,
        undefined,
        //we want to refetch the data when the add or delete modal closes
        _.isNil(openedModal)
    )

    const handleChangeSearchField = useCallback((value) => setSearchFilter(value), [setSearchFilter])

    const handleChangePage = useCallback((newPage) => setPage(newPage), [])

    const renderAddButton = useCallback(() => {
        if(isManualSubscription) {
            return (
                <Button
                    title='Adaugă părți'
                    onClick={() => openModal(modalTypes.ADD_PARTY_MODAL)}
                    color='secondary'
                />
            )
        }
    }, [isManualSubscription, openModal])

    if(_.isNil(partiesResult)) {
        return <React.Fragment />
    }

    return (
        <>
            <Datatable
                title='Părți'
                data={partiesResult.data?.results || []}
                headerButton={renderAddButton}
                columns={columns}
                loading={isFetching}
                previousPage={partiesResult.data?.pages?.previous}
                currentPage={partiesResult.data?.page}
                nextPage={partiesResult.data?.pages?.next}
                totalPages={partiesResult.data?.number_of_pages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută părți'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <AddPartyModal subscription={subscription} />
            <DeletePartyModal party={selectedParty} />
        </>

    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    openedModal: state.modals.type
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartiesSection)