import {
    BellIcon,
    CheckIcon,
    CalendarIcon,
    CogIcon,
    CurrencyEuroIcon,
    DocumentChartBarIcon,
    DocumentIcon,
    DocumentPlusIcon,
    FolderIcon,
    HomeIcon,
    LifebuoyIcon,
    LinkIcon,
    PencilIcon,
    UsersIcon
} from '@heroicons/react/24/outline'

import {isCurrentNavigationItem} from '../../utils'

class NavigationItem {
    constructor(name, path, icon) {
        this.name = name
        this.path = path
        this.icon = icon
        this.current = isCurrentNavigationItem(path)
    }
}

export const navigation = [
    new NavigationItem('Dashboard', '/dashboard', HomeIcon),
    new NavigationItem('Clienți', '/clients', UsersIcon),
    new NavigationItem('Proiecte', '/projects', FolderIcon),
    new NavigationItem('Sarcini & Pontaj', '/tasks', CheckIcon),
    new NavigationItem('Calendar', '/calendar', CalendarIcon),
    new NavigationItem('Contracte', '/contracts', PencilIcon),
    new NavigationItem('Date externe', '/external-data', LinkIcon),
    new NavigationItem('Documente', '/documents', DocumentIcon),
    new NavigationItem('Cheltuieli', '/expenses', CurrencyEuroIcon),
    new NavigationItem('Facturi', '/invoices', DocumentPlusIcon),
    new NavigationItem('Raportare', '/reports', DocumentChartBarIcon),
    new NavigationItem('Notificări', '/notifications', BellIcon)
]

export const secondaryNavigation = [
    new NavigationItem('Setări', '/settings', CogIcon),
    new NavigationItem('Asistență', '/support', LifebuoyIcon)
]
