import React from 'react'

import _ from 'lodash'

import {Loader} from '../Loader'

import './DetailsCard.scss'

export default function DetailsCard({title, rows, headerButton, loading, className}) {
    return (
        <div className={`details-card ${className}`}>
            <div className="header">
                <p className='header-title'>{title}</p>
                {headerButton && headerButton()}
            </div>
            {!loading ? (
                <div className="details-card-content">
                    {rows.map((row, rowIdx) => <DetailsCardRow key={rowIdx} label={row.label} value={row.value} />)}
                </div>
            ) : (
                <div className="center-loader">
                    <Loader />
                </div>
            )}
        </div>
    )
}

function DetailsCardRow({label, value}) {
    return (
        <div className="details-card-row">
            <p className="label">{label}</p>
            <p className='value'>{!_.isNil(value) ? value : '-'}</p>
        </div>
    )
}
