import {MutationOptions, useMutation} from '@tanstack/react-query'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {Resource} from 'avoapp-react-common/dist/redux'
import {Invoice} from '../types/api'

import {
    createSmartBillInvoice,
    cancelSmartBillInvoice,
    deleteSmartBillInvoice,
    manualUpdateSmartBillInvoice
    // @ts-ignore
} from 'avoapp-react-common/dist/redux/smartbill'
import type {DefaultError} from '@tanstack/query-core'

export const useCreateSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(createSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useCancelSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(cancelSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useDeleteSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(deleteSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useManualUpdateSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(manualUpdateSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useSpecMutation = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (spec) => {
            return await performRequest(spec)
        },
        ...overrides
    })
}

export const useMutationResourceUpdate = (resource: Resource, overrides?: MutationOptions) => {
    return useMutation<unknown, DefaultError, any, unknown>({
        // @ts-ignore
        mutationFn: async (data: object, id: number) => {
            return await performRequest(
                resource.update(data, id)
            )
        },
        ...overrides
    })
}

export const useMutationResourceCreate = (resource: Resource, overrides?: MutationOptions) => {
    return useMutation<unknown, DefaultError, any, unknown>({
        // @ts-ignore
        mutationFn: async (data: object) => {
            return await performRequest(
                resource.create(data)
            )
        },
        ...overrides
    })
}

export const useMutationResourceDelete = (resource: Resource, overrides?: MutationOptions) => {
    return useMutation<unknown, DefaultError, any, unknown>({
        // @ts-ignore
        mutationFn: async (id: number) => {
            return await performRequest(
                resource.destroy({}, id)
            )
        },
        ...overrides
    })
}
