import React from 'react'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'

export const DeleteInvoicePaymentDistribution = ({
    open,
    isLoading,
    distribution,
    deleteInvoicePaymentDistribution,
    closeModal
}) => {
    return (
        <Modal open={open && !_.isNil(distribution) && !_.isEmpty(distribution)} title='Șterge distribuire'>
            <div className='delete-modal'>
                <p className='message'>Ești sigur că vrei să ștergi această distribuire?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteInvoicePaymentDistribution(distribution)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_INVOICE_PAYMENT_DISTRIBUTION,
    isLoading: state.invoicePaymentDistributions.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteInvoicePaymentDistribution: (distribution) => dispatch(
        RESOURCES.invoicePaymentDistributions.destroy(distribution)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoicePaymentDistribution)