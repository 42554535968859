
import React, {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'

import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon, EllipsisHorizontalIcon} from '@heroicons/react/24/solid'

import {values} from 'lodash'

import {calendarViewTypes} from '../../../../constants'

import './CalendarViewSelect.scss'

export default function CalendarViewSelect({calendarViewType, onToday}) {
    const calendarViewOptions = {
        [calendarViewTypes.DAY_VIEW]: {
            label: 'Zi',
            value: calendarViewTypes.DAY_VIEW
        },
        [calendarViewTypes.WEEK_VIEW]: {
            label: 'Săptămană',
            value: calendarViewTypes.WEEK_VIEW
        },
        [calendarViewTypes.MONTH_VIEW]: {
            label: 'Lună',
            value: calendarViewTypes.MONTH_VIEW
        }
    }

    const [currentCalendarView, setCurrentCalendarView] = useState(calendarViewOptions[calendarViewType])

    return (
        <div className='avo-calendar-view-select-container'>
            <Menu as='div' className='avo-calendar-view-select-menu'>
                <Menu.Button type='button' className='avo-calendar-view-select-menu-opener'>
                    {currentCalendarView?.label || null}
                    <ChevronDownIcon className='avo-calendar-view-select-menu-opener-icon' aria-hidden='true' />
                </Menu.Button>
                <Menu.Button className='avo-calendar-view-select-menu-mobile-opener'>
                    <span className='sr-only'>Open menu</span>
                    <EllipsisHorizontalIcon className='avo-calendar-view-select-menu-opener-icon' aria-hidden='true' />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Menu.Items className='avo-calendar-view-select-items-container'>
                        <div className='avo-calendar-view-select-menu-section md:hidden'>
                            <CalendarViewSelectOption option={{label: 'Astăzi'}} onSelect={onToday}/>
                        </div>
                        <div className="avo-calendar-view-select-menu-section">
                            {values(calendarViewOptions).map((option) =>
                                <CalendarViewSelectOption
                                    option={option}
                                    onSelect={(option) => setCurrentCalendarView(option)}
                                    key={option.value}
                                />
                            )}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

const CalendarViewSelectOption = ({option, onSelect}) => {
    return (
        <Menu.Item>
            <Link
                to={option.value ? `/tasks/calendar/${option.value}` : '#'}
                className='avo-calendar-view-select-option-container'
                onClick={() => onSelect(option)}
            >
                {option.label}
            </Link>
        </Menu.Item>
    )
}
