import React, {useCallback, useState} from 'react'

import {orderBy} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'

import {Datatable} from '../../../../components/Datatable'
import {ViewDetailsLink} from '../../../../components/ViewDetailsLink'

import {ClientDetailsDisclaimer} from '../ClientDetailsDisclaimer'
import {actionIds, tasksColumns} from './constants.ts'

import './Tasks.scss'

export const Tasks = ({
    tasks,
    isLoadingTasks,
    totalTasks,
    totalPages,
    nextPage,
    previousPage,
    currentPage,
    listTasks,
    selectedEntityID,
    match,
    match: {params: {clientID, secondaryAction}}

}) => {
    const [searchFilter, setSearchFilter] = useState('')

    const handleFetchTasks = useCallback((page = 1) => {
        const fetchCompleted = secondaryAction === actionIds.FINISHED_TASKS

        listTasks(selectedEntityID, clientID, fetchCompleted, searchFilter, page)
    }, [clientID, listTasks, searchFilter, secondaryAction, selectedEntityID])

    useDebouncedEffect(handleFetchTasks, [searchFilter, secondaryAction, clientID], debounceWait)

    const handleChangeSearchField = useCallback((value) => { setSearchFilter(value)}, [])

    const handleChangePage = useCallback((page) => {
        if(!isLoadingTasks) {
            handleFetchTasks(page)
        }
    }, [handleFetchTasks, isLoadingTasks])

    switch(match.params.secondaryAction) {
        case actionIds.ACTIVE_TASKS:
            return (
                <div className='page-client-show'>
                    <ClientDetailsDisclaimer resource='sarcinile' />
                    <Datatable
                        title={`Listă sarcini active (${totalTasks})`}
                        data={tasks}
                        columns={[
                            ...tasksColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({value: taskID}) => <ViewDetailsLink to={`/tasks/${taskID}`} />,
                                disableSortBy: true
                            }
                        ]}
                        loading={isLoadingTasks}
                        totalPages={totalPages}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentPage={currentPage}
                        onChangePage={handleChangePage}
                        searchable
                        searchValue={searchFilter}
                        searchPlaceholder='Caută sarcini'
                        onSearch={(event) => handleChangeSearchField(event.target.value)}
                    />
                </div>
            )
        case actionIds.FINISHED_TASKS:
            return (
                <div className='page-client-show'>
                    <ClientDetailsDisclaimer resource='sarcinile' />
                    <Datatable
                        title='Sarcini finalizate'
                        data={tasks}
                        columns={[
                            ...tasksColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({value: taskID}) => <ViewDetailsLink to={`/tasks/${taskID}`} />,
                                disableSortBy: true
                            }
                        ]}
                        loading={isLoadingTasks}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onChangePage={handleChangePage}
                        searchable
                        searchValue={searchFilter}
                        searchPlaceholder='Caută sarcini inactive'
                        onSearch={(event) => handleChangeSearchField(event.target.value)}
                    />
                </div>
            )
        default:
            return
    }
}

const mapStateToProps = (state) => ({
    tasks: orderBy(state.tasks.data, ['start', 'created'], ['desc', 'desc']),
    isLoadingTasks: state.tasks.isLoading,
    totalTasks: state.tasks.count,
    totalPages: state.tasks.totalPages,
    nextPage: state.tasks.next,
    previousPage: state.tasks.previous,
    currentPage: state.tasks.current,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = dispatch => ({
    listTasks: (entityID, clientID, isCompleted, search, page) => dispatch(
        RESOURCES.tasks.list(
            {
                entity_id: entityID,
                client_id: clientID,
                is_completed: isCompleted,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)