import React from 'react'

import {isEmpty} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import './ErrorsList.scss'

export default function ErrorsList({errors}) {
    if(!isEmpty(errors)) {
        return (
            <div className="errors-list">
                {errors.map((error) => <ErrorComponent message={getErrorMessage(error)}/>)}
            </div>
        )
    }

    return null
}

export function ErrorComponent({message}) {
    return (
        <div className='error-div'>
            <p className='error-text'>
                {message}
            </p>
        </div>
    )
}
