import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    country: Yup.object().label('Țara').nullable().required('Țara este obligatorie'),
    county: Yup.mixed().label('Județ').required('Județul este obligatoriu'),
    locality: Yup.string().label('Localitate / Oraș').required('Câmpul Localitate / Oraș este obligatoriu!'),
    postalCode: Yup.string().label('Cod poștal'),
    address: Yup.string().label('Adresa').required('Adresa este obligatorie!'),
    addressDescription: Yup.string().label('Descriere adresă').required('Descrierea adresei este obligatorie!'),
    isPrimary: Yup.boolean().label('Adresă principală')
})
