import React from 'react'

import {connect} from 'react-redux'

import {PageLoader} from '../../../../components/PageLoader'

import './InvoicePreview.scss'
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer'

export const InvoicePreview = ({currentInvoice, isLoading, user}) => {
    if (isLoading) return <PageLoader />

    return (
        <div className="page-info">
            <div className="invoice-preview-page-container">
                {!!currentInvoice.file_pdf? (
                    <div className="invoice-preview-container">
                        <DocumentViewer fileMeta={currentInvoice.file_pdf} user={user} />
                    </div>
                ) : <p className="no-file">Nu există niciun fișier încărcat</p>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentInvoice: state.invoices.currentInvoice,
    isLoading: state.invoices.isLoading,
    user: state.users.user
})

const mapDispatchToProps = (_dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePreview)
