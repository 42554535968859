// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {FilterType} from '../../../../components/FilterForm/types'
import {buildFilter} from '../../../../components/FilterForm/utils'
import {PathsSeriesGetParametersQueryType} from '../../../../types/openapi'
import {startOfYear} from 'date-fns'

export const tableListFilter = buildFilter({
    date__gte: {
        filterType: FilterType.DATE,
        label: 'Data start',
        defaultValue: startOfYear(new Date())
    },
    date__lte: {
        filterType: FilterType.DATE,
        label: 'Data stop'
    },
    series_id: {
        filterType:FilterType.RESOURCE,
        label: 'Serie factură',
        resource: RESOURCES.documentSeries,
        isClearable: true,
        valueAccessor: 'id',
        labelAccessor: 'name',
        queryFilters: {type: PathsSeriesGetParametersQueryType.fiscal_invoice}
    },
    series_number: {
        filterType: FilterType.NUMBER,
        label: 'Număr factură'
    },
    state: {
        filterType: FilterType.SELECT,
        label: 'Status',
        options: [
            {
                value: 'draft',
                label: 'Draft'
            },
            {
                value: 'issued',
                label: 'Emis'
            },
            {
                value: 'paid',
                label: 'Plătit'
            },
            {
                value: 'paid_partially',
                label: 'Plătit parțial'
            },
            {
                value: 'canceled',
                label: 'Anulat'
            }
        ]
    },
    client_id: {
        filterType:FilterType.RESOURCE,
        label: 'Client',
        resource: RESOURCES.clients,
        isClearable: true,
        valueAccessor: 'id',
        labelAccessor: 'name'
    },
    contract_id: {
        filterType:FilterType.RESOURCE,
        label: 'Contract',
        resource: RESOURCES.contracts,
        isClearable: true,
        valueAccessor: 'id',
        labelAccessor: 'name'
    },
    onlyNonCollected: {
        filterType: FilterType.BOOLEAN,
        label: 'Doar neîncasate',
        excludeFromForm: true,
        defaultValue: false
    },
    search: {filterType:FilterType.STRING, label: 'Search', excludeFromForm: true},
    page: {filterType:FilterType.NUMBER, label: 'Page', excludeFromForm: true}
})
