import React, {useState} from 'react'

import {lightFormat} from 'date-fns'
import {isEmpty, isNull, toString} from 'lodash'

import {connect} from 'react-redux'

import {upperSnakeCase} from '../../../../utils'
import {billingPaymentMethods, currencies, successBonusTypes, taxPercentageOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DetailsCard} from '../../../../components/DetailsCard'
import {EditContractSettingsDisclaimerModal} from '../../../../components/EditContractSettingsDisclaimerModal'

import {EditAddendumForm} from './partials'

export const AddendumSettings = ({addendum, isLoading, contract}) => {
    const [editMode, setEditMode] = useState(false)

    const addendumGeneralInfoRows = (addendum) => [
        {
            label: 'Contract',
            value: addendum.contract.name
        },
        {label: 'Obiect addendum', value: addendum.legal_object},
        {
            label: 'Data semnării',
            value: addendum.signed_date ? lightFormat(new Date(addendum.signed_date), 'dd/MM/yyyy') : ''
        },
        {
            label: 'Data producere efente',
            value: addendum.start_date ? lightFormat(new Date(addendum.start_date), 'dd/MM/yyyy') : ''
        },
        {
            label: 'Data încetare',
            value: addendum.end_date ? lightFormat(new Date(addendum.end_date), 'dd/MM/yyyy') : ''
        },
        {
            label: 'Sumă de recuperat',
            value: addendum.amount_to_recover
                ? `${addendum.amount_to_recover} ${
                        currencies[upperSnakeCase(addendum.amount_to_recover_currency)].label
                    }`
                : ''
        },
        {
            label: 'Tip facturare',
            value: addendum.billing_payment_method
                ? billingPaymentMethods[upperSnakeCase(addendum.billing_payment_method)].label
                : ''
        },
        {
            label: 'Sumă',
            value: addendum.billing_amount
                ? `${addendum.billing_amount} ${
                        currencies[upperSnakeCase(addendum.billing_currency)].label
                    }`
                : ''
        },
        {
            label: 'Taxă adăugtă',
            value:
              taxPercentageOptions[parseInt(addendum.billing_tax_percentage)]?.label ||
             `${addendum.billing_tax_percentage}%`
        },
        {
            label: 'Recurent',
            value: !isNull(addendum.billing_recurring_months)
                ? `${addendum.billing_recurring_months} luni`
                : 'Nu'
        },
        {
            label: 'Onorariu succes',
            value:
              !isNull(addendum.billing_success_bonus_value) && !isNull(addendum.billing_success_bonus_type)
                  ? `${addendum.billing_success_bonus_value}${
                            addendum.billing_success_bonus_type === successBonusTypes.PERCENT.value
                                ? '%'
                                : ` ${currencies[upperSnakeCase(addendum.billing_success_bonus_currency)].label}`
                  }`
                  : ''
        },
        {label: 'Șablon', value: toString(addendum.template.name)}
    ]

    return (
        <div className="addendum-details-page-container">
            <EditContractSettingsDisclaimerModal />
            {!isEmpty(addendum) ? (
                <div className="addendum-info-cards-container">
                    {editMode ? (
                        <EditAddendumForm handleExitEditMode={() => setEditMode(false)} />
                    ) : (
                        <DetailsCard
                            title='Setări addendum'
                            rows={addendumGeneralInfoRows(addendum)}
                            headerButton={() => (
                                <Button
                                    title='Editează'
                                    onClick={() => setEditMode(true)}
                                    size='small'
                                />
                            )}
                            loading={isLoading}
                        />

                    )}
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    addendum: state.addendums.currentAddendum,
    contract: state.contracts.currentContract,
    isLoading: state.addendums.isLoading
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AddendumSettings)