import React, {useEffect, useState} from 'react'

import {groupBy, values} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {documentSeriesTypes} from '../../../../utils/types'
import {documentSeriesColumns} from './constants'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'

import {AddDocumentSeriesModal, DeleteDocumentSeriesModal, EditDocumentSeriesModal} from './partials'

import './DocumentSeries.scss'

export const Documents = ({documentSeries, isLoading, selectedEntityID, openModal, listAllDocumentSeries}) => {
    const [selectedSeriesType, setSelectedSeriesType] = useState(null)
    const [selectedDocumentSeries, setSelectedDocumentSeries] = useState(null)

    useEffect(() => { listAllDocumentSeries({entity_id: selectedEntityID}) }, [listAllDocumentSeries, selectedEntityID])

    return (
        <div className="page-info">
            <AddDocumentSeriesModal initialType={selectedSeriesType} />
            <EditDocumentSeriesModal selectedDocumentSeries={selectedDocumentSeries} />
            <DeleteDocumentSeriesModal selectedDocumentSeries={selectedDocumentSeries} />
            <div className="document-series-settings">
                <div className="document-series-tables-container">
                    <Datatable
                        title='Serii interne contracte'
                        data={values(documentSeries[documentSeriesTypes.CONTRACT_INTERNAL.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.CONTRACT_INTERNAL)
                                }}
                                color='secondary'
                            />
                        )}
                    />
                    <Datatable
                        title='Serii barou contracte'
                        data={values(documentSeries[documentSeriesTypes.CONTRACT_BAR.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.CONTRACT_BAR)
                                }}
                                color='secondary'
                            />
                        )}
                    />
                    <Datatable
                        title='Serii interne împuterniciri'
                        data={values(documentSeries[documentSeriesTypes.EMPOWERMENT_INTERNAL.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.EMPOWERMENT_INTERNAL)
                                }}
                                color='secondary'
                            />
                        )}
                    />
                    <Datatable
                        title='Serii barou împuterniciri'
                        data={values(documentSeries[documentSeriesTypes.EMPOWERMENT_BAR.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.EMPOWERMENT_BAR)
                                }}
                                color='secondary'
                            />
                        )}
                    />
                    <Datatable
                        title='Serii facturi fiscale'
                        data={values(documentSeries[documentSeriesTypes.FISCAL_INVOICE.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.FISCAL_INVOICE)
                                }}
                                color='secondary'
                            />
                        )}
                    />
                    <Datatable
                        title='Serii facturi proforme'
                        data={values(documentSeries[documentSeriesTypes.PROFORMA_INVOICE.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.PROFORMA_INVOICE)
                                }}
                                color='secondary'
                            />
                        )}
                    />
                    <Datatable
                        title='Serii chitanțe'
                        data={values(documentSeries[documentSeriesTypes.RECEIPT.value])}
                        columns={[
                            ...documentSeriesColumns,
                            {
                                Header: '',
                                accessor: 'id',
                                Cell: ({row: {original: docSeries}}) => (
                                    <DatatableRowButtons
                                        mainButtonTitle='Editează'
                                        onMainButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.EDIT_DOCUMENT_SERIES)
                                        }}
                                        onDeleteButtonClick={() => {
                                            setSelectedDocumentSeries(docSeries)
                                            openModal(modalTypes.DELETE_DOCUMENT_SERIES)
                                        }}
                                    />
                                )
                            }
                        ]}
                        headerButton={() => (
                            <Button
                                title='Adaugă serie'
                                onClick={() => {
                                    openModal(modalTypes.ADD_DOCUMENT_SERIES)
                                    setSelectedSeriesType(documentSeriesTypes.RECEIPT)
                                }}
                                color='secondary'
                            />
                        )}
                    />

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const documentSeries = groupBy(state.documentSeries.data, 'type')

    return {
        documentSeries: documentSeries,
        isLoading: state.documentSeries.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents)