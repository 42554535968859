// @ts-ignore
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'

import {FilterType} from '../../../../components/FilterForm/types'
import {isCompletedOptions} from './constants'
import _ from 'lodash'
import {buildFilter} from '../../../../components/FilterForm/utils'

export const TableListFilter = buildFilter({
    project_id: {
        filterType:FilterType.RESOURCE,
        label: 'Proiect',
        resource: RESOURCES_V1.projects,
        valueAccessor: 'id',
        labelAccessor: 'name'
    },
    is_completed: {
        filterType:FilterType.SELECT,
        label: 'Status',
        options: _.values(isCompletedOptions),
        defaultValue: isCompletedOptions.FALSE
    },
    search: {filterType:FilterType.STRING, label: 'Search', excludeFromForm: true},
    page: {filterType:FilterType.NUMBER, label: 'Page', excludeFromForm: true},
    page_size: {filterType:FilterType.NUMBER, label: 'Page Size', excludeFromForm: true, defaultValue: 5},
    owner_id: {
        filterType:FilterType.RESOURCE,
        label: '',
        resource: RESOURCES.entityProfiles,
        valueAccessor: 'id',
        labelAccessor: 'full_name',
        isClearable: true,
        excludeFromForm: true
    }
})