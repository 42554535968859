import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal} from './modals'

const {invoicePaymentDistributions: resource} = RESOURCES

function* handleCreateSuccess({payload: invoice}) {
    toast.success('Distribuirea a fost adaugată cu succes.')
    yield put(closeModal())
}

function* handleDestroyInvoiceSuccess() {
    toast.success('Distribuirea a fost ștearsă cu succes.')
    yield put(closeModal())
}

function* handleDestroyInvoiceFail() {
    toast.error('Am întâmpinat o eroare. Dacă eroare persistă te rugăm se ne contactezi!')

    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyInvoiceSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyInvoiceFail)
}
