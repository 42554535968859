import React, {useEffect, useMemo} from 'react'

import {useFormik} from 'formik'

import {formatISO} from 'date-fns'
import _, {camelCase, isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../redux/modals'

import {invoicesSchema} from '../../assets/validations'
import {getFieldOptions, toApiDateFormat} from '../../utils'
import {roundFixed} from '../../utils/math'
import {RON_CURRENCY_VALUE, ronCurrency} from '../../utils/constants'
import {taxPercentageOptions, unitOptions} from '../../utils/types'

import {AddDiscountModal} from '../../components/AddDiscountModal'
import {AddStornoModal} from '../../components/AddStornoModal'
import {Button} from '../../components/Button'
import {DatePicker} from '../../components/DatePicker'
import {ErrorsList} from '../../components/ErrorComponents'
import {Input} from '../../components/Input'
import {InvoiceEntriesSection} from '../../components/InvoiceEntriesSection'
import {InvoiceInfobox} from '../../components/InvoiceInfobox'
import {InvoiceTotalErrors} from '../../components/InvoiceTotalErrors'
import {PageLoader} from '../../components/PageLoader'
import {RequiredFieldsText} from '../../components/RequiredFieldsText'
import {Select} from '../../components/Select'

import {documentTemplateTypes} from './constants'

import './InvoiceEdit.scss'
import {Loader} from '../../components/Loader'

const InvoiceEdit = ({
    currentInvoice,
    isLoading,
    match: {params: {invoiceID}},
    retrieveInvoice,
    nonFieldErrors,
    fieldErrors,
    entityProfiles,
    listEntityProfiles,
    updateInvoice,
    selectedEntityID,
    invoicesOptions,
    getInvoicesOptions,
    listTaskTimeLogs,
    openModal
}) => {
    const isCurrentInvoiceLoaded = useMemo(() => {
        return (
            !_.isNil(currentInvoice) &&
            !_.isEmpty(currentInvoice) &&
            !_.isNil(invoicesOptions) &&
            !_.isEmpty(invoicesOptions)
        )
    }, [currentInvoice, invoicesOptions])

    const currencies = useMemo(() => {
        return getFieldOptions(invoicesOptions, 'currency')
    }, [invoicesOptions])

    const invoiceCurrency = useMemo(() => {
        if(isCurrentInvoiceLoaded) {
            return _.find(currencies, ['value', currentInvoice?.currency])
        }

        return undefined
    }, [currencies, currentInvoice?.currency, isCurrentInvoiceLoaded])

    const invoiceSeries = useMemo(() => {
        if(isCurrentInvoiceLoaded) {
            return {
                value: currentInvoice.series_id,
                label: `${currentInvoice.series_name} (${currentInvoice.series_number})`
            }
        }

        return undefined
    }, [currentInvoice.series_id, currentInvoice.series_name, currentInvoice.series_number, isCurrentInvoiceLoaded])

    const isCurrentInvoiceLocalDataLoaded = useMemo(() => {
        return !_.isNil(invoiceCurrency) && !_.isNil(invoiceSeries)
    }, [invoiceCurrency, invoiceSeries])

    useEffect(() => {
        if(!isCurrentInvoiceLoaded) {
            retrieveInvoice(invoiceID)
        }
    }, [invoiceID, isCurrentInvoiceLoaded, retrieveInvoice])

    useEffect(() => {
        if(_.isNil(invoicesOptions) || _.isEmpty(invoicesOptions)) {
            getInvoicesOptions()
        }
    }, [getInvoicesOptions, invoicesOptions])

    useEffect(() => {
        listEntityProfiles(selectedEntityID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntityID])

    const invoiceEntries = useMemo(() => {
        if(isCurrentInvoiceLoaded) {
            return currentInvoice?.entries?.map((entry) => {
                const priceRON = !_.isNil(entry.exchange_rate) ?
                    roundFixed(entry.exchange_rate * entry.price, 2) :
                    entry.price

                const taxOption = _.find(
                    taxPercentageOptions,
                    (taxOption) => parseInt(taxOption.value) === parseInt(entry.tax_percentage)
                )

                return {
                    name: entry.name,
                    unit: _.find(unitOptions, ['value', entry.unit]),
                    quantity: entry.quantity,
                    currency: _.find(currencies, ['value', entry.currency]),
                    tax: taxOption,
                    taxValue: entry.tax_value,
                    taxValueRON: entry.tax_value_RON,
                    price: entry.price,
                    priceRON: priceRON,
                    exchangeRate: entry.exchange_rate || '',
                    total: entry.quantity * entry.price,
                    totalRON: entry.quantity * priceRON
                }
            })
        }

        return []
    }, [currencies, currentInvoice?.entries, isCurrentInvoiceLoaded])

    const {
        setFieldValue,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isValid
    } = useFormik({
        initialValues: {
            issueDate: isCurrentInvoiceLoaded ? new Date(currentInvoice.issue_date) : new Date(),
            dueDate: isCurrentInvoiceLoaded ? new Date(currentInvoice.due_date) : new Date(),
            entries: invoiceEntries
        },
        enableReinitialize: true,
        validationSchema: invoicesSchema.edit,
        onSubmit: (values) => {
            const invoiceData = {
                issue_date: formatISO(values.issueDate, {representation: 'date'}),
                due_date: formatISO(values.dueDate, {representation: 'date'}),
                entries: values.entries.map((entry) => ({
                    name: entry.name,
                    storno_id: entry.stornoID,
                    unit: entry.unit.value,
                    quantity: entry.quantity,
                    tax_percentage: entry.tax.value,
                    tax_name: 'VAT',
                    tax_value: entry.taxValue,
                    tax_value_RON: entry.taxValueRON,
                    price: entry.price,
                    is_discount: entry.isDiscount,
                    currency: entry.currency.value,
                    exchange_rate: entry.exchangeRate
                })),
                total: invoiceTotal.totalWithoutTax,
                total_RON: invoiceTotal.totalWithoutTaxRON,
                total_VAT: invoiceTotal.tax,
                total_VAT_RON: invoiceTotal.taxRON,
                total_with_VAT: invoiceTotal.total,
                total_with_VAT_RON: invoiceTotal.totalRON
            }

            updateInvoice(invoiceData, invoiceID)
        }
    })

    const invoiceTotal = useMemo(() => {
        let totalWithoutTax = 0
        let totalWithoutTaxRON = 0

        let tax = 0
        let taxRON = 0

        if(currentInvoice?.currency === RON_CURRENCY_VALUE) {
            _.forEach(values.entries, (entry) => {
                const entryTotal = entry.quantity * entry.priceRON
                const entryTax = (entry.tax.value / 100) * entryTotal

                totalWithoutTax = totalWithoutTax + entryTotal
                totalWithoutTaxRON = totalWithoutTax

                tax = tax + entryTax
                taxRON = tax
            })
        } else {
            _.forEach(values.entries, (entry) => {
                const entryTotal = entry.quantity * entry.price
                const entryTax = (entry.tax.value / 100) * entryTotal

                const entryTotalRON = entry.quantity * entry.priceRON
                const entryTaxRON = (entry.tax.value / 100) * entryTotalRON

                totalWithoutTax = totalWithoutTax + entryTotal
                tax = tax + entryTax

                totalWithoutTaxRON = totalWithoutTaxRON + entryTotalRON
                taxRON = taxRON + entryTaxRON
            })
        }

        return {
            totalWithoutTax: roundFixed(totalWithoutTax, 2),
            totalWithoutTaxRON: roundFixed(totalWithoutTaxRON, 2),
            tax: roundFixed(tax, 2),
            taxRON: roundFixed(taxRON, 2),
            total: roundFixed(totalWithoutTax + tax, 2),
            totalRON: roundFixed(totalWithoutTaxRON + taxRON, 2)
        }
    }, [currentInvoice?.currency, values.entries])

    const hasReportInterval = useMemo(() => {
        return !_.isNil(currentInvoice.reports_start) && !_.isNil(currentInvoice.reports_stop)
    }, [currentInvoice.reports_start, currentInvoice.reports_stop])

    const selectedReportInterval = useMemo(() => {
        if(hasReportInterval) {
            return {
                start: values.activityReportStart,
                stop: values.activityReportStop
            }
        }

        return undefined
    }, [hasReportInterval, values.activityReportStart, values.activityReportStop])

    const isUsingVat = useMemo(() => {
        const selectedEntity = entityProfiles.filter(({entity}) => entity.id === selectedEntityID)[0]?.entity
        return selectedEntity?.is_vat_payer
    }, [entityProfiles, selectedEntityID])

    useEffect(() => {
        if(hasReportInterval && !_.isNil(currentInvoice.contract)) {
            const timeLogsParams = {
                entity_id: selectedEntityID,
                contract_id: currentInvoice.contract_id,
                date__gte: toApiDateFormat(new Date(currentInvoice.reports_start)),
                date__lte: toApiDateFormat(new Date(currentInvoice.reports_stop))
            }

            listTaskTimeLogs(timeLogsParams)
        }
    }, [
        currentInvoice.contract,
        currentInvoice.contract_id,
        currentInvoice.reports_start,
        currentInvoice.reports_stop,
        hasReportInterval,
        listTaskTimeLogs,
        selectedEntityID
    ])

    useEffect(() => {
        if (fieldErrors) {
            const elementName = camelCase(Object.keys(fieldErrors)[0])
            const element = document.getElementsByName(elementName)[0]

            if (element) {
                element.parentElement.parentElement.scrollIntoView({behavior: 'smooth'})
            }
        }
    }, [fieldErrors])

    if(isCurrentInvoiceLoaded && isCurrentInvoiceLocalDataLoaded) {
        return (
            <div className='resource-details-content'>
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <form className='edit-invoice-form-container'>
                        <div className="edit-invoice-form-row">
                            <Select
                                label='Client*'
                                value={currentInvoice.client}
                                options={_.values(currentInvoice.client)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                disabled
                                fullWidth
                            />
                            <Select
                                label='Proiect*'
                                value={currentInvoice.project}
                                options={_.values(currentInvoice)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                disabled
                                fullWidth
                            />
                            <Select
                                label='Contract'
                                value={currentInvoice.contract}
                                options={_.values(currentInvoice.contract)}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                disabled
                                fullWidth
                            />
                        </div>
                        <div className="edit-invoice-form-row">
                            <Select
                                label='Șablon factură*'
                                value={currentInvoice.template}
                                options={_.values(currentInvoice.template)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled
                                fullWidth
                            />
                            <Select
                                label='Serie factură*'
                                value={invoiceSeries}
                                options={_.values(invoiceSeries)}
                                disabled
                                fullWidth
                            />
                        </div>
                        <div className="edit-invoice-form-row">
                            <DatePicker
                                label='Data emiterii*'
                                value={values.issueDate}
                                onChange={(date) => _.isNull(date) ?
                                    setFieldValue('issueDate', date) :
                                    setFieldValue('issueDate', new Date(date))
                                }
                                onBlur={handleBlur('issueDate')}
                                name='issueDate'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.issueDate}
                                fullWidth
                            />
                            <DatePicker
                                label='Data scadență*'
                                value={values.dueDate}
                                onChange={(date) => _.isNull(date) ?
                                    setFieldValue('dueDate', date) :
                                    setFieldValue('dueDate', new Date(date))
                                }
                                onBlur={handleBlur('dueDate')}
                                name='dueDate'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.dueDate}
                                fullWidth
                            />
                        </div>
                        <div className="edit-invoice-form-row">
                            <Select
                                label='Moneda*'
                                value={invoiceCurrency}
                                options={_.values(invoiceCurrency)}
                                disabled
                                fullWidth
                            />
                            {invoiceCurrency.value !== RON_CURRENCY_VALUE && (
                                <Input label='Curs Valutar*' value={currentInvoice.exchange_rate} disabled fullWidth/>
                            )}
                        </div>
                        <div className="edit-invoice-form-row">
                            <Select
                                label='Șablon raport activitate*'
                                value={currentInvoice.reports_template}
                                options={_.values(currentInvoice.reports_template)}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                disabled
                                fullWidth
                            />
                            <div className='interval-picker-container'>
                                <div className='interval-datepickers-container'>
                                    <DatePicker
                                        label='Raport activitate start*'
                                        value={!_.isNil(currentInvoice.reports_start) ?
                                            new Date(currentInvoice.reports_start) :
                                            null
                                        }
                                        disabled
                                        fullWidth
                                    />
                                    <DatePicker
                                        label='Raport activitate stop*'
                                        value={!_.isNil(currentInvoice.reports_stop) ?
                                            new Date(currentInvoice.reports_stop) :
                                            null
                                        }
                                        disabled
                                        fullWidth
                                    />
                                </div>
                            </div>
                        </div>
                        <RequiredFieldsText />
                        <InvoiceInfobox
                            interval={selectedReportInterval}
                            contract={values.contractId}
                            project={values.projectId}
                        />
                        {!isEmpty(entityProfiles) ? <InvoiceEntriesSection
                            entries={values.entries}
                            invoiceContract={values.contractId}
                            invoiceCurrency={invoiceCurrency}
                            invoiceExchangeRate={currentInvoice.exchange_rate}
                            handleAddEntry={(entry) => setFieldValue('entries', [...values.entries, entry])}
                            handleEditEntry={(entry, entryIdx) => {
                                const newEntries = _.cloneDeep(values.entries)
                                newEntries[entryIdx] = entry

                                setFieldValue('entries', newEntries)
                            }}
                            handleRemoveEntry={(entryToRemove) => {
                                const entriesToRemove = _.filter(values.entries, (entry) => (
                                    entry === entryToRemove ||
                                    (entry.isDiscount && entry.connectedEntry === entryToRemove)
                                ))

                                setFieldValue('entries', _.difference(values.entries, entriesToRemove))
                            }}
                            isUsingVat={isUsingVat}
                        /> : <Loader />}
                        <div className="totals-section">
                            <div className="buttons-container">
                                <Button
                                    title='Adaugă storno'
                                    onClick={() => openModal(modalTypes.ADD_STORNO)}
                                    disabled={_.isNil(values.issueDate)}
                                    variant='outlined'
                                    color='gray'
                                    fullWidth
                                />
                                <AddStornoModal
                                    handleAddStorno={(stornoEntries) => {
                                        const newEntries = _.cloneDeep(values.entries)
                                        newEntries.push(...stornoEntries)

                                        setFieldValue('entries', newEntries)
                                    }}
                                    invoiceIssueDate={values.issueDate}
                                    invoiceClient={currentInvoice.client}
                                    invoiceCurrency={invoiceCurrency}
                                />
                                <Button
                                    title='Adaugă discount'
                                    onClick={() => openModal(modalTypes.ADD_DISCOUNT)}
                                    disabled={_.isEmpty(values.entries)}
                                    variant='outlined'
                                    color='gray'
                                    fullWidth
                                />
                                <AddDiscountModal
                                    entries={values.entries}
                                    invoiceTotal={invoiceTotal}
                                    invoiceCurrency={invoiceCurrency}
                                    invoiceExchangeRate={currentInvoice.exchange_rate}
                                    handleAddDiscount={(discountEntry, discountIndex) => {
                                        const newEntries = _.cloneDeep(values.entries)
                                        newEntries.splice(discountIndex, 0, discountEntry)

                                        setFieldValue('entries', newEntries)
                                    }}
                                />
                            </div>
                            <div className="total-texts-container">
                                {isUsingVat && <>
                                    <p className="total-text">
                                        Total fără TVA:{' '}
                                        <span>{invoiceTotal.totalWithoutTax} {invoiceCurrency.label}</span>
                                        {invoiceCurrency.value !== RON_CURRENCY_VALUE && (
                                            <span>{' '}({invoiceTotal.totalWithoutTaxRON} {ronCurrency.label})</span>
                                        )}
                                    </p>
                                    <p className="total-text">
                                        Total TVA:{' '}
                                        <span>{invoiceTotal.tax} {invoiceCurrency.label}</span>
                                        {invoiceCurrency.value !== RON_CURRENCY_VALUE && (
                                            <span>{' '}({invoiceTotal.taxRON} {ronCurrency.label})</span>
                                        )}
                                    </p>
                                </>}
                                <p className="total-text">
                                    TOTAL:{' '}
                                    <span>{invoiceTotal.total} {invoiceCurrency.label}</span>
                                    {invoiceCurrency.value !== RON_CURRENCY_VALUE && (
                                        <span>{' '}({invoiceTotal.totalRON} {ronCurrency.label})</span>
                                    )}
                                </p>
                            </div>
                        </div>
                        <InvoiceTotalErrors />
                        <Button
                            title='Salvează modificări factură'
                            onClick={handleSubmit}
                            disabled={!isValid}
                            loading={isLoading}
                            color='secondary'
                            type='submit'
                            fullWidth
                        />
                    </form>
                </>
            </div>
        )
    }

    if(!isCurrentInvoiceLoaded && !isCurrentInvoiceLocalDataLoaded && isLoading) {
        return <PageLoader />
    }
}

const mapStateToProps = (state) => ({
    currentInvoice: state.invoices.currentInvoice,
    isLoading: state.invoices.isLoading,
    nonFieldErrors: state.invoices.nonFieldErrors,
    fieldErrors: state.invoices.fieldErrors,
    invoicesOptions: state.invoices.options,
    selectedEntityID: state.localConfigs.selectedEntityID,
    exchangeRate: state.exchangeRate.data,
    entityProfiles: _.values(state.entityProfiles.data),
    reportTemplates: _.filter(
        state.documentTemplates.data,
        ['type', _.find(documentTemplateTypes, ['value', 'document_reports']).value]
    ),
    isLoadingDocumentTemplates: state.documentTemplates.isLoading,
    taskTimeLogs: _.values(state.taskTimeLogs.data),
    isLoadingTaskTimeLogs: state.taskTimeLogs.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    retrieveInvoice: (invoiceID) => dispatch(RESOURCES.invoices.retrieve(invoiceID)),
    updateInvoice: (values, invoiceID) => dispatch(RESOURCES.invoices.update(values, invoiceID)),
    getInvoicesOptions: () => dispatch(RESOURCES.invoices.getOptions()),
    listTaskTimeLogs: (params) => RESOURCES.taskTimeLogs.list(params),
    listEntityProfiles: (entityID) => dispatch(RESOURCES.entityProfiles.listAll({entity_id:  entityID})),
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceEdit)