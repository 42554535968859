import React, {useEffect} from 'react'
import {ArrowLeftIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import {isEmpty, get, values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {Button} from '../../../../components/Button'
import {PageLoader} from '../../../../components/PageLoader'

import './ExpenseDetails.scss'
import {Datatable} from '../../../../components/Datatable'
import {columns} from '../../partials/ExpensePaymentsList/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {DatatableRowButtons} from '../../../../components/DatatableRowButtons'
import {push} from 'connected-react-router'
import {AddExpenseAllocationModal} from '../../../../components/AddExpenseAllocationModal'
import {modalTypes, openModal} from '../../../../redux/modals'

const ExpenseDetails = ({
    match: {params: {expenseID}},
    expense,
    isLoading,
    expensePayments,
    navigate,
    listExpensePayments,
    retrieveExpense,
    selectedEntityID,
    modalIsOpen,
    openModal,
    history: {goBack}
}) => {

    useEffect(() => {
        if(!modalIsOpen) {
            retrieveExpense(expenseID)
            listExpensePayments(selectedEntityID, null, {expense_id: expenseID})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen])

    const fileName = get(expense.file_meta, 'name')

    return (
        <div className='resource-details-content'>
            <div className='back-button-container'>
                <Button
                    title='Mergi înapoi'
                    onClick={goBack}
                    size='small'
                    color='gray'
                    variant='outlined'
                    icon={() => <ArrowLeftIcon />}
                    iconLeft
                />
            </div>
            {!isEmpty(expense) ? (
                <>
                    <div className="header-container">
                        <div className="header-resource-title-container">
                            <p className='header-resource-title'>{expense.title}</p>
                        </div>
                        {expense.description && <p className="task-description">{expense.description}</p>}
                    </div>
                    <div className="content-container expense-details">
                        <div className="task-info-card">
                            <div className="task-info-card-header">
                                <p className='header-title'>Informații cheltuială</p>
                            </div>
                            <div className="task-info-card-content">
                                <div className="task-info-card-row">
                                    <p className="label">Data</p>
                                    <p className='value'>{lightFormat(new Date(expense.date), 'dd/MM/yyyy')}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Proiect</p>
                                    <p className='value'>{expense.project?.name || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Contract</p>
                                    <p className='value'>{expense.contract?.name || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Sumă</p>
                                    <p className='value'>{expense.amount} {expense.currency}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Curs Valutar</p>
                                    <p className='value'>{expense.exchange_rate || '-'}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Sumă RON</p>
                                    <p className='value'>{expense.amount_RON}</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Document</p>
                                    <p className='value'>
                                        {expense.file_meta ? (
                                            <a
                                                rel='noreferrer'
                                                target='_blank'
                                                href={expense.file_meta.url_public}>{fileName}
                                            </a>
                                        ) : '-'
                                        }</p>
                                </div>
                                <div className="task-info-card-row">
                                    <p className="label">Informații cheltuiala</p>
                                    <p className='value'>{
                                        expense.description
                                            ? <p className="task-description">{expense.description}</p> : '-'
                                    }</p>
                                </div>

                            </div>
                        </div>
                        <div className="task-activities-container">
                            <Datatable
                                title='Încasări'
                                headerButton={() => expense.uncovered_allocation && <Button
                                    title='Adaugă legatura'
                                    color='secondary'
                                    onClick={() => openModal(modalTypes.ADD_EXPENSE_ALLOCATION)}
                                />}
                                loading={isLoading}
                                data={!isLoading ? expensePayments : []}
                                columns={[
                                    ...columns,
                                    {
                                        Header: 'Acțiuni',
                                        accessor: 'id',
                                        Cell: ({value: expensePaymentID, row: {original: expensePayment}}) => (
                                            <DatatableRowButtons
                                                mainButtonTitle='Vezi'
                                                onMainButtonClick={() => {
                                                    navigate(`/expense-payments/${expensePaymentID}`)
                                                }}
                                            />
                                        )
                                    }
                                ]}
                            />
                        </div>
                    </div>
                    <AddExpenseAllocationModal
                        expenseID={expenseID}
                        allocatedExpensePayments={expensePayments}
                        expenseAmountRON={expense.amount_RON}
                        projectID={expense.project_id}
                    />
                </>
            ) : isLoading ? (
                <PageLoader />
            ) : null}
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const {match: {params: {expenseID}}} = props

    return {
        modalIsOpen: state.modals.type === modalTypes.ADD_EXPENSE_ALLOCATION,
        expense: get(state.expenses.data, expenseID, {}),
        expensePayments: values(state.expensePayments.data),
        isLoading: state.expenses.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    retrieveExpense: (expenseID) => dispatch(RESOURCES.expenses.retrieve(expenseID)),
    navigate: (route) => dispatch(push(route)),
    listExpensePayments: (entityID, search, filters, page) => dispatch(
        RESOURCES.expensePayments.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetails)