import {toast} from 'react-toastify'
import {takeEvery, put} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {modalTypes, openModal} from './modals'

const {tickets: resource} = RESOURCES

function* handleCreateTicketSuccess() {
    yield put(openModal(modalTypes.TICKET_CREATED))
    toast.success('Ticketul a fost creat cu succes!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateTicketSuccess)
}
