import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'

import {values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {modalTypes} from '../../../../redux/modals'

import {legalObjectsSchema} from '../../../../assets/validations'

import {Modal} from '../../../Modal'
import {Input} from '../../../Input'
import {Select} from '../../../Select'
import {Button} from '../../../Button'
import {RequiredFieldsText} from '../../../RequiredFieldsText'
import {ErrorsList} from '../../../ErrorComponents'

export const CreateLegalObjectModal = ({open,
    categoryValue,
    legalObjectCategories,
    isLoadingCategories,
    onChangeCategory,
    initialObjectName,
    selectedEntityID,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    createLegalObject,
    listLegalObjectCategories
}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {listLegalObjectCategories()}, [])

    return (
        <Modal open={open} title='Crează un obiect legal nou' >
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    name: initialObjectName,
                    legalObjectCategoryId: categoryValue
                }}
                validationSchema={legalObjectsSchema}
                onSubmit={(values) =>
                    createLegalObject({
                        entity_id: selectedEntityID,
                        legal_object_category_id: values.legalObjectCategoryId.value,
                        name: values.name
                    })}
            >
                {({handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched}) => (
                    <Form className='space-y-6'>
                        <Select
                            label='Categorie obiect legal*'
                            value={categoryValue}
                            options={legalObjectCategories}
                            onChange={(e) => {
                                onChangeCategory(e)
                                setFieldValue('legalObjectCategoryId', e)
                            }}
                            onBlur={handleBlur('legalObjectCategoryId')}
                            name='legalObjectCategoryId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.legalObjectCategoryId}
                            disabled={isLoadingCategories}
                            fullWidth
                        />
                        <Input
                            label='Nume obiect*'
                            value={values.name}
                            onChange={handleChange('name')}
                            onBlur={handleBlur('name')}
                            name='name'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.name}
                            fullWidth
                        />
                        <RequiredFieldsText />
                        <Button
                            title='Crează obiect legal'
                            loading={isLoading}
                            type='submit'
                            onClick={handleSubmit}
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const legalObjectCategories = values(state.legalObjectCategories.data).map(
        (category) => ({label: category.name, value: category.id})
    )

    return {
        open: state.modals.type === modalTypes.CREATE_LEGAL_OBJECT,
        selectedEntityID: state.localConfigs.selectedEntityID,
        legalObjectCategories: legalObjectCategories,
        isLoadingCategories: state.legalObjectCategories.isLoading,
        fieldErrors: state.legalObjects.fieldErrors,
        nonFieldErrors: state.legalObjects.nonFieldErrors,
        isLoading: state.legalObjects.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    createLegalObject: (values) => dispatch(RESOURCES.legalObjects.create(values)),
    listLegalObjectCategories: () => dispatch(RESOURCES.legalObjectCategories.list())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateLegalObjectModal)