import React, {useCallback, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {ArrowDownTrayIcon} from '@heroicons/react/24/outline'

import {lightFormat} from 'date-fns'
import fileDownload from 'js-file-download'
import {isEmpty, isNil, toNumber} from 'lodash'
import _ from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {CANCELED_STATE} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DeleteInvoiceButton} from '../../../../components/DeleteInvoiceButton'
import {DeleteInvoiceModal} from '../../../../components/DeleteInvoiceModal'
import {Loader} from '../../../../components/Loader'
import {Toggle} from '../../../../components/Toggle'

import './InvoicesList.scss'
import InvoiceExternalSyncIcon from '../../../InvoiceDetails/partials/InvoiceExternalSyncIcon/InvoiceExternalSyncIcon'
import {
    useCancelSmartBillInvoice, useMutationResourceUpdate
} from '../../../../mutations/rest'
import {externalSyncActions, externalSyncStates} from '../../constants'
import {InvoiceExternalSyncResolveModal} from '../../../../components/InvoiceExternalSyncResolveModal'
import {TableLink} from '../../../../components/TableLink'
import {useQueryResourceList} from '../../../../queries/rest'
import {tableListFilter} from './filters'
import {keepPreviousData} from '@tanstack/react-query'
import {useQueryParams} from 'use-query-params'
import {FilterForm} from '../../../../components/FilterForm'

export const InvoicesList = ({
    selectedEntityID,
    usersPreferences,
    cancelInvoice,
    openDeleteInvoiceModal,
    openInvoiceExternalSyncResolveModal,
    openSyncInvoiceModal
}) => {
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [isPendingExport, setIsPendingExport] = useState(false)
    const [query, setQuery] = useQueryParams(tableListFilter.mapping)

    const onSmartBillFinished = useCallback((data, action) => {
        const {errors, status} = data

        if (_.includes([403, 418], status)) {
            // SmartBill is not enabled for this entity. Missing API key or action not needed.
            return
        }

        if (status >= 400) {
            const errorMessage = _.get(
                errors, 'non_field_errors.0.message', 'A apărut o eroare la sincronizarea cu SmartBill'
            )
            openSyncInvoiceModal(selectedInvoice.id, action, errorMessage)
        } else {
            toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
        }
    }, [selectedInvoice, openSyncInvoiceModal])

    const {mutateAsync: cancelSmartBillInvoice} = useCancelSmartBillInvoice({
        onSuccess: (data, variables, context) => {onSmartBillFinished(data, externalSyncActions.CANCEL)}
    })

    const updateInvoiceMutation = useMutationResourceUpdate(RESOURCES.invoices)

    const getCanceledInvoiceClassName = useCallback((invoice) => {
        if(invoice.state === 'canceled') {
            return 'invoice-canceled'
        }

        return ''
    }, [])

    const {
        data: invoicesReportsResponse,
        isFetching: invoicesReportsFetching,
        refetch: refetchReports
    } = useQueryResourceList(
        RESOURCES.invoicesReports,
        {
            entity_id: selectedEntityID,
            ...tableListFilter.getAPIFilters(query),
            ...(query.onlyNonCollected && {state: 'unpaid'})
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 5
        }
    )

    const {data: invoicesResponse, isFetching: invoicesFetching, refetch: refetchInvoices} = useQueryResourceList(
        RESOURCES.invoices,
        {
            entity_id: selectedEntityID,
            ...tableListFilter.getAPIFilters(query),
            ...(query.onlyNonCollected && {state: 'unpaid'})
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 5
        }
    )

    const handleDeleteInvoice = useCallback(async (invoice) => {
        setSelectedInvoice(invoice)
        if(invoice.deletion_prevented) {
            const {status: cancelHTTPStatus} = await cancelSmartBillInvoice(invoice)
            if(cancelHTTPStatus === 400) {
                return
            }
            // cancelInvoice(invoice.id)
            await updateInvoiceMutation.mutateAsync({state: CANCELED_STATE, id: invoice.id})
            await refetchInvoices()
            await refetchReports()
        } else {
            openDeleteInvoiceModal()
        }
        // eslint-disable-next-line
    }, [cancelInvoice, openDeleteInvoiceModal])

    const invoices = invoicesResponse?.data?.results || []
    const reportsReady = (
        !isNil(invoicesReportsResponse) && !isEmpty(invoicesReportsResponse) && !invoicesReportsFetching
    )

    const invoicesColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'issue_date',
                Cell: ({value: issueDate, row:{original: invoice}}) => (
                    <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                        {issueDate ? lightFormat(new Date(issueDate), 'dd/MM/yyyy') : '-'}
                    </span>
                )
            },
            {
                Header: 'Serie și număr',
                accessor: 'series_id',
                Cell: ({_value, row: {original: invoice}}) => (
                    <TableLink to={`/invoices/${invoice.id}`}>
                        <span
                            className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {invoice.series_name} {invoice.series_number}
                        </span>
                    </TableLink>
                )
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({value: client, row: {original: invoice}}) => (
                    <TableLink to={`/clients/${client.id}`}>
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {client ? client.name : '-'}
                        </span>
                    </TableLink>
                ),
                style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project, row: {original: invoice}}) => (
                    <TableLink to={`/projects/${project.id}`}>
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {project ? project.name : '-'}
                        </span>
                    </TableLink>
                ),
                style: {maxWidth: '15vw', overflow: 'hidden', textOverflow: 'ellipsis'}
            },
            {
                Header: 'Suma',
                accessor: 'total_with_VAT',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return (
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {total} {invoice.currency}
                        </span>
                    )
                }
            },
            {
                Header: 'De încasat',
                accessor: 'total_unpaid',
                Cell: ({value: total, row: {original: invoice}}) => {
                    return (
                        <span className={`invoice-cell ${getCanceledInvoiceClassName(invoice)}`}>
                            {total} {invoice.currency}
                        </span>
                    )
                }
            },

            ...usersPreferences.has_smartbill_integration ? [{
                Header: 'SmartBill',
                accessor: 'sync_state_smartbill',
                Cell: ({value, row: {original: invoice}}) => (
                    <span onClick={() => {
                        setSelectedInvoice(invoice)

                        if (value === externalSyncStates.MANUAL_NEEDS_UPDATE) {
                            openInvoiceExternalSyncResolveModal()
                        }
                    }}>
                        <InvoiceExternalSyncIcon
                            state={value}
                        />
                    </span>
                ),
                style: {
                    width: '55px'
                }
            }] : [],
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({value: invoiceID, row: {original: invoice}}) => (
                    <div className='datatable-row-buttons-container'>
                        <Link to={`/invoices/${invoiceID}`}>
                            <Button title='Vezi' size='small' />
                        </Link>
                        <DeleteInvoiceButton
                            invoice={invoice}
                            onClick={() => handleDeleteInvoice(invoice)}
                        />
                    </div>
                )
            }
        ]
    }, [usersPreferences, getCanceledInvoiceClassName, openInvoiceExternalSyncResolveModal, handleDeleteInvoice])

    return (
        <div className="page-info">
            <Datatable
                title='Facturi fiscale'
                data={invoices}
                loading={invoicesFetching || updateInvoiceMutation.isPending}
                columns={invoicesColumns}
                customHeader={() =>
                    <div className='custom-invoices-header'>
                        <Toggle
                            checked={query.onlyNonCollected}
                            label='Arată doar facturile neîncasate'
                            onChange={() => {
                                setQuery({
                                    ...query,
                                    onlyNonCollected: !query.onlyNonCollected
                                })
                            }}
                        />
                        <div className="total-filtered-container">
                            {reportsReady ? (
                                <>
                                    <div>
                                        Total sume facturate:
                                        <span className="total-sum">
                                            {invoicesReportsResponse.data.RON.total_with_VAT} (RON)
                                        </span>
                                        {toNumber(invoicesReportsResponse.data.EUR.total_with_VAT) > 0 && (
                                            <>
                                                , {}
                                                <span
                                                    className="total-sum">
                                                    {invoicesReportsResponse.data.EUR.total_with_VAT} (EUR)
                                                </span>
                                            </>
                                        )}

                                    </div>
                                    <div>
                                        Total sume neîncasate:
                                        <span className="total-sum">
                                            {invoicesReportsResponse.data.RON.total_unpaid} (RON)
                                        </span>
                                        {toNumber(invoicesReportsResponse.data.EUR.total_with_VAT) > 0 && (
                                            <>
                                                , {}
                                                <span
                                                    className="total-sum">
                                                    {invoicesReportsResponse.data.EUR.total_unpaid} (EUR)
                                                </span>
                                            </>
                                        )}

                                    </div>
                                </>
                            ) : (
                                <Loader size='small'/>
                            )}
                        </div>
                    </div>
                }
                nextPage={invoicesResponse?.data?.pages.next}
                previousPage={invoicesResponse?.data?.pages.previous}
                currentPage={invoicesResponse?.data?.page}
                totalPages={invoicesResponse?.data?.number_of_pages}
                onChangePage={(page) => {
                    setQuery({
                        ...query,
                        page: page
                    })
                }}
                filterable
                filters={tableListFilter.getUIFilters(query)}
                filtersForm={() => <FilterForm filterClass={tableListFilter}/>}
                handleRemoveFilter={(filter) => {
                    setQuery({
                        ...query,
                        [filter.key]: null
                    })
                }}
                searchable
                searchPlaceholder='Caută facturi'
                searchDefaultValue={query.search}
                onSearch={(event) => setQuery({
                    ...query,
                    search: event.target.value
                })}
                searchContainerButton={() => (
                    <Button
                        loading={isPendingExport}
                        disabled={isEmpty(invoices) || isPendingExport}
                        title='Descarcă CSV'
                        icon={() => <ArrowDownTrayIcon/>}
                        onClick={async () => {
                            setIsPendingExport(true)

                            const response = await performRequest(RESOURCES.invoices.listCSV({
                                entity_id: selectedEntityID,
                                ...tableListFilter.getAPIFilters(query),
                                ...(query.onlyNonCollected && {state: 'unpaid'})
                            }))
                            setIsPendingExport(false)

                            if (response.status === 200) {
                                toast.success('Fișierul CSV a fost generat.')
                                fileDownload(response.data, 'export-facturi.csv')
                            } else {
                                toast.error('A apărut o eroare la generarea raportului.')
                            }
                        }}
                    />
                )}
            />
            <DeleteInvoiceModal
                selectedInvoice={selectedInvoice}
                reloadData={async () => {
                    await refetchInvoices()
                    await refetchReports()
                }}/>
            <InvoiceExternalSyncResolveModal
                selectedInvoice={selectedInvoice}
                reloadData={async () => {
                    await refetchInvoices()
                }}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    usersPreferences: state.usersPreferences.data
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteInvoiceModal: () => dispatch(openModal(modalTypes.DELETE_INVOICE)),
    openInvoiceExternalSyncResolveModal: () => dispatch(openModal(modalTypes.INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL)),
    openSyncInvoiceModal: (invoiceID, action, errorMessage) => dispatch(
        openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoiceID, action, errorMessage})
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList)
