import {buildFilter} from '../../../../components/FilterForm/utils'
import {FilterType} from '../../../../components/FilterForm/types'
// @ts-ignore
import {RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

export const tableListFilter = buildFilter({
    project_id: {
        filterType:FilterType.RESOURCE,
        label: 'Proiect',
        resource: RESOURCES_V1.projects,
        valueAccessor: 'id',
        labelAccessor: 'name'
    },
    client_id: {
        filterType: FilterType.RESOURCE,
        label: 'Client',
        resource: RESOURCES.clients,
        valueAccessor: 'id',
        labelAccessor: 'name'
    },
    state: {
        filterType: FilterType.SELECT,
        label: 'Stare',
        options: [
            {value: 'issued', label: 'Emis'},
            {value: 'canceled', label: 'Anulat'}
        ]
    },
    invoicing_state: {
        filterType: FilterType.SELECT,
        label: 'Stare Facturare',
        options: [
            {value: 'invoiced', label: 'Facturat'},
            {value: 'to_be_invoiced', label: 'De facturat'}
        ]
    },
    created__gte: {
        filterType: FilterType.DATE,
        label: 'Data început'
    },
    created__lte: {
        filterType: FilterType.DATE,
        label: 'Data sfârșit'
    }
})
