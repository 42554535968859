import React from 'react'

import {isEmpty} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {closeModal, modalTypes} from '../../redux/modals'

import {Modal} from '../Modal'
import {Button} from '../Button'

import '../../assets/scss/DeleteModals.scss'

function DeleteExpenseModal({open, selectedExpense, isLoading, deleteExpense, closeModal}) {
    return (
        <Modal open={open && !isEmpty(selectedExpense)} title='Șterge cheltuială'>
            <div className='delete-modal'>
                <p className='message'>Ești sigur că vrei să ștergi această cheltuială?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteExpense(selectedExpense)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_EXPENSE,
    isLoading: state.expenses.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteExpense: (selectedExpense) => dispatch(RESOURCES.expenses.destroy(selectedExpense))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteExpenseModal)
