
import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {isNil} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {columns} from './constants'
import {CANCELED_STATE} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'

import {ClientDetailsDisclaimer} from '../ClientDetailsDisclaimer'

import './Contracts.scss'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {useDebouncedState} from '../../../../utils/hooks'
import {useQueryParams} from 'use-query-params'
import {FilterForm} from '../../../../components/FilterForm'
import {tableListFilter} from './filters'

export const Contracts = ({
    selectedEntityID,
    match: {params: {clientID}}
}) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('')
    const [page, setPage] = useState(1)

    const [query, setQuery] = useQueryParams(tableListFilter.mapping)

    const {data: contractsResponse, isFetching: contractsFetching} = useQueryResourceList(
        RESOURCES.contracts,
        {
            entity_id: selectedEntityID,
            client_id: clientID,
            page: page,
            search: searchFilter,
            ...tableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 5
        }
    )

    return (
        <div className='page-client-show client-contracts-list'>
            <ClientDetailsDisclaimer resource='contractele' />
            <Datatable
                title='Contracte'
                data={contractsResponse?.data?.results || []}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: contractID, row: {original: contract}}) => (
                            <div className='flex gap-2 items-center'>
                                <Link to={`/contracts/${contractID}/preview`}>
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Dropdown
                                    title='Descarcă'
                                    disabled={isNil(contract.last_version?.file) && isNil(contract.signed_file)}
                                    items={[
                                        {
                                            customContent: () => contract.last_version?.file ? (
                                                <a
                                                    href={contract.last_version.file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă
                                                </a>
                                            ) : <React.Fragment />
                                        },
                                        {
                                            customContent: () => contract.signed_file ? (
                                                <a
                                                    href={contract.signed_file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă fișier semnat
                                                </a>
                                            ) : <React.Fragment />
                                        }
                                    ]}
                                    variant='text'
                                    color='secondary'
                                    size='small'
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={contractsFetching}
                previousPage={contractsResponse?.data?.pages?.previous}
                currentPage={page}
                nextPage={contractsResponse?.data?.pages?.next}
                totalPages={contractsResponse?.data?.number_of_pages}
                onChangePage={(page) => {
                    setPage(page)
                }}
                filterable
                filters={tableListFilter.getUIFilters(query)}
                filtersForm={() => <FilterForm filterClass={tableListFilter} />}
                handleRemoveFilter={(filter) => {
                    setQuery({
                        ...query,
                        [filter.key]: null
                    })
                }}
                searchable
                searchPlaceholder='Caută contracte'
                onSearch={(event) => {
                    setSearchFilter(event.target.value)
                }}
                rowProps={row => ({
                    className: row.original.state === CANCELED_STATE ? 'strikeout-row' : null
                })}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(Contracts)
