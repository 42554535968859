import {put, takeEvery} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal} from './modals'

const {subscriptions: resource} = RESOURCES

function* handleDestroyBpiSubscriptionSuccess() {
    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.destroy.success, handleDestroyBpiSubscriptionSuccess)
}
