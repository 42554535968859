import {get, has, isArray, isNull, isUndefined, join, keys, map} from 'lodash'

import {PencilIcon, PlusIcon, XMarkIcon} from '@heroicons/react/24/solid'

import {ValueAddedOrDeleted, ValueChanged} from './partials'
import _ from 'lodash'
import {DocumentArrowUpIcon, HandRaisedIcon} from '@heroicons/react/24/outline'

export const actionTypes = {
    create: 'create',
    update: 'update',
    delete: 'delete',
    externalSync: 'external_sync'
}

export const externalSyncStatesNames = {
    'manual_update': 'Actualizare manuală',
    'manual_needs_update': 'Necesită actualizare manuală',
    'successful': 'Sincronizat',
    'failed': 'Eșuat'
}

export const externalSyncProviderNames = {
    'smartbill': 'SmartBill'
}

export const fieldNames = {
    name: 'nume',
    type: 'tip',
    vat_code: 'CUI/CNP',
    email: 'adresă de email',
    phone: 'telefon',
    business_registration_number: 'reg. com.',
    first_name: 'prenume',
    legal_representative: 'reprezentant legal',
    role: 'rol',
    start: 'start',
    stop: 'stop',
    task: 'sarcină',
    clients_ids: 'clienți',
    address: 'adresă',
    state: 'stare',
    file: 'fișier'
}

export const resources = {
    projects: 'proiect',
    clients: 'client',
    'contact-persons': 'persoană de contact',
    documents: 'document',
    'document-versions': 'document',
    tasks: 'sarcină',
    contracts: 'contract',
    'client-bank-accounts': 'cont bancar',
    'client-addresses': 'adresa client',
    'expenses': 'cheltuială',
    'expense-payments': 'plată',
    invoices: 'factură',
    'invoice-payments': 'plată factură',
    addendums: 'act adițional',
    empowerments: 'împuternicire',
    notes: 'notă'
}

export const getLogIcon = (log) => {
    switch (log.action) {
        case actionTypes.create:
            return {
                icon: () => <PlusIcon />,
                bgColorClass: 'bg-avo-green'
            }
        case actionTypes.delete:
            return {
                icon: () => <XMarkIcon />,
                bgColorClass: 'bg-avo-red'
            }

        case actionTypes.externalSync:
            const state = _.get(log, 'meta.state')

            switch (state) {
                case 'successful':
                    return {
                        icon: () => <DocumentArrowUpIcon />,
                        bgColorClass: 'bg-avo-green'
                    }
                case 'failed':
                    return {
                        icon: () => <DocumentArrowUpIcon />,
                        bgColorClass: 'bg-avo-red'
                    }
                case 'manual_needs_update':
                    return {
                        icon: () => <HandRaisedIcon />,
                        bgColorClass: 'bg-yellow-400'
                    }
                case 'manual_update':
                    return {
                        icon: () => <HandRaisedIcon />,
                        bgColorClass: 'bg-avo-green'
                    }
                default:
                    return {
                        icon: () => <DocumentArrowUpIcon />,
                        bgColorClass: 'bg-yellow-400'
                    }
            }

        default:
            return {
                icon: () => <PencilIcon />,
                bgColorClass: 'bg-gray-400'
            }
    }
}

const getResourceName = (log) => {
    if (resources[log.resource_name]) {
        return resources[log.resource_name]
    } else {
        console.warn('new resource', log.resource_name)

        return log.resource_name
    }
}

export const getLogText = (log) => {
    switch (log.action) {
        case actionTypes.create:
            return (
                <p className="log-text">
                    Adăugare {getResourceName(log)}: <span className='log-text-span'>{log.object_representation}</span>
                </p>
            )
        case actionTypes.update:
            return (
                <div className="multi-change-container">
                    <p className="multi-change-text">
                        Schimbări:
                    </p>
                    <ul className='log-texts-list'>
                        {map(keys(log.changes), (key, changeIdx) => (
                            <li key={changeIdx}>
                                {getUpdateText(get(log.changes, key), get(log.snapshots, key), key)}
                            </li>
                        ))}
                    </ul>
                </div>
            )

        case actionTypes.delete:
            return (
                <p className="log-text">
                    Ștergere {getResourceName(log)}: <span className='log-text-span'>{log.object_representation}</span>
                </p>
            )
        case actionTypes.externalSync:
            return (
                <p className="log-text">
                    {getExternalSyncText(log)}
                </p>
            )
        default:
            console.warn('New log action', log)
            return <p className="log-text">Modificări</p>
    }
}

const getExternalSyncText = (log) => {
    const providerName = _.get(externalSyncProviderNames, log.meta.provider)
    const error = _.get(log, 'meta.error', 'Necunoscut')

    switch (log.meta.state) {
        case 'manual_update':
            return `Sincronizare ${providerName}: s-a marcat rezolvată acțiunea manuală`
        case 'manual_needs_update':
            return `Sincronizare ${providerName}: necesită acțiune manuală`
        case 'successful':
            return `Sincronizare ${providerName} efectuată cu succes`
        case 'failed':
            return `Sincronizare ${providerName} eșuată. Eroare: ${error}`
        default:
            return 'Necunoscut'
    }
}

export const getUpdateText = (change, snapshots, fieldName) => {
    const isNameMapped = !isUndefined(fieldNames[fieldName])

    if (!isNameMapped) {
        console.warn('New changed field with name %s', fieldName, change)
    }

    const oldRawValue = isArray(change.old_value) ? join(change.old_value, ',') : change.old_value
    const newRawValue = isArray(change.new_value) ? join(change.new_value, ',') : change.new_value
    const isRelation = has(change, 'resource_name')
    const oldValue = isRelation ? join(
        map(isArray(change.old_value) ? change.old_value : [change.old_value], (item) => get(snapshots, item)), ', '
    ) : oldRawValue

    const newValue = isRelation ? join(
        map(isArray(change.new_value) ? change.new_value : [change.new_value], (item) => get(snapshots, item)), ', '
    ) : newRawValue

    if (isNull(change.old_value)) {
        return (
            <ValueAddedOrDeleted value={change.new_value} label='Adăugare' mappedFieldName={fieldNames[fieldName]} />
        )
    }
    else if (isNull(change.new_value)) {
        return (
            <ValueAddedOrDeleted value={change.old_value} label='Stergere' mappedFieldName={fieldNames[fieldName]} />
        )
    }
    else {
        return (
            <ValueChanged
                newValue={newValue} oldValue={oldValue} label='Schimbare' mappedFieldName={fieldNames[fieldName]}
            />
        )
    }
}
