import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {Action} from 'avoapp-react-common/dist/redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {
    setCurrent,
    ACTIONS as DOCUMENT_VERSIONS_ACTIONS,
    getDocumentVersion
} from 'avoapp-react-common/dist/redux/documentVersions'

import {closeModal} from './modals'

const ACTIONS = {
    GET_LAST_DOCUMENT_VERSION: new Action('GET_LAST_DOCUMENT_VERSION')
}

function* handleCreateDocumentVersionSuccess({payload: documentVersion}) {
    toast.success('Documentul a fost încărcat cu succes!')

    yield put(RESOURCES.documents.retrieve(documentVersion.document_id))
    yield put(closeModal())
}

function handleCreateDocumentVersionFail() {
    toast.error('Documentul NU a putut fi încărcat. Te rugăm să încerci din nou!')
}

function* handleGetLastDocumentVersion({payload: {documentId, params}}) {
    yield put(getDocumentVersion(documentId, 'last', params))
}

function* handleGetDocumentVersionSuccess({payload: documentVersion}) {
    yield put(setCurrent(documentVersion))
}

function* handleUpdateLastDocumentVersionSuccess() {
    toast.success('Documentul a fost actualizat cu succes!')
    yield put(closeModal())
}

function* handleDestroyDocumentSuccess({meta: {object: documentVersion}}) {
    toast.success('Versiunea documentului a fost ștearsă cu succes')

    yield put(push(`/documents/${documentVersion.document_id}/archive`))
    yield put(closeModal())
}

function handleDestroyDocumentFail() {
    toast.error('Versiunea documentului NU a putut fi ștearsă. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(ACTIONS.GET_LAST_DOCUMENT_VERSION.main, handleGetLastDocumentVersion)
    yield takeEvery(DOCUMENT_VERSIONS_ACTIONS.GET_DOCUMENT_VERSION.success, handleGetDocumentVersionSuccess)
    yield takeEvery(DOCUMENT_VERSIONS_ACTIONS.CREATE_DOCUMENT_VERSION.success, handleCreateDocumentVersionSuccess)
    yield takeEvery(DOCUMENT_VERSIONS_ACTIONS.CREATE_DOCUMENT_VERSION.fail, handleCreateDocumentVersionFail)
    yield takeEvery(DOCUMENT_VERSIONS_ACTIONS.DESTROY_DOCUMENT_VERSION.success, handleDestroyDocumentSuccess)
    yield takeEvery(DOCUMENT_VERSIONS_ACTIONS.DESTROY_DOCUMENT_VERSION.fail, handleDestroyDocumentFail)
    yield takeEvery(
        DOCUMENT_VERSIONS_ACTIONS.UPDATE_LAST_DOCUMENT_VERSION.success,
        handleUpdateLastDocumentVersionSuccess
    )
}

export const getLastDocumentVersion = (id, params = {}) => ({
    type: ACTIONS.GET_LAST_DOCUMENT_VERSION.main,
    payload: {documentId: id, params}
})