import React, {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'

import {isUndefined} from 'lodash'

import './Dropdown.scss'

export default function Dropdown({title, items, variant, size, color, fullWidth, disabled, className}) {
    return (
        <Menu as="div" className="avo-dropdown-menu">
            <div>
                <Menu.Button
                    className={
                        'avo-dropdown-menu-button ' +
                        `${variant || 'contained'} ` +
                        `${size || 'medium'} ` +
                        `${color || 'primary'} ` +
                        `${fullWidth ? 'full-width' : ''} ` +
                        `${className || ''}`
                    }
                    disabled={disabled}
                >
                    {title}
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="avo-dropdown-menu-items-container">
                    <div className="py-1">
                        {items.map((item, itemIdx) => (
                            <Menu.Item key={itemIdx}>
                                {!isUndefined(item.customContent) ? (
                                    item.customContent()
                                ) : (
                                    <div onClick={item.onClick} className='avo-dropdown-menu-item'>
                                        {item.title}
                                    </div>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
