import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {values as _values, debounce} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {createDocumentFromTemplate} from '../../redux/documents'
import {modalTypes} from '../../redux/modals'

import {debounceWait} from '../../utils/constants'
import {documentsSchema} from '../../assets/validations'

import {Modal} from '../Modal'
import {Input} from '../Input'
import {Select} from '../Select'
import {Button} from '../Button'
import {ErrorsList} from '../ErrorComponents'
import {RequiredFieldsText} from '../RequiredFieldsText'

import './CreateDocumentModal.scss'
import {documentTemplateTypes} from '../../pages/ContractAdd/constants'

export class CreateDocumentModal extends Component {
    constructor() {
        super()

        this.state = {
            projectsQuery: ''
        }
    }

    componentDidUpdate(prevProps ) {
        const {open, listDocumentTemplates, selectedEntityID} = this.props

        if(open && prevProps.open !== open) {
            this.handleFetchProjects()

            let params = {
                entity_id: selectedEntityID,
                type: documentTemplateTypes.find((type) => type.value === 'archive').value
            }
            listDocumentTemplates(params)
        }
    }

    handleFetchProjects = (query = this.state.projectsQuery) => {
        const {searchProjects} = this.props

        searchProjects(query)
    }

    debounceSearchProjects = debounce(this.handleFetchProjects, debounceWait)

    handleChangeProjectsSearchField = (value) => {
        this.setState({projectsQuery: value})
        this.debounceSearchProjects(value)
    }
    render() {
        const {
            open,
            projectsIds,
            isLoading,
            nonFieldErrors,
            fieldErrors,
            documentTemplates,
            isLoadingDocumentTemplates,
            projects,
            isLoadingProjects,
            selectedEntityID,
            createDocumentFromTemplate
        } = this.props

        return (
            <Modal open={open} title='Crează document'>
                <div className="create-document-modal-content-container">
                    <Formik
                        initialValues={{
                            projectsIds: projectsIds || [],
                            entityId: selectedEntityID,
                            name: '',
                            documentTemplateId: null
                        }}
                        validationSchema={documentsSchema.fromTemplate}
                        onSubmit={(values) => {
                            const documentData = {
                                ...values,
                                documentTemplateId: values.documentTemplateId.id,
                                projectsIds: values.projectsIds.map((project) =>
                                    typeof project === 'string' ? project : project.id
                                )
                            }

                            createDocumentFromTemplate(documentData)
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            isValid
                        }) => (
                            <Form className='create-document-form'>
                                <ErrorsList errors={nonFieldErrors} />
                                {!projectsIds && (
                                    <Select
                                        label='Proiecte'
                                        value={values.projectsIds}
                                        placeholder='Alege un proiect'
                                        options={projects}
                                        onChange={(option) => setFieldValue('projectsIds', option)}
                                        onInputChange={(value) => this.handleChangeProjectsSearchField(value)}
                                        onBlur={handleBlur('projectsIds')}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        name='projectsIds'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.projectsIds}
                                        loading={isLoadingProjects}
                                        isMulti
                                    />
                                )}
                                <Input
                                    label='Nume document*'
                                    value={values.name}
                                    onChange={handleChange('name')}
                                    onBlur={handleBlur('name')}
                                    name='name'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.name}
                                    fullWidth
                                />
                                <Select
                                    label='Selectează un template*'
                                    value={values.documentTemplate}
                                    options={documentTemplates}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(e) => setFieldValue('documentTemplateId', e)}
                                    onBlur={handleBlur('documentTemplateId')}
                                    name='documentTemplateId'
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.documentTemplateId}
                                    loading={isLoadingDocumentTemplates}
                                    isClearable
                                    fullWidth
                                />
                                <RequiredFieldsText />
                                <Button
                                    title='Salvează'
                                    onClick={handleSubmit}
                                    disabled={!isValid}
                                    loading={isLoading}
                                    fullWidth
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.CREATE_DOCUMENT,
    isLoading: state.documents.isLoading,
    fieldErrors: state.documents.fieldErrors,
    nonFieldErrors: state.documents.nonFieldErrors,
    documentTemplates: _values(state.documentTemplates.data),
    isLoadingDocumentTemplates: state.documentTemplates.isLoading,
    projects: _values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    searchProjects: (search) => dispatch(RESOURCES_V1.projects.search(search)),
    listDocumentTemplates: (params) => dispatch(RESOURCES.documentTemplates.list(params)),
    createDocumentFromTemplate: (data) => dispatch(createDocumentFromTemplate(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocumentModal)