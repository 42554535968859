import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal} from './modals'

const {documentTemplates: resource} = RESOURCES

function* handleCreateDocumentTemplateSuccess() {
    toast.success('Șablonul a fost creat cu succes')
    yield put(closeModal())
}

function handleDocumentTemplateFail({payload: {file}}) {
    toast.error(<div>
        A aparut o eroare la crearea șablonului
        <br/>Vă rugăm să contactați serviciul de asistență
    </div>)
}

function handleUpdateDocumentTemplateSuccess() {
    toast.success('Șablonul a fost editat cu succes')
}

function* handleDestroyDocumentTemplateSuccess() {
    toast.success('Șablonul a fost șters cu succes')

    yield put(push('/settings/documents/templates'))
    yield put(closeModal())
}

function handleDestroyDocumentTemplateFail() {
    toast.error('Șablonul NU a putut fi șters. Te rugăm să încerci din nou!')
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateDocumentTemplateSuccess)
    yield takeEvery(resource.actions.create.fail, handleDocumentTemplateFail)
    yield takeEvery(resource.actions.update.success, handleUpdateDocumentTemplateSuccess)
    yield takeEvery(resource.actions.update.fail, handleDocumentTemplateFail)
    yield takeEvery(resource.actions.destroy.success, handleDestroyDocumentTemplateSuccess)
    yield takeEvery(resource.actions.destroy.fail, handleDestroyDocumentTemplateFail)
}
