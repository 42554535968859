import * as Yup from 'yup'

export const validationSchema = {
    documentsList: Yup.object().shape({
        name: Yup.string().label('Nume').required('Numele este obligatoriu!'),
        projectsIds: Yup.array()
            .label('Proiecte')
            .min(1, 'Selectați cel puțin un proiect')
            .required('Selectați cel puțin un proiect')
    }),
    fromTemplate: Yup.object().shape({
        name: Yup.string().label('Nume').required('Numele este obligatoriu!'),
        documentTemplateId: Yup.object()
            .label('Template document')
            .nullable()
            .required('Template-ul document e obligatoriu!')
    })
}
