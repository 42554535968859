import React from 'react'

interface ProgressPieProps {
    percentage: number
}

const ProgressPie: React.FC<ProgressPieProps> = ({percentage}) => {
    const radius = 50  // Radius of the circle
    const strokeWidth = 10  // Stroke width to cover the center
    const circumference = 2 * Math.PI * radius  // Circumference of the circle
    const strokeDashoffset = circumference - (percentage / 100) * circumference

    return (
        <svg width="25" height="25" viewBox="0 0 120 120">
            <circle
                cx="60"
                cy="60"
                r={radius}
                stroke="#e6e6e6"
                strokeWidth={strokeWidth}
                fill="none"
            />
            <circle
                cx="60"
                cy="60"
                width="0px"
                height="0px"
                r={radius}
                stroke="rgb(49 171 98 / 1)"
                strokeWidth={strokeWidth}
                fill="none"
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                transform="rotate(-90 60 60)"
            />
        </svg>
    )
}

export default ProgressPie
