import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'

import {connect} from 'react-redux'
import {modalTypes} from '../../../../../redux/modals'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {documentSeriesSchema} from '../../../../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase} from '../../../../../utils'

import {Modal} from '../../../../../components/Modal'
import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {Checkbox} from '../../../../../components/Checkbox'
import {ErrorsList} from '../../../../../components/ErrorComponents'

import './DocumentSeriesForms.scss'

export const AddDocumentSeriesModal = ({
    open,
    // TODO: use options for initialType
    initialType,
    selectedEntityID,
    fieldErrors,
    nonFieldErrors,
    isLoading,
    documentSeriesOptions,
    getDocumentSeriesOptions,
    createDocumentSeries
}) => {
    const types = getFieldOptions(documentSeriesOptions, 'type')

    useEffect(() => {
        getDocumentSeriesOptions()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal open={open} title='Adaugă serie'>
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    type: initialType || null,
                    name: '',
                    label: '',
                    start: '',
                    stop: '',
                    default: false
                }}
                validationSchema={documentSeriesSchema}
                validateOnMount
                onSubmit={(values) => {
                    const documentSeriesData = {
                        ...objectKeysToSnakeCase(values),
                        entity_id: selectedEntityID,
                        type: values.type.value
                    }

                    createDocumentSeries(documentSeriesData)
                }}
            >
                {({handleChange, handleBlur, setFieldValue, handleSubmit, values, errors, touched, isValid}) => (
                    <Form className='add-document-series-form-container'>
                        <Select
                            label='Tip'
                            value={values.type}
                            options={types}
                            onChange={(e) => setFieldValue('type', e)}
                            onBlur={handleBlur('type')}
                            name='type'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.type}
                            fullWidth
                        />
                        <div className="split-row-3">
                            <Input
                                label='Prefix'
                                value={values.name}
                                onChange={handleChange('name')}
                                onBlur={handleBlur('name')}
                                name='name'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.name}
                                fullWidth
                            />
                            <Input
                                label='Eticheta'
                                value={values.label}
                                onChange={handleChange('label')}
                                onBlur={handleBlur('label')}
                                name='label'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.label}
                                fullWidth
                            />
                            <Input
                                label='Start'
                                value={values.start}
                                onChange={handleChange('start')}
                                onBlur={handleBlur('start')}
                                name='start'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.start}
                                fullWidth
                            />
                            <Input
                                label='Stop'
                                value={values.stop}
                                onChange={handleChange('stop')}
                                onBlur={handleBlur('stop')}
                                name='stop'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.stop}
                                fullWidth
                            />
                        </div>
                        <Checkbox
                            label='Serie principala'
                            checked={values.default}
                            onChange={(e) => setFieldValue('default', e.target.checked)}
                        />
                        <Button
                            title='Salvează'
                            type='submit'
                            onClick={handleSubmit}
                            loading={isLoading}
                            disabled={!isValid}
                            fullWidth
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.ADD_DOCUMENT_SERIES,
    selectedEntityID: state.localConfigs.selectedEntityID,
    documentSeriesOptions: state.documentSeries.options,
    fieldErrors: state.documentSeries.fieldErrors,
    nonFieldErrors: state.documentSeries.nonFieldErrors,
    isLoading: state.documentSeries.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    getDocumentSeriesOptions: () => dispatch(RESOURCES.documentSeries.getOptions()),
    createDocumentSeries: (documentSeriesData) => dispatch(RESOURCES.documentSeries.create(documentSeriesData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentSeriesModal)