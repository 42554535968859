import React from 'react'
import {connect} from 'react-redux'

export const Profile = () => {
    return (
        <div className="page-info">
            <p>In curs de dezvoltare.</p>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)