import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal, modalTypes} from './modals'
import {datatablePageSize} from '../utils/datatables'
import {generateFiltersForAPI} from './filters/utils'
import {getExpenseBalance} from 'avoapp-react-common/dist/redux/expenses'

const {expensePayments: resource} = RESOURCES

function* handleCreateExpensePaymentSuccess() {
    toast.success('Plata a fost adaugată cu succes.')

    const openModal = yield select((state) => state.modals)
    const filters = yield select((state) => state.filters.expenses)
    const appliedFilters = generateFiltersForAPI(filters)
    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    const currentProject = yield select((state) => state.projects.currentProject)

    if(openModal.type === modalTypes.ADD_EXPENSE_PAYMENT) {
        yield put(closeModal())
    }
    yield put(RESOURCES.expenses.list(
        {
            ...appliedFilters,
            project_id: currentProject.id,
            entity_id: selectedEntityID,
            search: null,
            page: 1,
            page_size: datatablePageSize
        },
        // overwriteData
        true
    ))
    yield put(getExpenseBalance({entity_id: selectedEntityID, project_id: currentProject.id}))
}

function* handleDeleteExpensePaymentSuccess() {
    toast.success('Plata a fost ștearsă cu succes.')

    const openModal = yield select((state) => state.modals)
    const filters = yield select((state) => state.filters.expenses)
    const appliedFilters = generateFiltersForAPI(filters)
    const selectedEntityID = yield select((state) => state.localConfigs.selectedEntityID)
    const currentProject = yield select((state) => state.projects.currentProject)

    if(openModal.type === modalTypes.DELETE_EXPENSE_PAYMENT) {
        yield put(closeModal())
    }

    yield put(RESOURCES.expenses.list(
        {
            ...appliedFilters,
            project_id: currentProject.id,
            entity_id: selectedEntityID,
            search: null,
            page: 1,
            page_size: datatablePageSize
        },
        // overwriteData
        true
    ))
    yield put(getExpenseBalance({entity_id: selectedEntityID, project_id: currentProject.id}))
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateExpensePaymentSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDeleteExpensePaymentSuccess)
}
