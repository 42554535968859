import React from 'react'
import {Link} from 'react-router-dom'

import _ from 'lodash'

import {connect} from 'react-redux'

import {Button} from '../Button'
import {ErrorComponent} from '../ErrorComponents/ErrorsList'

export const InvoiceTotalErrors = ({hasTotalErrors}) => {
    if(hasTotalErrors) {
        return (
            <div className="mt-4 flex flex-col items-center gap-2">
                <ErrorComponent
                    message='Total invalid! Incearcă din nou! Daca eroarea persistă rugăm sa ne contactezi!'
                />
                <Link to='support'>
                    <Button title='Scrie un tichet.' variant='text' />
                </Link>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const invoiceErros = state.invoices.errors

    const getHasTotalErrors = () => {
        const totalErrors = _.filter(Object.keys(invoiceErros), (k) => _.includes(_.lowerCase(k), 'total'))

        return !_.isEmpty(totalErrors)
    }

    return {
        hasTotalErrors: getHasTotalErrors()
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTotalErrors)