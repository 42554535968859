import React, {useCallback, useEffect} from 'react'
import {Link} from 'react-router-dom'

import {ClockIcon, PlayIcon, StopIcon} from '@heroicons/react/24/outline'

import {isToday} from 'date-fns'
import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../redux/modals'
import {stopRunningTaskTimeLog} from '../../redux/taskTimeLogs'

import {toApiDateFormat, toApiTimeFormat} from '../../utils'

import {Button} from '../Button'
import {CheckTaskTimeLogModal} from '../CheckTaskTimeLogModal'

import {SelectTaskModal, Timer} from './partials'

import AutoStoppedTaskTimeLogModal from '../AutoStoppedTaskTimeLogModal/AutoStoppedTaskTimeLogModal'
import './TimeTracker.scss'

export const TimeTracker = ({
    currentTimeLog,
    selectedEntityID,
    openModal,
    createTimeLog,
    stopTaskTimeLog
}) => {
    const handleStartTimer =useCallback(() => {
        createTimeLog({
            entity_id: selectedEntityID,
            date: toApiDateFormat(new Date()),
            start: toApiTimeFormat(new Date())
        })
    }, [createTimeLog, selectedEntityID])

    const handleStopTimer = useCallback((hasAutoStopped = false) => {
        stopTaskTimeLog(hasAutoStopped)
    }, [stopTaskTimeLog])

    useEffect(() => {
        // The task should stop automatically if it was started in any other day than today
        if(
            _.isNil(currentTimeLog?.stop) &&
             !_.isNil(currentTimeLog?.date) &&
             !isToday(new Date(currentTimeLog?.date))
        ) {

            handleStopTimer(true)
        }
    }, [currentTimeLog.date, currentTimeLog?.stop, handleStopTimer, openModal])

    return (
        <div className='time-tracker-container'>
            <div className="side-container">
                <Link to='/tasks/time-logs'>
                    <ClockIcon className='side-icon' />
                </Link>
            </div>
            {!_.isEmpty(currentTimeLog) && _.isNil(currentTimeLog.stop) ? (
                <div className='center-container' onClick={() => openModal(modalTypes.SELECT_TASK)}>
                    <div className='task-timer-container'>
                        <div className='task-container'>
                            {!_.isNil(currentTimeLog.task) ? (
                                <p className='task-title'>{currentTimeLog.task.title}</p>
                            ) : (
                                <p className='select-task'>Selectează sarcină</p>
                            )}
                        </div>
                        <div className='timer-container'>
                            <Timer />
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="side-container">
                {_.isEmpty(currentTimeLog) || !_.isNil(currentTimeLog.stop) ? (
                    <Button title='Începe pontaj' icon={() => <PlayIcon />} onClick={handleStartTimer}/>
                ) : (
                    <StopIcon className='side-icon' onClick={() => handleStopTimer(false)} />
                )}
            </div>
            <SelectTaskModal />
            <CheckTaskTimeLogModal />
            <AutoStoppedTaskTimeLogModal />
        </div>
    )
}

const mapStateToProps = (state) => ({
    currentTimeLog: state.taskTimeLogs.currentTaskTimeLog,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    createTimeLog: (data) => dispatch(RESOURCES.taskTimeLogs.create(data)),
    stopTaskTimeLog: (hasAutoStopped = false) => dispatch(stopRunningTaskTimeLog(hasAutoStopped))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeTracker)