import {useEffect} from 'react'
import {useFormikContext} from 'formik'

import {values as _values, find, isEmpty} from 'lodash'

import {connect} from 'react-redux'

export const AutoSelectRequester = ({contactPersons, contactPerson, handleResetContactPersonsQuery}) => {
    const {setFieldValue} = useFormikContext()

    useEffect(() => {
        if(!isEmpty(contactPerson)) {
            setFieldValue(
                'requesterId',
                find(contactPersons, (person) => parseInt(person.id) === parseInt(contactPerson.id)) || null
            )

            handleResetContactPersonsQuery()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactPerson])

    return null
}

const mapStateToProps = (state) => ({
    contactPersons: _values(state.contactPersons.searchData),
    contactPerson: state.contactPersons.currentContactPerson
})

export default connect(mapStateToProps)(AutoSelectRequester)