import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().label('Prenume').required('Prenumele este obligatoriu!'),
    lastName: Yup.string().label('Nume').required('Numele este obligatoriu!'),
    roleId: Yup.object().label('Rol').nullable(),
    email: Yup.string().label('Email'),
    legalRepresentative: Yup.boolean().label('Reprezentant legal'),
    receiveBpiNotifications: Yup.boolean().label('Notificări Monitor BPI'),
    receiveMdNotifications: Yup.boolean().label('Notificări Monitor Dosare')
})
