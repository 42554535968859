import React from 'react'

import {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'

function DeleteProformaModal({open, selectedProforma, isLoading, deleteProforma, closeModal}) {
    return (
        <Modal open={open && !isEmpty(selectedProforma)} title='Șterge factură fiscală'>
            <div className='delete-modal'>
                <p className='message'>Ești sigur că vrei să ștergi această factură proformă?</p>
                <div className="buttons-container">
                    <Button
                        title='Nu, renunță'
                        onClick={closeModal}
                        variant='outlined'
                        loading={isLoading}
                        color='gray'
                    />
                    <Button
                        title='Da, șterge'
                        onClick={() => deleteProforma(selectedProforma)}
                        loading={isLoading}
                        color='red'
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_PROFORMA,
    isLoading: state.proformas.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteProforma: (selectedProforma) => dispatch(RESOURCES.proformas.destroy(selectedProforma))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProformaModal)
