export const calendarViewTypes = {
    WEEK_VIEW: 'week',
    DAY_VIEW: 'day',
    MONTH_VIEW: 'month'
}

export const prefix = '/tasks'

export const actionIds = {
    TASKS_LIST: 'list',
    TIME_LOGS: 'time-logs'
}

export const actions = {
    [actionIds.TASKS_LIST]: {
        id: actionIds.TASKS_LIST,
        label: 'Sarcini'
    },
    [actionIds.TIME_LOGS]: {
        id: actionIds.TIME_LOGS,
        label: 'Pontaj'
    }
}