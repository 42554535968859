import {ViewClientLink} from './partials'

export const clientsColumns = [
    {
        Header: 'Nume',
        accessor: 'name'
    },
    {
        Header: 'CUI/CNP',
        accessor: 'vat_code'
    },
    {
        Header: 'Loc./Oraș',
        accessor: 'city',
        Cell: ({row}) => row.original.primary_address?.locality ? row.original.primary_address?.locality : ''
    },
    {
        Header: 'Județ',
        accessor: 'county',
        Cell: ({row}) => row.original.primary_address?.county ? row.original.primary_address?.county : ''
    },
    {
        Header: 'Țară',
        accessor: 'country',
        Cell: ({row}) => row.original.primary_address?.country || ''
    },
    {
        Header: '',
        accessor: 'id',
        Cell: ({value}) => <ViewClientLink to={`/clients/${value}`} />,
        disableSortBy: true
    }
]
